import React from 'react';
import { Route } from 'react-router-dom';
import FileManagerListContainer from '../views/FileManagerListContainer';

const routes = [
  {
    path: "/file-manager",
    name: "File Manager List",
    component: FileManagerListContainer,
    exact: true,
  },
  {
    path: "/file-manager/:directory", // @todo: add directory to accept multiple directories
    name: "File Manager List Details",
    component: FileManagerListContainer,
    exact: true,
  },
];

function fileManagerRoutes() {
  {
    return routes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}

export default fileManagerRoutes;
