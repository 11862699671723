import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { GetParentCategoriesList } from "../../../master/OptionData/ParentCategory/ParentCategoryAction/ParentCategoryOptionListAction";
import PaginationLaravel from "../../../master/pagination/PaginationLaravel";
import LoadingSpinner from "../../../master/spinner/LoadingSpinner";
import { GetCategoriesList } from "../../_redux/actions/CategoriesAction";
// import CategoryFilter from "./CategoryFilter";
import CategoryListShortDetail from "./CategoryListShortDetail";

const CategoryList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.categories.isLoading);
  const categoriesList = useSelector((state) => state.categories.categoriesList);
  const categoriesPaginatedList = useSelector((state) => state.categories.categoriesPaginatedList);
  const { searchText, categoryType, selectedParentId } = useSelector(state => state.categories);
  
  useEffect(() => {
    dispatch(GetCategoriesList(categoriesPaginatedList.current_page, searchText, categoryType.value, selectedParentId));
  }, [searchText, categoryType.value, dispatch, categoriesPaginatedList.current_page, selectedParentId]);

  const changePage = (data) => {
      dispatch(GetCategoriesList(data.page, searchText, categoryType.value, selectedParentId));
  };

  return (
    <div className="card-body">
      {
        isLoading ? <div className="d-flex justify-content-center"> <LoadingSpinner text="Loading categories list...." /> </div> : ''
      }

      <div className="react-bootstrap-table table-responsive">
        <table className="table table table-head-custom table-vertical-center">
          <thead>
            <tr>
              <td>Sl</td>
              <td>Name</td>
              <td>Logo</td>
              <td>Banner</td>
              <td>Code</td>
              <td>Navbar</td>
              <td>Homepage</td>
              <td>Status</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {categoriesList &&
              categoriesList.map((item, index) => (
                <CategoryListShortDetail key={index} index={index} item={item} newIndex={categoriesPaginatedList.from} />
              ))}
            {categoriesList == null && (
              <p className="text-danger text-center">No Data Found</p>
            )}
          </tbody>
          <tfoot></tfoot>
        </table>
        {!isLoading && categoriesList && categoriesList.length === 0 && (
          <div className="alert alert-warning mt-5">
            Sorry ! Products Not Found.
          </div>
        )}
        <PaginationLaravel
          changePage={changePage}
          data={categoriesPaginatedList}
        />
      </div>
    </div>
  );
};

export default CategoryList;
