import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';

import { UpdateUnits, emptyUnitMessages } from '../_redux/actions/UnitsAction';

const UnitsEdit = withRouter(({ history, props }) => {
	const { register, handleSubmit, errors } = useForm();
	const dispatch = useDispatch();

	const { editStatus, editMessage, isLoading } = useSelector((state) => state.units);

	const { id, actual_name, short_name } = props.location.state.units

	const [state, setState] = React.useState({
		id: id,
		actual_name: actual_name,
		short_name: short_name,
	});

	const handleChange = ({ currentTarget: input }) => {
		const cloneObj = { ...state };
		cloneObj[input.name] = input.value;
		setState(cloneObj);
	};

	useEffect(() => {

		if (editStatus && editMessage.length > 0) {
			dispatch(emptyUnitMessages());
			history.push('/units/list');
		}
	}, [editStatus, editMessage]);

	const onSubmit = async (e) => {
		dispatch(UpdateUnits(state));
	};

	return (
		<>
			<div className="container">
				<div className="card card-custom gutter-b">
					<div className="card-header">
						<div className="card-title">
							<h3 className="card-label">Edit Unit</h3>
						</div>
					</div>
					<div className="card-body">
						<form
							className="form form-label-right"
							onSubmit={handleSubmit(onSubmit)}
							method="post"
						>
							<div className="form-group row">
								<div className="col-lg-4">
									<label className="form-label mt-2"> Unit Name</label>
									<Form.Control
										type="text"
										placeholder="Enter Unit name, eg: Piece"
										name="actual_name"
										className="fromStyle"
										onChange={handleChange}
										value={state.actual_name}
										ref={register({
											required: true,
											maxLength: 100,
										})}
									/>
									<div className="inputError margin-minus-8">
										{errors.actual_name &&
											errors.actual_name.type === 'required' &&
											"Please give unit name"}
									</div>
								</div>

								<div className="col-lg-4">
									<label className="form-label mt-2">Short Name</label>
									<Form.Control
										type="text"
										placeholder="Enter Short Name, eg: pc"
										name="short_name"
										className="fromStyle"
										value={state.short_name}
										onChange={handleChange}
										ref={register({
											required: false,
											maxLength: 100,
										})}
									/>
									<div className="inputError margin-minus-8">
										{errors.short_name &&
											errors.short_name.type === 'required' &&
											"Please give unit short name"}
									</div>
								</div>
							</div>

							<div className="form-group row">
								<div className="col-sm-10">
									<a
										onClick={() => {
											history.push('/units/list');
										}}
									>
										<button type="button" className="btn btn-secondary btn-lg mr-2">
											Back
										</button>
									</a>
									{isLoading &&
										<button type="submit" className="btn btn-primary btn-lg" disabled={true} >
											<span>Saving</span>
											<span className="ml-3 spinner spinner-white"></span>
										</button>
									}
									{!isLoading &&
										<button type="submit" className="btn btn-primary btn-lg">
											<span>Save</span>
										</button>
									}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
});

export default UnitsEdit;
