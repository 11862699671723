import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import { RHFInput } from 'react-hook-form-input';
import { deletePreviewImage, getWebsiteDetails, handleChangeWebsiteInput, updateWebsite } from '../../_redux/actions/WebsiteInfoAction';
import SimpleEditor from '../../../master/components/text-editor/SimpleEditor';
import LoadingSpinner from '../../../master/spinner/LoadingSpinner';

const WebsiteInfo = () => {

    const dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue } = useForm();
    const { websiteInput, isLoading, isSubmitting } = useSelector((state) => state.WebsiteInfoReducer);
    const [logoPreview, setLogoPreview] = useState(null)
    const [bannerPreview, setBannerPreview] = useState(null)
    const imagePreviewURL = `${process.env.REACT_APP_API_PUBLIC_URL}images/website/`

    const handleChangeTextInput = (name, value, e = null) => {
        dispatch(handleChangeWebsiteInput(name, value, e))
    }

    useEffect(() => {
        dispatch(getWebsiteDetails());
    }, []);

    useEffect(() => {
        if (typeof websiteInput.logo !== "undefined" && websiteInput.logo !== null) {
            setLogoPreview(websiteInput.logoPreviewUrl);
        } else {
            setLogoPreview(`${imagePreviewURL}${websiteInput.logoPreviewUrl}`);
        }

        if (typeof websiteInput.banner !== "undefined" && websiteInput.banner !== null) {
            setBannerPreview(websiteInput.bannerPreviewUrl);
        } else {
            setBannerPreview(`${imagePreviewURL}${websiteInput.bannerPreviewUrl}`);
        }

        setValue("theme", websiteInput.websiteTheme);
    }, [websiteInput])

    const websiteTheme = [
        { label: "Light", value: "light" },
        { label: "Dark", value: "dark" },
        { label: "Primary", value: "primary" },
    ];

    const onSubmit = (e) => {
        dispatch(updateWebsite(websiteInput));
    };

    return (
        <div className="card-body">
            <form
                className="form form-label-right"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                autoComplete="off"
            >
                {
                    isLoading && (
                        <LoadingSpinner text={"Loading website edit information...."} />
                    )
                }
                {
                    !isLoading && (
                        <div className="card-body">
                            <div className="form-group row">
                                {/**for business logo & banner */}
                                <div className="col-lg-3 border-right p-1">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="text-center mb-2" title="Remove">
                                                {/* <div className="preview-delete-icon"><i className="fa fa-times text-danger" onClick={() => dispatch(deletePreviewImage('logo'))}></i></div> */}
                                                {
                                                    websiteInput.logoPreviewUrl !== null ?
                                                        <img src={logoPreview} className="img businessPreview img-thumbnail" alt="" />
                                                        :
                                                        <img src="/media/default/icons/user-male.png" className="img businessPreview img-thumbnail" alt="" />
                                                }
                                            </div>

                                            <Form.Control
                                                type="file"
                                                placeholder="Choose Logo"
                                                name="logo"
                                                onChange={(e) => handleChangeTextInput('logo', e.target.files[0], e)}
                                                className="fromStyle"
                                                ref={register({
                                                    required: false,
                                                    maxLength: 100,
                                                })}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label mt-5">Banner</label>
                                            {
                                                websiteInput.bannerPreviewUrl !== null ?
                                                    <div className="" title="Remove">
                                                        <div className="preview-delete-icon pointer"><i className="fa fa-times text-danger" onClick={() => dispatch(deletePreviewImage('banner'))}></i></div>
                                                        <img src={bannerPreview} className="img bannerPreview img-thumbnail" alt="" />
                                                    </div> :
                                                    <img src="/media/default/icons/bannerThumbnail.png" className="img bannerPreview img-thumbnail" alt="" />
                                            }
                                            <Form.Control
                                                type="file"
                                                placeholder="Choose Banner"
                                                name="banner"
                                                onChange={(e) => handleChangeTextInput('banner', e.target.files[0], e)}
                                                className="fromStyle mt-3"
                                                ref={register({
                                                    required: false,
                                                    maxLength: 100,
                                                })}
                                            />

                                        </div>
                                    </div>
                                </div>

                                {/**for business others information */}
                                <div className="col-lg-9">
                                    <div className="border p-2">
                                        <h3>Basic Information</h3>
                                        <div className="form-group row">
                                            <div className="col-lg-6">
                                                <label className="form-label">Website Name</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Website Name"
                                                    name="name"
                                                    className="fromStyle"
                                                    value={websiteInput.name}
                                                    onChange={(e) => handleChangeTextInput('name', e.target.value)}
                                                    ref={register({
                                                        required: true,
                                                        maxLength: 100,
                                                    })}
                                                />

                                                <div className="inputError margin-minus-8">
                                                    {errors.name &&
                                                        errors.name.type === 'required' &&
                                                        "Website name can't be blank"}
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <label className="form-label mt-2">Website Theme</label>
                                                <RHFInput
                                                    as={<Select options={websiteTheme} />}
                                                    name="theme"
                                                    register={register}
                                                    value={websiteInput.websiteTheme}
                                                    placeholder="Select Theme"
                                                    rules={{ required: true }}
                                                    onChange={(option) => (
                                                        handleChangeTextInput("theme", option.value),
                                                        handleChangeTextInput("websiteTheme", option)
                                                    )}
                                                    setValue={setValue}
                                                />
                                                <div className="inputError margin-minus-8">
                                                    {errors.theme &&
                                                        errors.theme.type === 'required' &&
                                                        "Website theme can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label">Website Address</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="website link address"
                                                    name="address"
                                                    className="fromStyle"
                                                    value={websiteInput.address}
                                                    onChange={(e) => handleChangeTextInput('address', e.target.value)}
                                                    ref={register({
                                                        required: true,
                                                        maxLength: 100,
                                                    })}
                                                />

                                                <div className="inputError margin-minus-8">
                                                    {errors.address &&
                                                        errors.address.type === 'required' &&
                                                        "Website address can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label">Email</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter email"
                                                    name="email"
                                                    className="fromStyle"
                                                    value={websiteInput.email}
                                                    onChange={(e) => handleChangeTextInput('email', e.target.value)}
                                                    ref={register({
                                                        required: true,
                                                        maxLength: 100,
                                                    })}
                                                />

                                                <div className="inputError margin-minus-8">
                                                    {errors.email &&
                                                        errors.email.type === 'required' &&
                                                        "Email can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label">Phone</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter phone number"
                                                    name="phone_no"
                                                    className="fromStyle"
                                                    value={websiteInput.phone_no}
                                                    onChange={(e) => handleChangeTextInput('phone_no', e.target.value)}
                                                    ref={register({
                                                        required: true,
                                                        maxLength: 100,
                                                    })}
                                                />

                                                <div className="inputError margin-minus-8">
                                                    {errors.phone_no &&
                                                        errors.phone_no.type === 'required' &&
                                                        "Phone number can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label">Footer Text</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter phone number"
                                                    name="footer_text"
                                                    className="fromStyle"
                                                    value={websiteInput.footer_text}
                                                    onChange={(e) => handleChangeTextInput('footer_text', e.target.value)}
                                                    ref={register({
                                                        required: true,
                                                        maxLength: 100,
                                                    })}
                                                />

                                                <div className="inputError margin-minus-8">
                                                    {errors.footer_text &&
                                                        errors.footer_text.type === 'required' &&
                                                        "Footer text can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label">Seller Panel URL</label>
                                                <Form.Control
                                                    type="url"
                                                    placeholder="Enter Seller Panel URL"
                                                    name="seller_panel_url"
                                                    className="fromStyle"
                                                    value={websiteInput.seller_panel_url}
                                                    onChange={(e) => handleChangeTextInput('seller_panel_url', e.target.value)}
                                                    ref={register({
                                                        required: true,
                                                        maxLength: 100,
                                                    })}
                                                />

                                                <div className="inputError margin-minus-8">
                                                    {errors.seller_panel_url &&
                                                        errors.seller_panel_url.type === 'required' &&
                                                        "Seller Panel URL can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label">Registered Name</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Registered Name"
                                                    name="registered_name"
                                                    className="fromStyle"
                                                    value={websiteInput.registered_name}
                                                    onChange={(e) => handleChangeTextInput('registered_name', e.target.value)}
                                                    ref={register({
                                                        required: true,
                                                        maxLength: 100,
                                                    })}
                                                />

                                                <div className="inputError margin-minus-8">
                                                    {errors.registered_name &&
                                                        errors.registered_name.type === 'required' &&
                                                        "Registered Name can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label">Trade License No</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Trade License No"
                                                    name="trade_license_no"
                                                    className="fromStyle"
                                                    value={websiteInput.trade_license_no}
                                                    onChange={(e) => handleChangeTextInput('trade_license_no', e.target.value)}
                                                    ref={register({
                                                        required: false,
                                                        maxLength: 100,
                                                    })}
                                                />

                                                <div className="inputError margin-minus-8">
                                                    {errors.trade_license_no &&
                                                        errors.trade_license_no.type === 'required' &&
                                                        "Trade License No can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label">Vat Register No</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Vat Register No"
                                                    name="vat_reg_no"
                                                    className="fromStyle"
                                                    value={websiteInput.vat_reg_no}
                                                    onChange={(e) => handleChangeTextInput('vat_reg_no', e.target.value)}
                                                    ref={register({
                                                        required: false,
                                                        maxLength: 100,
                                                    })}
                                                />

                                                <div className="inputError margin-minus-8">
                                                    {errors.vat_reg_no &&
                                                        errors.vat_reg_no.type === 'required' &&
                                                        "Vat Register No can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label">TIN No</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter TIN No"
                                                    name="tin"
                                                    className="fromStyle"
                                                    value={websiteInput.tin}
                                                    onChange={(e) => handleChangeTextInput('tin', e.target.value)}
                                                    ref={register({
                                                        required: false,
                                                        maxLength: 100,
                                                    })}
                                                />

                                                <div className="inputError margin-minus-8">
                                                    {errors.tin &&
                                                        errors.tin.type === 'required' &&
                                                        "TIN No can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label">Playstore App URL</label>
                                                <Form.Control
                                                    type="url"
                                                    placeholder="Enter Playstore App URL"
                                                    name="app_playstore_link"
                                                    className="fromStyle"
                                                    value={websiteInput.app_playstore_link}
                                                    onChange={(e) => handleChangeTextInput('app_playstore_link', e.target.value)}
                                                    ref={register({
                                                        required: false,
                                                        maxLength: 100,
                                                    })}
                                                />

                                                <div className="inputError margin-minus-8">
                                                    {errors.app_playstore_link &&
                                                        errors.app_playstore_link.type === 'required' &&
                                                        "Playstore App URL can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label">Appstore App URL</label>
                                                <Form.Control
                                                    type="url"
                                                    placeholder="Enter Appstore App URL"
                                                    name="app_appstore_link"
                                                    className="fromStyle"
                                                    value={websiteInput.app_appstore_link}
                                                    onChange={(e) => handleChangeTextInput('app_appstore_link', e.target.value)}
                                                    ref={register({
                                                        required: false,
                                                        maxLength: 100,
                                                    })}
                                                />

                                                <div className="inputError margin-minus-8">
                                                    {errors.app_appstore_link &&
                                                        errors.app_appstore_link.type === 'required' &&
                                                        "Appstore App URL can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mt-2">
                                                <label className="form-label">Description</label>
                                                <SimpleEditor
                                                    name="description"
                                                    value={websiteInput.description}
                                                    onValueChange={(e) => handleChangeTextInput('description', e)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="border p-2 mt-2">
                                        <h3>Social Link</h3>
                                        <div className="form-group row">
                                            <div className="col-lg-6">
                                                <label className="form-label mt-2">Facebook</label>
                                                <InputGroup className="fromStyle">
                                                    <FormControl
                                                        id="inlineFormInputGroupUsername"
                                                        name="facebook_link"
                                                        className="fromStyle"
                                                        placeholder="Facebook Link"
                                                        value={websiteInput.facebook_link}
                                                        onChange={(e) => handleChangeTextInput('facebook_link', e.target.value)}
                                                        ref={register({
                                                            required: true,
                                                            maxLength: 100,
                                                        })}
                                                    />
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text><i class="fab fa-facebook"></i></InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                                <div className="inputError margin-minus-8">
                                                    {errors.facebook_link &&
                                                        errors.facebook_link.type === 'required' &&
                                                        "Facebook link can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label mt-2">Twitter</label>
                                                <InputGroup className="fromStyle">
                                                    <FormControl
                                                        id="inlineFormInputGroupUsername"
                                                        name="twitter_link"
                                                        className="fromStyle"
                                                        placeholder="Twitter Link"
                                                        value={websiteInput.twitter_link}
                                                        onChange={(e) => handleChangeTextInput('twitter_link', e.target.value)}
                                                        ref={register({
                                                            required: true,
                                                            maxLength: 100,
                                                        })}
                                                    />
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text><i class="fab fa-twitter-square"></i></InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                                <div className="inputError margin-minus-8">
                                                    {errors.twitter_link &&
                                                        errors.twitter_link.type === 'required' &&
                                                        "Twitter link can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label mt-2">LinkedIn</label>
                                                <InputGroup className="fromStyle">
                                                    <FormControl
                                                        id="inlineFormInputGroupUsername"
                                                        name="linkedin_link"
                                                        className="fromStyle"
                                                        placeholder="LinkedIn Link"
                                                        value={websiteInput.linkedin_link}
                                                        onChange={(e) => handleChangeTextInput('linkedin_link', e.target.value)}
                                                        ref={register({
                                                            required: true,
                                                            maxLength: 100,
                                                        })}
                                                    />
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text><i class="fab fa-linkedin"></i></InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                                <div className="inputError margin-minus-8">
                                                    {errors.linkedin_link &&
                                                        errors.linkedin_link.type === 'required' &&
                                                        "LinkedIn link can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label mt-2">Youtube</label>
                                                <InputGroup className="fromStyle">
                                                    <FormControl
                                                        id="inlineFormInputGroupUsername"
                                                        name="youtube_link"
                                                        className="fromStyle"
                                                        placeholder="Youtube Link"
                                                        value={websiteInput.youtube_link}
                                                        onChange={(e) => handleChangeTextInput('youtube_link', e.target.value)}
                                                        ref={register({
                                                            required: true,
                                                            maxLength: 100,
                                                        })}
                                                    />
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text><i class="fab fa-youtube"></i></InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                                <div className="inputError margin-minus-8">
                                                    {errors.youtube_link &&
                                                        errors.youtube_link.type === 'required' &&
                                                        "Youtube link can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label mt-2">Instagram</label>
                                                <InputGroup className="fromStyle">
                                                    <FormControl
                                                        id="inlineFormInputGroupUsername"
                                                        name="instagram_link"
                                                        className="fromStyle"
                                                        placeholder="instagram Link"
                                                        value={websiteInput.instagram_link}
                                                        onChange={(e) => handleChangeTextInput('instagram_link', e.target.value)}
                                                        ref={register({
                                                            required: false,
                                                            maxLength: 100,
                                                        })}
                                                    />
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text><i class="fab fa-instagram"></i></InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                                <div className="inputError margin-minus-8">
                                                    {errors.instagram_link &&
                                                        errors.instagram_link.type === 'required' &&
                                                        "instagram link can't be blank"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="border p-2 mt-2">
                                        <h3>More Setting</h3>
                                        <div className="form-group row">
                                            <div className="col-lg-6 mt-3">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Enable First Purchase Offer"
                                                    name="enable_first_purchase_offer"
                                                    id="enable_first_purchase_offer"
                                                    checked={websiteInput.enable_first_purchase_offer === 0 ? true : false}
                                                    value={websiteInput.enable_first_purchase_offer}
                                                    onChange={(e) => handleChangeTextInput('enable_first_purchase_offer', websiteInput.enable_first_purchase_offer === 0 ? 1 : 0)}
                                                />
                                            </div>

                                            <div className="col-lg-6 mt-3">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Enable Referral System"
                                                    name="enable_referrel_system"
                                                    id="enable_referrel_system"
                                                    // checked={websiteInput.enable_referrel_commision === 0 ? true : false}
                                                    value={websiteInput.enable_referrel_commision}
                                                    onChange={(e) => handleChangeTextInput('enable_referrel_commision', websiteInput.enable_tooltip === 0 ? 1 : 0)}
                                                />
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label mt-5">Default Profit Percent</label>
                                                <InputGroup className="fromStyle">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Default Profit Percent"
                                                        name="default_profit_percent"
                                                        className="fromStyle"
                                                        value={websiteInput.default_profit_percent}
                                                        onChange={(e) => handleChangeTextInput('default_profit_percent', e.target.value)}
                                                        ref={register({
                                                            required: true,
                                                            maxLength: 100,
                                                        })}
                                                    />
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>%</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                                <div className="inputError margin-minus-8">
                                                    {errors.default_profit_percent &&
                                                        errors.default_profit_percent.type === 'required' &&
                                                        "Default Referral commission can't be blank"}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label mt-5">Referral Commission Percent</label>
                                                <InputGroup className="fromStyle">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Referral Commission Percent"
                                                        name="default_referrel_commision"
                                                        className="fromStyle"
                                                        value={websiteInput.default_referrel_commision}
                                                        onChange={(e) => handleChangeTextInput('default_referrel_commision', e.target.value)}
                                                        ref={register({
                                                            required: true,
                                                            maxLength: 100,
                                                        })}
                                                    />
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>%</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                </InputGroup>

                                                <div className="inputError margin-minus-8">
                                                    {errors.default_referrel_commision &&
                                                        errors.default_referrel_commision.type === 'required' &&
                                                        "Referrel commision can't be blank"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="row border-top p-2 mr-2">
                    <div className="col-lg-12 text-right">
                        {isSubmitting &&
                            <button class="btn btn-primary btn-lg" disabled={true} >
                                <span>Saving...</span>
                                <span className="ml-3 spinner spinner-white mr-2"></span>
                            </button>
                        }

                        {!isSubmitting &&
                            <button type="submit" class="btn btn-primary btn-lg">
                                <span>Save</span>
                            </button>
                        }
                    </div>
                </div>
            </form>
        </div>
    );
};

export default WebsiteInfo;