import * as Types from '../../types/Types';

const initialState = {
  websiteInput: {
    name: "",
    logo: null,
    logoPreviewUrl: null,
    favicon: null,
    faviconPreviewUrl: null,
    banner: null,
    bannerPreviewUrl: null,
    phone_no: "",
    email: "",
    theme: "default",
    websiteTheme: '',
    description: "",
    meta_description: "",
    meta_keywords: "",
    footer_text: "",
    address: "",
    default_profit_percent: 0,
    enable_referrel_commision: 0,
    default_referrel_commision: 0,
    facebook_link: "",
    youtube_link: "",
    linkedin_link: "",
    twitter_link: "",
    instagram_link: "",
    whatsapp_link: ""
  },
  isLoading: false,
  isSubmitting: false
};

const WebsiteInfoReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.WEBSITE_INPUT_CHANGE:
      const websiteInput = { ...state.websiteInput };
      websiteInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        websiteInput,
      };
   
    case Types.GET_WEBSITE_DETAILS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        websiteInput: action.payload.websiteDetails,
      };
    case Types.UPDATE_WEBSITE:
      return {
        ...state,
        isSubmitting: action.payload.isLoading,
        // websiteInput: action.payload.websiteDetails,
      };
   
    default:
      break;
  }
  return newState;
};

export default WebsiteInfoReducer;
