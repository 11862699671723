import * as Types from '../types/Types';

const initialState = {
    PollInputData: {
        title: "",
        type: "",
        status: 1,
        enable_item_comparison: false,
        description: "",
        created_by: 1,
        options: [
            {
                value: "",
                item_id: null
            },
            {
                value: "",
                item_id: null
            },
        ],
    },
    isSubmitting: false,
    isLoading: false,
    PollListData: [],
    PollPaginateList: [],
    itemOptionList: [],
};

const PollReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.CHANGE_POLL_INPUT_DATA:
            const PollInputData = { ...state.PollInputData };
            if (action.payload.name === "value" || action.payload.name === "item_id") {
                if (action.payload.name === "value") {
                    PollInputData.options[action.payload.index].value = action.payload.value;
                } else {
                    PollInputData.options[action.payload.index].item_id = action.payload.value;
                }
            } else {
                PollInputData[action.payload.name] = action.payload.value;
            }
            return {
                ...state,
                PollInputData,
            };
        case Types.SUBMIT_POLL:
            if (action.payload.status) {
                return {
                    ...state,
                    isSubmitting: action.payload.isLoading,
                    PollInputData: initialState.PollInputData,
                };
            } else {
                return {
                    ...state,
                    isSubmitting: action.payload.isLoading,
                };
            }
        case Types.ITEM_OPTION_LIST:
            return {
                ...state,
                itemOptionList: getItemOption(action.payload.data)
            };
        case Types.GET_POLL_LIST:
            return {
                ...state,
                PollListData: action.payload.PollListData,
                PollPaginateList: action.payload.PollPaginateList,
                isLoading: action.payload.isLoading,
            };
        case Types.DELETE_POLL_DATA:
            return {
                ...state,
                isLoading: action.payload,
            };
        case Types.GET_POLL_DETAILS:
            if (action.payload.status) {
                let pollInput = action.payload.PollDetails;
                pollInput.newItemList = []
                for (let i = 0; i < pollInput.options.length; i++) {
                    const element = pollInput.options[i];
                    const item_id = {
                        id: element.id,
                        label: element.item.label,
                        value: element.item.id
                    }
                    const newItem = {
                        id: element.id,
                        value: element.value,
                        item_id: item_id
                    }
                    pollInput.newItemList.push(newItem);
                }
                pollInput.options = pollInput.newItemList;
                return {
                    ...state,
                    PollInputData: pollInput,
                    // isLoading: action.payload.isLoading,
                };
            }
            break;

        //updated polls
        case Types.UPDATED_POLL_DATA:
            if (action.payload.status) {
                return {
                    ...state,
                    isSubmitting: action.payload.isLoading,
                    // PollInputData: initialState.PollInputData,
                };
            } else {
                return {
                    ...state,
                    isSubmitting: action.payload.isLoading,
                };
            }
            
        default:
            break;
    }
    return newState;
};
const getItemOption = (data) => {
    let optionList = [];
    if (typeof data !== 'undefined' && data.length > 0) {
        data.forEach((item) => {
            let items = {
                value: item.value,
                label: item.label,
            };
            optionList.push(items);
        });
    }
    return optionList;
}

export default PollReducer;
