import React, { useEffect, memo, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { GetBusinessList } from '../../../master/OptionData/BusinessList/BusinessListAction/BusinessOptionListAction';
import { GetCategoriesSortedList } from '../../../category/_redux/actions/CategoriesAction';
import { getBrandListOptonData } from '../../../master/OptionData/BrandOptionList/BrandOptionListAction/BrandOptionListAction'
import { changeFilterItemList, getItemTypes } from '../../_redux/actions/ItemAction';
import { getSelectedOption } from '../../../../services/FormatData';
import axios from 'axios';
import { showToast } from '../../../master/utils/ToastHelper';
import { getCurrentDateTime } from '../../../../services/DateHelper';
import { Spinner } from 'react-bootstrap';
import { getUnitOptionList } from '../../../master/OptionData/Unit/UnitOptionListAction/UnitOptionListAction';

const ItemExport = () => {
    const { register, setValue } = useForm();
    const dispatch = useDispatch();
    const { businessList } = useSelector((state) => state.BusinessOptionList);
    const { BrandsOptionList } = useSelector((state) => state.BrandOptionListReducer);
    const { UnitOptionList } = useSelector((state) => state.UnitOptionListReducer);
    const { categoriesSortedList } = useSelector((state) => state.categories);
    const { filter, itemTypes } = useSelector((state) => state.item);

    useEffect(() => {
        dispatch(getBrandListOptonData());
        dispatch(GetCategoriesSortedList());
        dispatch(GetBusinessList());
        dispatch(getItemTypes());
        dispatch(getUnitOptionList())
    }, []);

    const changeFilter = (name, value) => {
        let { category, brand, shop, unit, type } = filter;

        switch (name) {
            case 'category':
                category = value !== null ? value.value : '';
                dispatch(changeFilterItemList('category', category));
                break;
            case 'brand':
                brand = value !== null ? value.value : '';
                dispatch(changeFilterItemList('brand', brand));
                break;
            case 'shop':
                shop = value !== null ? value.value : '';
                dispatch(changeFilterItemList('shop', shop));
                break;
            case 'unit':
                unit = value !== null ? value.value : '';
                dispatch(changeFilterItemList('unit', unit));
                break;
            case 'type':
                type = value !== null ? value.value : '';
                dispatch(changeFilterItemList('type', type));
                break;

            default:
                break;
        }
    }

    function useQuery () {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    }
    const status = useQuery().get("status")

    const [generating, setGenerating] = useState(false);
    const exportItems = () => {
        setGenerating(true);

        // Add status filter to query
        filter.status = ((status !== null) || (status !== '')) ? status : 'active';
        try {
            axios({
                url: `${process.env.REACT_APP_API_URL}items/export`,
                method: 'POST',
                data: filter,
                responseType: 'blob',
            }).then((response) => {
                setGenerating(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `products-${getCurrentDateTime()}.csv`);
                document.body.appendChild(link);
                link.click();
            }).catch(err => {
                setGenerating(false);
                console.log('err', err);
                showToast('error', 'Error', 'Something went wrong');
            })
        } catch (error) {
            showToast('error', 'Something went wrong to export the file.');
        }
    }

    return (
        <form className="form form-label-right">
            <div className="form-group row mt-2">
                <div className="col-3 mt-2">
                    <RHFInput
                        as={<Select options={businessList} placeholder="Search By Shop" />}
                        name="shop"
                        register={register}
                        setValue={setValue}
                        isClearable
                        value={getSelectedOption(businessList, filter.shop)}
                        onChange={(option) => changeFilter('shop', option)}
                    />
                </div>
                <div className="col-3 mt-2">
                    <RHFInput
                        as={<Select options={categoriesSortedList} placeholder="Search By Category" />}
                        name="category"
                        register={register}
                        setValue={setValue}
                        isClearable
                        value={getSelectedOption(categoriesSortedList, filter.category)}
                        onChange={(option) => changeFilter('category', option)}
                    />
                </div>
                <div className="col-3 mt-2">
                    <RHFInput
                        as={<Select options={BrandsOptionList} placeholder="Search By Brand" />}
                        name="brand"
                        register={register}
                        setValue={setValue}
                        isClearable
                        value={getSelectedOption(BrandsOptionList, filter.brand)}
                        onChange={(option) => changeFilter('brand', option)}
                    />
                </div>
                <div className="col-3 mt-2">
                    <RHFInput
                        as={<Select options={itemTypes} placeholder="Search By Type" />}
                        name="type"
                        register={register}
                        setValue={setValue}
                        isClearable
                        value={getSelectedOption(itemTypes, filter.type)}
                        onChange={(option) => changeFilter('type', option)}
                    />
                </div>
                <div className="col-3 mt-2">
                    <RHFInput
                        as={<Select options={UnitOptionList} placeholder="Unit of Measurment" />}
                        name="unit"
                        register={register}
                        setValue={setValue}
                        isClearable
                        value={getSelectedOption(UnitOptionList, filter.unit)}
                        onChange={(option) => changeFilter('unit', option)}
                    />
                </div>
                <div className="col-12 mt-2">
                    <button type="button" className="btn btn-primary mt-5" onClick={exportItems} disabled={generating}>
                        <i className="fa fa-download"></i>
                        { generating ? "Generating..." : 'Generate Now' }
                        <Spinner animation="border" variant="light" className="ml-2" style={{ display: generating ? 'inline-block' : 'none' }} />
                    </button>
                </div>
            </div>
        </form>
    );
}

export default memo(ItemExport);