import * as Types from "../types/Types";

const initialState = {
    isLoading: false,
    attributeInputData: {
        name: '',
        category_id: '',
        values: [],
        deleted_values: []
    },
    attributeValues: {
        value: '',
        code: '',
    },
    attributeList: [],
    attributePaginatedList: []

};
const AttrubuteReducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case Types.CHANGE_ATTRIBUTE_INPUT:
            const attributeInputData = { ...state.attributeInputData };
            attributeInputData[action.payload.name] = action.payload.value;

            const attributeValues = { ...state.attributeValues };
            attributeValues[action.payload.name] = action.payload.value;
            return {
                ...state,
                attributeInputData,
                attributeValues,
            };

        case Types.ADD_MULTIPLE_ATTRIBUTE:
            let data = action.payload.attributeData;
            const rowData = action.payload.attributeValues
            const multipleData = {
                value: rowData.value,
                code: rowData.code
            }
            data.values.push(multipleData);
            return {
                ...state,
                attributeInputData: data,
                attributeValues: initialState.attributeValues,
            };

        case Types.DELETE_MULTIPLE_ATTRIBUTE:
            const multipleAttribute = { ...state.attributeInputData };
            multipleAttribute.values.splice(action.payload, 1);
            return {
                ...state,
                attributeInputData: multipleAttribute,
            };
        case Types.DELETE_MULTIPLE_ATTRIBUTE_EDIT:
            const multipleAttributeEdit = { ...state.attributeInputData };
            multipleAttributeEdit.values.splice(action.payload, 1);
            return {
                ...state,
                attributeInputData: multipleAttributeEdit,
            };
        case Types.SUBMIT_ATTRIBUTE_DATA:
            if (action.payload.status) {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                    attributeInputData: {
                        name: '',
                        category_id: '',
                        values: [],
                        deleted_values: []
                    },
                    attributeValues: initialState.attributeValues,
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            }
        case Types.UPDATE_ATTRIBUTES:
            if (action.payload.status) {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            }

        case Types.EMPTY_DISPATCH:
            return {
                ...state,
                attributeInputData: initialState.attributeInputData,
            };

        case Types.GET_ATTRIBUTE_LIST:
            if (action.payload.status) {
                return {
                    ...state,
                    attributeList: action.payload.attributeList,
                    attributePaginatedList: action.payload.attributePaginatedList,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            }
        case Types.GET_ATRIBUTE_DETAILS:
            if (action.payload.status) {
                return {
                    ...state,
                    attributeInputData: action.payload.data,
                    isLoading: action.payload.isLoading,
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            }
        case Types.DELETE_ATTRIBUTE:
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            break;
    }
    return newState;
};

export default AttrubuteReducer;