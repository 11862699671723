import { toast } from "react-toastify";

/**
 * Show Toast Notification.
 *
 * @param {string} type eg: success, error, warning, info
 * @param {string} msg eg: Product added successfully.
 * @param {int} autoClose autoclose toast time in milliseconds.
 * @param {string} className custom class name for toast.
 */
export const showToast = (type="success", msg, autoClose=null, className=null) => {
  // Process toast datas
  autoClose      = (autoClose === null) ? 2000 : autoClose;
  const position = toast.POSITION.TOP_CENTER;

  switch (type) {
      case 'success':
        className = (className === null) ? "bg-success" : className;
        toast.success(msg, { autoClose, position, className });
        break;

      case 'error':
        className = (className === null) ? "bg-danger" : className;
        toast.error(msg, { autoClose, position, className });
        break;

      case 'info':
        className = (className === null) ? "bg-dark" : className;
        toast.info(msg, { autoClose, position, className });
        break;

      case 'warn':
      case 'warning':
        className = (className === null) ? "bg-warning" : className;
        toast.warn(msg, { autoClose, position, className });
        break;

      default:
        className = (className === null) ? "bg-primary" : className;
        toast(msg, { autoClose, position, type, className });
        break;
  }
};
