import * as Types from "../../types/Types";
import axios from "axios";

export const GetLocationList = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };

  axios
    .get(`${process.env.REACT_APP_API_URL}locations`, {
      headers: headers,
    })
    .then((res) => {
      let data = res.data;
      dispatch({ type: Types.GET_LOCATIONS_LIST, payload: data });
    });
};

export const AddLocation = (stateData) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };

  let responseData = {
    status: false,
    message: "",
    isLoading: true,
  };

  dispatch({ type: Types.ADD_LOCATIONS, payload: responseData });

  const {
    name,
    country,
    state,
    zip_code,
    landmark,
    city,
    mobile,
    alternate_number,
    email,
  } = stateData;
  let postData = {
    name: name,
    business_id: stateData.businessData.value,
    country: country,
    state: state,
    zip_code: zip_code,
    landmark: landmark,
    city: city,
    mobile: mobile,
    alternate_number: alternate_number,
    email: email,
  };

  axios
    .post(`${process.env.REACT_APP_API_URL}locations`, postData, {
      headers: headers,
    })
    .then((res) => {
      let data = res.data;
      responseData.message = data.message;
      responseData.status = data.status;
      responseData.isLoading = false;
      dispatch({ type: Types.ADD_LOCATIONS, payload: responseData });
    })
    .catch((err) => {
      responseData.isLoading = false;
      dispatch({ type: Types.ADD_LOCATIONS, payload: responseData });
    });
};

export const LocationEmptyMessage = () => async (dispatch) => {
  dispatch({ type: Types.EMPTY_LOCATION_ADD_MESSAGE, payload: true });
};
export const LocationEmptyEditMessage = () => async (dispatch) => {
  dispatch({ type: Types.EMPTY_LOCATIONS_EDIT_MESSAGE, payload: true });
};

export const UpdateLocation = (LocationData) => async (dispatch) => {
  // let data = {
  //     status: false,
  //     message: "",
  //     isLoading: false
  // };
  let responseData = {
    status: false,
    message: "",
    isLoading: true,
  };
  dispatch({ type: Types.UPDATE_LOCATIONS, payload: responseData });
  const {
    name,
    business_id,
    state,
    country,
    zip_code,
    landmark,
    city,
    mobile,
    alternate_number,
    email,
  } = LocationData;
  let updateData = {
    name: name,
    business_id: business_id,
    country: country,
    state: state,
    zip_code: zip_code,
    landmark: landmark,
    city: city,
    mobile: mobile,
    alternate_number: alternate_number,
    email: email,
  };

  axios
    .put(
      `${process.env.REACT_APP_API_URL}locations/${LocationData.id}`,
      updateData
    )
    .then(async (res) => {
      responseData.message = res.data.message;
      responseData.isLoading = false;
      responseData.status = res.data.status;
      dispatch({ type: Types.UPDATE_LOCATIONS, payload: responseData });
    })
    .catch((err) => {
      // responseData.message = res.data.message;
      responseData.isLoading = false;
      dispatch({ type: Types.UPDATE_LOCATIONS, payload: responseData });
    });
};
