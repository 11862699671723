import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../../../modules/master/utils/ToastHelper";
import { generateFormDataFromObject } from "../../../../../../modules/master/utils/FileHelper";

export const ChangePollInputData = (name, value, index) => (dispatch) => {
    const formData = {
        name: name,
        value: value,
        index,
    }
    dispatch({ type: Types.CHANGE_POLL_INPUT_DATA, payload: formData });
}

// store poll 
export const submitPoll = (PollInputData) => (dispatch => {
    let responseData = {
        status: false,
        message: "",
        isLoading: true,
    };
    dispatch({ type: Types.SUBMIT_POLL, payload: responseData });
    const headers = {
        "Content-Type": "application/json",
    };

    Axios.post(`${process.env.REACT_APP_API_URL}polls`, PollInputData, {
        headers: headers,
    }).then((res) => {
        if (res.data.status) {
            let data = res.data;
            responseData.message = data.message;
            responseData.status = data.status;
            responseData.isLoading = false;
            showToast('success', responseData.message);
            dispatch({ type: Types.SUBMIT_POLL, payload: responseData });
        }
    }).catch((err) => {
        const { response } = err;
        const { request, ...errorObject } = response;
        responseData.isLoading = false;
        showToast('error', response.data.message);
        dispatch({ type: Types.SUBMIT_POLL, payload: responseData });
    });
})

//drop down list for item  
export const getItemsOptionList = () => async (dispatch) => {
    Axios.get(`${process.env.REACT_APP_API_URL}items/all/for-dropdown`)
      .then((res) => {
        let data = res.data;
        dispatch({ type: Types.ITEM_OPTION_LIST, payload: data });
      });
  };

//get voucher list data
export const getPollsLIst = (page, searchText = null) => async (dispatch) => {
    let response = {
        couponList: [],
        status: false,
        message: "",
        isLoading: true,
        errors: [],
    };
    dispatch({ type: Types.GET_POLL_LIST, payload: response });
    let url = "";
    url = `${process.env.REACT_APP_API_URL}polls?isPaginated=1&paginateNo=10`;

    if (page !== null || page === "") {
        url += `&page=${page}`;
    }


    if (searchText !== null) {
        url += `&search=${searchText}`;
    } else {
        // url += `&certificate/details?search=${searchText}`
    }

    try {
        await Axios.get(url)
            .then((res) => {
                const { data, message, status } = res.data;
                response.status = status;
                response.PollListData = data.data;
                response.message = message;
                response.PollPaginateList = data;
                response.isLoading = false;
                dispatch({ type: Types.GET_POLL_LIST, payload: response });
            })
            .catch((err) => {
                // toast.error(err);
            });
    } catch (error) {
        response.isLoading = false;
        dispatch({ type: Types.GET_POLL_LIST, payload: response });        // toast.error(error);
    }
    // response.isLoading = false;
    // dispatch({ type: Types.GET_POLL_LIST, payload: response });
};

//delete gift data list 
export const handleDeletePoll = (id) => (dispatch) => {
    let isLoading = true;
    dispatch({ type: Types.DELETE_POLL_DATA, payload: isLoading })
    Axios.delete(`${process.env.REACT_APP_API_URL}polls/${id}`)
        .then((res) => {
            if (res.data.status) {
                const data = res.data;
                showToast('success', data.message);
                dispatch({ type: Types.DELETE_POLL_DATA, payload: false });
                dispatch(getPollsLIst())
            }
        })
}

//get polls details
export const getPollsDetails = (id) => async (dispatch) => {
    let response = {
        status: false,
        message: "",
        isLoading: true,
        errors: [],
        PollDetails: {},
    };
    dispatch({ type: Types.GET_POLL_DETAILS, payload: response });

    await Axios.get(`${process.env.REACT_APP_API_URL}polls/${id}`)
        .then((res) => {
            const { data, message, status } = res.data;
            if (data.type !== null) {
                data.PollTypes = {
                    label: data.type[0].toUpperCase() + data.type.substring(1),
                    value: data.type
                }
            }
            response.status = status;
            response.message = message;
            response.PollDetails = data;
            response.isLoading = false;
            dispatch({ type: Types.GET_POLL_DETAILS, payload: response });
        })
        .catch((err) => {
            response.isLoading = false;
            dispatch({ type: Types.GET_POLL_DETAILS, payload: response });
        });
}

//updated polls
export const updatedPolls = (PollInputData, id) => (dispatch => {
    let responseData = {
        status: false,
        message: "",
        isLoading: true,
    };
    dispatch({ type: Types.UPDATED_POLL_DATA, payload: responseData });
    const headers = {
        "Content-Type": "application/json",
    };

    PollInputData.options[0].item_id = PollInputData.options[0].item_id !== null ? PollInputData.options[0].item_id.value : null;
    PollInputData.options[1].item_id = PollInputData.options[1].item_id !== null ? PollInputData.options[1].item_id.value : null;
    
    Axios.put(`${process.env.REACT_APP_API_URL}polls/${id}`, PollInputData, {
        headers: headers,
    }).then((res) => {
        if (res.data.status) {
            let data = res.data;
            responseData.message = data.message;
            responseData.status = data.status;
            responseData.isLoading = false;
            showToast('success', responseData.message);
            dispatch({ type: Types.UPDATED_POLL_DATA, payload: responseData });
        }
    }).catch((err) => {
        const { response } = err;
        const { request, ...errorObject } = response;
        responseData.isLoading = false;
        showToast('error', response.data.message);
        dispatch({ type: Types.UPDATED_POLL_DATA, payload: responseData });
    });
})

