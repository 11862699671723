import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SimpleTooltip from '../../../master/components/tooltip/SimpleTooltip';
import { deleteCategory, updateCategory } from '../../_redux/actions/CategoriesAction';
import { confirmAlert } from "react-confirm-alert";
import PermissionWiseDisplay from '../../../../app/modules/role/PermissionWiseDisplay';
import { copyToClipboard } from '../../../../services/KeyboardHelper';
import { getImageUrl } from '../../../../services/FormatHelper';

const CategoryListShortDetail = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const { index, item, newIndex } = props;
    const categoriesList = useSelector((state) => state.categories.categoriesList);
 
    const { id, name, image_url, banner_url, banner, image, short_code, is_visible_navbar, navbar_priority, is_visible_homepage, priority, deleted_at } = item;
 
    // delete category item 
    const confirmDelete = (id) => {
        dispatch(deleteCategory(id));
    }

    const handleDeleteCategory = (id) => {
        confirmAlert({
            title: "Confirm To Delete",
            message: `Are you sure to delete ${name} category..?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => confirmDelete(id),
                },
                {
                    label: "No"
                }
            ]
        });
    };

    const direction = {
        homepage: 'homepage',
        navbar: 'navbar'
    }

    /**
     * 
     * @param {*} direction - homepage | navbar
     * @param {*} item 
     */

    const toggleVisible = (page, item) => {
        const category = {...item};
        category.image = null;
        category.banner = null;
        
        if(+category.is_visible_homepage === 0) {
            category.is_visible_homepage = false;
        }

        if(+category.is_visible_homepage === 1) {
            category.is_visible_homepage = true;
        }
        
        if(+category.is_visible_navbar === 0) {
            category.is_visible_navbar = false;
        }

        if(+category.is_visible_navbar === 1) {
            category.is_visible_navbar = true;
        }

        if(page === direction.homepage) {
            if(category.is_visible_homepage === false) {
                category.is_visible_homepage = true;
            } else {
                category.is_visible_homepage = false;
            }
        } else {
            if(category.is_visible_navbar === false) {
                category.is_visible_navbar = true;
            } else {
                category.is_visible_navbar = false;
            }
        }

        category.businessData = {
            value: category.business_id
        }
        category.parentCategory = {
            value: category.parent_id
        }

        dispatch(updateCategory(category, category.id, categoriesList))
    }

    return (
        <tr key={index}>
            <td>{newIndex + index}</td>
            <td>{name}</td>
            <td>
                {image !== null && <img src={`${getImageUrl(image, 'category')}`} width={50} />}
            </td>
            <td>
                {banner !== null && <img src={`${getImageUrl(banner, 'category')}`} width={50} />}
            </td>
            <td>
                <span className="badge badge-primary pointer" onClick={() => copyToClipboard(short_code)}> {short_code} </span>
            </td>
            <td>
                <span onClick={() => toggleVisible(direction.navbar, item)} className={`pointer badge badge-${ parseInt(is_visible_navbar) ? 'primary' : 'warning'}`}> 
                    {parseInt(is_visible_navbar) ? 'Enabled' : 'Disabled'} 
                </span>
                <br />
                Sl - {navbar_priority}
            </td>
            <td>
                <span onClick={() => toggleVisible(direction.homepage, item)} className={`pointer badge badge-${ parseInt(is_visible_homepage) ? 'primary' : 'warning'}`}> 
                    {parseInt(is_visible_homepage) ? 'Enabled' : 'Disabled'} 
                </span>
                <br />
                Sl - {priority}
            </td>
            <td>
                <span className={`badge badge-${deleted_at === null ? 'success' : 'warning'}`}>
                    {deleted_at === null ? 'Active' : 'Inactive'}
                </span>
            </td>
            <td>
                <SimpleTooltip title="View Category">
                    <a
                        className="btn btn-icon btn-light btn-hover-info btn-sm"
                        onClick={() => {
                            history.push(`/categories/view/${id}`);
                        }}
                    >
                        <i className="fa fa-eye"></i>
                    </a>
                </SimpleTooltip>
                &nbsp;&nbsp;
                <PermissionWiseDisplay permission_name="category.edit" display={false} >
                    <SimpleTooltip title={`Edit Category ${name}`}>
                        <a
                            className="btn btn-icon btn-light btn-hover-success btn-sm"
                            onClick={() => {
                                history.push(`/categories/edit/${id}`);
                            }}
                            title="Edit Category"
                        >
                            <i className="fa fa-edit"></i>
                        </a>
                    </SimpleTooltip>
                </PermissionWiseDisplay>
                &nbsp;&nbsp;
                <PermissionWiseDisplay permission_name="category.delete" display={false}>
                    <a
                        className="btn btn-icon btn-light btn-hover-danger btn-sm"
                        onClick={() => handleDeleteCategory(id)}
                    >
                        <i className="fa fa-trash text-danger"></i>
                    </a>
                </PermissionWiseDisplay>
            </td>
        </tr>
    );
}

export default CategoryListShortDetail;