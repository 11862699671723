import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { GetCategoriesList } from "../../_redux/actions/categories/CategoriesAction";

const CategoriesList = withRouter(({ history, props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetCategoriesList());
  }, [dispatch, props]);

  const categoriesGetData = useSelector(
    (state) => state.categories.categoriesList
  );

  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 class="card-label">Category list</h3>
            </div>
            <div className="card-toolbar">
              <a
                onClick={() => {
                  history.push("/categories/add");
                }}
              >
                <button type="button" class="btn btn-primary">
                  New Category
                </button>
              </a>
            </div>
          </div>
          <div className="card-body">
            {/* <form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-2">
                  <input className="form-control" placeholder="Search" />
                </div>
                <div>
                  <label className="form-label">Status</label>
                </div>
                <div className="col-lg-2">
                  <select
                    className="form-control"
                    name="status"
                    placeholder="Filter by Status"
                  >
                    <option>All</option>
                    <option value="0">Selling</option>
                    <option value="1">Sold</option>
                  </select>
                </div>

                <div>
                  <label className="form-label">Type</label>
                </div>
                <div className="col-lg-2">
                  <select
                    className="form-control"
                    name="status"
                    placeholder="Filter by Status"
                  >
                    <option>All</option>
                    <option value="0">Selling</option>
                    <option value="1">Sold</option>
                  </select>
                </div>

                <div className="col-lg-2">
                  <button
                    className="btn"
                    style={{ backgroundColor: '#E1F0FF', color: '#3699FF' }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form> */}
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <td>SL</td>
                    <td>Category name</td>
                    <td>Image</td>
                    {/* <td>Business id</td> */}
                    <td>Short Code</td>
                    <td>description</td>
                  </tr>
                </thead>
                <tbody>
                  {categoriesGetData &&
                    categoriesGetData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>
                          <img
                            src={`${process.env.REACT_APP_API_SERVER}images/categories/${item.image}`}
                            width={50}
                          />
                        </td>
                        {/* <td>{item.business_id}</td> */}
                        <td>{item.short_code}</td>

                        <td>
                          <a
                            className="btn btn-icon btn-light btn-hover-info btn-sm"
                            // onClick={() => {
                            //   history.push('/vessels/edit', { vessel: item });
                            // }}
                          >
                            <i className="fa fa-edit"></i>
                          </a>
                          &nbsp;&nbsp;&nbsp;
                          {/* <a
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => {
                              if (
                                window.confirm(
                                  'Are you sure you wish to delete this item?'
                                )
                              )
                                vesselDelete(item.intID);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </a> */}
                        </td>
                      </tr>
                    ))}
                  {categoriesGetData == null && (
                    <p className="text-danger text-center">No Data Found</p>
                  )}
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default CategoriesList;
