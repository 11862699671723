import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl'
import { ChangeRegisterInputField, vendorRegisterFirstStep, vendorRegister, getCountry, getDivision, getCity, getArea } from '../_redux/actions/registerAction';
import './registration.scss';
import CountDown from '../../../../modules/master/utils/CountDown';
import { RHFInput } from 'react-hook-form-input';
import Select from 'react-select';

const ErrorMessage = ({ errorText }) => {
    return (
        <p className="error_message">
            {errorText}
        </p>
    );
};

const Registration = () => {
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { register, handleSubmit, errors, setValue, watch } = useForm();
    const { registerInput, isOTPLoading, isLoading, isSendOTP, isRegistrationSuccessful, countryList, divisionList, cityList, areaList } = useSelector((state) => state.RegisterReducer)
    const [isOTP, setIsOTP] = useState(false);
    const password = useRef({});
    const history = useHistory();
    password.current = watch("password", "");

    //handle change input 
    const handleChangeTextInput = (name, value) => {
        dispatch(ChangeRegisterInputField(name, value))
    }

    //get otp by first step register
    const handleSubmitOTP = () => {
        dispatch(vendorRegisterFirstStep(registerInput));
    }

    const handleRegister = (e) => {
        dispatch(vendorRegister(registerInput));
    };

    useEffect(() => {
        if (isSendOTP) {
            setIsOTP(true)
        }
    }, [isSendOTP])

    useEffect(() => {
        dispatch(getDivision())
    }, [])


    useEffect(() => {
        if (isRegistrationSuccessful) {
            history.push('/auth/login');
        }
    }, [isRegistrationSuccessful]);

    setTimeout(
        () => setIsOTP(false),
        300000
    );

    return (
        <div id="seller-register-page">
            <div className="row justify-content-center">
                <div className="col-8">
                    <h2 className="seller-sign-up-text">Seller Sign Up</h2>
                    <div className="card card-body p-4 m-5 seller-registration-card">
                        <div className="account_info_body">
                            <form
                                onSubmit={handleSubmit(handleRegister)}
                                method="post"
                                autoComplete="off"
                                encType="multipart/form-data"
                                autoSave="off"
                            >
                                <div className="row justify-content-center mb-5">
                                    <div className="col-md-6 mb-2 mt-0">
                                        <div>
                                            <label htmlFor="business_type" className="form-label">Shop Type <span className="text-danger">*</span></label>
                                            <label className="pointer">
                                                <input
                                                    id="polli"
                                                    value="polli"
                                                    name="business_type"
                                                    type="radio"
                                                    className="ml-4"
                                                    onChange={() => handleChangeTextInput('business_type', 'polli')}
                                                /> &nbsp; Polli Dokan
                                            </label>
                                            <label className="pointer">
                                                <input
                                                    id="business"
                                                    value="business"
                                                    name="business_type"
                                                    type="radio"
                                                    className="ml-4"
                                                    onChange={() => handleChangeTextInput('business_type', 'business')}
                                                /> &nbsp; Business
                                            </label>
                                        </div>
                                        {
                                            errors.business_type && errors.business_type.type === 'required' && (
                                                <ErrorMessage errorText="Shop type can't be blank!" />
                                            )
                                        }
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="firstName" className="form-label">Your Name <span className="text-danger">*</span></label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="first_name"
                                                // value={registerInput.first_name}
                                                onChange={(e) => handleChangeTextInput('first_name', e.target.value)}
                                                ref={register({
                                                    required: true,
                                                    maxLength: 100,
                                                })}
                                            />
                                            {
                                                errors.first_name && errors.first_name.type === 'required' && (
                                                    <ErrorMessage errorText="First name can't be blank!" />
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="business_name" className="form-label">Shop Name <span className="text-danger">*</span></label>

                                            <input
                                                id="business_name"
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="business_name"
                                                // value={registerInput.business_name}
                                                onChange={(e) => handleChangeTextInput('business_name', e.target.value)}
                                                ref={register({
                                                    required: true,
                                                    maxLength: 100,
                                                })}
                                            />
                                            {
                                                errors.business_name && errors.business_name.type === 'required' && (
                                                    <ErrorMessage errorText="Shop name can't be blank!" />
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="lastName" className="form-label">Email Address <span className="text-danger">*</span></label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder=""
                                                name="email"
                                                // value={registerInput.email}
                                                onChange={(e) => handleChangeTextInput('email', e.target.value)}
                                                ref={register({
                                                    required: true,
                                                    maxLength: 100,
                                                })}
                                            />
                                            {
                                                errors.email && errors.email.type === 'required' && (
                                                    <ErrorMessage errorText="Email address can't be blank!" />
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="lastName" className="form-label">Phone Number <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="phone_no"
                                                // value={registerInput.phone_no}
                                                onChange={(e) => handleChangeTextInput('phone_no', e.target.value)}
                                                ref={register({
                                                    required: true,
                                                    maxLength: 100,
                                                })}
                                            />
                                            {
                                                errors.phone_no && errors.phone_no.type === 'required' && (
                                                    <ErrorMessage errorText="Phone number can't be blank!" />
                                                )
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="lastName" className="form-label">Country <span className="text-danger">*</span></label>
                                            <RHFInput
                                                as={<Select options={countryList} className="form-control" />}
                                                className="register_rhf_input"
                                                placeholder="Select country"
                                                rules={{ required: true }}
                                                name="country_id"
                                                register={register}
                                                // value={selectedAddress.selectedCountry}
                                                onChange={(option) => {
                                                    handleChangeTextInput("country", option.label);
                                                    handleChangeTextInput("country_id", option.value);
                                                    // dispatch(handleChangeTextInput("division_id", ""))
                                                    // dispatch(handleChangeTextInput("city_id", ""))
                                                    // dispatch(handleChangeTextInput("zilla_id", ""))
                                                    // dispatch(handleChangeTextInput("area_id", ""))
                                                    dispatch(getDivision(option.value));
                                                }}
                                                setValue={setValue}
                                            />
                                            {
                                                errors.country_id && errors.country_id.type === 'required' && (
                                                    <ErrorMessage errorText="Country can't be blank!" />
                                                )
                                            }
                                        </div>
                                    </div> */}
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="state_id" className="form-label">Division <span className="text-danger">*</span></label>
                                            <RHFInput
                                                id="state_id"
                                                as={<Select options={divisionList} className="form-control" />}
                                                className="register_rhf_input"
                                                placeholder="Select Division"
                                                rules={{ required: true }}
                                                name="state_id"
                                                register={register}
                                                // value={registerInput.state_id}
                                                onChange={(option) => {
                                                    handleChangeTextInput("division", option.label);
                                                    handleChangeTextInput("state_id", option.value);
                                                    dispatch(getCity(option.value));
                                                }}
                                                setValue={setValue}

                                            />
                                            {
                                                errors.division_id && errors.division_id.type === 'required' && (
                                                    <ErrorMessage errorText="Division can't be blank!" />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="city_id" className="form-label">Zilla <span className="text-danger">*</span></label>
                                            <RHFInput
                                                id="city_id"
                                                as={<Select options={cityList} className="form-control" />}
                                                className="register_rhf_input"
                                                placeholder="Select Zilla"
                                                rules={{ required: true }}
                                                name="city_id"
                                                register={register}
                                                // value={selectedAddress.selectedCountry}
                                                onChange={(option) => {
                                                    handleChangeTextInput("zilla", option.label);
                                                    handleChangeTextInput("city_id", option.value);
                                                    dispatch(getArea(option.value));
                                                }}
                                                setValue={setValue}

                                            />
                                            {
                                                errors.city_id && errors.city_id.type === 'required' && (
                                                    <ErrorMessage errorText="Zilla can't be blank!" />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="area_id" className="form-label">Upzilla <span className="text-danger">*</span></label>
                                            <RHFInput
                                                id="area_id"
                                                as={<Select options={areaList} className="form-control" />}
                                                className="register_rhf_input"
                                                placeholder="Select upzilla"
                                                rules={{ required: true }}
                                                name="area_id"
                                                register={register}
                                                // value={selectedAddress.selectedCountry}
                                                onChange={(option) => {
                                                    handleChangeTextInput("area", option.label);
                                                    handleChangeTextInput("area_id", option.value);
                                                }}
                                                setValue={setValue}

                                            />
                                            {
                                                errors.area_id && errors.area_id.type === 'required' && (
                                                    <ErrorMessage errorText="Upazilla can't be blank!" />
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="landmark" className="form-label">Your Address <span className="text-danger">*</span></label>
                                            <input type="text"
                                                id="landmark"
                                                className="form-control"
                                                placeholder=""
                                                name="landmark"
                                                // value={registerInput.landmark}
                                                onChange={(e) => handleChangeTextInput('landmark', e.target.value)}
                                                ref={register({
                                                    required: true,
                                                    maxLength: 100,
                                                })}
                                            />
                                            {
                                                errors.landmark && errors.landmark.type === 'required' && (
                                                    <ErrorMessage errorText="Your address can't be blank!" />
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="mb-3">
                                            {
                                                isOTPLoading && (
                                                    <button type="button" disabled={true} className="btn btn-danger btn-sm d-flex btn-get-otp" onClick={handleSubmitOTP}>
                                                        Getting OTP... <i className="fa fa-chevron-right"></i>
                                                    </button>
                                                )
                                            }
                                            {
                                                !isOTPLoading && (
                                                    <button type="button"
                                                        className={isOTP ? "btn btn-danger btn-sm d-flex btn-get-otp button_disabled" : "btn btn-danger btn-sm d-flex btn-get-otp"}
                                                        onClick={handleSubmitOTP}>
                                                        GET OTP
                                                    </button>
                                                )
                                            }

                                        </div>
                                    </div>
                                    {
                                        isSendOTP &&
                                        <>
                                            <div className="col-md-2">
                                                <div className="mb-3">
                                                    <label htmlFor="lastName" className="form-label">OTP</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        name="otp"
                                                        // value={registerInput.otp}
                                                        onChange={(e) => handleChangeTextInput('otp', e.target.value)}
                                                        ref={register({
                                                            required: false,
                                                            maxLength: 100,
                                                        })}
                                                    />
                                                    {
                                                        errors.otp && errors.otp.type === 'required' && (
                                                            <ErrorMessage errorText="Please enter your one time password!" />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            {
                                                isOTP && (
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <CountDown alert_bg="alert_warning_bg" minutes={5} countDownText="OTP Will Expire in " expireText="Resend OTP" />
                                                        </div>
                                                    </div>
                                                )
                                            }


                                            <div className="col-md-4">
                                                <label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>
                                                <div className="account_input_group">
                                                    <input
                                                        type={showPassword === true ? "text" : "password"}
                                                        className="form-control" id="inlineFormInputGroup"
                                                        placeholder=""
                                                        name="password"
                                                        // value={registerInput.password}
                                                        onChange={(e) => handleChangeTextInput("password", e.target.value)}
                                                    // ref={register({
                                                    //     required: "⚠ You must specify a password",
                                                    //     minLength: {
                                                    //         value: 8,
                                                    //         message: "Password must have at least 8 characters"
                                                    //     }
                                                    // })}
                                                    />
                                                    {
                                                        errors.password && (
                                                            <ErrorMessage errorText={errors.password.message} />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="Confirm_password" className="form-label">Confirm Password <span className="text-danger">*</span></label>
                                                <div className="account_input_group">
                                                    <input
                                                        type={showConfirmPassword === true ? "text" : "password"}
                                                        className="form-control" id="inlineFormInputGroup"
                                                        placeholder=""
                                                        name="password_confirmation"
                                                        // value={registerInput.password_confirmation}
                                                        onChange={(e) =>
                                                            handleChangeTextInput("password_confirmation", e.target.value)
                                                        }
                                                    // ref={register({
                                                    //     validate: (value) =>
                                                    //         value === password.current || "The passwords do not match",
                                                    // })}
                                                    />
                                                    {errors.password_confirmation && (
                                                        <ErrorMessage errorText={errors.password_confirmation.message} />
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    }

                                </div>

                                <div className="form-check custome_form_checkbox mt-3">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="account_info_label pointer" htmlFor="flexCheckDefault">
                                        I've read everything and clear about FatonShop's {" "}
                                        <Link to="/terms-condition">
                                            Terms & Conditions
                                        </Link>
                                    </label>
                                </div>

                                <div className="account_btn_group">
                                    <div className="text-center mt-3">
                                        <button type="submit" disabled={isLoading ? true : false} className="btn btn-success btn-sign-up" onClick={handleSubmit(handleRegister)} >
                                            {isLoading && <><Spinner animation="border" role="status"> {" "} </Spinner>{" "} Signing Up... </>}
                                            {!isLoading && <>Sign Up</>}
                                        </button>

                                        <p className="mt-4">
                                            Already have an account ? <Link to="/"> Sign In </Link>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default injectIntl(Registration);
