/*******************************************************************************************************
        Sub Module: Coupon
********************************************************************************************************/
export const CHANGE_OFFER_INPUT = 'CHANGE_OFFER_INPUT';
export const STORE_PRODUCT_OFFER = 'STORE_PRODUCT_OFFER';
export const GET_OFFER_LIST = 'GET_OFFER_LIST';
export const GET_OFFER_TYPE_LIST = 'GET_OFFER_TYPE_LIST';
export const GET_OFFER_DETAILS = 'GET_OFFER_DETAILS';
export const DELETE_OFFER = 'DELETE_OFFER';
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const EMPTY_DISPATCH = 'EMPTY_DISPATCH';
export const CHANGE_OFFER_STATUS = 'CHANGE_OFFER_STATUS';
