import * as Types from "../../types/Types";
import Axios from "axios";
import { showToast } from "../../../master/utils/ToastHelper";
import { generateFormDataFromObject } from "../../../master/utils/FileHelper";

export const handleChangeWebsiteInput = (name, value, e = null) => (dispatch) => {
  let data = {
    name   : name,
    value  : value,
    e
  }
  dispatch({ type: Types.WEBSITE_INPUT_CHANGE, payload: data });

  if (name === 'logo' || name === 'banner') {
    let reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      if (name === "logo") {
        data.name = 'logoPreviewUrl';
      } else {
        data.name = 'bannerPreviewUrl';
      }
      data.value = reader.result;
      dispatch({ type: Types.WEBSITE_INPUT_CHANGE, payload: data });
    }
    reader.readAsDataURL(file)
  }
};

export const deletePreviewImage = (name) => (dispatch) => {

  let data = {
    name   : name,
    value  : null,
  }
  dispatch({ type: Types.WEBSITE_INPUT_CHANGE, payload: data });

  if (name === 'logo') {
    data.name = 'logoPreviewUrl';
  } else {
    data.name = 'bannerPreviewUrl';
  }
  dispatch({ type: Types.WEBSITE_INPUT_CHANGE, payload: data });
};


// //get website details 
export const getWebsiteDetails = () => async (dispatch) => {
  let response    = {
    status        : false,
    message       : "",
    isLoading     : true,
    websiteDetails: {}
  };
  dispatch({ type: Types.GET_WEBSITE_DETAILS, payload: response });

  await Axios.get(`${process.env.REACT_APP_API_URL}website/info`)
    .then((res) => {
      if (res.data.status) {
        const { data, message, status } = res.data;
        if (data.theme !== null && data.theme !== "") {
          data.websiteTheme = {
            label           : data.theme.toUpperCase(),
            value           : data.theme
          }
        } else {
          data.websiteTheme = ''
        }
        if (data.logo !== null && data.logo !== "") {
          data.logoPreviewUrl = data.logo;
          data.logo           = null;
        }
        if (data.banner !== null && data.banner !== "") {
          data.bannerPreviewUrl = data.banner;
          data.banner           = null;
        }
        response.status         = status;
        response.websiteDetails = data;
        response.message        = message;
        response.isLoading      = false;
        dispatch({ type: Types.GET_WEBSITE_DETAILS, payload: response });
      }
    }).catch((err) => {
      response.isLoading = false;
      dispatch({ type: Types.GET_WEBSITE_DETAILS, payload: response });
    });
}

//updated business 
export const updateWebsite = (websiteInput) => async (dispatch) => {
  let responseData = {
    status         : false,
    message        : "",
    isLoading      : true,
  };

  dispatch({ type: Types.UPDATE_WEBSITE, payload: responseData });
  const headers = {
    "Content-Type": "application/json",
  };

  // let websiteData    = websiteInput;
  // websiteData.logo   = websiteData.logoPreviewUrl;
  // websiteData.banner = websiteData.bannerPreviewUrl;
  const websiteInputData = generateFormDataFromObject(websiteInput)

  Axios.post(`${process.env.REACT_APP_API_URL}website/info/store`, websiteInputData, {
    headers: headers,
  })
    .then((res) => {
      if (res.data.status) {
        let data               = res.data;
        responseData.message   = data.message;
        responseData.status    = data.status;
        responseData.isLoading = false;
        showToast('success', responseData.message);
        dispatch({ type: Types.UPDATE_WEBSITE, payload: responseData });
      }
    }).catch((err) => {
      const errorMessage = "Sorry, Something went wrong!"
      responseData.isLoading = false;
      showToast('error', errorMessage);
      dispatch({ type: Types.UPDATE_WEBSITE, payload: responseData });
    });
};
