import React from 'react';
import CategoriesList from '../../components/categories/CategoriesList';

const CategoriesListContainer = () => {
  return (
    <>
      <CategoriesList />
    </>
  );
};

export default CategoriesListContainer;
