import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
// import { useForm } from "react-hook-form";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPollsLIst, handleDeletePoll } from './_redux/PollAction/PollAction';
import PaginationLaravel from '../../../../modules/master/pagination/PaginationLaravel';
import LoadingSpinner from '../../../../modules/master/spinner/LoadingSpinner';

const PollList = (() => {

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const PollListData = useSelector((state) => state.PollReducer.PollListData)
  const PollPaginateList = useSelector((state) => state.PollReducer.PollPaginateList)
  const isLoading = useSelector((state) => state.PollReducer.isLoading)
  // const { register, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    dispatch(getPollsLIst(currentPage));
  }, [dispatch, currentPage]);

  const changePage = (data) => {
    setCurrentPage(data.page);
    dispatch(getPollsLIst(data.page));
  };

  const searchItems = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText.length === 0) {
      dispatch(getPollsLIst(currentPage));
    } else {
      dispatch(getPollsLIst(currentPage, searchText));
    }
  };


  // delete poll list 
  const confirmDelete = (id) => {
    dispatch(handleDeletePoll(id));
  }
  const GiftCardDelete = (item) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(item.id),
        },
        {
          label: "No"
        }
      ]
    });
  };


  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">


          <div className="card-body">

            <div className="row mt-2">
              <h3 className="card-label">Poll list</h3>

              <Form.Group as={Col} controlId="formGridState">
                <input
                  type="search"
                  className="form-control product-search-input formHeight"
                  placeholder="Search by poll title"
                  value={searchText}
                  onChange={searchItems}
                />
              </Form.Group>
             
              <Form.Group as={Col} controlId="formGridState">
                {/* <RHFInput
                  as={<Select options={''} />}
                  rules={{ required: true }}
                  name="coupon_type_id"
                  placeholder="Filter by discount type"
                  register={register}
                  setValue={setValue}
                /> */}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                {/* <RHFInput
                  as={<Select options={''} />}
                  rules={{ required: true }}
                  placeholder="Filter by amount type"
                  name="amount_type_id"
                  register={register}
                  setValue={setValue}
                /> */}
              </Form.Group>

              <i className="fas fa-filter tableFilter mt-1 mr-2"></i>
              <i className="far fa-filter"></i>
              <Link to="/poll/add" className="btn btn-primary text-center text-white btn-sm custome-addnew-btn certificate-add-btn" >
                Add New
            </Link>
            </div>

            <div className="react-bootstrap-table table-responsive">
              {isLoading && <LoadingSpinner text="Loading Poll List..." />}
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <td>SL</td>
                    <td>Title</td>
                    <td>Poll Type</td>
                    <td>Has Item Comparison</td>
                  </tr>
                </thead>
                <tbody>
                  {PollListData &&
                    PollListData.map((item, index) => (
                      <tr key={index}>
                        <td>{PollPaginateList.from + index}</td>
                        <td>{item.title}</td>
                        <td>{item.type}</td>
                        <td>{item.enable_item_comparison}</td>
                        <td>
                          <Link className="btn btn-icon btn-light btn-hover-info btn-sm" to={`/poll/edit/${item.id}`}>
                            <i className="fa fa-edit"></i>
                          </Link>

                          &nbsp;&nbsp;&nbsp;
                          <a href
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => GiftCardDelete(item)}
                          >
                            <i className="fa fa-trash"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot></tfoot>
              </table>
              {!isLoading && (PollListData && PollListData.length) === 0 && (
                <div className="alert alert-warning mt-5">
                  Sorry ! No Poll List Found.
                </div>
              )}
              <PaginationLaravel
                changePage={changePage}
                data={PollPaginateList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default PollList;
