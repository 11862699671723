export const getOfferForData = () => {
  return [
    {
      label: "Item",
      value: "item",
    },
    {
      label: "Multiple SKU's",
      value: "multiple_items",
    },
    {
      label: "Multiple SKU(csv)",
      value: "multiple_items_csv",
    },
    {
      label: "Category",
      value: "category",
    },
    {
      label: "Brand",
      value: "brand",
    },
    {
      label: "Shop",
      value: "shop",
    },
  ];
};
