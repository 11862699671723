/**
| Menu Permission Types
**/
export const GET_MENU_LIST = "GET_MENU_LIST";
export const AUTH_LOGIN_CHECK = "AUTH_LOGIN_CHECK";
export const EMPTY_AUTH_MESSAGE = "EMPTY_AUTH_MESSAGE";

// registration types
export const AUTH_REGISTER = "AUTH_REGISTER";
export const CHANGE_REGISTER_INPUT_FIELD = "CHANGE_REGISTER_INPUT_FIELD";
export const REGISTER_FIRST_STEP = "REGISTER_FIRST_STEP";
export const REGISTER_FIRST_STEP_LOADING = "REGISTER_FIRST_STEP_LOADING";
export const EMPTY_DISPATCH = "EMPTY_DISPATCH";

// Vendor Register
export const VENDOR_REGISTER = "VENDOR_REGISTER";
export const VENDOR_REGISTER_GET_OTP = "VENDOR_REGISTER_GET_OTP";
export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";
export const GET_DIVISION_LIST = "GET_DIVISION_LIST";
export const GET_CITIES_LIST = "GET_CITIES_LIST";
export const GET_AREA_LIST = "GET_AREA_LIST";