import React from "react";
import { Route } from "react-router-dom";
import UnitsAddContainer from "../../units/views/UnitsAddContainer";
import UnitsEditContainer from "../../units/views/UnitsEditContainer";
import UnitsListContainer from "../../units/views/UnitsListContainer";

const routesUnits = [
  {
    path: "/units/list",
    name: "UNits List",
    component: UnitsListContainer,
    exact: true,
  },
  {
    path: "/units/add",
    name: "Units add",
    component: UnitsAddContainer,
    exact: true,
  },
  {
    path: "/units/edit",
    name: "units Edit",
    component: UnitsEditContainer,
    exact: true,
  }
];

function getUnitRoutes() {
  {
    return routesUnits.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default getUnitRoutes;
