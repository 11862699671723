import * as Types from "../authTypes";

const initialState = {
    isLoading: false,
    isOTPLoading: false,
    isSendOTP: false,
    isRegistrationSuccessful: false,
    is_first_validated: false,
    registerInput: {
        first_name: '',
        last_name: '',
        business_name: '',
        business_type: '',
        phone_no: null,
        email: null,
        country: "Bangladesh",
        state_id: "",
        city_id: "",
        area_id: "",
        otp: "",
        password: "",
        type: 'vendor',
        password_confirmation: "",
        landmark: ""
    },
    registerData: null,
    countryList: [],
    divisionList: [],
    cityList: [],
    areaList: [],
};

const registerReducer = (state = initialState, action) => {
    switch (action.type) {

        case Types.CHANGE_REGISTER_INPUT_FIELD:
            const registerInput = { ...state.registerInput };
            registerInput[action.payload.name] = action.payload.value
            return {
                ...state,
                registerInput
            };

        case Types.VENDOR_REGISTER_GET_OTP:
            return {
                ...state,
                isOTPLoading: action.payload.isLoading,
                isSendOTP: action.payload.isSendOTP,
            }

        case Types.VENDOR_REGISTER:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                registerInput: initialState.registerInput,
                isRegistrationSuccessful: action.payload.status
            }

        case Types.GET_COUNTRIES_LIST:
            return {
                ...state,
                countryList: getCountries(action.payload),
            };

        case Types.GET_DIVISION_LIST:
            return {
                ...state,
                divisionList: action.payload,
            };
        case Types.GET_CITIES_LIST:
            return {
                ...state,
                cityList: action.payload,
            };
        case Types.GET_AREA_LIST:
            return {
                ...state,
                areaList: action.payload,
            };
        default:
            break;
    }
    return { ...state }
};

// country list
const getCountries = (data) => {
    let options = [];
    if (data) {
        data.forEach((item) => {
            let itemData = {
                value: item.id,
                label: item.name,
            };
            options.push(itemData);
        });
    }
    return options;
};
export default registerReducer;