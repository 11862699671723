/*******************************************************************************************************
        Sub Module: Sliders
********************************************************************************************************/

export const CHANGE_SLIDE_INPUT = 'CHANGE_SLIDE_INPUT';
export const CREATE_NEW_SLIDE = 'CREATE_NEW_SLIDE';
export const GET_SLIDER_LIST = 'GET_SLIDER_LIST';
export const DELETE_SLIDER = 'DELETE_SLIDER';
export const GET_SLIDE_DETAILS = 'GET_SLIDE_DETAILS';
export const UPDATED_SLIDE = 'UPDATED_SLIDE';
export const EMPTY_BRANDS_EDIT_MESSAGE = 'EMPTY_BRANDS_EDIT_MESSAGE';
export const EMPTY_BRANDS_ADD_MESSAGE = 'EMPTY_BRANDS_ADD_MESSAGE';
export const EMPTY_DISPATCH = 'EMPTY_DISPATCH';
