import React, { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { RHFInput } from 'react-hook-form-input';
import { addMultipleAttribute, DeleteMultipleAttributes, getAttributeDetails, handleChangeAttributeInput, UpdatedAttribute } from '../../_redux/actions/AttributeAction';
import { GetCategoriesSortedList } from '../../../category/_redux/actions/CategoriesAction';
import PermissionWiseDisplay from '../../../../app/modules/role/PermissionWiseDisplay';

const AttributeEdit = withRouter(({ history }) => {
    const { id } = useParams()
    const { register, handleSubmit, setValue } = useForm();
    const dispatch = useDispatch();
    const attributeInputData = useSelector((state) => state.AttrubuteReducer.attributeInputData);
    const attributeValues = useSelector((state) => state.AttrubuteReducer.attributeValues);
    const isLoading = useSelector((state) => state.AttrubuteReducer.isLoading);

    const categoriesSortedList = useSelector((state) => state.categories.categoriesSortedList);
    const handleChangeTextInput = (name, value, e = null) => {
        dispatch(handleChangeAttributeInput(name, value, e));
    };

    const handleMultipleAttrubuteAdd = () => {
        dispatch(addMultipleAttribute(attributeInputData, attributeValues));
    }

    const deleteMultipleAttribute = (index, item = null) => {
        if (item !== null) {
            const deleted_values = [...attributeInputData.deleted_values, item];
            dispatch(handleChangeAttributeInput("deleted_values", deleted_values));
        }
        dispatch(DeleteMultipleAttributes(index));
    };

    const onSubmit = () => {
        dispatch(UpdatedAttribute(attributeInputData, id));
    };
    useEffect(() => {
        dispatch(GetCategoriesSortedList());
        dispatch(getAttributeDetails(id))
    }, [])
    return (
        <PermissionWiseDisplay permission_name="Attribute.Edit" display={false}>
            <div className="container-fluid">
                <div className="card card-custom gutter-b">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">Edit Attribute</h3>
                        </div>
                        <div className="card-toolbar">
                            <a onClick={() => { history.push("/attribute/list") }} >
                                <button type="button" classNamea="btn btn-primary">
                                    <i className="fa fa-arrow-left"></i> Attribute
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="card-body">
                        {/**add form */}
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            method="post"
                            autoComplete="off"
                            encType="multipart/form-data">

                            <div className="form-group row">
                                <div className="col-lg-3">
                                    <label className="form-label mt-2">Attribute Name</label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Attribute Name"
                                        name="name"
                                        className="fromStyle"
                                        value={attributeInputData.name}
                                        onChange={(e) => handleChangeTextInput('name', e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label mt-2">Category</label>
                                    <RHFInput
                                        as={<Select options={categoriesSortedList} />}
                                        rules={{ required: false }}
                                        name="category_id"
                                        register={register}
                                        value={attributeInputData.category}
                                        setValue={setValue}
                                        onChange={(option) => (
                                            handleChangeTextInput("category_id", option.value),
                                            handleChangeTextInput("category", option)
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-lg-3">
                                    <label className="form-label mt-2">Attribute Value</label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Attribute Value"
                                        name="value"
                                        className="fromStyle"
                                        value={attributeValues.value}
                                        onChange={(e) => handleChangeTextInput('value', e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label mt-2">Attribute Code</label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Attribute Code"
                                        name="code"
                                        className="fromStyle"
                                        value={attributeValues.code}
                                        onChange={(e) => handleChangeTextInput('code', e.target.value)}
                                    />
                                </div>
                                <div className="mt-5">
                                    <Button variant="primary" onClick={(e) => handleMultipleAttrubuteAdd(e)}><i class="fas fa-plus-circle"></i> Add</Button>
                                </div>
                            </div>

                            {
                                attributeInputData && attributeInputData.values.length > 0 && (
                                    <>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <table className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>SI</th>
                                                            <th>Value</th>
                                                            <th>Code</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            attributeInputData.values.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.value}</td>
                                                                    <td>{item.code}</td>
                                                                    <td style={{ width: 70, textAlign: "center" }}>
                                                                        <i className="fa fa-trash text-danger pointer" onClick={() => deleteMultipleAttribute(index, item)}> </i>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                            {isLoading &&
                                <Button type="submit" variant="primary" className="float-right">
                                    <span>Updating....</span>
                                    <span className="ml-3 spinner spinner-white"></span>
                                </Button>
                            }
                            {
                                !isLoading && <Button type="submit" variant="primary" className="float-right">UPDATE</Button>
                            }

                        </form>
                    </div>
                </div>
            </div>
        </PermissionWiseDisplay>
    );
});

export default AttributeEdit;
