import * as Types from "../types/Types";
import axios from "axios";
import { generateFormDataFromObject } from "../../../master/utils/FileHelper";
import { showToast } from "../../../master/utils/ToastHelper";
import { getImageUrl } from "../../../../services/FormatHelper";
import { printErrorMessages } from "../../../../services/ServerErrorMessages";

export const getPageList = (page, searchText = null) => async (dispatch) => {
	let responseList = {
		isLoading: true,
		data: {},
		status: false,
		pageList: [],
		pagePaginationList: []
	};
	dispatch({ type: Types.GET_PAGE_LIST, payload: responseList });
	let pageListUrl = "";
	pageListUrl = `${process.env.REACT_APP_API_URL}website/pages?isPaginated=1&isActive=1&paginateNo=20`;
	if (page !== null || page === "") {
		pageListUrl += `&page=${page}`;
	}
	if (searchText !== null) {
		pageListUrl += `&search=${searchText}&isActive=1`;
	} else {
		// url += `&certificate/details?search=${searchText}`
	}
	try {
		await axios.get(pageListUrl)
			.then((res) => {
				const { data, message, status } = res.data;
				responseList.status = status;
				responseList.pageList = data.data;
				responseList.message = message;
				responseList.pagePaginationList = data;
				responseList.isLoading = false;
			}).catch((err) => {
			});
	} catch (error) {
		responseList.message = "Something Went Wrong !";
		showToast('error', responseList.message);
	}
	responseList.isLoading = false;
	dispatch({ type: Types.GET_PAGE_LIST, payload: responseList });
};


/**
 * Get Page Details Data
 * 
 * Make image as null for first.
 * 
 * @param {int} pageID 
 * @returns 
 */
 export const getPageDetails = (pageID) => async (dispatch) => {

	let responseList = {
		isLoading: true,
		data: {},
		status: false,
		pageDetails: null,
	};

	dispatch({ type: Types.GET_PAGE_DETAILS, payload: responseList });

	try {
		await axios.get(`${process.env.REACT_APP_API_URL}website/pages/${pageID}`)
			.then((res) => {
				const { data, message, status } = res.data;
				responseList.status = status;
				responseList.pageDetails = data;
				responseList.message = message;
				responseList.isLoading = false;

				if (responseList.pageDetails) {
					responseList.pageDetails.imagePreviewUrl = getImageUrl(data.image, 'page');
					responseList.pageDetails.image = null;
				}

				dispatch({ type: Types.GET_PAGE_DETAILS, payload: responseList });
			}).catch((err) => {
				//something went wrong
			});
	} catch (error) {
		responseList.message = "Something Went Wrong !";
		showToast('error', responseList.message);
		dispatch({ type: Types.GET_PAGE_DETAILS, payload: responseList });
	}

}

/**
 * Input value change
 */
export const handleChangePageInput = (name, value, e = null) => (dispatch) => {
	let data = {
		name: name,
		value: value,
	}
	dispatch({ type: Types.CHANGE_PAGE_INPUT, payload: data });
	if (name === 'image') {
		let reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			if (name === "image") {
				data.name = 'imagePreviewUrl';
			}
			data.value = reader.result;
			dispatch({ type: Types.CHANGE_PAGE_INPUT, payload: data });
		}
		reader.readAsDataURL(file)
	}
};

//handle delete preview image
export const deletePageImage = (name) => (dispatch) => {
	let data = {
		name: name,
		value: null,
	}
	dispatch({ type: Types.CHANGE_PAGE_INPUT, payload: data });

	if (name === 'image') {
		data.name = 'imagePreviewUrl';
	}
	dispatch({ type: Types.CHANGE_PAGE_INPUT, payload: data });
};

//store page
export const storePage = (formInput) => async (dispatch) => {
	let responseData = {
		status: false,
		isLoading: true,
	};
	dispatch({ type: Types.ADD_PAGE, payload: responseData });
	const headers = {
		"Content-Type": "application/json",
	};
	const pageData = generateFormDataFromObject(formInput)
	axios.post(`${process.env.REACT_APP_API_URL}website/pages`, pageData, {
		headers: headers,
	})
		.then((res) => {
			if (res.data.status) {
				let data = res.data;
				responseData.message = data.message;
				responseData.status = data.status;
				responseData.isLoading = false;
				showToast('success', responseData.message);
				dispatch({ type: Types.ADD_PAGE, payload: responseData });
			}
		}).catch(err => {
			const errorMessage = "Sorry, Something went wrong!"
			responseData.isLoading = false;
			const inputError = err.request.response ? JSON.parse(err.request.response).errors : null;

			if (inputError) {
				showToast('error', printErrorMessages(inputError));
			} else {
				showToast('error', errorMessage);
			}
			dispatch({ type: Types.ADD_PAGE, payload: responseData });
		});
};

//updated page details
export const updatePage = (formInput, id) => async (dispatch) => {
	let responseData = {
		status: false,
		isLoading: true,
	};

	dispatch({ type: Types.UPDATE_PAGE, payload: responseData });

	const headers = {
		"Content-Type": "application/json",
	};

	const pageData = generateFormDataFromObject(formInput)

	axios.post(`${process.env.REACT_APP_API_URL}website/pages/${id}?_method=PUT`, pageData, {
		headers: headers,
	})
		.then((res) => {
			if (res.data.status) {
				let data = res.data;
				responseData.message = data.message;
				responseData.status = data.status;
				responseData.isLoading = false;
				showToast('success', responseData.message);
				dispatch({ type: Types.UPDATE_PAGE, payload: responseData });
			}
		}).catch((err) => {
			const errorMessage = "Sorry, Something went wrong!"
			responseData.isLoading = false;
			showToast('error', errorMessage);
			dispatch({ type: Types.UPDATE_PAGE, payload: responseData });
		});
};

/**
 * Delete a Page
 *
 * @param {int} id
 * @returns
 */
export const handleDeletePage = (id) => (dispatch) => {
	let isLoading = true;
	dispatch({ type: Types.DELETE_PAGE_DATA, payload: isLoading })
	axios.delete(`${process.env.REACT_APP_API_URL}website/pages/${id}`)
		.then((res) => {
			if (res.data.status) {
				const data = res.data;
				showToast('success', data.message);
				dispatch({ type: Types.DELETE_PAGE_DATA, payload: false });
				dispatch(getPageList())
			}
		})
}

export const emptyPageInputAction = () => (dispatch) => {
	dispatch({ type: Types.EMPTY_PAGE_INPUT, payload: null });
}
