import React from "react";
import PermissionWiseDisplay from "../../../app/modules/role/PermissionWiseDisplay";
import BusinessAdd from "../components/BusinessAdd";

const BusinessEditContainer = (props) => {
    return (
        <PermissionWiseDisplay permission_name="Shop.Edit,Shop.Edit.Own" display={true}>
            <BusinessAdd {...props} id={props.match.params.id} />
        </PermissionWiseDisplay>
    );
};
export default BusinessEditContainer;
