import React, { useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { useState } from 'react';
import './DateRange.css'

const DateRange = ({ setDate, handleClose }) => {

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 1),
            key: 'selection'
        },
    ]);

    const handleSelect = (item) => {
        setDateRange([item.selection]);
        handleClose();
    }

    useEffect(() => {
        setDate(dateRange);
    }, [dateRange])

    return (

        <div className="w-100">
            <DateRangePicker
                // onChange={item => setDateRange([item.selection])}
                onChange={item => handleSelect(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={dateRange}
                direction="horizontal"
            />;
        </div>
    );
};

export default DateRange;