import React from "react";
import PollEdit from "../../components/Poll/PollEdit";

const PollEditContainer = (props) => {
    return (
        <>
            <PollEdit props={props} />
        </>
    );
};

export default PollEditContainer;
