import React from 'react';
import PermissionWiseDisplay from '../../../../app/modules/role/PermissionWiseDisplay';
import GiftCardsList from '../../components/giftCards/GiftCardsList';

const GiftCardsListContainer = () => {
  return (
    <>
      <PermissionWiseDisplay permission_name="Giftcard.View" display={true}>
        <GiftCardsList />
      </PermissionWiseDisplay>
    </>
  );
};

export default GiftCardsListContainer;
