import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { RHFInput } from 'react-hook-form-input';
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { getDiscountTypeList } from '../../../master/OptionData/DiscountType/DiscountTypeAction/DiscountTypeAction';
import SimpleEditor from '../../../master/components/text-editor/SimpleEditor';
import { deletePreviewImage, handleCouponInputChange, handleCouponSubmit } from '../../_redux/actions/CouponAction';
import moment from "moment"
const CouponCrate = () => {
    const { register, handleSubmit, errors, setValue } = useForm();
    const dispatch = useDispatch();
    const history = useHistory()

    const dicountTypeList = useSelector((state) => state.DiscountTypeReducer.dicountTypeList);
    const couponInputData = useSelector((state) => state.CouponReducer.couponInputData);
    const isLoading = useSelector((state) => state.CouponReducer.isLoading);

    const handleChangeTextInput = (name, value, e = null) => {
        dispatch(handleCouponInputChange(name, value, e))
    }

    const amountType = [
        { label: 'Percentage', value: 'percentage' },
        { label: 'Numeric', value: 'numeric' }
    ]
    useEffect(() => {
        dispatch(getDiscountTypeList());
    }, [])
    const onSubmit = async (e) => {
        dispatch(handleCouponSubmit(couponInputData));
    };
    return (
        <>
            <div className="container-fluid">
                <div className="card card-custom gutter-b">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">Coupon Add</h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <form
                            className="form form-label-right"
                            onSubmit={handleSubmit(onSubmit)}
                            method="post"
                            autoComplete="off"
                            encType="multipart/form-data"
                        >
                            <div className="form-group row">
                                <div className="col-lg-3">
                                    <label className="form-label mt-2">Coupon Code</label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Brands name"
                                        name="code"
                                        className="fromStyle"
                                        value={couponInputData.code}
                                        onChange={(e) => handleChangeTextInput('code', e.target.value)}
                                        ref={register({
                                            required: false,
                                            maxLength: 100,
                                        })}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label mt-2">Discount Type</label>
                                    <RHFInput
                                        as={<Select options={dicountTypeList} />}
                                        rules={{ required: false }}
                                        name="coupon_type_id"
                                        register={register}
                                        value={couponInputData.coupon_type_id}
                                        setValue={setValue}
                                        onChange={(option) => (
                                            handleChangeTextInput("coupon_type_id", option.value),
                                            handleChangeTextInput("coupon_type", option)
                                        )}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label mt-2">Amount Type</label>
                                    <RHFInput
                                        as={<Select options={amountType} />}
                                        rules={{ required: false }}
                                        name="amount_type"
                                        register={register}
                                        value={couponInputData.amount_type}
                                        setValue={setValue}
                                        onChange={(option) => (
                                            handleChangeTextInput("amount_type", option.value),
                                            handleChangeTextInput("amount", option)
                                        )}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label mt-2">Discount Amount</label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Description"
                                        name="coupon_amount"
                                        className="fromStyle"
                                        value={couponInputData.coupon_amount}
                                        onChange={(e) => handleChangeTextInput('coupon_amount', e.target.value)}
                                        ref={register({
                                            required: false,
                                            maxLength: 100,
                                        })}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label mt-2">Min Amount</label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Minimum"
                                        name="min_amount"
                                        className="fromStyle"
                                        value={couponInputData.min_amount}
                                        onChange={(e) => handleChangeTextInput('min_amount', e.target.value)}
                                        ref={register({
                                            required: false,
                                            maxLength: 100,
                                        })}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label mt-2">Max Amount</label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Maximum"
                                        name="max_amount"
                                        className="fromStyle"
                                        value={couponInputData.max_amount}
                                        onChange={(e) => handleChangeTextInput('max_amount', e.target.value)}
                                        ref={register({
                                            required: false,
                                            maxLength: 100,
                                        })}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label mt-2">Coupon Start Date</label>
                                    {/* <DatePicker
                                        name="coupon_start_date"
                                        className="form-control fromStyle formHeight"
                                        placeholderText="select start date"
                                        selected={couponInputData.coupon_start_date}
                                        onChange={(e) =>
                                            handleChangeTextInput("coupon_start_date", e)
                                        }
                                        ref={register({
                                            required: false,
                                            maxLength: 100,
                                        })}
                                    /> */}
                                    <DatePicker
                                        name="coupon_start_date"
                                        type="date"
                                        className="form-control fromStyle formHeight"
                                        placeholderText="select expiry date"
                                        // dateFormat="MM-dd-yyyy"
                                        name="coupon_expiry_date"
                                        selected={couponInputData.coupon_start_date !== '' ? moment(couponInputData.coupon_start_date).toDate() : null}
                                        onChange={(date) => handleChangeTextInput("coupon_start_date", date)}
                                        ref={register({
                                            required: true,
                                            maxLength: 100,
                                        })}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label mt-2">Coupon Expire Date</label>
                                    <DatePicker
                                        name="coupon_expiry_date"
                                        type="date"
                                        minDate={couponInputData.coupon_start_date}
                                        className="form-control fromStyle formHeight"
                                        placeholderText="select expiry date"
                                        // dateFormat="MM-dd-yyyy"
                                        selected={couponInputData.coupon_expiry_date !== '' ? moment(couponInputData.coupon_expiry_date).toDate() : null}
                                        onChange={(date) => handleChangeTextInput("coupon_expiry_date", date)}
                                        ref={register({
                                            required: true,
                                            maxLength: 100,
                                        })}
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <label className="form-label">Total Per-user Limit</label>
                                    <Form.Control
                                        type="number"
                                        placeholder="total per user limit"
                                        name="usage_limit_per_user"
                                        className="fromStyle"
                                        value={couponInputData.usage_limit_per_user}
                                        onChange={(e) => handleChangeTextInput('usage_limit_per_user', e.target.value)}
                                        ref={register({
                                            required: false,
                                            maxLength: 100,
                                        })}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Image</label>
                                    <Form.Control
                                        type="file"
                                        placeholder="Enter Image"
                                        name="image"
                                        onChange={(e) => handleChangeTextInput('image', e.target.files[0], e)}
                                        className="fromStyle"
                                        ref={register({
                                            required: false,
                                            maxLength: 100,
                                        })}
                                    />
                                    {
                                        couponInputData.imagePreviewURl !== null &&
                                        <div className="imgPreview" title="Remove">
                                            <div className="preview-delete-icon"><i className="fa fa-times text-danger" onClick={() => dispatch(deletePreviewImage('image'))}></i></div>
                                            <img src={couponInputData.imagePreviewURl} className="img img-thumbnail" />
                                        </div>
                                    }
                                </div>

                                <div className="col-lg-6">
                                    <label className="form-label">Description</label>
                                    <SimpleEditor
                                        name="description"
                                        value={couponInputData.description}
                                        onValueChange={(e) => handleChangeTextInput('description', e)}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <Form.Check
                                        type="checkbox"
                                        label="Allow Free Shipping"
                                        name="is_free_shiping"
                                        id="is_free_shiping"
                                        value={couponInputData.is_free_shiping}
                                        onChange={(e) => 'is_free_shiping', couponInputData.is_free_shiping === '0' ? 1 : 0}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <Form.Check
                                        type="checkbox"
                                        label="Is Individual Use"
                                        name="is_individual_use"
                                        id="is_individual_use"
                                        value={couponInputData.is_individual_use}
                                        onChange={(e) => 'is_individual_use', couponInputData.is_individual_use === '0' ? 1 : 0}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-sm-10">
                                    <button type="button" className="btn btn-secondary btn-lg mr-2" onClick={() => { history.push('/coupon/list') }}>
                                        Back
                                    </button>

                                    {isLoading && (
                                        <button type="submit" className="btn btn-primary btn-lg" >
                                            <span>Submitting...</span>
                                            <span className="ml-3 spinner spinner-white"></span>
                                        </button>
                                    )}

                                    {!isLoading &&
                                        <button type="submit" className="btn btn-primary btn-lg">
                                            <span>Submit</span>
                                        </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CouponCrate;
