import React from 'react';
import { Link } from 'react-router-dom';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import CouponList from '../components/list/CouponList';

const CouponListContainer = () => {

  return (
    <PermissionWiseDisplay permission_name="Coupon.View" display={true}>
      <CouponList />
    </PermissionWiseDisplay>
  );
};

export default CouponListContainer;
