import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import CategoryFilter from '../components/list/CategoryFilter';
import CategoryList from '../components/list/CategoryList';

const CategoryListContainer = () => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <PermissionWiseDisplay permission_name='category.view' display={true}>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="row w-100 align-items-center py-3">

              <div className="col-md-2">
                <div className="card-title">
                    <h3 className="card-label">Categories</h3>
                </div>
              </div>
  
              <div className="col-md-8">
                <CategoryFilter />
              </div>


                <div className="col-md-2">
                  <PermissionWiseDisplay permission_name="category.create" display={false}>
                    <Link to="/categories/create">
                        <button type="button" className="btn btn-primary">
                        <i className="fa fa-plus"></i> New Category
                        </button>
                    </Link>
                  </PermissionWiseDisplay>
                </div>

            </div>
          </div>
          <CategoryList />
        </div>
      </div>
    </PermissionWiseDisplay>
  );
};

export default CategoryListContainer;
