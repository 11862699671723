import React from 'react';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import CouponCrate from '../components/create/CouponCreate';

const CouponAddContainer = () => {
    return (
        <PermissionWiseDisplay permission_name="Coupon.Create" display={true}>
            <CouponCrate />
        </PermissionWiseDisplay>
    );
};

export default CouponAddContainer;