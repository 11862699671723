import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formatDate } from '../../../services/DateHelper';
import PaginationLaravel from '../../master/pagination/PaginationLaravel';
import LoadingSpinner from '../../master/spinner/LoadingSpinner';
import { GetCustomerList } from '../_redux/actions/CustomerAction';

const CustomerList = () => {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const { customerList, customerPaginatedData, isLoading } = useSelector((state) => state.customer);

	useEffect(() => {
		dispatch(GetCustomerList(currentPage));
	}, []);

	const changePage = (data) => {
		setCurrentPage(data.page);
		dispatch(GetCustomerList(data.page));
	};

	return (
		<>
			<div className="container">
				<div className="card card-custom gutter-b">
					<div className="card-header">
						<div className="card-title">
							<h3 class="card-label">Customers</h3>
						</div>
					</div>
					<div className="card-body">
						{isLoading && <LoadingSpinner text="Loading Customers..." />}

						<div className="react-bootstrap-table table-responsive">
							<table className="table table table-head-custom table-vertical-center">
								<thead>
									<tr>
										<td>SL</td>
										<td>Customer name</td>
										<td>Email</td>
										<td>Phone</td>
										<td>Registered Since</td>
										<td>Ip</td>
										<td>Browser</td>
										<td>Device</td>
										<td>Division</td>
										<td>Zilla</td>
										<td>Upazilla</td>
										<td>Village & Address</td>
										<td>Status</td>
										{/* <td>Action</td> */}
									</tr>
								</thead>
								<tbody>
									{customerList && customerList.map((customer, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>{customer.first_name + ' ' + customer.last_name}</td>
											<td>
												<a href={`mailto:${customer.email}`}><i className="fa fa-envelope"></i> {customer.email}</a>
											</td>
											<td>
												<a href={`tel:${customer.phone_no}`}><i className="fa fa-phone"></i> {customer.phone_no}</a>
											</td>
											<td>{formatDate(customer.created_at)}</td>
											<td>{customer.ip ? customer.ip : '0.0.0.0'}</td>
											<td>{customer.browser ? customer.browser : 'default'}</td>
											<td>{customer.device ? customer.device : 'default'}</td>
											<td>{customer.division}</td>
											<td>{customer.city}</td>
											<td>{customer.area}</td>
											<td>
												{customer.street1},
												{customer.street2}
											</td>
											<td>
												<Badge variant={customer.status === 'inactive' ? "warning" : "success"}>{customer.status === 'inactive' ? "Inactive" : "Active"}</Badge>
											</td>
											{/* <td></td> */}
										</tr>
									))}

									{!customerList.length && (
										<tr className="text-danger text-center">
											<td colspan={8}>No Customer Found.</td>
										</tr>
									)}
								</tbody>
							</table>

							<PaginationLaravel
								changePage={changePage}
								data={customerPaginatedData}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default CustomerList;
