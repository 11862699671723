import React from 'react';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import UnitsAdd from '../components/UnitsAdd';


const UnitsAddContainer = () => {
  return (
    <>
      <PermissionWiseDisplay permission_name="Unit.Create" display={true}>
        <UnitsAdd />
      </PermissionWiseDisplay>
    </>
  );
};

export default UnitsAddContainer;
