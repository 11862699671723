import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getInvoicesList } from "../../_redux/actions/InvoiceAction";
import moment from "moment";
import PaginationLaravel from "../../../master/pagination/PaginationLaravel";
import LoadingSpinner from "../../../master/spinner/LoadingSpinner";
import { Dropdown } from "react-bootstrap";
import SimpleModal from "../../../master/components/Modal/SimpleModal";
import InvoiceDetails from "../view/InvoiceDetails";
import { useHistory } from "react-router-dom";
import ViewInvoice from "../view/ViewInvoice";
const OrderList = () => {
  const dispatch = useDispatch()
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const InvoicesList = useSelector((state) => state.InvoiceReducer.InvoicesList)
  const InvoicesPaginatedList = useSelector((state) => state.InvoiceReducer.InvoicesPaginatedList)
  const isLoading = useSelector((state) => state.InvoiceReducer.isLoading)

  useEffect(() => {
    dispatch(getInvoicesList(currentPage));
  }, [dispatch, currentPage]);

  const changePage = (data) => {
    setCurrentPage(data.page);
    dispatch(getInvoicesList(data.page));
  };
  const searchItems = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText.length === 0) {
      dispatch(getInvoicesList(currentPage));
    } else {
      dispatch(getInvoicesList(currentPage, searchText));
    }
  };
  const [ShowInvoicesModel, setShowInvoicesModel] = useState(false);
  const [invoiceView, setInvoiceView] = useState(false);
  const [InvoicesID, setInvoicesID] = useState(null);

  const handleShowInvoiceModel = (item) => {
    setShowInvoicesModel(true);
    setInvoicesID(item.id);
  }
  const handleShowInvoiceViewModel = (item) => {
    setInvoiceView(true);
    setInvoicesID(item.id);
  }
  return (
    <div className="card-body">
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Invoices</h3>
          </div>
          <div className="card-toolbar">
            <input
              autoComplete="off"
              type="text"
              className="form-control"
              name="searchText"
              placeholder="Search"
              value={searchText}
              onChange={searchItems}
            />
          </div>
        </div>
        {isLoading && <LoadingSpinner text="Loading Invoices List..." />}
        <div className="react-bootstrap-table table-responsive">
          <table className="table table table-head-custom table-vertical-center">
            <thead>
              <tr>
                <th>SL</th>
                <th>Seller Invoice</th>
                <th>Invoice No</th>
                <th>Total Amount</th>
                <th>Grand Total</th>
                <th>Paid</th>
                <th>Orders Date</th>
                <th className="f-width">Status</th>
                <th className="f-width">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                InvoicesList && InvoicesList.map((item, index) => ( 
                  <tr key={index}>
                    <td>{InvoicesPaginatedList.from + index}</td>
                    <td>#{item.invoice_no !== null ? item.invoice_no : '---'}</td>
                    <td>#{item.transaction_id !== null ? item.transaction_id : '---'}</td>
                    <td>{item.total_amount !== null ? item.total_amount : '---'} ৳</td>
                    <td>{item.grand_total !== null ? item.grand_total : '---'} ৳</td>
                    <td>{item.paid_total !== null ? item.paid_total : '---'} ৳</td>
                    <td>{item.created_at !== null ? moment(item.created_at).format("DD-MM-YYYY") : '---'}</td>
                    <td>
                      <div className={`badge text-light ${item.status.toLowerCase() === 'due' ? 'badge-warning' : item.status.toLowerCase() === 'processing' ? 'badge-primary' : item.status.toLowerCase() === 'completed' ? 'badge-success' : item.status.toLowerCase() === 'cancelled' && 'badge-danger' }`}>
                        {item.status !== null ? item.status : '---'}
                      </div>
                    </td>
                    <td><Dropdown>
                      <Dropdown.Toggle variant="success"> Action </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={()=> handleShowInvoiceViewModel(item)}>View</Dropdown.Item>
                        <Dropdown.Item onClick={()=> handleShowInvoiceModel(item)}>Details</Dropdown.Item>
                        <Dropdown.Item onClick={()=> history.push(`/invoices/lifeCycle/${item.id}`) }>Life Cycle</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {!isLoading && InvoicesList && InvoicesList.length === 0 && (
            <div className="alert alert-warning mt-5 m-3">
              Sorry ! No Invoice Found.
            </div>
          )}
          <PaginationLaravel
            changePage={changePage}
            data={InvoicesPaginatedList}
          />
        </div>
        <SimpleModal
          size="xl"
          show={ShowInvoicesModel}
          handleClose={() => setShowInvoicesModel(false)}
          handleShow={() => setShowInvoicesModel(true)}
          modalTitle={"Invoice Details"}
        >
          <InvoiceDetails handleClose={() => setShowInvoicesModel(false)} id={InvoicesID} />
        </SimpleModal>
        <SimpleModal
          size="xl"
          show={invoiceView}
          handleClose={() => setInvoiceView(false)}
          handleShow={() => setInvoiceView(true)}
          modalTitle={"Invoice View"}
        >
          <ViewInvoice handleClose={() => setInvoiceView(false)} id={InvoicesID} />
        </SimpleModal>
      </div>
    </div>
  );
};

export default OrderList;
