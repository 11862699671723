import * as Types from "../types/Types";
import { formatSelect2Options } from '../../../../services/FormatData';
const initialState = {
  productOfferData: {
    offer_name         : "",
    selection_type     : "",
    item_id            : "",
    category_id        : "",
    brand_id           : "",
    business_id        : "",
    current_price      : "",
    offer_price        : "",
    offer_price_type   : "percentage",
    offer_type         : "",
    start_date         : "",
    end_date           : "",
    is_unlimited       : "0",
    is_visible         : "1",
    apply_only_no_offer: "0",
    multiple_items     : ""
  },
  isLoading          : false,
  isStatusChanging   : false,
  offerList          : [],
  offerTypes         : [],
  offerPaginationList: [],
  itemDetails        : null,
  isOfferUpdated     : false,
};
const ProductOfferReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.CHANGE_OFFER_INPUT:
      const productOfferData = { ...state.productOfferData };
      productOfferData[action.payload.name] = action.payload.value;
      return {
        ...state,
        productOfferData,
      };

    case Types.STORE_PRODUCT_OFFER:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        isOfferUpdated: action.payload.isOfferUpdated,
      };

    //get coupon list
    case Types.GET_OFFER_LIST:
      if (action.payload.status) {
        return {
          ...state,
          offerList: action.payload.offerList,
          offerPaginationList: action.payload.offerPaginationList,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: true,
        };
      }

    case Types.GET_OFFER_TYPE_LIST:
      const payloadData = action.payload || [];
      return {
        ...state,
        offerTypes: formatSelect2Options(payloadData, { 'value': 'slug', 'label': 'name' }) // Process to handle select2 options
      };

    case Types.GET_OFFER_DETAILS:
        return {
          ...state,
          productOfferData: action.payload.itemDetails,
          itemDetails: action.payload.isLoading ? null : action.payload.itemDetails,
          isOfferUpdated: false
        };

    case Types.CHANGE_OFFER_STATUS:
      return {
        ...state,
        isStatusChanging: action.payload.isStatusChanging,
      };

    case Types.DELETE_OFFER:
      return {
        ...state,
        isLoading: action.payload,
      };

    case Types.EMPTY_DISPATCH:
      return {
        ...state,
        productOfferData: initialState.productOfferData,
        isOfferUpdated: false,
        itemDetails: null
      };
    default:
      break;
  }
  return newState;
};

export default ProductOfferReducer;
