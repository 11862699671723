/*******************************************************************************************************
        Sub Module: BRANDS
********************************************************************************************************/
export const GET_BRANDS_LIST = 'GET_BRANDS_LIST';
export const ADD_BRANDS = 'ADD_BRANDS';
export const UPDATE_BRANDS = 'UPDATE_BRANDS';
export const EMPTY_DISPATCH = 'EMPTY_DISPATCH';
export const CHANGE_BRANDS_INPUT = 'CHANGE_BRANDS_INPUT';
export const EMPTY_BRANDS_EDIT_MESSAGE = 'EMPTY_BRANDS_EDIT_MESSAGE';
export const EMPTY_BRANDS_ADD_MESSAGE = 'EMPTY_BRANDS_ADD_MESSAGE';
export const GET_BRANDS_DETAILS = 'GET_BRANDS_DETAILS';
export const DELETE_BRAND_DATA = 'DELETE_BRAND_DATA';