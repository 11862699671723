import * as Types from "./Types";
import axios from "axios";

export const getBestSoldProductsAction = () => (dispatch) => {
    let responseData = {
        labels: [],
        data: [],
        isLoading: true
    }
    dispatch({ type: Types.GET_BEST_SOLD_PRODUCT_DATA, payload: responseData });
    axios.get(`${process.env.REACT_APP_API_URL}statistics/get-best-sale-products`)
        .then((res) => {
            responseData.isLoading = false;
            res.data.data.forEach(element => {
                responseData.data.push(element.total_sale);
                responseData.labels.push(element.item_name.substring(0, 8) + '..');
            });
            dispatch({ type: Types.GET_BEST_SOLD_PRODUCT_DATA, payload: responseData });
        })
        .catch(err => {
            responseData.isLoading = false;
            dispatch({ type: Types.GET_BEST_SOLD_PRODUCT_DATA, payload: responseData });
        });
}
