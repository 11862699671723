import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { getDashbaordData } from "../redux/actions/DashboardAction";
import DougHuntChart from '../../master/components/Chart/DougHuntChart';
import BarChart from '../../master/components/Chart/BarChart';
import BestSoldProductChart from "./best-sold/BestSoldProductChart";
import { formatCurrency } from "../../../services/currency";
import LoadingSpinner from "../../master/spinner/LoadingSpinner";

const DashBoardCard = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { cardDatas, loadingCardDatas } = useSelector((state) => state.dashboardReducer);

	useEffect(() => {
		dispatch(getDashbaordData());
	}, []);

	const bg = ['#129021', '#CF0633', '#F85108', '#0FA49B', '#047EDF', '#352D7B', '#352D7B', '#047EDF', '#0FA49B', '#F85108', '#CF0633', '#129021',]

	const formatData = (data) => {
		if (data.type === 'number') {
			if (data.value > 999 && data.value < 1000000) {
				return (data.value / 1000).toFixed(0) + 'K';
			} else if (data.value > 1000000) {
				return (data.value / 1000000).toFixed(0) + 'M';
			}

			return formatCurrency(data.value, true, '')
		}

		return data.value
	}

	return (
		<>
			{/* <PermissionWiseDisplay */}
			<div className="container-fluid dashboard__pb dashboard-cardsection mb-5 mt-5">
				{
					loadingCardDatas &&
					<div className="card card-body p-4">
						<LoadingSpinner text="Loading Dashboard Data ..." />
					</div>
				}
				<div className="row">
					{
						cardDatas.map((data, index) => {
							return (
								<div className="col-xl-2 col-lg-2 col-md-4 mb-6" key={index}>
									<div className="paid__widget pointer" style={{ backgroundColor: bg[index], color: data['text-color'] }} onClick={() => history.push(data.url)}>
										<div className="widget__left">
											{/* <img src={data.icon} alt={data.title} className="float-left" /> */}
											<h4 className="float-left ml-2 mt-1">
												{formatData(data)}
											</h4>
											<div className="clearfix"></div>
										</div>
										<div className="widget__right">
											<p>{data.title}</p>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>

				<div className="row mt-5">
					<div className="col-lg-4 col-12 mb-3">
						<BestSoldProductChart />
					</div>
					<div className="col-lg-4 col-12 mb-3">
						<DougHuntChart />
					</div>
					<div className="col-lg-4 col-12 mb-3">
						<BarChart />
					</div>
				</div>
			</div>
		</>
	);
};

export default DashBoardCard;
