import * as Types from '../types/Types';

const initialState = {
    voucherInputData: {
        title: "",
        price_value_for: "",
        change_price_value: "",
        description: "",
        image: "",
        imagePreviewURl: ""
    },
    isLoading: false,
    voucherListData: [],
    voucherPaginateList: [],

};

const VoucherReducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case Types.CHANGE_VOUCER_INPUT_DATA:
            const voucherInputData = { ...state.voucherInputData };
            voucherInputData[action.payload.name] = action.payload.value;
            return {
                ...state,
                voucherInputData,
            };
        case Types.SUBMIT_VOUCHER:
            if (action.payload.status) {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                    voucherInputData: initialState.voucherInputData,
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            }
        case Types.GET_VOUCHER_LIST:
            return {
                ...state,
                voucherListData: action.payload.voucherListData,
                voucherPaginateList: action.payload.voucherPaginateList,
                isLoading: action.payload.isLoading,
            };
        case Types.DELETE_VOUCHER_DATA:
            return {
                ...state,
                isLoading: action.payload,
            };
        case Types.GET_VOUCHER_DETAILS:
            return {
                ...state,
                voucherInputData: action.payload.voucherDetails,
                // isLoading: action.payload.isLoading,
            };
        //UPDATED GIFT CARDS
        case Types.UPDATED_VOUCHER_DATA:
            if (action.payload.status) {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                    // voucherInputData: initialState.voucherInputData,
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            }
        default:
            break;
    }
    return newState;
};

export default VoucherReducer;
