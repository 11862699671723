import React from 'react';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import BusinessList from '../components/BusinessList';

const BusinessListContainer = () => {
  return (
    <PermissionWiseDisplay permission_name="Shop.List" display={true}>
      <BusinessList />
    </PermissionWiseDisplay>
  );
};
export default BusinessListContainer;
