import * as Types from "../types/Types";
import axios from "axios";

export const getDashbaordData = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}statistics/get-dashboard-data`)
        .then((res) => {
            dispatch({ type: Types.GET_DASHBOARD_DATA, payload: res.data.data });
        }).catch(err => {
            dispatch({ type: Types.GET_DASHBOARD_DATA, payload: [] });
        });
}