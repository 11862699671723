import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import TextHelp from "../../../master/components/labels/TextHelp";
import {
  getOfferTypes,
  handleOfferInputChange,
  handleStoreOffer,
  getOfferDetails
} from "../../_redux/actions/ProductOfferAction";
import { getSelectedOption } from "../../../../services/FormatData";
import { getToDate, getCurrentDate } from "../../../../services/DateHelper";
import LabelOptional from "../../../master/components/labels/LabelOptional";
import LabelRequired from "../../../master/components/labels/LabelRequired";
import { getOfferForData } from "../../data/data";
import { getPriceTypes } from "../../../item/data/data";
import LoadingSpinner from "../../../master/spinner/LoadingSpinner";
import { showToast } from "../../../master/utils/ToastHelper";
import bulkOfferDemo from '../../files/bulk-offer-demo.csv';

const ProductOfferCreate = () => {
  const {id} = useParams()
  const dispatch = useDispatch();
  const history = useHistory();
  const selectionTypes = getOfferForData();
  const offerPriceTypes = getPriceTypes();

  const { register, handleSubmit, errors, setValue } = useForm();
  const { productOfferData, offerTypes, isLoading, itemDetails, isOfferUpdated } = useSelector((state) => state.ProductOffer);
  const { BrandsOptionList } = useSelector( (state) => state.BrandOptionListReducer);
  const { categoriesSortedList } = useSelector((state) => state.categories);
  const { businessList } = useSelector((state) => state.BusinessOptionList);
  const { itemOptionList } = useSelector((state) => state.ItemOptionListReducer);

  const handleChangeTextInput = (name, value, e = null) => {
    dispatch(handleOfferInputChange(name, value, e));
  };

  const [selectedFile, setSelectedFile] = useState(undefined);
  const onFileChangeHandler = (e) => {
      const selected_file = e.target.files[0];
      if(selected_file) {
          setSelectedFile(selected_file)
          handleChangeTextInput("multiple_items_csv", selected_file)
      }
  }

  const onSubmit = () => {
    if(productOfferData.selection_type === 'multiple_items_csv') {
      if(!selectedFile) {
        showToast('error', 'Please give a csv file to give offer.');
        return;
      }
    }

    dispatch(handleStoreOffer(productOfferData, id));
  };

  useEffect(() => {
    dispatch(getOfferTypes());
  }, []);

  useEffect(() => {
    if ( typeof id !== 'undefined' && id !== null && id !== '' ) {
      dispatch(getOfferDetails(id));
    }
  }, [id]);

  const [isDetailLoading, setDetailLoading] = useState(false);
  useEffect(() => {
    if ( typeof id !== 'undefined' && id !== null && id !== '' ) {
      setDetailLoading(true);
      if ( typeof itemDetails !== 'undefined' && itemDetails !== null ) {
        setDetailLoading(false);

        // Select and add some pre-defined inputs.
        setValue("selection_type", getSelectedOption(selectionTypes, productOfferData.selection_type));
        setValue("offer_type", getSelectedOption(offerTypes, productOfferData.offer_type));
        if (productOfferData.selection_type === "category") {
          setValue("category_id", getSelectedOption(categoriesSortedList, productOfferData.category_id));
        }
        if (productOfferData.selection_type === "brand") {
          setValue("brand_id", getSelectedOption(BrandsOptionList, productOfferData.brand_id));
        }
        if (productOfferData.selection_type === "shop") {
          setValue("business_id", getSelectedOption(businessList, productOfferData.business_id));
        }
        if (productOfferData.selection_type === "item") {
          setValue("item_id", getSelectedOption(itemOptionList, productOfferData.item_id));
        }
      }
    }
  }, [id, itemDetails]);

  useEffect(() => {
    if (isOfferUpdated) {
      history.push("/offer-product/list");
    }
  }, [isOfferUpdated]);


  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">
                { typeof id === 'undefined' ? 'Create New ' : 'Edit ' } Offer &nbsp;&nbsp;
                {
                  <small classname="badge badge-default">
                    {productOfferData.offer_name}
                  </small>
                }
              </h3>
            </div>
          </div>

          <div className="card-body">
            <form
              className="form form-label-right"
              onSubmit={handleSubmit(onSubmit)}
              method="post"
              autoComplete="off"
              encType="multipart/form-data"
            >
              <div className="bg-light p-2">
                {
                  (isDetailLoading === true) &&
                  <LoadingSpinner text="Loading Product Offer data..." />
                }
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label className="form-label mt-2">
                      Offer Name <LabelOptional />
                    </label>
                    <Form.Control
                      type="text"
                      placeholder="eg: Happy new year offer"
                      name="offer_name"
                      className="fromStyle"
                      value={productOfferData.offer_name}
                      onChange={(e) =>
                        handleChangeTextInput("offer_name", e.target.value)
                      }
                      ref={register({
                        required: false,
                        maxLength: 50,
                      })}
                    />
                    <TextHelp text="Give Offer a Name. Or Keep it blank" />
                  </div>

                  <div className="col-lg-3">
                    <label className="form-label mt-2">Offer for ? <LabelRequired /></label>
                    <RHFInput
                      as={<Select options={selectionTypes} />}
                      rules={{ required: true }}
                      name="selection_type"
                      register={register}
                      placeholder="Select Offer for ?"
                      rules={{ required: true }}
                      value={getSelectedOption(selectionTypes, productOfferData.selection_type)}
                      setValue={setValue}
                      onChange={(option) =>
                        handleChangeTextInput("selection_type", option.value)
                      }
                    />
                    <div className="inputError margin-minus-8">
                      {errors.selection_type &&
                        errors.selection_type.type === "required" &&
                        "Please select a type for which offer will be applied."}
                    </div>
                  </div>

                  {productOfferData.selection_type === "item" && (
                    <div className="col-lg-3">
                      <label className="form-label mt-2">
                        Select Specific Product <LabelRequired />
                      </label>
                      <RHFInput
                        as={<Select options={itemOptionList} />}
                        rules={{ required: false }}
                        name="item_id"
                        register={register}
                        placeholder="Select Item"
                        rules={{ required: false }}
                        value={getSelectedOption(itemOptionList, productOfferData.item_id)}
                        setValue={setValue}
                        onChange={(option) => (
                          handleChangeTextInput("item_id", option.value),
                          handleChangeTextInput("item", option)
                        )}
                      />
                    </div>
                  )}

                  {productOfferData.selection_type === "multiple_items" && (
                    <div className="col-lg-3">
                      <label className="form-label mt-2">
                        Item's SKU List<LabelRequired />
                      </label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        placeholder="DESHI12,DESHI14,DESHI15"
                        name="multiple_items"
                        className="fromStyle"
                        value={productOfferData.multiple_items}
                        onChange={(e) =>
                          handleChangeTextInput("multiple_items", e.target.value)
                        }
                        ref={register({
                          required: false,
                          maxLength: 100,
                        })}
                      />
                      <TextHelp text="Give SKU's by comma seprated." />
                    </div>
                  )}

                  {productOfferData.selection_type === "multiple_items_csv" && (
                    <div className="col-lg-3">
                      <label className="form-label mt-2">
                        CSV File <LabelRequired />
                        <a target="_blank" href={bulkOfferDemo} className="btn btn-primary btn-sm" style={{ fontSize: 10, padding: '5px 10px', color: '#fff !important', margin: '0px 0px 2px 10px' }}><i className="fa fa-download"></i> Demo</a>
                      </label>
                      <div>
                          <input type="file" onChange={onFileChangeHandler} accept=".csv" />
                      </div>
                      <TextHelp text="Upload CSV file" />
                    </div>
                  )}

                  {productOfferData.selection_type === "category" && (
                    <div className="col-lg-3">
                      <label className="form-label mt-2">
                        Category Wise Offer <LabelRequired />
                      </label>
                      <RHFInput
                        as={<Select options={categoriesSortedList} />}
                        rules={{ required: false }}
                        name="category_id"
                        register={register}
                        placeholder='Select a category'
                        value={getSelectedOption(categoriesSortedList, productOfferData.category_id)}
                        setValue={setValue}
                        onChange={(option) =>
                          handleChangeTextInput("category_id", option.value)
                        }
                      />
                    </div>
                  )}

                  {productOfferData.selection_type === "brand" && (
                    <div className="col-lg-3">
                      <label className="form-label mt-2">
                        Brand Wise Offer <LabelRequired />
                      </label>
                      <RHFInput
                        as={<Select options={BrandsOptionList} />}
                        rules={{ required: false }}
                        name="brand_id"
                        register={register}
                        placeholder="Select a brand"
                        value={getSelectedOption(BrandsOptionList, productOfferData.brand_id)}
                        setValue={setValue}
                        onChange={(option) =>
                          handleChangeTextInput("brand_id", option.value)
                        }
                      />
                    </div>
                  )}

                  {productOfferData.selection_type === "shop" && (
                    <div className="col-lg-3">
                      <label className="form-label mt-2">
                        Shop Wise Offer <LabelRequired />
                      </label>
                      <RHFInput
                        as={<Select options={businessList} />}
                        rules={{ required: false }}
                        name="business_id"
                        register={register}
                        placeholder="Select a shop"
                        value={getSelectedOption(businessList, productOfferData.business_id)}
                        setValue={setValue}
                        onChange={(option) =>
                          handleChangeTextInput("business_id", option.value)
                        }
                      />
                    </div>
                  )}

                  {productOfferData.selection_type === "item" && (
                    <div className="col-lg-3">
                      <label className="form-label mt-2">Current Price</label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Current Price"
                        name="current_price"
                        className="fromStyle"
                        disabled={true}
                        value={productOfferData.current_price}
                        onChange={(e) =>
                          handleChangeTextInput("current_price", e.target.value)
                        }
                        ref={register({
                          required: false,
                          maxLength: 100,
                        })}
                      />
                    </div>
                  )}

                  <div className="col-lg-3">
                    <label className="form-label mt-2">
                      {productOfferData.selection_type === 'multiple_items_csv' ? 'Default ' : ''}
                      Discount Amount <LabelRequired />
                    </label>
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      name="offer_price"
                      className="fromStyle form-control pl-1 pr-1"
                      value={productOfferData.offer_price}
                      onChange={(e) =>
                        handleChangeTextInput("offer_price", e.target.value)
                      }
                      ref={register({
                        required: true,
                      })}
                    />
                    <div className="inputError margin-minus-8">
                      {errors.offer_price &&
                        errors.offer_price.type === "required" &&
                        "Please give offer price."}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <label className="form-label mt-2">Fixed or Percent <LabelRequired /></label>
                    <RHFInput
                      as={<Select options={offerPriceTypes} />}
                      rules={{ required: false }}
                      name="offer_price_type"
                      register={register}
                      placeholder="Fixed or Percent(%)"
                      value={getSelectedOption(
                        offerPriceTypes,
                        productOfferData.offer_price_type
                      )}
                      setValue={setValue}
                      onChange={(option) =>
                        handleChangeTextInput("offer_price_type", option.value)
                      }
                    />
                  </div>

                  <div className="col-lg-3">
                    <label className="form-label mt-2">
                      {productOfferData.selection_type === 'multiple_items_csv' ? 'Default ' : ''}
                      Offer Type <LabelRequired />
                      </label>
                    <RHFInput
                      as={<Select options={offerTypes} />}
                      name="offer_type"
                      value={getSelectedOption(offerTypes, productOfferData.offer_type)}
                      setValue={setValue}
                      placeholder="Select offer type"
                      rules={{ required: true }}
                      register={register}
                      isClearable
                      onChange={(option) => {
                        if(option === null){
                          handleChangeTextInput("offer_type", '')
                        } else {
                          handleChangeTextInput("offer_type", option.value)
                        }
                      }}
                    />
                    <div className="inputError margin-minus-8">
                      {errors.offer_type &&
                        errors.offer_type.type === "required" &&
                        "Offer type can't be blank !"}
                    </div>
                  </div>
                </div>
              </div>

              {/**Offer Period */}
              <div className="bg-light p-2 mt-5">
                <h4>
                  {productOfferData.selection_type === 'multiple_items_csv' ? 'Default ' : ''}
                  Offer Period
                </h4>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <label className="form-label mt-2"> Start Date</label>
                    <DatePicker
                      name="offer_start_date"
                      type="date"
                      className="form-control fromStyle formHeight"
                      placeholderText="Select offer start date"
                      disabled={
                        productOfferData.is_unlimited === "1" ? true : false
                      }
                      dateFormat="yyyy/MM/dd h:mm aa"
                      minDate={getCurrentDate()}
                      name="start_date"
                      selected={
                        (productOfferData.start_date !== "" && productOfferData.start_date !== null)
                          ? getToDate(productOfferData.start_date)
                          : null
                      }
                      onChange={(date) =>
                        handleChangeTextInput("start_date", date)
                      }
                      showTimeSelect
                      ref={register({
                        required: true,
                        maxLength: 100,
                      })}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label mt-2"> End Date</label>
                    <DatePicker
                      name="offer_end_date"
                      type="date"
                      className="form-control fromStyle formHeight"
                      placeholderText="Select offer end date"
                      disabled={
                        productOfferData.is_unlimited === "1" ? true : false
                      }
                      dateFormat="yyyy/MM/dd h:mm aa"
                      minDate={productOfferData.start_date !== "" ? productOfferData.start_date : getCurrentDate()}
                      name="end_date"
                      selected={
                        (productOfferData.end_date !== "" && productOfferData.end_date !== null)
                          ? getToDate(productOfferData.end_date)
                          : null
                      }
                      onChange={(date) =>
                        handleChangeTextInput("end_date", date)
                      }
                      showTimeSelect
                      ref={register({
                        required: true,
                        maxLength: 100,
                      })}
                    />
                  </div>
                  <div className="col-lg-2 mt-5">
                    <Form.Check
                      type="checkbox"
                      label="Is Unlimited"
                      name="is_unlimited"
                      id="is_unlimited"
                      value={productOfferData.is_unlimited}
                      checked={productOfferData.is_unlimited == "0" ? false: true}
                      onChange={(e) =>
                        handleChangeTextInput(
                          "is_unlimited",
                          productOfferData.is_unlimited == "0" ? "1" : "0"
                        )
                      }
                    />
                  </div>
                  <div className="col-lg-2 mt-5">
                    <Form.Check
                      type="checkbox"
                      label={productOfferData.is_visible == "1" ? 'Active' : 'Inactive'}
                      name="is_visible"
                      id="is_visible"
                      value={productOfferData.is_visible}
                      checked={productOfferData.is_visible == "0" ? false: true}
                      onChange={(e) =>
                        handleChangeTextInput(
                          "is_visible",
                          productOfferData.is_visible == "0" ? "1" : "0"
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              {/* Offer Exclude or include */}
              <div className="bg-light p-2 mt-5">
                <h4>Apply Other Condition</h4>
                <div className="form-group row">
                  <div className="col-lg-3 mt-5">
                    <Form.Check
                      type="checkbox"
                      label="Apply only items which no offer."
                      name="apply_only_no_offer"
                      id="apply_only_no_offer"
                      value={productOfferData.apply_only_no_offer}
                      checked={productOfferData.apply_only_no_offer == "0" ? false: true}
                      onChange={(e) =>
                        handleChangeTextInput(
                          "apply_only_no_offer",
                          productOfferData.apply_only_no_offer == "0" ? "1" : "0"
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="form-group row mt-3">
                <div className="col-sm-10">
                  <button
                    type="button"
                    className="btn btn-secondary btn-lg mr-2"
                    onClick={() => {
                      history.push("/offer-product/list/");
                    }}
                  >
                    Back
                  </button>

                  {isLoading && (
                    <button type="submit" className="btn btn-primary btn-lg">
                      <span>Saving...</span>
                      <span className="ml-3 spinner spinner-white"></span>
                    </button>
                  )}

                  {!isLoading && (
                    <button type="submit" className="btn btn-primary btn-lg">
                      <span>Save Offer</span>
                    </button>
                  )}
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </>
  );
};

export default ProductOfferCreate;
