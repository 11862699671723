import React from 'react';
import PropTypes from 'prop-types';

const TextHelp = (props) => {
    const { text, iconEnable, style } = props;
    return (
        <span className={`text-primary text-help`} title={text} style={style}>
            { iconEnable != false && <i className="fa fa-info-circle"></i>}
            {' '} {text}
        </span>
    );
}

TextHelp.propTypes = {
    text: PropTypes.string.isRequired,
    iconEnable: PropTypes.bool,
    style: PropTypes.object,
};
export default TextHelp;