import * as Types from '../Types/Types';

const initialState = {
    taxTypeOptionList: [],
};

const TaxTypeOptionListReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_TAX_TYPE_OPTION_LIST:
            return {
                ...state,
                taxTypeOptionList: getTaxTypeData(action.payload.data)
            };
        default:
            break;
    }
    return newState;
};

/**
 * getTaxTypeData
 * 
 * @param array data 
 * @return array printable business list
 */
const getTaxTypeData = (data) => {
    let TaxTypeOptionList = [];
    if (typeof data !== 'undefined' && data.length > 0) {
        data.forEach((item) => {
            let items = {
                value: item.id,
                label: item.name,
            };
            TaxTypeOptionList.push(items);
        });
    }
    return TaxTypeOptionList;
}
export default TaxTypeOptionListReducer;
