import * as Types from "../types/Types";
import moment from "moment";
const initialState = {
    couponInputData: {
        code: "",
        description: "",
        image: null,
        imagePreviewURl: null,
        business_id: "",
        coupon_type_id: "",
        amount_type: "",
        coupon_amount: "",
        min_amount: "",
        max_amount: "",
        coupon_start_date: "",
        coupon_expiry_date: "",
        is_free_shiping: "0",
        usage_limit: 0,
        usage_count: 0,
        usage_limit_per_user: "",
        is_individual_use: "0",
        exclude_sale_items: "",
        product_ids: "",
        exclude_product_ids: "",
        product_categories: "",
        exclude_product_categories: "",
    },
    isLoading: false,
    couponList: [],
    couponPaginationList: [],
    couponDetails: null,

};
const CouponReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.CHANGE_COUPON_INPUT:
            const couponInputData = { ...state.couponInputData };
            couponInputData[action.payload.name] = action.payload.value;
            return {
                ...state,
                couponInputData,
            };
        case Types.ADD_COUPON:
            if (action.payload.status) {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                    // couponInputData: initialState.couponInputData,
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            }
        //get coupon list 
        case Types.GET_COUPON_LIST:
            if (action.payload.status) {
                return {
                    ...state,
                    couponList: action.payload.couponList,
                    couponPaginationList: action.payload.couponPaginationList,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        //get coupon deetails 
        case Types.GET_COUPON_DETAILS:
            if (action.payload.status) {
                return {
                    ...state,
                    couponInputData: action.payload.couponDetails,
                    couponDetails: action.payload.couponDetails,
                };
            } else {
                return {
                    ...state,
                };
            }
        //delete 
        case Types.DELETE_COUPON:
            return {
                ...state,
                isLoading: action.payload,
            };
        //updated 
        case Types.UPDATE_COUPON:
            if (action.payload.status) {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                    // couponInputData: initialState.couponInputData,
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            }
        case Types.EMPTY_DISPATCH:
            return {
                ...state,
                couponInputData: initialState.couponInputData,
            };
        default:
            break;
    }
    return newState;
};


export default CouponReducer;