import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { DeleteTax, GetTaxList } from '../../_redux/actions/tax/TaxAction';

const TaxList = withRouter(({ history }) => {
  const dispatch = useDispatch();

  const {deleteStatus, deleteMessage, taxGetData} = useSelector((state) => state.tax);

  useEffect(() => {
    dispatch(GetTaxList());
    if (typeof deleteMessage === null || typeof deleteMessage === 'undefined') {
      toast.error('Somthing Went Wrong', {
        autoClose: 2000,
        className: 'dangerColor',
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (deleteStatus && deleteMessage.length > 0) {
        toast.success(deleteMessage, {
          autoClose: 2000,
          className: 'primaryColor',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // dispatch(LocationEmptyMessage());
        history.push('/tax/list');
      }

      if (!deleteStatus && deleteMessage.length > 0) {
        toast.error(deleteMessage, {
          autoClose: 2000,
          className: 'dangerColor',
          position: toast.POSITION.TOP_RIGHT,
        });
        // dispatch(LocationEmptyMessage());
      }
    }
  }, [deleteStatus, deleteMessage]);

  const taxDelete =(intID)=>{
    dispatch(DeleteTax(intID));
    dispatch(GetTaxList());
  }

  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 class="card-label">Tax list</h3>
            </div>
            <div className="card-toolbar">
              <a
                onClick={() => {
                  history.push('/tax/add');
                }}
              >
                <button type="button" class="btn btn-primary">
                  New Tax
                </button>
              </a>
            </div>
          </div>
          <div className="card-body">
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <td>SL</td>
                    <td>Name</td>
                    <td>Business</td>
                    <td>Start Date</td>
                    <td>Calculation Type</td>
                    <td>Amount</td>
                    <td>Tax Grouped ?</td>
                    <td>Rounded Type</td>
                  </tr>
                </thead>
                <tbody>
                  {taxGetData &&
                    taxGetData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.businessname}</td>
                        <td>{item.start_date}</td>
                        <td>{item.calculation_type}</td>
                        <td>{item.amount}</td>
                        <td>{item.is_tax_group}</td>
                        <td>{item.rounding_type}</td>

                        <td>
                          <a
                            className="btn btn-icon btn-light btn-hover-info btn-sm"
                            onClick={() => {
                              history.push('/tax/edit', { tax: item });
                            }}
                          >
                            <i className="fa fa-edit"></i>
                          </a>
                          &nbsp;&nbsp;&nbsp;
                          <a
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => {
                              if (
                                window.confirm(
                                  'Are you sure you wish to delete this item?'
                                )
                              )
                                taxDelete(item.id);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  {taxGetData == null && (
                    <p className="text-danger text-center">No Data Found</p>
                  )}
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default TaxList;
