import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SimpleModal from '../../master/components/Modal/SimpleModal';
import PermissionWiseDisplay from "../../../app/modules/role/PermissionWiseDisplay";
import PaginationLaravel from '../../master/pagination/PaginationLaravel';
import LoadingSpinner from '../../master/spinner/LoadingSpinner';
import { getFileManagerAction } from '../_redux/actions/FileManagerAction';
import FileManagerSingle from './FileManagerSingle';
import FileUpload from './FileUpload';

const FileManagerList = () => {
	const { directory } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const { documents, documentsPaginatedData, isLoading } = useSelector((state) => state.documents);
	const [showUploadModal, setShowUploadModal] = useState(false);

	useEffect(() => {
		if (typeof directory !== 'undefined' && directory !== '') {
			dispatch(getFileManagerAction(currentPage, directory));
		} else {
			dispatch(getFileManagerAction(currentPage));
		}
	}, [directory]);

	const changePage = (data) => {
		setCurrentPage(data.page);

		if (typeof directory !== 'undefined' && directory !== '') {
			dispatch(getFileManagerAction(data.page, directory));
		} else {
			dispatch(getFileManagerAction(data.page));
		}
	};

	/**
	 * On close modal refresh the document list and close modal popup.
	 */
	const onCloseUploadModal = () => {
		setShowUploadModal(false);

		dispatch(getFileManagerAction(currentPage, directory));
	};

	return (
		<>
			<div className="container">
				<div className="card card-custom gutter-b">
					<div className="card-header">
						{/* <div className="card-title">
							<h3 class="card-label">
								File Manager &nbsp;&nbsp;&nbsp;
								{
									typeof directory !== 'undefined' && directory !== '' &&
									<i onClick={() => history.goBack()} className="fa fa-arrow-left pointer"></i>
								}
							</h3>
						</div> */}
						<div className="row mt-2" style={{ width: '100%' }}>
							<div className="col-md-4">
								<div className="card-title">
									<h3 className="card-label">
										File Manager &nbsp;&nbsp;&nbsp;
										{
											typeof directory !== 'undefined' && directory !== '' &&
											<i onClick={() => history.goBack()} className="fa fa-arrow-left pointer"></i>
										}
									</h3>
								</div>
							</div>
							<div className="col-md-6"></div>
							<PermissionWiseDisplay permission_name='admin_seller,file_manager.create' display={false} >
								<div className="col-md-2 mb-2">
									{
										directory && (
											<a onClick={() => setShowUploadModal(true)}>
												<button disabled={directory ? false : true} type="button" className="btn btn-primary">
													<i className="fa fa-plus"></i> Upload Files
												</button>
											</a>
										)
									}
								</div>
							</PermissionWiseDisplay>
						</div>
					</div>
					<div className="card-body">
						{isLoading && <LoadingSpinner text="Loading File Manager..." />}

						<div className="row">
							{ documents && documents.map((document, index) => (
								<FileManagerSingle document={document} key={index} />
							))}
						</div>

						{!documents.length && !isLoading && (
							<div className="text-danger text-center">
								<p>No files or folder found !</p>
							</div>
						)}

						<PaginationLaravel
							changePage={changePage}
							data={documentsPaginatedData}
						/>
					</div>
				</div>
			</div>

			<SimpleModal
				size="md"
				show={showUploadModal}
				handleClose={() => onCloseUploadModal()}
				handleShow={() => setShowUploadModal(true)}
				modalTitle={"Upload Files"}
				>
				<FileUpload handleClose={() => setShowUploadModal(false)}/>
			</SimpleModal>
		</>
	);
}

export default FileManagerList;
