import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
import { customersSlice } from '../app/modules/ECommerce/_redux/customers/customersSlice';
import { productsSlice } from '../app/modules/ECommerce/_redux/products/productsSlice';
import { remarksSlice } from '../app/modules/ECommerce/_redux/remarks/remarksSlice';
import { specificationsSlice } from '../app/modules/ECommerce/_redux/specifications/specificationsSlice';

import authMenuPermissionReducer from '../app/modules/Auth/_redux/menu-permission/authMenuPermissionReducer';
import LocationsReducer from '../domains/suppliers/_redux/reducers/LocationsReducer';
import TaxReducer from '../domains/suppliers/_redux/reducers/TaxReducer';
import GiftCardReducer from '../domains/suppliers/_redux/reducers/GiftCardReducer';

import CategoriesReducer from '../modules/category/_redux/reducers/CategoriesReducer';
import BusinessReducer from '../modules/business/_redux/reducers/BusinessReducer';
import ItemReducer from '../modules/item/_redux/reducers/ItemReducer';
import BrandsReducer from '../modules/brand/_redux/reducers/BrandsReducer';
import UnitsReducer from '../modules/units/_redux/reducers/UnitsReducer';
import CustomerReducer from '../modules/customer/_redux/reducers/CustomerReducer';
import OrdersReducer from '../modules/orders/_redux/reducers/OrdersReducer';
import BusinessListOptionReducer from '../modules/master/OptionData/BusinessList/BusinessListReducer/BusinessListOptionReducer';
import ParentCategoryOptionReducer from '../modules/master/OptionData/ParentCategory/ParentCategoryReducer/ParentCategoryOptionReducer';
import BrandOptionListReducer from '../modules/master/OptionData/BrandOptionList/BrandOptionListReducer/BrandOptionListReducer';
import UnitOptionListReducer from '../modules/master/OptionData/Unit/UnitOptionListReducer/UnitOptionListReducer';
import TaxTypeOptionListReducer from '../modules/master/OptionData/TaxType/TaxTypeOptionListReducer/TaxTypeOptionListReducer';
import SubCategoryReducer from '../modules/master/OptionData/SubCategory/SubCategoryReducer/SubCategoryReducer';
import RoleReducer from '../modules/role-permission-management/information/_redux/reducers/RoleReducer';
import UserReducer from "../modules/role-permission-management/information/_redux/reducers/UserReducer";
import InvoiceReducer from '../modules/Invoices/_redux/reducers/InvoiceReducer';
import AttrubuteReducer from '../modules/attribute/_redux/reducers/AttributeReducer';
import CategoryListData from '../modules/master/OptionData/Category/CategoryReducer/CategoryReducer';
import AttributeListReducer from '../modules/master/OptionData/AttributeOptionList/AttributeOptionListReducer/AttributeOptionListReducer';
import DashboardReducer from '../modules/dashboard/redux/reducers/DashboardReducer';
import AttributeValuesReducer from '../modules/master/OptionData/AttributeValues/AttributeValuesListReducer/AttributeValuesListReducer';
import DiscountTypeReducer from '../modules/master/OptionData/DiscountType/DiscountTypeReducer/DiscountTypeReducer';
import CouponReducer from '../modules/coupon/_redux/reducers/CouponReducer';
import GiftCartReducer from '../domains/suppliers/components/giftCards/_redux/GiftReducer/GiftReducer';
import VoucherReducer from '../domains/suppliers/components/Voucher/_redux/VoucherReducer/VoucherReducer';
import BestSoldReducer from '../modules/dashboard/components/best-sold/redux/BestSoldReducer';
import SliderReducer from '../modules/Slider/_redux/reducers/SliderReducer';
import PollReducer from '../domains/suppliers/components/Poll/_redux/PollReducer/PollReducer';
import ProductOfferReducer from '../modules/ProductOffer/_redux/reducers/ProductOfferReducer';
import CurrenciesReducer from '../domains/suppliers/components/currencies/_redux/Reducer/CurrenciesReducer';
import CountryListOptionDataReducer from '../modules/master/OptionData/CountryListOptionData/CountryListOptionDataReducer/CountryListOptionDataReducer';
import CurrencyOptionListReducer from '../modules/master/OptionData/CurrencyOptionList/CurrencyOptionListReducer/CurrencyOptionListReducer';
import ItemOptionListReducer from '../modules/master/OptionData/ItemOptionList/ItemOptionListReducer/ItemOptionListReducer';
import PageReducer from '../modules/pages/_redux/reducers/PageReducer';
import RegisterReducer from '../app/modules/Auth/_redux/reducers/registerReducer';
import WebsiteInfoReducer from '../modules/website-edit/_redux/reducers/WebsiteInfoReducer';
import ForgetPasswordReducer from '../app/modules/Auth/_redux/reducers/forget-password-reducer';
import PaymentReducer from '../modules/payments/redux/reducers/PaymentReducer';
import FileManagerReducer from '../modules/file-manager/_redux/reducers/FileManagerReducer';

export const rootReducer = combineReducers({
    auth: auth.reducer,
    RegisterReducer: RegisterReducer,
    ForgetPasswordReducer: ForgetPasswordReducer,
    customers: customersSlice.reducer,
    products: productsSlice.reducer,
    remarks: remarksSlice.reducer,
    specifications: specificationsSlice.reducer,

    /** Menu Reducers */
    menu: authMenuPermissionReducer,

    business: BusinessReducer,
    locations: LocationsReducer,
    currencies: CurrenciesReducer,
    customer: CustomerReducer,
    brands: BrandsReducer,
    tax: TaxReducer,
    units: UnitsReducer,
    giftCard: GiftCardReducer,

    item: ItemReducer,
    categories: CategoriesReducer,
    orders: OrdersReducer,
    roleReducer: RoleReducer,
    userRole: UserReducer,
    InvoiceReducer: InvoiceReducer,

    //attribute modules
    AttrubuteReducer: AttrubuteReducer,

    //dynamic dropdown option list
    BusinessOptionList: BusinessListOptionReducer,
    parentCategoryList: ParentCategoryOptionReducer,
    SubCategoryReducer: SubCategoryReducer,
    BrandOptionListReducer: BrandOptionListReducer,
    UnitOptionListReducer: UnitOptionListReducer,
    TaxTypeOptionListReducer: TaxTypeOptionListReducer,
    CategoryListData: CategoryListData,
    AttributeListReducer: AttributeListReducer,
    AttributeValuesReducer: AttributeValuesReducer,
    DiscountTypeReducer: DiscountTypeReducer,
    CountryListOptionDataReducer: CountryListOptionDataReducer,
    CurrencyOptionListReducer: CurrencyOptionListReducer,
    ItemOptionListReducer: ItemOptionListReducer,

    //coupon
    CouponReducer: CouponReducer,
    //offer
    ProductOffer: ProductOfferReducer,
    //slide
    SliderReducer: SliderReducer,

    //gift card
    GiftCartReducer: GiftCartReducer,
    VoucherReducer: VoucherReducer,

    //poll
    PollReducer: PollReducer,

    // Payment
    payment: PaymentReducer,

    // Dashboard Reducers
    dashboardReducer: DashboardReducer,
    bestSoldReducer: BestSoldReducer,
    PageReducer: PageReducer,
    WebsiteInfoReducer: WebsiteInfoReducer,

    // File Manager
    documents: FileManagerReducer
});

export function* rootSaga() {
    yield all([auth.saga()]);
}