import React from "react";
import ItemDetails from "../components/ItemDetails";

const ItemDetailsContainer = () => {
    return (
        <>
            <ItemDetails />
        </>
    );
};

export default ItemDetailsContainer;
