import * as Types from "../types/Types";
import axios from "axios";
import { showToast } from "../../../master/utils/ToastHelper";
import { generateFormDataFromObject } from "../../../master/utils/FileHelper";

const baseURL = process.env.REACT_APP_API_URL + "documents";

/**
 * Get all files from server.
 *
 * @param {int} pageNo
 * @param {int|object|null} directory
 *
 * @returns void
 */
export const getFileManagerAction = (pageNo = 1, directory = null) => (
  dispatch
) => {
  let response = {
    documents: [],
    isLoading: true,
  };

  dispatch({ type: Types.GET_FILE_MANAGER_LIST, payload: response });
  let url = `${baseURL}?isPaginated=1&paginateNo=100&page=${pageNo}`;

  // If no parent directory is provided, then pass the root directory.
  directory = directory === null ? "root" : directory;
  url += `&directory=${directory}`;

  axios
    .get(url)
    .then((res) => {
      const { data } = res.data;
      response.documents = data.data;
      response.documentsPaginatedData = data;
      response.isLoading = false;
      dispatch({ type: Types.GET_FILE_MANAGER_LIST, payload: response });
    })
    .catch((err) =>
      showToast("error", "Something went wrong fetching documents.")
    );
};

/**
 * Upload file to server and add to file manager.
 *
 * @param {array} files
 * @param {int|object} directory_id
 *
 * @returns the response from the server
 */
export const uploadFileManagerAction = (files, directory_id) => (dispatch) => {
  let response = {
    documents: [],
    isUploading: true,
  };

  dispatch({ type: Types.FILE_MANAGER_UPLOAD_FILES, payload: response });

  const data = {
    files,
    directory_id,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  dispatch({ type: Types.FILE_MANAGER_UPLOAD_FILES, payload: response });
  const postData = generateFormDataFromObject(data);

  axios
    .post(baseURL, postData, { headers: headers })
    .then((res) => {
      response.documents = res.data.data;
      response.isUploading = false;
      dispatch({ type: Types.FILE_MANAGER_UPLOAD_FILES, payload: response });
    })
    .catch((err) => {
      showToast("error", "Something went wrong fetching documents.");
      response.isUploading = false;
      dispatch({ type: Types.FILE_MANAGER_UPLOAD_FILES, payload: response });
    });
};
