import React from "react";
import { useHistory } from 'react-router-dom';
import CategoriesView from "../components/view/CategoriesView";

const CategoryViewContainer = (props) => {
    const history = useHistory();

    return (
        <>
            <div className="container">
                <div className="card card-custom gutter-b">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">View Category</h3>
                        </div>
                        <div className="card-toolbar">
                            <a
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                <button type="button" className="btn btn-primary">
                                    <i className="fa fa-arrow-left"></i> Categories
                                </button>
                            </a>
                        </div>
                    </div>
                    <CategoriesView id={props.match.params.id} />
                </div>
            </div>
        </>
    );
};

export default CategoryViewContainer;
