import React from 'react'
import { checkFeaturePermission } from '../Auth/_redux/menu-permission/module-permission';
import ErrorViewComponent from './ErrorViewComponent';

const PermissionWiseDisplay = (props) => {
    const { permission_name, children, display } = props;
    const permissionErrorDisplay = typeof display === 'undefined' || display === null ?    false : display;

    return (
        <>
            {
                checkFeaturePermission(permission_name.toLocaleLowerCase()) ?
                    children : <ErrorViewComponent
                        code={401}
                        display={permissionErrorDisplay}
                    />
            }
        </>
    );
}

export default PermissionWiseDisplay;