import * as Types from '../types/Types';

const initialState = {
  isLoading: false,
  sliderInput: {
    title: '',
    business_id: '',
    image: '',
    mobile_image: '',
    imagePreviewURl: '',
    mobileImagePreviewURl: '',
    description: '',
    created_by: 1,
    is_text_enable: 0,
    text: '',
    text_color: '',
    is_button_enable: 0,
    button_text: '',
    button_link: '',
    button_color: '',
    status: 1,
    businessType: {}
  },
  sliderList: [],
  sliderPaginationList: [],
  sliderDetails: {},
};

const SliderReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.CHANGE_SLIDE_INPUT:
      const sliderInput = { ...state.sliderInput };
      sliderInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        sliderInput
      };
    case Types.CREATE_NEW_SLIDE:
      if (action.payload.status) {
        return {
          ...state,
          isLoading: action.payload.isLoading,
          sliderInput: initialState.sliderInput,
        };
      } else {
        return {
          ...state,
          isLoading: action.payload.isLoading,
        };
      }
    case Types.GET_SLIDER_LIST:
      return {
        ...state,
        sliderList: action.payload.sliderList,
        sliderPaginationList: action.payload.sliderPaginationList,
        isLoading: action.payload.isLoading,
      }
    case Types.DELETE_SLIDER:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }
    case Types.GET_SLIDE_DETAILS:
      if (action.payload.status) {
        return {
          ...state,
          sliderInput: action.payload.sliderDetails,
          sliderDetails: action.payload.sliderDetails,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: true,
        };
      }
    case Types.UPDATED_SLIDE:
      return {
        ...state,
        // sliderInput: action.payload.sliderDetails,
        isLoading: action.payload.isLoading,
      }
    case Types.EMPTY_DISPATCH:
      return {
        ...state,
        sliderInput: initialState.sliderInput,
      };
    default:
      break;
  }
  return newState;
};
export default SliderReducer;
