/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Switch, Route } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from './ForgotPassword';
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import './authPage.scss'
import UserVerification from "./Verification";
import ResetPassword from "./ResetPassword";

export function AuthPage() {
	// return (
	//   <>
	//     <div className="d-flex flex-column flex-root">
	//       {/*begin::Login*/}
	//       <div
	//         className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
	//         id="kt_login"
	//       >
	//         {/*begin::Aside*/}
	//         <div
	//           className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
	//           style={{
	//             background: '#187de4'
	//           }}
	//         >
	//           {/*begin: Aside Container*/}
	//           <div className="d-flex flex-row-fluid flex-column justify-content-between">
	//             {/* start:: Aside header */}
	//             <Link to="/" className="flex-column-auto mt-5">
	//               <img
	//                 alt="Logo"
	//                 className="max-h-70px"
	//                 src={toAbsoluteUrl("/media/logos/logo-light.png")}
	//               />
	//             </Link>
	//             {/* end:: Aside header */}

	//             {/* start:: Aside content */}
	//             <div className="flex-column-fluid d-flex flex-column justify-content-center">
	//               <h3 className="font-size-h1 mb-5 text-white">
	//                 Welcome to eShop Seller panel
	//               </h3>
	//             </div>
	//             {/* end:: Aside content */}

	//             {/* start:: Aside footer for desktop */}
	//             <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
	//               <div className="opacity-70 font-weight-bold	text-white">
	//                 &copy; 2021
	//               </div>
	//               <div className="d-flex">
	//                 <Link to="/" className="text-white">
	//                   Privacy
	//                 </Link>
	//                 <Link to="/" className="text-white ml-10">
	//                   Legal
	//                 </Link>
	//                 <Link to="/" className="text-white ml-10">
	//                   Contact
	//                 </Link>
	//               </div>
	//             </div>
	//             {/* end:: Aside footer for desktop */}
	//           </div>
	//           {/*end: Aside Container*/}
	//         </div>
	//         {/*begin::Aside*/}

	//         {/*begin::Content*/}
	//         <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
	//           {/*begin::Content header*/}
	//           <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
	//             <span className="font-weight-bold text-dark-50">
	//               Don't have an account yet?
	//             </span>
	//             <a
	//               href="#"
	//               className="font-weight-bold ml-2"
	//               id="kt_login_signup"
	//               target="_blank"
	//             >
	//               Sign Up!
	//             </a>
	//           </div>
	//           {/*end::Content header*/}

	//           {/* begin::Content body */}
	//           <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
	//             <Switch>
	//               <ContentRoute path="/auth/login" component={Login} />
	//               <ContentRoute
	//                 path="/auth/registration"
	//                 component={Registration}
	//               />
	//               <ContentRoute
	//                 path="/auth/forgot-password"
	//                 component={ForgotPassword}
	//               />
	//               <Redirect from="/auth" exact={true} to="/auth/login" />
	//               <Redirect to="/auth/login" />
	//             </Switch>
	//           </div>
	//           {/*end::Content body*/}

	//           {/* begin::Mobile footer */}
	//           <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
	//             <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
	//               &copy; 2020 Admin Seller panel
	//             </div>
	//             <div className="d-flex order-1 order-sm-2 my-2">
	//               <Link to="/" className="text-dark-75 text-hover-primary">
	//                 Privacys
	//               </Link>
	//               <Link
	//                 to="/"
	//                 className="text-dark-75 text-hover-primary ml-4"
	//               >
	//                 Legal
	//               </Link>
	//               <Link
	//                 to="/"
	//                 className="text-dark-75 text-hover-primary ml-4"
	//               >
	//                 Contact
	//               </Link>
	//             </div>
	//           </div>
	//           {/* end::Mobile footer */}
	//         </div>
	//         {/*end::Content*/}
	//       </div>
	//       {/*end::Login*/}
	//     </div>
	//   </>
	// );

	return (
		<div className="auth-page">
			<div className="auth-page__content">
				<div className="auth-page__header">
					<div className="auth-page__header-logo">
						<img src="/media/logos/logo.png" alt="logo" />
					</div>
				</div>
				<div className="auth-page__body">
					<Switch>
						<Route
							path="/auth/registration"
							component={Registration}
						/>
						<Route path="/auth/forget-password" component={ForgotPassword} />
						<Route path="/auth/verification" component={UserVerification} />
						<Route path="/auth/reset-password" component={ResetPassword} />
						<ContentRoute path="/auth/login" component={Login} />
						{/* <Redirect from="/auth" exact={true} to="/auth/login" /> */}
						{/* <Redirect to="/auth/login" /> */}
					</Switch>
				</div>
			</div>
		</div>
	)
}
