import React from 'react';
import BrandsAdd from '../components/BrandsAdd';

const BrandsAddContainer = () => {
  return (
    <>
      <BrandsAdd />
    </>
  );
};

export default BrandsAddContainer;
