import React from "react";
import TaxEdit from "../../components/tax/TaxEdit";


const TaxEditContainer = (props) => {
    return (
        <>
            <TaxEdit props={props} />
        </>
    );
};

export default TaxEditContainer;
