import React from "react";
import ItemEdit from "../components/ItemEdit";

const ItemEditContainer = () => {
    return (
        <>
            <ItemEdit />
        </>
    );
};

export default ItemEditContainer;
