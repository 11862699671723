import React, { useEffect, memo, useMemo } from 'react'
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { GetCategoriesSortedList } from '../../category/_redux/actions/CategoriesAction';
import { getBrandListOptonData } from '../../master/OptionData/BrandOptionList/BrandOptionListAction/BrandOptionListAction'
import { changeFilterItemList, getProductItemsList } from '../_redux/actions/ItemAction';
import { getSelectedOption } from '../../../services/FormatData';

const ItemFilter = () => {
    const { register, setValue } = useForm();
    const dispatch = useDispatch()
    const { BrandsOptionList } = useSelector((state) => state.BrandOptionListReducer);
    const { categoriesSortedList } = useSelector((state) => state.categories);
    const { filter } = useSelector((state) => state.item);

    useEffect(() => {
        dispatch(getBrandListOptonData());
        dispatch(GetCategoriesSortedList());
    }, []);

    const changeFilter = (name, value, debounced = false) => {
        let { page, search, category, brand, status, sku } = filter;

        switch (name) {
            case 'category':
                category = value !== null ? value.value : '';
                dispatch(changeFilterItemList('category', category));
                break;
            case 'brand':
                brand = value !== null ? value.value : '';
                dispatch(changeFilterItemList('brand', brand));
                break;
            case 'search':
                search = value;
                dispatch(changeFilterItemList('search', value));
                break;
            case 'sku':
                sku = value;
                dispatch(changeFilterItemList('sku', value));
                break;

            default:
                break;
        }

        if ((name === 'search' || name === 'sku') && ! debounced) {
            searchableInputHandler(name, value);
        } else {
            dispatch(getProductItemsList(page, search, category, brand, status, sku));
        }
    }

    // Searching with implement debounce
    const searchableInputHandler = useMemo(() => debounce(
        (name, value) => changeFilter(name, value, true), 500
    ), []);

    // Cleanup debounced function
    useEffect(() => {
        return () => {
            searchableInputHandler.cancel();
        }
    }, []);

    return (
        <form className="form form-label-right">
            <div className="form-group row mt-2">
                <div className="col-3">
                    <input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Search By Name"
                        value={filter.search}
                        onChange={(e) => changeFilter('search', e.target.value)}
                    />
                </div>
                <div className="col-3">
                    <input
                        type="text"
                        name="sku"
                        className="form-control"
                        placeholder="Search By SKU"
                        value={filter.sku}
                        onChange={(e) => changeFilter('sku', e.target.value)}
                    />
                </div>
                <div className="col-3">
                    <RHFInput
                        as={<Select options={categoriesSortedList} placeholder="Search By Category" />}
                        name="category"
                        register={register}
                        setValue={setValue}
                        isClearable
                        value={getSelectedOption(categoriesSortedList, filter.category)}
                        onChange={(option) => changeFilter('category', option)}
                    />
                </div>
                <div className="col-3">
                    <RHFInput
                        as={<Select options={BrandsOptionList} placeholder="Search By Brand" />}
                        name="brand"
                        register={register}
                        setValue={setValue}
                        isClearable
                        value={getSelectedOption(BrandsOptionList, filter.brand)}
                        onChange={(option) => changeFilter('brand', option)}
                    />
                </div>
            </div>
        </form>
    );
}

export default memo(ItemFilter);