import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ErrorMessage from '../../../../modules/master/ErrorMessage/ErrorMessage';
import { validateOtp } from '../_redux/actions/forget-password-action';

function UserVerification() {
    const {email, isLoading, isOtpVerified} = useSelector(state => state.ForgetPasswordReducer);
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();
    const history = useHistory();

    if(isOtpVerified) {
        history.push('/auth/reset-password')
    }

    const onSubmit = data => {
        dispatch(validateOtp(data.otp))
    }

    return (
        <div className="forget-password my-5 row justify-content-center">
        <div className='container col-md-6 card card-body p-4'>
            <h4 className="pb-3">User verification</h4>
            <div className="row">
                <div className="col-md-12">
                    <div className="bg-white p-4">
                        <p>Please check your email or phone number for OTP</p>

                        <form 
                            onSubmit={handleSubmit(onSubmit)}
                            autoComplete="off">
                            
                            <div>
                                <div className="mb-3">
                                    <input
                                        disabled={true}
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        value={email}
                                        ref={register({
                                            required: true,
                                            maxLength: 50,
                                        })}
                                    />
                                    {errors.email && errors.email.type === 'required' && (
                                        <ErrorMessage errorText="Valid phone number or email is required" />
                                    )}

                                </div>
                            </div>
                            <div>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="OTP Code"
                                        name="otp"
                                        ref={register({
                                            required: "one time passcode (OTP) required",
                                            maxLength: {
                                                value: 6,
                                                message: "6 digits OTP required"
                                            },
                                            minLength: {
                                                value: 6,
                                                message: "6 digits OTP required"
                                            }
                                        })}
                                    />
                                    {errors.otp  && (
                                        <ErrorMessage errorText={errors.otp.message} />
                                    )}
                                </div>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-success btn-sign-up">
                                        Verify code
                                        &#8203;
                                            {
                                                isLoading && (
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                )
                                            }
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default UserVerification;
