import * as Types from "../../types/Types";
import axios from "axios";
import { generateFormDataFromObject } from "../../../master/utils/FileHelper";
import { showToast } from "../../../master/utils/ToastHelper";

export const GetBrandsList = (page, searchText = null) => async (dispatch) => {
	let responseList = {
		isLoading: true,
		data: {},
		status: false,
	};
	dispatch({ type: Types.GET_BRANDS_LIST, payload: responseList });
	let BrandListURL = `${process.env.REACT_APP_API_URL}brands?isPaginated=1&status=-1`;

	if (page !== null || page === "") {
		BrandListURL += `&page=${page}`;
	}
	if (searchText !== null) {
		BrandListURL += `&search=${searchText}`;
	} else {
		// url += `&certificate/details?search=${searchText}`
	}
	try {
		await axios.get(BrandListURL)
			.then((res) => {
				const { data, status } = res.data;
				responseList.status = status;
				responseList.brandsList = data.data;
				responseList.BrandListPaginateData = data;
				responseList.isLoading = false;
				dispatch(emptyDispatch())
			}).catch((err) => {
			});
	} catch (error) {
		responseList.message = "Something Went Wrong !";
		responseList.isLoading = false;
		showToast('error', responseList.message);
	}
	dispatch({ type: Types.GET_BRANDS_LIST, payload: responseList });
};

export const handleChangeBrands = (name, value, e = null) => (dispatch) => {
	let data = {
		name: name,
		value: value,
	}
	dispatch({ type: Types.CHANGE_BRANDS_INPUT, payload: data });

	if (name === 'banner' || name === 'image') {
		let reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			if (name === "banner") {
				data.name = 'bannerPreviewUrl';
			} else {
				data.name = 'logoPreviewUrl';
			}
			data.value = reader.result;
			dispatch({ type: Types.CHANGE_BRANDS_INPUT, payload: data });
		}
		reader.readAsDataURL(file)
	}
};

export const deleteBrandsImage = (name) => (dispatch) => {

	let data = {
		name: name,
		value: null,
	}
	dispatch({ type: Types.CHANGE_BRANDS_INPUT, payload: data });

	if (name === 'banner') {
		data.name = 'bannerPreviewUrl';
	} else {
		data.name = 'logoPreviewUrl';
	}
	dispatch({ type: Types.CHANGE_BRANDS_INPUT, payload: data });
};

//add new brand
export const AddBrands = (brandInputData) => (dispatch) => {
	if (brandInputData.name === null || brandInputData.name === "") {
		showToast('error', "Brand name can't be blank!")
		return false;
	}

	if (brandInputData.description === null || brandInputData.description === "") {
		showToast('error', "Description can't be blank!")
		return false;
	}

	let responseData = {
		status: false,
		message: "",
		isLoading: true,
	};
	dispatch({ type: Types.ADD_BRANDS, payload: responseData });
	const headers = {
		"Content-Type": "application/json",
	};
	brandInputData.categories = getBrandIdsFromObjArray(brandInputData.categories);

	const brandData = generateFormDataFromObject(brandInputData);
	axios.post(`${process.env.REACT_APP_API_URL}brands`, brandData, {
		headers: headers,
	})
		.then((res) => {
			if (res.data.status) {
				let data = res.data;
				responseData.message = data.message;
				responseData.status = data.status;
				responseData.isLoading = false;
				showToast('success', responseData.message);
				dispatch({ type: Types.ADD_BRANDS, payload: responseData });
			}
		}).catch((err) => {
			const errorMessage = "Sorry, Something went wrong!"
			responseData.isLoading = false;
			showToast('error', errorMessage);
			dispatch({ type: Types.ADD_BRANDS, payload: responseData });
		});
};

//brand edit
export const updateBrands = (brandInputData, id) => async (dispatch) => {
	if (brandInputData.name === null || brandInputData.name === "") {
		showToast('error', "Brand name can't be blank!")
		return false;
	}

	brandInputData.categories = getBrandIdsFromObjArray(brandInputData.categories);

	if (brandInputData.description === null || brandInputData.description === "") {
		showToast('error', "Description can't be blank!")
		return false;
	}

	let responseData = {
		status: false,
		message: "",
		isLoading: true,
	};
	dispatch({ type: Types.UPDATE_BRANDS, payload: responseData });
	const brandData = generateFormDataFromObject(brandInputData);

	axios.post(`${process.env.REACT_APP_API_URL}brands/${id}?_method=PUT`, brandData)
		.then((res) => {
			if (res.data.status) {
				let data = res.data;
				responseData.message = data.message;
				responseData.status = data.status;
				responseData.isLoading = false;
				showToast('success', responseData.message);
				dispatch({ type: Types.UPDATE_BRANDS, payload: responseData });
			}
		}).catch((err) => {
			const errorMessage = "Sorry, Something went wrong!"
			responseData.isLoading = false;
			showToast('error', errorMessage);
			dispatch({ type: Types.UPDATE_BRANDS, payload: responseData });
		});
};

const getBrandIdsFromObjArray = (brandsObjArray) => {
	let brands = [];
	brandsObjArray.forEach(brand => {
		brands.push(parseInt(brand.value));
	});

	return brands;
}

//get brands details
export const getBrandDetails = (id) => (dispatch) => {
	let formData = {
		name: '',
		business_id: '',
		business: null,
		description: "",
		banner: null,
		image: null,
		created_by: 1,
		bannerPreviewUrl: null,
		logoPreviewUrl: null
	}
	let business = null;
	if (id !== null) {
		axios.get(`${process.env.REACT_APP_API_URL}brands/${id}?withSubCategories=1`)
			.then((res) => {
				if (res.data.status) {
					const data = res.data.data;
					if (data.business.id !== null && data.business.id !== '') {
						business = {
							value: data.business.id,
							label: data.business.name
						}
					} else {
						business = '';
					};
					formData.name = data.name;
					formData.business_id = data.business_id;
					formData.description = data.description;
					formData.business = business;
					formData.banner = null;
					formData.image = null;
					formData.created_by = 1;
					formData.bannerPreviewUrl = data.banner_url;
					formData.logoPreviewUrl = data.image_url;
					formData.categories = data.categories;
					formData.status = data.status;
					dispatch({ type: Types.GET_BRANDS_DETAILS, payload: { formData, data } })
				}
			})
	} else {
		dispatch({ type: Types.GET_BRANDS_DETAILS, payload: null })
	}

}

export const BrandsEmptyAddMessage = () => async (dispatch) => {
	dispatch({ type: Types.EMPTY_BRANDS_ADD_MESSAGE, payload: true });
};

export const BrandsEmptyEditMessage = () => async (dispatch) => {
	dispatch({ type: Types.EMPTY_BRANDS_EDIT_MESSAGE, payload: true });
};

// delete brand data
export const handleDeleteBrand = (id) => (dispatch) => {
	let isLoading = true;
	dispatch({ type: Types.DELETE_BRAND_DATA, payload: isLoading })
	axios.delete(`${process.env.REACT_APP_API_URL}brands/${id}`)
		.then((res) => {
			if (res.data.status) {
				const data = res.data;
				showToast('success', data.message);
				dispatch({ type: Types.DELETE_BRAND_DATA, payload: false });
				dispatch(GetBrandsList())
			}
		})
}

export const emptyDispatch = () => (dispatch) => {
	dispatch({ type: Types.EMPTY_DISPATCH, payload: true });
}