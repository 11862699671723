import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
// import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';


import { RHFInput } from 'react-hook-form-input';

import { GetBusinessList } from '../../_redux/actions/BusinessAction';

import { LocationEmptyEditMessage, UpdateLocation } from '../../_redux/actions/LocationAction';


const LocationEdit = withRouter(({ history, props }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();
  // const [files, setFiles] = useState([]);
  toast.configure();
  const { id, name, business_id, businessName, country, zip_code, landmark, city, mobile, alternate_number, email } = props.location.state.locations
  const [state, setState] = React.useState({
    id: id,
    name: name,
    business_id: business_id,
    country: country,
    state: props.location.state.locations.state,
    zip_code: zip_code,
    landmark: landmark,
    city: city,
    mobile: mobile,
    alternate_number: alternate_number,
    email: email,
    busienssDataSet: {
      value: business_id,
      label: businessName,
    }
  });



  const handleChange = ({ currentTarget: input }) => {
    const cloneObj = { ...state };
    cloneObj[input.name] = input.value;
    setState(cloneObj);
  };

  const selectHandle = (item, name) => {
    const cloneObj = { ...state };
    cloneObj[name] = item;
    setState(cloneObj);
  };

  const businessList = useSelector((state) => state.business.businessList);
  const editStatus = useSelector((state) => state.locations.editStatus);
  const editMessage = useSelector((state) => state.locations.editMessage);
  const isLoading = useSelector((state) => state.locations.isLoading);

  let business = [];
  if (businessList) {
    businessList.forEach((item) => {
      let items = {
        value: item.id,
        label: item.name,
      };
      business.push(items);
    });
  }

  useEffect(() => {

    dispatch(GetBusinessList());
    if (typeof editMessage === 'undefined' || editMessage === null) {
      toast.error('Somthing Went Wrong', {
        autoClose: 2000,
        className: 'dangerColor',
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (editStatus && editMessage.length > 0) {
        toast.success(editMessage, {
          autoClose: 2000,
          className: 'primaryColor',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        dispatch(LocationEmptyEditMessage());
        history.push('/location/list');
      }

      if (!editStatus && editMessage.length > 0) {
        toast.error(editMessage, {
          autoClose: 2000,
          className: 'dangerColor',
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(LocationEmptyEditMessage());
      }
    }
  }, [editStatus, editMessage]);

  const onSubmit = async (e) => {
    dispatch(UpdateLocation(state));
  };

  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 class="card-label">Location Update</h3>
            </div>
          </div>
          <div className="card-body">
            <form
              className="form form-label-right"
              onSubmit={handleSubmit(onSubmit)}
              method="post"
            >
              <div className="form-group row">
                <div className="col-lg-4">
                  <label className="form-label mt-2">Location Name</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Brands name"
                    name="name"
                    value={state.name}
                    className="fromStyle"
                    onChange={handleChange}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.name &&
                      errors.name.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Business</label>
                  <RHFInput
                    as={<Select options={business} />}
                    rules={{ required: false }}
                    name="businessData"
                    value={state.busienssDataSet}
                    register={register}
                    onChange={(e) => selectHandle(e, 'businessData')}
                    setValue={setValue}
                  />
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Country</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Country"
                    name="country"
                    className="fromStyle"
                    onChange={handleChange}
                    value={state.country}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.country &&
                      errors.country.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">State</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your State"
                    name="state"
                    value={state.state}
                    className="fromStyle"
                    onChange={handleChange}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.state &&
                      errors.state.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Zip Code </label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Zip Code "
                    name="zip_code"
                    value={state.zip_code}
                    className="fromStyle"
                    onChange={handleChange}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.zip_code &&
                      errors.zip_code.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Land Mark</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Land Mark"
                    name="landmark"
                    value={state.landmark}
                    className="fromStyle"
                    onChange={handleChange}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.landmark &&
                      errors.landmark.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">City</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City name"
                    name="city"
                    value={state.city}
                    className="fromStyle"
                    onChange={handleChange}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.city &&
                      errors.city.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Mobile No</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mobile No"
                    name="mobile"
                    value={state.mobile}
                    className="fromStyle"
                    onChange={handleChange}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.mobile &&
                      errors.mobile.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Alternate Number</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Alternate Number name"
                    name="alternate_number"
                    className="fromStyle"
                    value={state.alternate_number}
                    onChange={handleChange}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.alternate_number &&
                      errors.alternate_number.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Email</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Email"
                    name="email"
                    value={state.email}
                    className="fromStyle"
                    onChange={handleChange}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.email &&
                      errors.email.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>


                <div className="col-lg-4">
                  <label className="form-label mt-2">Description</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    name="description"
                    className="fromStyle"
                    onChange={handleChange}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.description &&
                      errors.description.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-sm-10">
                  <a
                    onClick={() => {
                      history.push('/brands/list');
                    }}
                  >
                    <button type="button" class="btn btn-secondary btn-lg mr-2">
                      Back
                    </button>
                  </a>
                  {isLoading &&
                    <button type="submit" class="btn btn-primary btn-lg" disabled={true} >
                      <span>Submit</span>
                      <span className="ml-3 spinner spinner-white"></span>
                    </button>
                  }

                  {!isLoading &&
                    <button type="submit" class="btn btn-primary btn-lg">
                      <span>Submit</span>
                    </button>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
});

export default LocationEdit;
