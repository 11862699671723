import React from 'react';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import BusinessAdd from '../components/BusinessAdd';

const BusinessAddContainer = () => {
  return (
    <>
      <PermissionWiseDisplay permission_name="Shop.Create" display={true} >
        <BusinessAdd />
      </PermissionWiseDisplay>
    </>
  );
};

export default BusinessAddContainer;
