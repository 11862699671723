import * as Types from '../../types/Types';

const initialState = {
  giftCardList: [],
  isLoading:false,
  addMessage: "",
  editMessage: "",
  deleteMessage: "",

  addStatus: false,
  editStatus: false,
  deleteStatus: false,
};

const GiftCardReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.GET_GIFT_CARD_LIST:
      return {
        ...state,
        giftCardList: action.payload.data,
      };
      case Types.ADD_GIFT_CARD:
        return {
          ...state,
          addMessage:action.payload.message,
          addStatus:action.payload.status,
          isLoading:action.payload.isLoading,
        };
        case Types.UPDATE_GIFT_CARD:
          return {
            ...state,
            editStatus: action.payload.status,
            editMessage: action.payload.message,
            isLoading: action.payload.isLoading,
          };
          case Types.DELETE_GIFT_CARD:
            // let DeleteGiftCardList = state.giftCardList.filter(function (el) {
            //   return el.intID !== action.payload.intID;
            // });  
        return {
          ...state,
          // giftCardList: DeleteGiftCardList,
          deleteStatus: action.payload.status,
          deleteMessage: action.payload.message,
        };

        case Types.EMPTY_GIFT_CARD_ADD_MESSAGE:
          return {
            ...state,
            addStatus: "",
            addMessage: "",
          };
        case Types.EMPTY_GIFT_CARD_EDIT_MESSAGE:
          return {
            ...state,
            editStatus: "",
            editMessage: "",
          };
 
    default:
      break;
  }
  return newState;
};
export default GiftCardReducer;
