import * as Types from "../types/Types";
import axios from "axios";
import moment from "moment";
import { showToast } from "../../../master/utils/ToastHelper";

export const getFilterOptionDataForOrderList = () => (dispatch) => {
    const filterOptionList = [
        { value: 5, label: 'Last 5 Days' },
        { value: 15, label: 'Last 15 Days' },
        { value: 30, label: 'Last 30 Days' },
        { value: 90, label: 'Last 90 Days' },
    ]

    dispatch({ type: Types.GET_ORDER_FILTER_OPTION_DATA, payload: filterOptionList });
}

export const changeFilterOrderList = (name, value) => (dispatch) => {
    const filter = {
        name,
        value
    }
    dispatch({ type: Types.CHANGE_ORDER_LIST_FILTER, payload: filter });
}

/**
 * Change Order Input value and Dispatch.
 *
 * @param {string} name
 * @param {string} value
 *
 * @return void
 */
export const changeOrderInput = (name, value) => (dispatch) => {
    const filter = {
        name,
        value
    }
    dispatch({ type: Types.CHANGE_ORDER_INPUT, payload: filter });
}

export const getOrderTypes = (formatted = false) => (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}order-statuses`;
    if (formatted) {
        url += '?formatted=true'
    }

    axios.get(url).then((res) => {
        dispatch({ type: Types.GET_ORDER_TYPES, payload: res.data.data });
    });
}

export const GetOrdersList = (pageNo, type = null, searchText = null, filterDate, customDate, status = null) => async (dispatch) => {

    dispatch({ type: Types.IS_LOADING, payload: true });

    pageNo = (typeof pageNo !== 'undefined' && pageNo !== '') ? parseInt(pageNo) : 1;

    let orderItemURL = "";
    // orderItemURL = `${process.env.REACT_APP_API_URL}sales?isPaginated=1&paginateNo=20`;
    orderItemURL = `${process.env.REACT_APP_API_URL}sales?page=${pageNo}`;

    if (typeof filterDate !== "undefined" && filterDate !== null) {
        const end_date = moment().format('YYYY-MM-DD');
        let start_date;
        if (filterDate === 5) {
            start_date = moment().subtract(5, 'day').format('YYYY-MM-DD');
        } else if (filterDate === 15) {
            start_date = moment().subtract(15, 'day').format('YYYY-MM-DD');
        } else if (filterDate === 30) {
            start_date = moment().subtract(30, 'day').format('YYYY-MM-DD');;
        } else if (filterDate === 90) {
            start_date = moment().subtract(90, 'day').format('YYYY-MM-DD');
        }

        orderItemURL += `&start_date=${start_date}&end_date=${end_date}`;
    }

    if (typeof customDate !== "undefined" && customDate !== null) {
        let from_date = moment(customDate[0].startDate).format("YYYY-MM-DD");
        let to_date = moment(customDate[0].endDate).format("YYYY-MM-DD");

        orderItemURL += `&start_date=${from_date}&end_date=${to_date}`;
    }

    if (searchText !== null && searchText !== "") {
        orderItemURL += `&search=${searchText}`;
    }

    if (type !== null) {
        orderItemURL += `&type=${type}`;
    }

    if (status !== null && status !== '') {
        orderItemURL += `&status=${status}`;
    }

    axios
        .get(orderItemURL)
        .then((res) => {
            let { data } = res.data;

            if (typeof data.data !== 'undefined' && Array.isArray(data.data)) {
                data.data.forEach((item, index) => {
                    // const discount_amount = item.discount_amount === null ? 0 : parseFloat(item.discount_amount);
                    // item.grand_total = parseFloat(item.final_total) + parseFloat(item.shipping_charges) - discount_amount;
                    data.data[index] = item;
                });
            } else {
                data.data = [];
            }

            dispatch({ type: Types.GET_ORDERS_LIST, payload: data });
        });
};

export const getOrderDetails = (id) => (dispatch) => {
    dispatch({ type: Types.IS_LOADING, payload: true });
    axios
        .get(`${process.env.REACT_APP_API_URL}sales/${id}`)
        .then((res) => {
            dispatch({ type: Types.GET_ORDERS_VIEW_LIST, payload: res.data.data });
        });
}

//get order life cycle detils data 
export const getOrderLifeCycleData = (id) => (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };
    dispatch({ type: Types.GET_ORDER_LIFECYCLE_DETAILS, payload: responseList });
    axios.get(`${process.env.REACT_APP_API_URL}sales/order-lifecycle/${id}`)
        .then((res) => {
            if (res.data.status) {
                const { data, message, status } = res.data;
                responseList.status = status;
                responseList.data = data;
                responseList.message = message;
                responseList.isLoading = false;
                dispatch({ type: Types.GET_ORDER_LIFECYCLE_DETAILS, payload: responseList });
            }
        }).catch((err) => {
            responseList.isLoading = false;
            dispatch({ type: Types.GET_ORDER_LIFECYCLE_DETAILS, payload: responseList });
        })
}

/**
 * Update Order.
 *
 * @param {object} data order input data
 * @param {int} id order ID
 * @returns void
 */
export const updateOrder = (data, id) => (dispatch) => {
    let response = {
        loading: true,
        status: false,
        message: '',
        data: null
    }

    const url = `${process.env.REACT_APP_API_URL}sales/${id}`;

    // Process data for processing and anything extra needed
    data = {
        order_id : parseInt(id),
        status : data.status,
        staff_note : data.staff_note,
        shipping_charges: data.shipping_charges
    }

    dispatch({ type: Types.UPDATE_ORDER, payload: response });

    axios.put(url, data)
        .then(res => {
            response.loading = false;
            response.status = res.data.status;
            response.message = res.data.message;
            showToast('success', res.data.message);
            dispatch({ type: Types.UPDATE_ORDER, payload: response });
        })
        .catch(err => {
            response.message = 'Something went wrong. Please try again.';
            response.loading = false;
            showToast('error', 'Something went wrong. Please try again.');
            dispatch({ type: Types.UPDATE_ORDER, payload: response });
        });
}

/**
 * Delete Order.
 *
 * @param {int} id order ID
 * @param {object} filter order filtered data
 *
 * @returns void
 */
export const deleteOrder = (id, filter) => (dispatch) => {
    let response = {
        loading: true,
        status: false,
        message: '',
        data: null
    }

    dispatch({ type: Types.DELETE_ORDER, payload: response });

    axios.delete(`${process.env.REACT_APP_API_URL}sales/${id}`)
        .then(res => {
            response.loading = false;
            response.status = res.data.status;
            response.message = res.data.message;
            showToast('success', res.data.message);
            dispatch({ type: Types.DELETE_ORDER, payload: response });
            dispatch(GetOrdersList(filter.page, filter.type, filter.searchText, filter.date, filter.customDate, filter.status));
        })
        .catch(err => {
            response.loading = false;
            response.message = 'Something went wrong. Please try again.';
            showToast('error', 'Something went wrong. Please try again.');
            dispatch({ type: Types.DELETE_ORDER, payload: response });
        });
}

/**
 * clear order data from reducer after unmount
 * @returns void
 */

export const clearOrderData = () => {
    return {
        type: Types.CLEAR_ORDER_DATA
    }
}