import axios from "axios";
import LocalStorageService from "./LocalStorageService";

const baseURL = process.env.REACT_APP_API_URL;
const storage = LocalStorageService.getService();
const token   = storage.getAccessToken();

export default axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    }
});