import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getGiftList, handleGiftDelete } from './_redux/GiftAction/GiftAction';
import PaginationLaravel from '../../../../modules/master/pagination/PaginationLaravel';
import LoadingSpinner from '../../../../modules/master/spinner/LoadingSpinner';
import PermissionWiseDisplay from '../../../../app/modules/role/PermissionWiseDisplay';

const GiftCardsList = (() => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const giftListData = useSelector((state) => state.GiftCartReducer.giftListData)
  const gitPaginatedData = useSelector((state) => state.GiftCartReducer.gitPaginatedData)
  const isLoading = useSelector((state) => state.GiftCartReducer.isLoading)

  useEffect(() => {
    dispatch(getGiftList(currentPage));
  }, [dispatch, currentPage]);

  const changePage = (data) => {
    setCurrentPage(data.page);
    dispatch(getGiftList(data.page));
  };

  const searchItems = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText.length === 0) {
      dispatch(getGiftList(currentPage));
    } else {
      dispatch(getGiftList(currentPage, searchText));
    }
  };


  // delete gift card list 
  const confirmDelete = (id) => {
    dispatch(handleGiftDelete(id));
  }
  const GiftCardDelete = (item) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(item.id),
        },
        {
          label: "No"
        }
      ]
    });
  };


  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">


          <div className="card-body">

            <div className="row mt-2">
              <h3 className="card-label">Gift Card list</h3>

              <Form.Group as={Col} controlId="formGridState">
                <input
                  type="search"
                  className="form-control product-search-input formHeight"
                  placeholder="Search by gift card title"
                  value={searchText}
                  onChange={searchItems}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                {/* <RHFInput
                  as={<Select options={''} />}
                  rules={{ required: true }}
                  name="coupon_type_id"
                  placeholder="Filter by discount type"
                  register={register}
                  setValue={setValue}
                /> */}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                {/* <RHFInput
                  as={<Select options={''} />}
                  rules={{ required: true }}
                  placeholder="Filter by amount type"
                  name="amount_type_id"
                  register={register}
                  setValue={setValue}
                /> */}
              </Form.Group>

              {/* <i className="fas fa-filter tableFilter mt-1 mr-2"></i>
              <i className="far fa-filter"></i> */}
              <PermissionWiseDisplay permission_name="Giftcard.Create" display={false}>
                <Link to="/giftCards/add" className="btn btn-primary text-center text-white btn-sm custome-addnew-btn certificate-add-btn" >
                  Add New
                </Link>
              </PermissionWiseDisplay>
            </div>

            <div className="react-bootstrap-table table-responsive">
              {isLoading && <LoadingSpinner text="Loading Gift Cards List..." />}

              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <td>SL</td>
                    <td>Title</td>
                    <td>price value</td>
                    <td>change price</td>
                    <td>Image</td>
                  </tr>
                </thead>
                <tbody>
                  {giftListData &&
                    giftListData.map((item, index) => (
                      <tr key={index}>
                        <td>{gitPaginatedData.from + index}</td>
                        <td>{item.title}</td>
                        <td>{item.price_value_for}</td>
                        <td>{item.change_price_value}</td>
                        <td>
                          <img src={item.image_url !== null && item.image_url !== '' ? item.image_url : 'N/A'} className="img-thumnail" height="50" width="50" alt="" />
                        </td>
                        <td>
                          <Link className="btn btn-icon btn-light btn-hover-info btn-sm" to={`/giftCards/edit/${item.id}`}>
                            <i className="fa fa-edit"></i>
                          </Link>

                          &nbsp;&nbsp;&nbsp;
                          <PermissionWiseDisplay permission_name="Giftcard.Delete" display={false}>
                            <a
                              className="btn btn-icon btn-light btn-hover-danger btn-sm"
                              onClick={() => GiftCardDelete(item)}
                            >
                              <i className="fa fa-trash"></i>
                            </a>
                          </PermissionWiseDisplay>
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot></tfoot>
              </table>
              {isLoading && (giftListData && giftListData.length) === 0 && (
                <div className="alert alert-warning mt-5">
                  Sorry ! No Coupon List Found.
                </div>
              )}
              <PaginationLaravel
                changePage={changePage}
                data={gitPaginatedData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default GiftCardsList;
