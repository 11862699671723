import * as Types from '../types/Types';

const initialState = {
    isLoading: false,
    InvoicesList: [],
    InvoicesPaginatedList: [],
    InvoicesDetails: null,
    InvoiceLifeCycleDetails: null,
    statusInput: {
        product_shift_date: "",
        customer_delivery_date: "",
        comment: "",
    }
};

const InvoiceReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_INVOICES_LIST:
            if (action.payload.status) {
                return {
                    ...state,
                    InvoicesList: action.payload.InvoicesList,
                    InvoicesPaginatedList: action.payload.InvoicesPaginatedList,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            }
        case Types.GET_INVOICES_DETAILS:
            if (action.payload.status) {
                return {
                    ...state,
                    InvoicesDetails: action.payload.data,
                    isLoading: action.payload.invoiceLoading,
                }
            } else {
                return {
                    ...state,
                    isLoading: false,
                }
            }
        case Types.GET_INVOICE_LIFECYCLE_DATA:
            return {
                ...state,
                InvoiceLifeCycleDetails: action.payload.data,
                isLoading: action.payload.isLoading,
            }
        case Types.CHANGE_STATUS_INPUT_DATA:
            const statusInput = { ...state.statusInput };
            statusInput[action.payload.name] = action.payload.value;
            return {
                ...state,
                statusInput,
            };
        default:
            break;
    }
    return newState;
};

export default InvoiceReducer;
