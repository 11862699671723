import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = () => {
  const barProperties = {
    labels: ["Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],

    datasets: [
      {
        label: "Sales Volume",

        backgroundColor: [
          "rgba(54, 153, 255, 1)",
          "rgba(54, 153, 255, 1)",
          "rgba(54, 153, 255, 1)",
        ],
        borderColor: [],

        borderWidth: 1,
        data: [55223, 69368, 81338, 83806, 92436, 90027, 106062],
      },
    ],
  };

  return (
    <>
      <div className="chart__box chart__box__custom bg-white">
        <div className="chart__head">
          <h4 className="text-bold">Sales Volume</h4>
        </div>
        <div className="test">
          <Bar
            data={barProperties}
            width="100%"
            height="80%"
            options={
              {
                // title: {
                //   display: true,
                //   text: "User Count",
                //   fontSize: 12,
                // },
                // legend: {
                //   display: true,
                //   position: "left",
                // },
              }
            }
          />
        </div>
      </div>
    </>
  );
};

export default BarChart;
