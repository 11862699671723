import React from "react";
import PermissionWiseDisplay from "../../../app/modules/role/PermissionWiseDisplay";
import WebsiteEdit from "../components/WebsiteEdit";

const WebsiteEditContainer = (props) => {
    return (
        <>
            <PermissionWiseDisplay permission_name="Website.Edit" display={true} >
                <WebsiteEdit props={props} />
            </PermissionWiseDisplay>
        </>
    );
};
export default WebsiteEditContainer;
