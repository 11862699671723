import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { RHFInput } from 'react-hook-form-input';

import { storeCategory, handleChangeCategory, deleteCategoryImage, emptyCategoryMessage, GetCategoriesSortedList } from '../../_redux/actions/CategoriesAction';
import SimpleEditor from '../../../master/components/text-editor/SimpleEditor';
import { GetBusinessList } from '../../../master/OptionData/BusinessList/BusinessListAction/BusinessOptionListAction';
// import { GetParentCategoriesList } from '../../../master/OptionData/ParentCategory/ParentCategoryAction/ParentCategoryOptionListAction';
import LabelRequired from '../../../master/components/labels/LabelRequired';
import LabelOptional from '../../../master/components/labels/LabelOptional';
import TextHelp from '../../../master/components/labels/TextHelp';

const CategoryAdd = withRouter(({ history }) => {
	const { register, handleSubmit, errors, setValue } = useForm();
	const dispatch = useDispatch();

	const handleChangeTextInput = (name, value, e = null) => {
		dispatch(handleChangeCategory(name, value, e));
	};

	const businessList = useSelector((state) => state.BusinessOptionList.businessList);
	const categoriesSortedList = useSelector((state) => state.categories.categoriesSortedList);
	const addStatus = useSelector((state) => state.categories.addStatus);
	const isAdding = useSelector((state) => state.categories.isAdding);
	const addMessage = useSelector((state) => state.categories.addMessage);
	// const isLoading = useSelector((state) => state.categories.isLoading);
	const formInput = useSelector((state) => state.categories.formInput);

	useEffect(() => {
		dispatch(GetBusinessList());
		dispatch(GetCategoriesSortedList());
	}, []);

	useEffect(() => {
		if (addStatus && addMessage !== null && addMessage.length > 0) {
			dispatch(emptyCategoryMessage());
			history.push('/categories');
		}
	}, [addStatus, addMessage]);

	const onSubmit = () => {
		dispatch(storeCategory(formInput));
	};

	return (
		<>
			<div className="container-fluid">
				<div className="card card-custom gutter-b">
					<div className="card-header">
						<div className="card-title">
							<h3 className="card-label">Create New Category</h3>
						</div>
						<div className="card-toolbar">
							<a
								onClick={() => {
									history.push("/categories");
								}}
							>
								<button type="button" className="btn btn-primary">
									<i className="fa fa-arrow-left"></i> Categories
								</button>
							</a>
						</div>
					</div>
					<div className="card-body">
						<form
							className="form form-label-right"
							onSubmit={handleSubmit(onSubmit)}
							method="post"
							encType="multipart/form-data"
						>
							<div className="form-group row">
								<div className="col-lg-4">
									<label className="form-label mt-2">
										Category Name <LabelRequired />
									</label>
									<Form.Control
										type="text"
										placeholder="Enter Category Name"
										name="name"
										className="fromStyle"
										onChange={(e) => handleChangeTextInput('name', e.target.value)}
										ref={register({
											required: true,
											maxLength: 100,
										})}
									/>
									<div className="inputError margin-minus-8">
										{errors.name &&
											errors.name.type === 'required' &&
											"Category name can't be blank !"}
									</div>
								</div>
								<div className="col-lg-4">
									<label className="form-label mt-2">
										Vendor <LabelRequired />
									</label>
									<RHFInput
										as={<Select options={businessList} />}
										rules={{ required: true }}
										name="businessData"
										register={register}
										onChange={(e) => handleChangeTextInput('businessData', e)}
										setValue={setValue}
									/>
									<div className="inputError margin-minus-8">
										{errors.businessData &&
											errors.businessData.type === 'required' &&
											"Business name can't be blank !"}
									</div>
								</div>
								<div className="col-lg-4">
									<label className="form-label mt-2">
										Category Code <LabelOptional />
									</label>
									<Form.Control
										type="text"
										placeholder="Enter Category Code"
										name="short_code"
										className="fromStyle"
										onChange={(e) => handleChangeTextInput('short_code', e.target.value)}
										ref={register({
											required: false,
											maxLength: 100,
										})}
									/>
									{/* <div className="inputError margin-minus-8">
                    {errors.short_code &&
                      errors.short_code.type === 'required' &&
                      "Category Code Can't be blank"}
                  </div> */}
								</div>

								<div className="col-lg-4">
									<label className="form-label mt-2">
										Parent Category <LabelOptional />
									</label>
									<RHFInput
										as={<Select options={categoriesSortedList} />}
										rules={{ required: false }}
										name="parentCategory"
										register={register}
										onChange={(e) => handleChangeTextInput('parentCategory', e)}
										setValue={setValue}
									/>
									<TextHelp text="Please select if it has a parent category, otherwise leave as empty !" />
									{/* <div className="inputError margin-minus-8">
									{errors.parentCategory &&
									errors.parentCategory.type === 'required' &&
									"Parent Category can't be blank !"}
								</div> */}
								</div>

								<div className="col-lg-4">
									<label className="form-label mt-2">
										Visible Navbar
										<span className="text-info text-sm">(Optional)</span>
									</label>
									<Form.Group controlId="formBasicCheckboxNavbar" >
										<Form.Check
											type="checkbox"
											label={formInput.is_visible_navbar ? 'Yes' : 'No'}
											name="is_visible_navbar"
											onChange={(e) =>{
												handleChangeTextInput('is_visible_navbar', e.target.checked)
											}}
										/>
									</Form.Group>
								</div>

								<div className="col-lg-4">
									<label className="form-label mt-2">
										Navbar Sl. No.
										<span className="text-info text-sm">(Optional)</span>
									</label>
									<Form.Control
										type="number"
										placeholder="Set Priority, eg: 1, 2, 100"
										name="navbar_priority"
										className="fromStyle"
										onChange={(e) => handleChangeTextInput('navbar_priority', e.target.value)}
										ref={register({
											required: false,
											maxLength: 100,
										})}
									/>
								</div>

								<div className="col-lg-4">
									<label className="form-label mt-2">
										Priority
										<span className="text-info text-sm">(Optional)</span>
									</label>
									<Form.Control
										type="number"
										placeholder="Enter Priority"
										name="priority"
										className="fromStyle"
										onChange={(e) => handleChangeTextInput('priority', e.target.value)}
										ref={register({
											required: false,
											maxLength: 100,
										})}
									/>
								</div>

								<div className="col-lg-4">
									<label className="form-label mt-2">
										Visible Home Page
										<span className="text-info text-sm">(Optional)</span>
									</label>
									<Form.Group controlId="formBasicCheckbox" >
										<Form.Check
											type="checkbox"
											label={formInput.is_visible_homepage ? 'Yes' : 'No'}
											name="is_visible_homepage"
											checked={formInput.is_visible_homepage}
											value={formInput.is_visible_homepage}
											onChange={(e) => handleChangeTextInput('is_visible_homepage', e.target.checked)}
										/>
									</Form.Group>
								</div>

								<div className="col-lg-4">
									<label className="form-label mt-2">
										Short Description
										<span className="text-info text-sm">(Optional)</span>
									</label>
									<Form.Control
										as="textarea"
										rows={3}
										placeholder="Enter Category Short Description"
										name="short_description"
										className="fromStyle"
										onChange={(e) => handleChangeTextInput('short_description', e.target.value)}
										ref={register({
											required: false,
											maxLength: 100,
										})}
									/>
								</div>

								<div className="col-lg-8">
									<label className="form-label mt-2">
										Description
										<span className="text-info text-sm">(Optional)</span>
									</label>
									<SimpleEditor
										value={formInput.description}
										onValueChange={(e) => handleChangeTextInput('description', e)}
									/>
								</div>

								<div className="col-lg-4">
									<label className="form-label mt-2">
										Logo Image
										<span className="text-info text-sm">(Optional)</span>
									</label>
									<Form.Control
										type="file"
										placeholder="Enter Logo Image"
										name="image"
										onChange={(e) => handleChangeTextInput('image', e.target.files[0], e)}
										className="fromStyle"
										ref={register({
											required: false,
											maxLength: 100,
										})}
									/>
									{
										formInput.logoPreviewUrl !== null &&
										<div className="imgPreview" title="Remove">
											<div className="preview-delete-icon"><i className="fa fa-times text-danger" onClick={() => dispatch(deleteCategoryImage('image'))}></i></div>
											<img src={formInput.logoPreviewUrl} className="img img-thumbnail" />
										</div>
									}
								</div>

								<div className="col-lg-4">
									<label className="form-label mt-2">
										Banner Image
										<span className="text-info text-sm">(Optional)</span>
									</label>
									<Form.Control
										type="file"
										placeholder="Enter Banner Image"
										name="banner"
										className="fromStyle"
										onChange={(e) => handleChangeTextInput('banner', e.target.files[0], e)}
										ref={register({
											required: false,
											maxLength: 100,
										})}
									/>
									{
										formInput.bannerPreviewUrl !== null &&
										<div className="imgPreview" title="Remove">
											<i className="fa fa-times text-danger" onClick={() => dispatch(deleteCategoryImage('banner'))}></i>
											<img src={formInput.bannerPreviewUrl} className="img img-thumbnail" />
										</div>
									}
								</div>

							</div>

							<div className="form-group row">
								<div className="col-sm-10">
									<a
										onClick={() => {
											history.push('/categories');
										}}
									>
										<button type="button" className="btn btn-secondary btn-lg mr-2">
											<i className="fa fa-chevron-left"></i> Back
										</button>
									</a>
									{isAdding &&
										<button type="submit" className="btn btn-primary btn-lg" disabled={true} >
											<span>Submitting...</span>
											<span className="ml-3 spinner spinner-white"></span>
										</button>
									}
									{!isAdding &&
										<button type="submit" className="btn btn-primary btn-lg">
											<i className="fa fa-check"></i> <span>Submit</span>
										</button>
									}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
});

export default CategoryAdd;
