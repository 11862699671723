import React from 'react';
import PermissionWiseDisplay from '../../../../app/modules/role/PermissionWiseDisplay';
import GiftCardsAdd from '../../components/giftCards/GiftCardsAdd';



const GiftCardsAddContainer = () => {
  return (
    <>
      <PermissionWiseDisplay permission_name="Giftcard.Create" display={true}>
        <GiftCardsAdd/>
      </PermissionWiseDisplay>
    </>
  );
};

export default GiftCardsAddContainer;
