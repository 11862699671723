import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SimpleTooltip from "../../../master/components/tooltip/SimpleTooltip";
import PaginationLaravel from "../../../master/pagination/PaginationLaravel";
import LoadingSpinner from "../../../master/spinner/LoadingSpinner";
import { getAttributeList, habdleDeleteAttribute } from "../../_redux/actions/AttributeAction";
import { confirmAlert } from "react-confirm-alert";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";
const AttributesList = ({ currentPage, setCurrentPage }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.AttrubuteReducer.isLoading);
  const attributeList = useSelector((state) => state.AttrubuteReducer.attributeList);
  const attributePaginatedList = useSelector((state) => state.AttrubuteReducer.attributePaginatedList);

  useEffect(() => {
    dispatch(getAttributeList(currentPage));
  }, [dispatch, currentPage]);

  const changePage = (data) => {
    setCurrentPage(data.page);
    dispatch(getAttributeList(data.page));
  };

  // delete products list 
  const confirmDelete = (id) => {
    dispatch(habdleDeleteAttribute(id));
  }
  const handleDeleteAttribute = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No"
        }
      ]
    });
  };
  // const [showItemModel, setShowItemModel] = useState(false);
  // const [ItemID, setItemID] = useState(null);

  // const handleProductDetailsModel = (item) => {
  //   setShowItemModel(true);
  //   setItemID(item.id);
  // }


  return (
    <div className="card-body">
      {isLoading && <LoadingSpinner text="Loading Attribute List..." />}
      <div className="react-bootstrap-table table-responsive">
        <table className="table table table-head-custom table-vertical-center">
          <thead>
            <tr>
              <td>SL</td>
              <td>Name</td>
              <td>Category</td>
              <td>Action</td>
            </tr>
          </thead>
          <PermissionWiseDisplay permission_name="Attribute.View" display={true}>
            <tbody>
              {attributeList && attributeList.map((item, index) => (
                  <tr  key={index}>
                    <td>{attributePaginatedList.from + index}</td>
                    <td>{item.name}</td>
                    <td>{item.category ? item.category.name : '--'}</td>
                    <td className="d-flex">
                      {/* <SimpleTooltip title="View Attribute">
                        <Link className="btn btn-icon btn-light btn-hover-info btn-sm" >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </SimpleTooltip> */}
                      &nbsp;&nbsp;
                      <PermissionWiseDisplay permission_name="Attribute.Edit" display={false}>
                        <SimpleTooltip title={`Edit ${item.name}`}>
                          <Link to={`/attribute/edit/${item.id}`} className="btn btn-icon btn-light btn-hover-info btn-sm" >
                            <i className="fa fa-edit"></i>
                          </Link>
                        </SimpleTooltip>
                      </PermissionWiseDisplay>
                      &nbsp;&nbsp;
                      <PermissionWiseDisplay permission_name="Attribute.Delete" display={false}>
                        <SimpleTooltip title={`Delete ${item.name}`}>
                          <a className="btn btn-icon btn-light btn-hover-danger btn-sm" onClick={(id) => handleDeleteAttribute(item.id)}>
                            <i className="fa fa-trash text-danger"></i>
                          </a>
                        </SimpleTooltip>
                      </PermissionWiseDisplay>
                    </td>
                  </tr>
              ))}

            </tbody>
          </PermissionWiseDisplay>
          <tfoot></tfoot>
        </table>
        {!isLoading && attributeList && attributeList.length === 0 && (
          <div className="alert alert-warning mt-5">
            Sorry ! Attribute Not Found.
          </div>
        )}
        <PaginationLaravel
          changePage={changePage}
          data={attributePaginatedList}
        />
      </div>
    </div>
  );
};

export default AttributesList;
