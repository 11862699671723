import React from 'react';
import AttributeEdit from '../components/edit/AttributeEdit';

const AttributeEditContainer = () => {
  return (
    <>
      <AttributeEdit />
    </>
  );
};

export default AttributeEditContainer;