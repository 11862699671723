import * as Types from "../types/Types";
import axios from "axios";
import { generateFormDataFromObject } from "../../../master/utils/FileHelper";
import { showToast } from "../../../master/utils/ToastHelper";

export const handleChangeSlideInput = (name, value, e = null) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  }
  dispatch({ type: Types.CHANGE_SLIDE_INPUT, payload: data });

  if (name === 'image' || name === 'mobile_image') {
    let reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      if (name === "image") {
        data.name = 'imagePreviewURl';
      } else if (name === "mobile_image") {
        data.name = 'mobileImagePreviewURl';
      }
      data.value = reader.result;
      dispatch({ type: Types.CHANGE_SLIDE_INPUT, payload: data });
    }
    reader.readAsDataURL(file)
  }
};

export const deleteBrandsImage = (name) => (dispatch) => {
  let data = {
    name: name,
    value: null,
  }
  dispatch({ type: Types.CHANGE_SLIDE_INPUT, payload: data });

  if (name === 'image') {
    data.name = 'imagePreviewURl';
  } else if (name === 'mobile_image') {
    data.name = 'mobileImagePreviewURl';
  }
  dispatch({ type: Types.CHANGE_SLIDE_INPUT, payload: data });
};

//add new slide
export const createNewSlide = (slideInput) => async (dispatch) => {
  if (slideInput.title === null || slideInput.title === "") {
    showToast('error', "Slide title can't be blank!")
    return false;
  }
  if (slideInput.business_id === null || slideInput.business_id === "") {
    showToast('error', "Shop can't be blank!")
    return false;
  }
  if (slideInput.description === null || slideInput.description === "") {
    showToast('error', "Description can't be blank!")
    return false;
  }
  let responseData = {
    status: false,
    message: "",
    isLoading: true,
  };
  dispatch({ type: Types.CREATE_NEW_SLIDE, payload: responseData });
  const headers = {
    "Content-Type": "application/json",
  };
  const slideData = generateFormDataFromObject(slideInput)
  axios.post(`${process.env.REACT_APP_API_URL}sliders`, slideData, {
    headers: headers,
  })
    .then((res) => {
      if (res.data.status) {
        let data = res.data;
        responseData.message = data.message;
        responseData.status = data.status;
        responseData.isLoading = false;
        showToast('success', responseData.message);
        dispatch({ type: Types.CREATE_NEW_SLIDE, payload: responseData });
      }
    }).catch((err) => {
      const errorMessage = "Sorry, Something went wrong!"
      responseData.isLoading = false;
      showToast('error', errorMessage);
      dispatch({ type: Types.CREATE_NEW_SLIDE, payload: responseData });
    });
};

// SLIDER LIST 
export const getSliderList = (page, searchText = null, couponType = null, amountType = null) => async (dispatch) => {
  let response = {
    sliderList: [],
    sliderPaginationList: [],
    status: false,
    message: "",
    isLoading: true,
    errors: [],
  };

  dispatch({ type: Types.GET_SLIDER_LIST, payload: response });
  let url = "";
  url = `${process.env.REACT_APP_API_URL}sliders?isPaginated=1&paginateNo=10`;

  if (page !== null || page === "") {
    url += `&page=${page}`;
  }

  if (searchText !== null) {
    url += `&search=${searchText}`;
  } else {
    // url += `&certificate/details?search=${searchText}`
  }

  try {
    await axios.get(url)
      .then((res) => {
        const { data, message, status } = res.data;
        response.status = status;
        response.sliderList = data.data;
        response.message = message;
        response.sliderPaginationList = data;
        response.isLoading = false;
        dispatch(emptyDispatch())
      })
      .catch((err) => {
        // toast.error(err);
      });
  } catch (error) {
    response.message = "Something Went Wrong !";
    // toast.error(error);
  }
  response.isLoading = false;
  dispatch({ type: Types.GET_SLIDER_LIST, payload: response });
};

//handle delete slider list
export const handleDeleteSlider = (id) => (dispatch) => {
  let isLoading = true;
  dispatch({ type: Types.DELETE_SLIDER, payload: isLoading })
  axios.delete(`${process.env.REACT_APP_API_URL}sliders/${id}`)
    .then((res) => {
      if (res.data.status) {
        const data = res.data;
        showToast('success', data.message);
        dispatch({ type: Types.DELETE_SLIDER, payload: false });
        dispatch(getSliderList())
      }
    })
}

//get slider details
export const getSliderDetails = (id) => (dispatch) => {
  let response = {
    status: false,
    message: "",
    isLoading: true,
    sliderDetails: {}
  };
  dispatch({ type: Types.GET_SLIDE_DETAILS, payload: response });

  axios.get(`${process.env.REACT_APP_API_URL}sliders/${id}`)
    .then((res) => {
      if (res.data.status) {
        const { data, message, status } = res.data;
        if (data.business_id !== null && data.business_id !== "") {
          data.businessType = {
            label: data.business.name,
            value: data.business.id,
          }
        } else {
          data.business = ''
        }
        if (data.image_url !== null && data.image_url !== "") {
          data.imagePreviewURl = data.image_url;
          data.image = null;
        }
        if (data.mobile_image_url !== null && data.mobile_image_url !== "") {
          data.mobileImagePreviewURl = data.mobile_image_url;
          data.mobile_image = null;
        }
        data.created_by = 1;

        response.status = status;
        response.sliderDetails = data;
        response.message = message;
        response.isLoading = false;
        dispatch({ type: Types.GET_SLIDE_DETAILS, payload: response });
      }
    }).catch((err) => {
      response.isLoading = false;
      dispatch({ type: Types.GET_SLIDE_DETAILS, payload: response });
    });
}

//updated slider
export const updatedSlider = (slideInput, id) => async (dispatch) => {
  if (slideInput.title === null || slideInput.title === "") {
    showToast('error', "Slider title can't be blank!")
    return false;
  }
  if (slideInput.business_id === null || slideInput.business_id === "") {
    showToast('error', "Business type can't be blank!")
    return false;
  }
  if (slideInput.description === null || slideInput.description === "") {
    showToast('error', "Description can't be blank!")
    return false;
  }
  let responseData = {
    status: false,
    message: "",
    isLoading: true,
  };
  dispatch({ type: Types.UPDATED_SLIDE, payload: responseData });
  const headers = {
    "Content-Type": "application/json",
  };
  const slideData = generateFormDataFromObject(slideInput)
  axios.post(`${process.env.REACT_APP_API_URL}sliders/${id}?_method=PUT`, slideData, {
    headers: headers,
  })
    .then((res) => {
      if (res.data.status) {
        let data = res.data;
        responseData.message = data.message;
        responseData.status = data.status;
        responseData.isLoading = false;
        showToast('success', responseData.message);
        dispatch({ type: Types.UPDATED_SLIDE, payload: responseData });
      }
    }).catch((err) => {
      const errorMessage = "Sorry, Something went wrong!"
      responseData.isLoading = false;
      showToast('error', errorMessage);
      dispatch({ type: Types.UPDATED_SLIDE, payload: responseData });
    });
};

export const emptyDispatch = () => (dispatch) => {
  dispatch({ type: Types.EMPTY_DISPATCH, payload: true });
}

// export const BrandsEmptyAddMessage = () => async (dispatch) => {
//   dispatch({ type: Types.EMPTY_BRANDS_ADD_MESSAGE, payload: true });
// };
// export const BrandsEmptyEditMessage = () => async (dispatch) => {
//   dispatch({ type: Types.EMPTY_BRANDS_EDIT_MESSAGE, payload: true });
// };

