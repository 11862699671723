import React from 'react';
import { Route } from 'react-router-dom';
import BrandsAddContainer from '../views/BrandsAddContainer';
import BrandsEditContainer from '../views/BrandsEditContainer';
import BrandsListContainer from '../views/BrandsListContainer';
import BrandViewContainer from '../views/BrandViewContainer';

const brandRoutes = [
  {
    path: "/brands/list",
    name: "Brands List",
    component: BrandsListContainer,
    exact: true,
  },
  {
    path: "/brands/add",
    name: "Brands Add",
    component: BrandsAddContainer,
    exact: true,
  },
  {
    path: "/brands/edit/:id",
    name: "Brands Edit",
    component: BrandsEditContainer,
    exact: true,
  },
  {
    path: "/brand/view/:id",
    name: "Vew brand details",
    component: BrandViewContainer,
    exact: true,
  },
];

function getBrandModuleRoutes() {
  {
    return brandRoutes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default getBrandModuleRoutes;
