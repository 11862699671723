import React from "react";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";
import UserEdit from "../components/UserEdit";

export default function EditUserContainer() {
  return (
    <div>
      <PermissionWiseDisplay permission_name="User.Edit" display={true}>
        <UserEdit />
      </PermissionWiseDisplay>
    </div>
  );
}
