import * as Types from '../Types/Types';

const initialState = {
    attributeList: [],
};

const AttributeListReducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case Types.GET_ATTRIBUTE_OPTION_LIST:
            return {
                ...state,
                attributeList: getAttributeList(action.payload.data)
            }

        default:
            break;
    }

    return newState;
};

/**
 * Get Attribute List for select from attributes array
 *
 * @param {array} data Attribute List Array
 *
 * @return array
 */
const getAttributeList = (data) => {
    let attributeData = [];
    if (typeof data !== 'undefined' && data !== null && data.length > 0) {
        data.forEach((item) => {
            let items = {
                value: item.id,
                label: item.name,
            };
            attributeData.push(items);
        });
    }

    return attributeData;
}

export default AttributeListReducer;
