import React from "react";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";
import RolePermissionList from "../components/RolePermissionList";

export default function RolePermissionListContainer() {
  return (
    <div>
      <PermissionWiseDisplay permission_name="Role.View" display={false}>
        <RolePermissionList />
      </PermissionWiseDisplay>
    </div>
  );
}
