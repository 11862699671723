import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import InvoiceStatusUpdated from './InvoiceStatusUpdated';
import InvoiceViewDetails from './InvoiceViewDetails';
import './css/invoice.css'
const ViewInvoice = ({ id }) => {
    return (
        <Tabs className="mt-5" defaultActiveKey="basicInfo" id="uncontrolled-tab-example">
            <Tab eventKey="basicInfo" title="Basic Information">
                <InvoiceViewDetails id={id} />
            </Tab>
            <Tab eventKey="status" title="Update Information">
                <InvoiceStatusUpdated />
            </Tab>
        </Tabs>
    );
};

export default ViewInvoice;