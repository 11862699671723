import React from 'react';
import { Route } from 'react-router-dom';
import InvoiceLifeCycle from '../components/InvoiceLifeCycle/InvoiceLifeCycle';
import InvoicesListContainer from '../views/InvoicesListContainer';

const invoices = [
  {
    path: '/orders/invoices',
    name: 'Invoices List',
    component: InvoicesListContainer,
    exact: true,
  },
  {
    path: '/invoices/lifeCycle/:id',
    name: 'Invoices LifeCycle',
    component: InvoiceLifeCycle,
    exact: true,
  },
];

function InvoiceRoutes() {
  {
    return invoices.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default InvoiceRoutes;
