import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import LoadingSpinner from '../../master/spinner/LoadingSpinner';
import { getBrandDetails } from '../_redux/actions/BrandsAction';

function BrandSingleDetails({id}) {
    const dispatch = useDispatch();
    const { brandDetails } = useSelector(state => state.brands)

    useEffect(() => {
        dispatch(getBrandDetails(id))
    }, []);

    return (
        <div className="card-body">
            {
                !brandDetails ? <div className="d-flex justify-content-center"> <LoadingSpinner text="Loading brand details...." /> </div> : ''
            }
            {
                brandDetails && (
                    <div className="row">
                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Id: {brandDetails.id && brandDetails.id}
                            </label>
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Name: {brandDetails.name && brandDetails.name}
                            </label>
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Slug: {brandDetails.slug && brandDetails.slug}
                            </label>
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Status: {brandDetails.status && brandDetails.status == 1 ? 'Active' : 'Deactive'}
                            </label>
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Created At: {brandDetails.created_at && brandDetails.created_at}
                            </label>
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                categories: {
                                    brandDetails.categories.map((item, index) => (
                                        <span>
                                            {item.label}
                                            {brandDetails.categories.length !== index + 1 && ', '}
                                        </span>
                                    ))
                                }
                            </label>
                        </div>
                        <div className='col-lg-4'>
                            <label className='form-label mt-2'>logo</label>
                            <div style={{width: '100px', height: '100px'}}>
                                {
                                    brandDetails.image_url && (
                                        <img style={{width: '100%', height: '100%'}} src={brandDetails.image_url} alt={brandDetails.name} />
                                    )
                                }
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <label className='form-label mt-2'>Banner</label>
                            <div style={{width: '400px', height: 'auto', maxHeight: '300px'}}>
                                {
                                    brandDetails.banner_url && (
                                        <img style={{width: '100%', height: '100%'}} src={brandDetails.banner_url} alt={brandDetails.name} />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default BrandSingleDetails
