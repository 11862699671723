import React from "react";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";
import CurrenciesEdit from "../../components/currencies/CurrenciesEdit";

const CurrenciesEditContainer = (props) => {
    return (
        <>
            <PermissionWiseDisplay permission_name="Currency.Edit" display={true} >
                <CurrenciesEdit props={props} />
            </PermissionWiseDisplay>
        </>
    );
};

export default CurrenciesEditContainer;
