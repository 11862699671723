import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { GetLocationList } from '../../_redux/actions/LocationAction';

const LocationsList = withRouter(({ history, props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetLocationList());
  }, [dispatch, props]);

  const locationsGetData = useSelector(
    (state) => state.locations.LocationsList
  );

  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 class="card-label">Location list</h3>
            </div>
            <div className="card-toolbar">
              <a
                onClick={() => {
                  history.push('/location/add');
                }}
              >
                <button type="button" class="btn btn-primary">
                  New Location
                </button>
              </a>
            </div>
          </div>
          <div className="card-body">
            {/* <form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-2">
                  <input className="form-control" placeholder="Search" />
                </div>
                <div>
                  <label className="form-label">Status</label>
                </div>
                <div className="col-lg-2">
                  <select
                    className="form-control"
                    name="status"
                    placeholder="Filter by Status"
                  >
                    <option>All</option>
                    <option value="0">Selling</option>
                    <option value="1">Sold</option>
                  </select>
                </div>

                <div>
                  <label className="form-label">Type</label>
                </div>
                <div className="col-lg-2">
                  <select
                    className="form-control"
                    name="status"
                    placeholder="Filter by Status"
                  >
                    <option>All</option>
                    <option value="0">Selling</option>
                    <option value="1">Sold</option>
                  </select>
                </div>

                <div className="col-lg-2">
                  <button
                    className="btn"
                    style={{ backgroundColor: '#E1F0FF', color: '#3699FF' }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form> */}
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <td>SL</td>
                    <td>Business Name</td>
                    <td>Locaton name</td>
                    <td>Land Mark</td>
                    <td>country</td>
                    <td>State</td>
                    <td>city</td>
                    <td>zip_code</td>
                    <td>mobile</td>
                    <td>alternate_number</td>
                    <td>email</td>
                  </tr>
                </thead>
                <tbody>
                  {locationsGetData &&
                    locationsGetData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.business_id}</td>
                        <td>{item.name}</td>
                        <td>{item.landmark}</td>
                        <td>{item.country}</td>
                        <td>{item.state}</td>
                        <td>{item.city}</td>
                        <td>{item.zip_code}</td>
                        <td>{item.mobile}</td>
                        <td>{item.alternate_number}</td>
                        <td>{item.email}</td>

                        <td>
                          <a
                            className="btn btn-icon btn-light btn-hover-info btn-sm"
                            onClick={() => {
                              history.push('/location/edit', { locations: item });
                            }}
                          >
                            <i className="fa fa-edit"></i>
                          </a>
                          &nbsp;&nbsp;&nbsp;
                          {/* <a
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => {
                              if (
                                window.confirm(
                                  'Are you sure you wish to delete this item?'
                                )
                              )
                                vesselDelete(item.intID);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </a> */}
                        </td>
                      </tr>
                    ))}
                  {locationsGetData == null && (
                    <p className="text-danger text-center">No Data Found</p>
                  )}
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default LocationsList;
