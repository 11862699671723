import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import LoadingSpinner from '../../master/spinner/LoadingSpinner';
import PaginationLaravel from '../../master/pagination/PaginationLaravel';
import ItemFilter from './ItemFilter';

import { changeFilterItemList, getProductItemsList } from '../_redux/actions/ItemAction';
import { confirmAlert } from "react-confirm-alert";
import { handleDeleteProductItems } from "../_redux/actions/ItemAction"

import { formatCurrency } from '../../../services/currency';
import { getImageUrl } from '../../../services/FormatHelper';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import { websiteUrl } from '../../../services/Settings';
import SimpleModal from '../../master/components/Modal/SimpleModal';
import ItemExport from './export/ItemExport';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ItemList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const query = useQuery().get("status")
    const status = query

    const { ProductList, ProductPaginatedList, isLoading, filter } = useSelector((state) => state.item);

    useEffect(() => {
        dispatch(getProductItemsList(ProductPaginatedList.current_page, filter.search, filter.category, filter.brand, status));
        dispatch(changeFilterItemList("status", status));
    }, [status]);

    const changePage = (data) => {
        dispatch(changeFilterItemList('page', data.page))
        dispatch(getProductItemsList(data.page, filter.search, filter.category, filter.brand, status));
    };

    // delete products list
    const confirmDelete = (id) => {
        dispatch(handleDeleteProductItems(id, ProductPaginatedList.current_page));
    }

    const handleProductDelete = (id) => {
        confirmAlert({
            title: "Confirm To Delete",
            message: `Are you sure to delete..?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => confirmDelete(id),
                },
                {
                    label: "No"
                }
            ]
        });
    };

    const [showExportModal, setShowExportModal] = useState(false);

    return (
        <div className="card card-custom gutter-b">
            <div className="card-header">
                <div className="row mt-2" style={{ width: '100%' }}>
                    <div className="col-md-2">
                        <div className="card-title">
                            <h3 className="card-label" style={{ textTransform: 'capitalize' }}>
                                { status !== 'active' ? status: ''} Product list
                            </h3>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <ItemFilter />
                    </div>
                    <PermissionWiseDisplay permission_name='product.create' display={false} >
                        <div className="col-md-1 p-0 m-0">
                            <Link to={'/item/add'} className="btn btn-primary text-white">
                                <i className="fa fa-plus"></i> New
                            </Link>
                        </div>
                    </PermissionWiseDisplay>
                    <PermissionWiseDisplay permission_name='admin_seller' display={false} >
                        <div className="col-md-1 p-0 m-0">
                            <button type="button" className="btn btn-primary pl-3 pr-3" onClick={() => setShowExportModal(true)}>
                                <i className="fa fa-download"></i> Export
                            </button>
                        </div>
                    </PermissionWiseDisplay>
                </div>
            </div>
            {isLoading && <LoadingSpinner text="Loading Products..." />}
            <div className="card-body">
                <div className="react-bootstrap-table table-responsive vh-100">
                    <table className="table table table-head-custom table-vertical-center">
                        <thead>
                            <tr>
                                <th>Sl</th>
                                <th style={{ width: '20%' }}>Product</th>
                                <th>Image</th>
                                <th>Vendor Price</th>
                                <th>MRP</th>
                                <th>Profit</th>
                                <th>Brand</th>
                                <th>Category</th>
                                <th>Sub Category</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ProductList &&
                                ProductList.map((item, index) => {
                                    return (
                                        <tr key={index} className="item-class">
                                            <td>{ProductPaginatedList.from + index}</td>
                                            <td title={`SKU: ${item.sku}`}>
                                                {item.name}
                                                <br />
                                                <b>View: </b>
                                                <a target="_blank" href={websiteUrl(`products/${item.sku}`)}>{item.sku}</a>
                                                <br />
                                                <b>SKU: </b>
                                                <span className="text-primary">
                                                   {item.sku_manual}
                                                </span>
                                                <div className="item-visible-on-hover">
                                                    <a href={websiteUrl(`products/${item.sku}`)} target="_blank" title="View Product in Website">
                                                        <i className="fa fa-link"></i>
                                                    </a>

                                                    <PermissionWiseDisplay permission_name="product.view" display={false}>
                                                        <a className="ml-3" title={`View Details of ${item.name}`} onClick={() => { history.push(`/items/details/${item.id}`) }}>
                                                            <i className="fa fa-eye"></i>
                                                        </a>
                                                    </PermissionWiseDisplay>

                                                    <PermissionWiseDisplay permission_name="product.edit" display={false}>
                                                        <a className="ml-3" title={`Edit ${item.name}`} onClick={() => { history.push(`/items/edit/${item.id}`) }}>
                                                            <i className="fa fa-edit"></i>
                                                        </a>
                                                    </PermissionWiseDisplay>
                                                </div>
                                            </td>
                                            <td>
                                                <img
                                                    src={getImageUrl(item.short_resolation_image, 'product')}
                                                    style={{ width: 50 }}
                                                    alt=""
                                                />
                                            </td>
                                            <td>{formatCurrency(item.vendor_price)}</td>
                                            <td>
                                                {(item.is_offer_enable !== false && item.is_offer_enable !== "0") && <del className="text-danger">{formatCurrency(item.default_selling_price)} <br /></del>}
                                                {formatCurrency((item.is_offer_enable !== false && item.is_offer_enable !== "0") ? item.offer_selling_price : item.default_selling_price)}
                                            </td>
                                            <td>
                                                {
                                                    calculateProfit(item)
                                                }
                                            </td>
                                            <td>
                                                {item.brand !== null ? item.brand : '--'}
                                            </td>
                                            <td>
                                                {item.category !== null ? item.category : '--'}
                                            </td>
                                            <td>
                                                {item.sub_category !== null ? item.sub_category : '--'}
                                            </td>

                                            <td> {item.type ? item.type : "--"}</td>
                                            <td>
                                                {item.status.toLowerCase() === 'active' ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">{item.status}</span>}
                                            </td>
                                            <td className="d-flex">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <PermissionWiseDisplay permission_name="product.view" display={false}>
                                                            <Dropdown.Item title={`Details of ${item.name}`} onClick={() => { history.push(`/items/details/${item.id}`) }}>
                                                                View
                                                            </Dropdown.Item>
                                                        </PermissionWiseDisplay>

                                                        <PermissionWiseDisplay permission_name="product.edit" display={false}>
                                                            <Dropdown.Item title={`Edit Product ${item.name}`} onClick={() => { history.push(`/items/edit/${item.id}`) }}>
                                                                Edit
                                                            </Dropdown.Item>
                                                        </PermissionWiseDisplay>

                                                        <PermissionWiseDisplay permission_name="product.delete" display={false}>
                                                            <Dropdown.Item title={`Delete Product ${item.name}`} onClick={() => handleProductDelete(item.id)}>
                                                                Delete
                                                            </Dropdown.Item>
                                                        </PermissionWiseDisplay>


                                                        {/* <Dropdown.Item onClick={() => toggleFeatured(item.id)} >
                                                            {item.featured ? "Remove from featured" : "Add to featured"}
                                                        </Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                        <tfoot></tfoot>
                    </table>

                    {!isLoading && ProductList && ProductList.length === 0 && (
                        <div className="alert alert-secondary mt-5 text-center p-5">
                            <p className='mb-3'>Sorry ! No product found. Please add new product.</p>
                            <Link to={'/item/add'} className="btn btn-primary btn-sm text-white">
                                <i className="fa fa-plus"></i> Add Now
                            </Link>
                        </div>
                    )}
                    <PaginationLaravel
                        changePage={changePage}
                        data={ProductPaginatedList}
                    />
                </div>
            </div>

            <SimpleModal
                size="lg"
                show={showExportModal}
                handleClose={() => setShowExportModal(false)}
                handleShow={() => setShowExportModal(true)}
                modalTitle={"Export Product Data"}
                >
                <ItemExport />
            </SimpleModal>
        </div>
    );
};


/**
 * calculate profit
 * @param {Object} -> object of item 
 * @returns {Number} -> return profit
 */
const calculateProfit = (item) => {
    const vendor_price = (item && item.vendor_price) || 0;
    const mrp = (item && item.default_selling_price) || 0;
    const offer_mrp = (item && item.offer_selling_price) || 0;
    const is_offer_enable = (item && item.is_offer_enable) || false;

    if(vendor_price == 0) {
        return 0;
    }

    if(is_offer_enable) {
        return parseInt(offer_mrp) - parseInt(vendor_price);
    }

    if(!is_offer_enable) {
        return parseInt(mrp) - parseInt(vendor_price);
    }
}

export default ItemList;