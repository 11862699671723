import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { RHFInput } from 'react-hook-form-input';
import { GetCategoryList } from '../../../master/OptionData/Category/CategoryAction/CategoryAction';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { getAttributeList } from '../../_redux/actions/AttributeAction';
import { GetCategoriesSortedList } from '../../../category/_redux/actions/CategoriesAction';
const AttributeFilter = ({ currentPage, setCurrentPage }) => {
    const { register, handleSubmit, errors, setValue } = useForm();

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState("");
    const CategoryListData = useSelector((state) => state.categories.categoriesSortedList);
    useEffect(() => {
        dispatch(GetCategoriesSortedList())
    }, [])

    useEffect(() => {
        dispatch(getAttributeList(currentPage));
    }, [dispatch, currentPage]);

    const searchItems = (e) => {
        const searchText = e.target.value;
        setSearchText(searchText);
        if (searchText.length === 0) {
            dispatch(getAttributeList(currentPage));
        } else {
            dispatch(getAttributeList(currentPage, searchText));
        }
    };

    const categorySelected = (category) => {
        dispatch(getAttributeList(currentPage, searchText, category));
    };
    return (
        <>
            <Row>
                <Col md={6}>
                    <input
                        type="text"
                        className="form-control"
                        name="searchText"
                        placeholder="Search by name"
                        value={searchText}
                        onChange={searchItems}
                    />
                </Col>
                <Col md={6}>
                    <RHFInput
                        as={<Select options={CategoryListData} />}
                        rules={{ required: false }}
                        name="category_id"
                        register={register}
                        // value={attributeInputData.category}
                        setValue={setValue}
                        onChange={(option) => (
                            categorySelected(option.value)
                        )}
                    />
                </Col>
            </Row>

        </>
    );
}

export default AttributeFilter;