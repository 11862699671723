import React from 'react';
import CategoryAdd from '../components/create/CategoryAdd';

const CategoryAddContainer = () => {
  return (
    <>
      <CategoryAdd />
    </>
  );
};

export default CategoryAddContainer;
