import React from 'react';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import FileManagerList from '../components/FileManagerList';

const FileManagerListContainer = () => {
  return (
    <PermissionWiseDisplay permission_name="admin_seller,file_manager.list" display={true}>
      <FileManagerList />
    </PermissionWiseDisplay>
  );
};

export default FileManagerListContainer;
