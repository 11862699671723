import React from 'react';
import PermissionWiseDisplay from '../../../../app/modules/role/PermissionWiseDisplay';
import CurrenciesList from '../../components/currencies/CurrenciesList';

const CurrenciesListContainer = () => {
  return (
    <>
      <PermissionWiseDisplay permission_name="Currency.List" display={true} >
        <CurrenciesList />
      </PermissionWiseDisplay>
    </>
  );
};

export default CurrenciesListContainer;
