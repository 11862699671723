import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Card, Col } from "react-bootstrap";
import { deleteOffer, getOfferList, toggleOfferStatus } from "../../_redux/actions/ProductOfferAction";
import { getDiscountTypeList } from "../../../master/OptionData/DiscountType/DiscountTypeAction/DiscountTypeAction";
import SimpleTooltip from "../../../master/components/tooltip/SimpleTooltip";
import PaginationLaravel from "../../../master/pagination/PaginationLaravel";
import LoadingSpinner from "../../../master/spinner/LoadingSpinner";
import { confirmAlert } from "react-confirm-alert";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";
import { formatCurrency, activeCurrency } from "../../../../services/currency";

const ProductOfferList = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const { offerList, isLoading, offerPaginationList } = useSelector(
    (state) => state.ProductOffer
  );

  useEffect(() => {
    dispatch(getOfferList(currentPage));
    dispatch(getDiscountTypeList());
  }, [dispatch, currentPage]);

  const changePage = (data) => {
    setCurrentPage(data.page);
    dispatch(getOfferList(data.page));
  };

  const searchItems = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText.length === 0) {
      dispatch(getOfferList(currentPage));
    } else {
      dispatch(getOfferList(currentPage, searchText));
    }
  };

  // delete products list
  const confirmDelete = (id) => {
    dispatch(deleteOffer(id));
  };

  const handleDeleteOffer = (item) => {
    confirmAlert({
      title: "Confirm to delete",
      message: `All of the offer data of ${item.item_name} ${item.selection_type} will be removed.
                Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(item.id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getOfferPrice = (item) => {
    if (item.offer_price_type === "percentage") {
      return parseFloat(
        item.current_price - (item.current_price * item.offer_price) / 100
      );
    } else {
      return parseFloat(item.current_price - item.offer_price);
    }
  };

  /**
   * Change Offer Status
   *
   * @param {object} item
   */
  const handleToggleStatus = (item) => {
    confirmAlert({
      title: "Confirm to change status",
      message: `Are you sure to change status of ${item.offer_name} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(toggleOfferStatus(item.id, !item.is_visible)),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <div className="row">
            <h1 className="tableheading">Offer List</h1>

            <Form.Group as={Col} controlId="formGridState">
              <input
                type="search"
                className="form-control product-search-input formHeight"
                placeholder="Search by offer type"
                value={searchText}
                onChange={searchItems}
              />
            </Form.Group>
            <i className="fas fa-filter tableFilter mt-1 mr-2"></i>
            <i className="far fa-filter"></i>
            <Link
              to="/offer-product/create"
              className="btn btn-primary text-center text-white btn-sm custome-addnew-btn certificate-add-btn"
            >
              Add / Update Offer
            </Link>
          </div>

          {isLoading && <LoadingSpinner text="Loading Offer List....." />}
          {!isLoading && offerList.length > 0 && (
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <th className="td-sl">#</th>
                    <th scope="col">Offer Name</th>
                    <th scope="col">Criteria Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Price(For Product)</th>
                    <th scope="col" className="text-center">
                      Discount
                    </th>
                    <th scope="col">Validity</th>
                    <th scope="col">Offer Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {offerList &&
                    offerList.length > 0 &&
                    offerList.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <span className="font-weight-bold">
                            {offerPaginationList.from + index}
                          </span>
                        </td>
                        <td>{item.offer_name}</td>
                        <td>{item.item_name === null ? '--' : item.item_name}</td>
                        <td>{item.selection_type}</td>
                        <td>
                          <span className="text-danger">
                            <del>
                              {item.current_price !== 0
                                ? formatCurrency(item.current_price)
                                : "--"}
                            </del>
                          </span>
                          {item.current_price !== 0 && (
                            <span>
                              <br />
                              <span className="text-primary">
                                {formatCurrency(getOfferPrice(item))}
                              </span>
                            </span>
                          )}
                        </td>
                        <td className="text-center">
                          {formatCurrency(item.offer_price, true, "")}
                          {item.offer_price_type === "percentage"
                            ? "%"
                            : activeCurrency("sign")}
                          <br />
                          <span className="badge badge-secondary">
                            {item.offer_price_type} Discount
                          </span>
                        </td>
                        <td>
                          {item.is_unlimited && (
                            <span className="badge badge-success">
                              Unlimited
                            </span>
                          )}
                          {!item.is_unlimited && (
                            <span>
                              <span className="badge badge-secondary">
                                {item.start_date}
                              </span>
                              {" - "}
                              <span className="badge badge-secondary">
                                {item.end_date}
                              </span>
                            </span>
                          )}
                        </td>
                        <td>
                          <span className="badge badge-secondary">
                            {item.offer_type !== null && item.offer_type !== ""
                              ? item.offer_type
                              : "--"}
                          </span>
                        </td>
                        <td>
                          {item.is_visible && (
                            <span className="badge badge-success">Active</span>
                          )}
                          {!item.is_visible && (
                            <span className="badge badge-warning">Inactive</span>
                          )}
                        </td>

                        <td className="d-flex">
                          &nbsp;&nbsp;
                          <PermissionWiseDisplay
                            permission_name="Offer.Edit"
                            display={false}
                          >
                            <SimpleTooltip title={`${item.is_visible ? 'De-activate' : 'Activate'} Offer`}>
                              <a
                                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                  onClick={() => handleToggleStatus(item)}
                                >
                                  { item.is_visible && <i className="fa fa-ban text-warning"></i> }
                                  { !item.is_visible && <i className="fa fa-check text-success"></i> }
                                </a>
                            </SimpleTooltip>
                          </PermissionWiseDisplay>
                          &nbsp;&nbsp;
                          <PermissionWiseDisplay
                            permission_name="Offer.Edit"
                            display={false}
                          >
                            <SimpleTooltip
                              title={`Edit ${item.offer_name}'s Offer`}
                            >
                              <Link to={`/offer-product/edit/${item.id}`} className="btn btn-icon btn-light btn-hover-success btn-sm">
                                <i className="fa fa-edit text-success"></i>
                              </Link>
                            </SimpleTooltip>
                          </PermissionWiseDisplay>
                          &nbsp;&nbsp;
                          <PermissionWiseDisplay
                            permission_name="Offer.Delete"
                            display={false}
                          >
                            <SimpleTooltip
                              title={`Delete ${item.offer_name}'s Offer`}
                            >
                              <a
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                onClick={() => handleDeleteOffer(item)}
                              >
                                <i className="fa fa-trash text-danger"></i>
                              </a>
                            </SimpleTooltip>
                          </PermissionWiseDisplay>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}

          {!isLoading && offerList.length === 0 && (
            <div className="alert alert-warning mt-5">
              Sorry ! No Offer Found yet.
            </div>
          )}

          <PaginationLaravel
            changePage={changePage}
            data={offerPaginationList}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default ProductOfferList;
