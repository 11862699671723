import React from 'react';
import PermissionWiseDisplay from '../../../../app/modules/role/PermissionWiseDisplay';
import VoucherList from '../../components/Voucher/VoucherList';

const VoucherListContainer = () => {
  return (
    <PermissionWiseDisplay permission_name="Voucher.View" display={true}>
      <VoucherList />
    </PermissionWiseDisplay>
  );
};

export default VoucherListContainer;
