import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPageList, handleDeletePage } from "../_redux/actions/PageAction";
import SimpleTooltip from "../../master/components/tooltip/SimpleTooltip";
import LoadingSpinner from "../../master/spinner/LoadingSpinner";
import { InputBase, Paper, IconButton } from "@material-ui/core";
import PaginationLaravel from "../../master/pagination/PaginationLaravel";
import SimpleModal from "../../master/components/Modal/SimpleModal";
import PageDetails from "./PageDetails";
import { ConfirmModel } from '../../master/confirmModel/ConfirmModel'
import { getImageUrl } from "../../../services/FormatHelper";
import PermissionWiseDisplay from "../../../app/modules/role/PermissionWiseDisplay";

const PageList = withRouter(() => {

	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const [searchText, setSearchText] = useState("");
	const [showPageModel, setShowPageModel] = useState(false);
	const [PageID, setPageID] = useState(null);
	const [viewPageTitle, setViewPageTitle] = useState(null);

	const { pageList, pagePaginationList, isLoading } = useSelector((state) => state.PageReducer);

	useEffect(() => {
		dispatch(getPageList(currentPage));
	}, [dispatch, currentPage]);

	//change page for view more
	const changePage = (data) => {
		setCurrentPage(data.page);
		dispatch(getPageList(data.page));
	};

	//filter by search
	const searchProduct = (e) => {
		const searchText = e.target.value;
		setSearchText(searchText);
		if (searchText.length === 0) {
			dispatch(getPageList(currentPage));
		} else {
			dispatch(getPageList(currentPage, searchText));
		}
	};

	// delete single page from list
	const confirmDelete = (id) => {
		dispatch(handleDeletePage(id));
	}
	const deletePage = id => {
		ConfirmModel(id, confirmDelete)
	}

	//open model for page details view
	const pageDetailsDisplay = (item) => {
		setShowPageModel(true);
		setViewPageTitle(item.title);
		setPageID(item.id);
	}

	return (
		<>
			<div className="container">
				<div className="card card-custom gutter-b">
					<div className="card-header">
						<div className="card-title">
							<h3 className="card-label">Pages</h3>
						</div>
						<div className="col-xl-4 col-lg-4 col-5 mt-3 float-left">
							<Paper className="searchInput">
								<InputBase
									className="search-input"
									placeholder="Search page, eg: About Us"
									value={searchText}
									onChange={searchProduct}
								/>
								<IconButton aria-label="Search" className="searchPlaceholder purchaseSearch">
									<i className="flaticon-search "></i>
								</IconButton>
							</Paper>
						</div>
						<div className="col-xl-4 col-lg-4 col-5 mt-3 float-left"></div>
						<div className="card-toolbar">
							<PermissionWiseDisplay permission_name="Page.Create" display={false}>
								<Link to="/page/add">
									<button type="button" className="btn btn-primary">
										New Page
									</button>
								</Link>
							</PermissionWiseDisplay>
						</div>
					</div>
					<div className="card-body">
						{isLoading && <LoadingSpinner text="Loading Page List..." />}
						<div className="row"></div>
						<div className="react-bootstrap-table table-responsive">
							<table className="table table table-head-custom table-vertical-center">
								<thead>
									<tr>
										<td>SL</td>
										<td>Page Title</td>
										<td>Slug</td>
										<td>Meta Description</td>
										<td>Meta Keywords</td>
										<td>Image</td>
										<td>Action</td>
									</tr>
								</thead>
								<tbody>
									{pageList &&
										pageList.map((item, index) => (
											<tr key={index}>
												<td>{pagePaginationList.from + index}</td>
												<td>{item.title}</td>
												<td>{item.slug}</td>
												<td>{item.meta_description}</td>
												<td>{item.meta_keywords}</td>
												<td>
													{
														item.image &&
														<img src={getImageUrl(item.image, 'page')} width={50} />
                          							}
												</td>
												<td className="d-flex">
													<SimpleTooltip title="View Page Details">
														<span
															className="btn btn-icon btn-light btn-hover-info btn-sm pointer"
															onClick={() => pageDetailsDisplay(item)}
														>
															<i className="fa fa-eye"></i>
														</span>
													</SimpleTooltip>
													&nbsp;&nbsp;
													<PermissionWiseDisplay permission_name="Page.Edit" display={false}>
														<SimpleTooltip title={`Edit ${item.title} Page`}>
															<Link
																to={`/page/edit/${item.id}`}
																className="btn btn-icon btn-light btn-hover-info btn-sm"
															>
																<i className="fa fa-edit"></i>
															</Link>
														</SimpleTooltip>
													</PermissionWiseDisplay>
													&nbsp;&nbsp;
													<PermissionWiseDisplay permission_name="Page.Delete" display={false}>
														<SimpleTooltip title={`Delete ${item.title} Page`}>
															<button
																className="btn btn-icon btn-light btn-hover-danger btn-sm"
																onClick={() => deletePage(item.id)}
															>
																<i className="fa fa-trash text-danger"></i>
															</button>
														</SimpleTooltip>
													</PermissionWiseDisplay>
												</td>
											</tr>
										))}
								</tbody>

								<tfoot></tfoot>
							</table>
							{!isLoading && pageList.length === 0 && (
								<div className="alert alert-warning mt-5">
									Sorry ! Page List Not Found.
								</div>
							)}
							<PaginationLaravel
								changePage={changePage}
								data={pagePaginationList}
							/>
						</div>
					</div>
				</div>
				<SimpleModal
					size="lg"
					show={showPageModel}
					handleClose={() => setShowPageModel(false)}
					handleShow={() => setShowPageModel(true)}
					id={viewPageTitle}
					modalTitle={"Page Details"}
				>
					<PageDetails handleClose={() => setShowPageModel(false)} id={PageID} />
				</SimpleModal>
			</div>
		</>
	);
});

export default PageList;
