/**
 * Get Price Types.
 *
 * @returns array
 */
export const getPriceTypes = () => {
  return [
    { label: "Fixed", value: "fixed" },
    { label: "Percent (%)", value: "percentage" },
  ];
};

/**
 * Get Tax Types for Items.
 *
 * @returns array
 */
export const getTaxTypes = () => {
  return [
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];
};
