import React from 'react';
import PageAdd from '../components/PageAdd';

const PagesAddContainer = () => {
  return (
    <PageAdd />
  );
};

export default PagesAddContainer;
