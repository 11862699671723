import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';

// import "./css/style.css";
import FileBase64 from 'react-file-base64';
import { RHFInput } from 'react-hook-form-input';
// import { GetCurrenciesList } from '../../_redux/actions/currencies/CurrenciesAction';
import { AddBusiness, GetBusinessList } from '../../_redux/actions/BusinessAction';
import { AddTax } from '../../_redux/actions/tax/TaxAction';

const TaxAdd = withRouter(({ history, props }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  toast.configure();

  const businessList = useSelector((state) => state.business.businessList);
  const addStatus = useSelector((state) => state.tax.addStatus);
  const addMessage = useSelector((state) => state.tax.addMessage);
  const isLoading = useSelector((state) => state.tax.isLoading);

  const [state, setState] = React.useState({
    name: '',
    businessData:"",
    start_date:'',
    calculation_type:'',
    amount:""
   
  });



  const handleChange = ({ currentTarget: input }) => {
    const cloneObj = { ...state };
    cloneObj[input.name] = input.value;
    setState(cloneObj);
  };

  const selectHandle = (item, name) => {
    const cloneObj = { ...state };
    cloneObj[name] = item;
    setState(cloneObj);
  };

  let business = [];
  if (businessList) {
    businessList.forEach((item) => {
      let items = {
        value: item.id,
        label: item.name,
      };
      business.push(items);
    });
  }

  useEffect(() => {
     
     dispatch(GetBusinessList());
    // dispatch(GetCountryDataAction());

    if (addMessage === null || typeof addMessage === 'undefined') {
      toast.error('Somthing Went Wrong', {
        autoClose: 2000,
        className: 'dangerColor',
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (addStatus && addMessage.length > 0) {
        toast.success(addMessage, {
          autoClose: 2000,
          className: 'primaryColor',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        history.push('/tax/list');
      }

      if (!addStatus && addMessage.length > 0) {
        toast.error(addMessage, {
          autoClose: 2000,
          className: 'dangerColor',
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }, [addStatus, addMessage]);

  const onSubmit = async (e) => {
    dispatch(AddTax(state));
  };

  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 class="card-label">Tax Add</h3>
            </div>
          </div>
          <div className="card-body">
            <form
              className="form form-label-right"
              onSubmit={handleSubmit(onSubmit)}
              method="post"
            >
              <div className="form-group row">
              <div className="col-lg-4">
                  <label className="form-label mt-2">Tax Name</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Brands name"
                    name="name"
                    className="fromStyle"
                    onChange={handleChange}
                    ref={register({
                      required: true,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.name &&
                      errors.name.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
              <div className="col-lg-4">
                  <label className="form-label mt-2">Business</label>
                  <RHFInput
                    as={<Select options={business} />}
                    rules={{ required: false }}
                    name="businessData"
                    register={register}
                    value=""
                    onChange={(e) => selectHandle(e, 'businessData')}
                    setValue={setValue}
                  />
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Start Date</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    name="startDate"
                    className="fromStyle"
                    onChange={handleChange}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.startDate &&
                      errors.startDate.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Calculation Type</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Calculation Type"
                    name="calculation_type"
                    className="fromStyle"
                    onChange={handleChange}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.calculation_type &&
                      errors.calculation_type.type === 'required' &&
                      "calculation_type Can't be blank"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">amount</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    name="amount"
                    className="fromStyle"
                    onChange={handleChange}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.amount &&
                      errors.amount.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-sm-10">
                  <a
                    onClick={() => {
                      history.push('/tax/list');
                    }}
                  >
                    <button type="button" class="btn btn-secondary btn-lg mr-2">
                      Back
                    </button>
                  </a>
                  {isLoading &&
                    <button type="submit" class="btn btn-primary btn-lg" disabled={true} >
                      <span>Submit</span>
                      <span className="ml-3 spinner spinner-white"></span>
                    </button>
                  }
                  {!isLoading &&
                    <button type="submit" class="btn btn-primary btn-lg">
                      <span>Submit</span>
                    </button>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
});

export default TaxAdd;
