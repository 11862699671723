import * as Types from '../Types/Types';

const initialState = {
    dicountTypeList: [],
};

const DiscountTypeReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_DISCOUNT_TYPE:
            return {
                ...state,
                dicountTypeList: getDiscountType(action.payload.data)
            };
        default:
            break;
    }
    return newState;
};

const getDiscountType = (data) => {
    let optionList = [];
    if (typeof data !== 'undefined' && data.length > 0) {
        data.forEach((item) => {
            let items = {
                value: item.id,
                label: item.name,
            };
            optionList.push(items);
        });
    }
    return optionList;
}
export default DiscountTypeReducer;
