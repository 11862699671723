import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileManagerAction } from "../_redux/actions/FileManagerAction";
import { saveAs } from 'file-saver';

const FileUpload = () => {
  const dispatch = useDispatch();
  const { directory } = useParams();
  const [files, setFiles] = useState("");

  const { isUploading, uploadedFiles } = useSelector(
    (state) => state.documents
  );

  const handleMultipleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if(!files) return;
    dispatch(uploadFileManagerAction(files, directory));
  };

  const saveImgUrlHandler = () => {
    const urls = uploadedFiles.map(item => `'${item.file_name}'`)
    var CSV = ['"image_url"', ...urls].join('\n');

    const blob = new Blob([CSV], {type: "text/csv;charset=utf-8"});

    const date = new Date();
    const getYear = date.getFullYear();
    const getMonth = date.getMonth();
    const getDay = date.getDate();
    const getHours = date.getHours();
    const getMinutes = date.getMinutes();

    const formateDate = `${getDay}-${getMonth}-${getYear}-${getHours}:${getMinutes}`;

    saveAs(blob, `img_urls-${formateDate}.csv`);
  }

  return (
    <div className="p-4">
      <form
        className="form form-label-right"
        onSubmit={handleUpload}
        method="post"
        autoComplete="off"
        encType="multipart/form-data"
      >
        <div className="">
          <div className="mb-5" >
            <label htmlFor="file" className="d-block">Upload Files (Multiple)</label>
            <div className="d-flex align-items-center justify-content-between">
              <input
                type="file"
                name=""
                id="file"
                multiple={true}
                onChange={handleMultipleFileChange}
              />
              <div>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isUploading}
                >
                  {!isUploading ? "Upload" : "Uploading..."}
                </button>
              </div>
            </div>
          </div>
        </div>

      </form>

      <div>
        <div className="clearfix"></div>
        {uploadedFiles.length > 0 && (
          <div>
            <div className="d-flex justify-content-between mb-5">
              <div>
                <h3>Uploaded Files: <span className="badge badge-info">{uploadedFiles.length}</span></h3>
              </div>
              <div>
                <button onClick={saveImgUrlHandler} className="btn btn-primary">Download Img Urls</button>
              </div>
            </div>
            <ul className="list-group">
              {uploadedFiles.map((file, index) => (
                <li className="list-group-item" key={file.id}>
                  {index+1}. {file.file_name} (<a href={file.link}>{file.link}</a>)
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
