import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { GetBrandsList, handleDeleteBrand } from "../_redux/actions/BrandsAction";
import SimpleTooltip from "../../master/components/tooltip/SimpleTooltip";
import LoadingSpinner from "../../master/spinner/LoadingSpinner";
import { InputBase, Paper, IconButton } from "@material-ui/core";
import PaginationLaravel from "../../master/pagination/PaginationLaravel";
import SimpleModal from "../../master/components/Modal/SimpleModal";
import BrandDetails from "./BrandDetails";
import PermissionWiseDisplay from "../../../app/modules/role/PermissionWiseDisplay";
import { copyToClipboard } from "../../../services/KeyboardHelper";

const BrandsList = withRouter(({ history }) => {
  const dispatch = useDispatch();
  const { brandsList, BrandListPaginateData, isLoading } = useSelector((state) => state.brands);
  const [currentPage, setCurrentPage] = useState(BrandListPaginateData.current_page);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(GetBrandsList(currentPage));
  }, [dispatch, currentPage]);

  const changePage = (data) => {
    setCurrentPage(data.page);
    dispatch(GetBrandsList(data.page));
  };

  const searchProduct = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText.length === 0) {
      dispatch(GetBrandsList(currentPage));
    } else {
      dispatch(GetBrandsList(currentPage, searchText));
    }
  };

  // delete brand list
  const confirmDelete = (id) => {
    dispatch(handleDeleteBrand(id));
  }

  const deleteBrand = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No"
        }
      ]
    });
  };

  const [showBrandModal, setShowBrandModal] = useState(false);
  const [BrandID, setBrandID] = useState(null);

  const brandsDataDetalsDisplay = (item) => {
    setShowBrandModal(true);
    setBrandID(item.id);
  }

  return (
    <>
      <div className="container-fluid">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Brands</h3>
            </div>
            <div className="col-xl-4 col-lg-4 col-5 mt-3 float-left">
              <Paper className="searchInput">
                <InputBase
                  className="search-input"
                  placeholder="Search "
                  value={searchText}
                  onChange={searchProduct}
                />
                <IconButton aria-label="Search" className="searchPlaceholder purchaseSearch">
                  <i className="flaticon-search "></i>
                </IconButton>
              </Paper>
            </div>
            <div className="col-xl-4 col-lg-4 col-5 mt-3 float-left"></div>
            <div className="card-toolbar">
              <PermissionWiseDisplay permission_name="Brand.Create" display={false}>
                <a href onClick={() => { history.push("/brands/add"); }} >
                  <button type="button" className="btn btn-primary">
                    <i className="fa fa-plus-circle"></i> New Brand
                  </button>
                </a>
              </PermissionWiseDisplay>
            </div>
          </div>
          <div className="card-body">
            {isLoading && <LoadingSpinner text="Loading Brands" />}
            <div className="row"></div>
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <td>SL</td>
                    <td>Brand name</td>
                    <td>Short code</td>
                    <td>Brand Logo</td>
                    <td>Banner</td>
                    <td>Status</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <PermissionWiseDisplay permission_name="brand.view">
                  <tbody>
                    {brandsList &&
                      brandsList.map((item, index) => (
                        <tr key={index}>
                          <td>{BrandListPaginateData.from + index}</td>
                          <td>{item.name}</td>
                          <td className="pointer" onClick={() => copyToClipboard(item.slug)}>{item.slug}</td>
                          <td>
                            <img
                              src={item.image_url}
                              style={{ width: 50 }}
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              alt=""
                              src={item.banner_url}
                              style={{ width: 50 }}
                            />
                          </td>
                          <td>
                            {
                              item.status ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Inactive</span>
                            }
                          </td>

                          <td>
                            <SimpleTooltip title="View Brand">
                              <Link
                                to={`/brand/view/${item.id}`}
                                className="btn btn-icon btn-light btn-hover-info btn-sm"
                                // onClick={() => brandsDataDetalsDisplay(item)}
                              >
                                <i className="fa fa-eye"></i>
                              </Link>
                            </SimpleTooltip>
                            &nbsp;&nbsp;
                            <PermissionWiseDisplay permission_name="Brand.Edit" display={false} >
                              <SimpleTooltip title={`Edit Brand ${item.name}`}>
                                <Link
                                  to={`/brands/edit/${item.id}`}
                                  className="btn btn-icon btn-light btn-hover-info btn-sm"
                                >
                                  <i className="fa fa-edit"></i>
                                </Link>
                              </SimpleTooltip>
                            </PermissionWiseDisplay>
                            &nbsp;&nbsp;
                            <PermissionWiseDisplay permission_name="Brand.Delete" display={false}>
                              <SimpleTooltip title={`Delete ${item.name}`}>
                                <a
                                  href
                                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                  onClick={(id) => deleteBrand(item.id)}
                                >
                                  <i className="fa fa-trash text-danger"></i>
                                </a>
                              </SimpleTooltip>
                            </PermissionWiseDisplay>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </PermissionWiseDisplay>

                <tfoot></tfoot>
              </table>
              {!isLoading && brandsList.length === 0 && (
                <div className="alert alert-warning mt-5">
                  Sorry ! Brand List Not Found.
                </div>
              )}
              <PaginationLaravel
                changePage={changePage}
                data={BrandListPaginateData}
              />
            </div>
          </div>
        </div>
        <SimpleModal
          size="xl"
          show={showBrandModal}
          handleClose={() => setShowBrandModal(false)}
          handleShow={() => setShowBrandModal(true)}
          id={BrandID}
          modalTitle={"Brand Details"}
        >
          <BrandDetails handleClose={() => setShowBrandModal(false)} id={BrandID} />
        </SimpleModal>
      </div>
    </>
  );
});

export default BrandsList;
