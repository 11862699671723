import React from "react";
import ItemAdd from "../components/ItemAdd";

const ItemAddContainer = () => {
    return (
        <>
            <ItemAdd />
        </>
    );
};

export default ItemAddContainer;
