import React from 'react';
import BrandsList from '../components/BrandsList';

const BrandsListContainer = () => {
  return (
    <>
      <BrandsList />
    </>
  );
};

export default BrandsListContainer;
