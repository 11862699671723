/*******************************************************************************************************
        Sub Module: Items
********************************************************************************************************/
export const GET_ITEM_LIST = 'GET_ITEM_LIST';
export const GET_ITEM_DETAILS = 'GET_ITEM_DETAILS';
export const ADD_ITEM = 'ADD_ITEM';
export const EMPTY_ITEM_INPUT = 'EMPTY_ITEM_INPUT';
export const EDIT_ITEM = 'EDIT_ITEM';
export const DELETE_PRODUCT_ITEMS = 'DELETE_PRODUCT_ITEMS';
export const DELETE_SURVEY_MULTIPLE_ATTACHMENT = 'DELETE_SURVEY_MULTIPLE_ATTACHMENT';
export const DELETE_SURVEY_MULTIPLE_ATTACHMENT_EDIT = 'DELETE_SURVEY_MULTIPLE_ATTACHMENT_EDIT';
export const EMPTY_DISPATCH = 'EMPTY_DISPATCH';

export const CHANGE_ITEM_INPUT_UPDATE = 'CHANGE_ITEM_INPUT_UPDATE';
export const ITEM_ADD_LOADING = 'ITEM_ADD_LOADING';

export const EMPTY_ITEM_ADD_MESSAGE = 'EMPTY_ITEM_ADD_MESSAGE';
export const EMPTY_ITEM_EDIT_MESSAGE = 'EMPTY_ITEM_EDIT_MESSAGE';
export const EMPTY_ITEM_DELETE_MESSAGE = 'EMPTY_ITEM_DELETE_MESSAGE';

export const CHANGE_ITEM_INPUT = 'CHANGE_ITEM_INPUT';
export const GET_BARCODE_TYPE = 'GET_BARCODE_TYPE';

export const ADD_MULTIPLE_ATTRIBUTE_VALUE = 'ADD_MULTIPLE_ATTRIBUTE_VALUE';
export const DELETE_MULTIPLE_ATTRIBUTES_VALUES = 'DELETE_MULTIPLE_ATTRIBUTES_VALUES';
export const DELETE_MULTIPLE_ATTRIBUTES_VALUES_EDIT = 'DELETE_MULTIPLE_ATTRIBUTES_VALUES_EDIT';

export const GET_ITEM_STATUS_LIST = 'GET_ITEM_STATUS_LIST';
export const GET_ITEM_TYPE_LIST = 'GET_ITEM_TYPE_LIST';
export const CHANGE_ITEM_FILTER = 'CHANGE_ITEM_FILTER';

export const BULK_UPLOAD_PROGRESS = 'BULK_UPLOAD_PROGRESS';
