import * as Types from '../types/Types';

const initialState = {
  documents: [],
  documentsPaginatedData: null,
  isLoading: true,
  isUploading: false,
  uploadedFiles: []
};

const FileManagerReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.GET_FILE_MANAGER_LIST:
      return {
        ...state,
        documents: typeof action.payload.documents !== 'undefined' ? action.payload.documents : [],
        documentsPaginatedData: action.payload.documentsPaginatedData,
        isLoading: action.payload.isLoading
      };

    case Types.FILE_MANAGER_UPLOAD_FILES:
      return {
        ...state,
        isUploading: action.payload.isUploading,
        uploadedFiles: action.payload.documents
      };

    default:
      break;
  }
  return newState;
};

export default FileManagerReducer;
