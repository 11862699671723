import React from 'react';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import UnitsList from '../components/UnitsList';

const UnitsListContainer = () => {
  return (
    <>
      <PermissionWiseDisplay permission_name="Unit.List" display={true}>
        <UnitsList />
      </PermissionWiseDisplay>
    </>
  );
};

export default UnitsListContainer;
