import React from 'react';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import SliderCreate from '../components/SliderCreate';

const SliderAddContainer = () => {
  return (
    <>
      <PermissionWiseDisplay permission_name="Slider.Create" display={true}>
        <SliderCreate />
      </PermissionWiseDisplay>
    </>
  );
};

export default SliderAddContainer;
