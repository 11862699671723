import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormControl, InputGroup, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { RHFInput } from 'react-hook-form-input';
import { deletePreviewImage, handleBusinessInputChange, AddBusiness, getBusinessDetails } from '../_redux/actions/BusinessAction';
import DatePicker from "react-datepicker";
import moment from "moment"
import { getAutoGeneratedPassword } from '../../../services/FormatHelper';
import TextHelp from '../../master/components/labels/TextHelp';
import LabelOptional from '../../master/components/labels/LabelOptional';
import LabelRequired from '../../master/components/labels/LabelRequired';
import { getCity, getDivision, getArea } from '../../../app/modules/Auth/_redux/actions/registerAction';
import { getPaymentMethodAction } from '../../payments/redux/actions/PaymentAction';
import { getSelect2OptionForStatus, getSelectedOption } from '../../../services/FormatData';

const BusinessAdd = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { id } = props;
	const { register, handleSubmit, errors, setValue } = useForm();
	const { businessInput, isLoading, socialOptions } = useSelector((state) => state.business);
	const { divisionList, cityList, areaList } = useSelector((state) => state.RegisterReducer)
	const { paymentMethods, mobilePaymentMethods } = useSelector((state) => state.payment)

	const handleChangeTextInput = (name, value, e = null) => {
		dispatch(handleBusinessInputChange(name, value, e));
	}

	const onSubmit = async (e) => {
		dispatch(AddBusiness(businessInput));
		history.goBack();
	};

	useEffect(() => {
		dispatch(getDivision());
		dispatch(getPaymentMethodAction());
	}, []);

	const changeState = (state) => {
		if (state.value) {
			dispatch(getCity(state.value));
			handleChangeTextInput('state', state);
			handleChangeTextInput('state_id', state.value);
			setValue('state', state);

			handleChangeTextInput('city', '');
			setValue('city', '');
			handleChangeTextInput('area', '');
			setValue('area', '');
		}
	}

	const changeCity = (city) => {
		if (city.value) {
			dispatch(getArea(city.value));
			handleChangeTextInput('city', city);
			handleChangeTextInput('city_id', city.value);
			setValue('city', city);
			handleChangeTextInput('area', '');
			setValue('area', '');
		}
	}

	const changeArea = (area) => {
		if (area.value) {
			handleChangeTextInput('area', area);
			handleChangeTextInput('area_id', area.value);
			setValue('area', area);
		}
	}

	const changeBusinessType = (businessType) => {
		if (businessType.value) {
			handleChangeTextInput('businessType', businessType);
			handleChangeTextInput('business_type', businessType.value);
			setValue('businessType', businessType);
			setValue('business_type', businessType.value);
		}
	}

	const mobileBankingNoTypes = [
		{ label: 'Personal', value: 'Personal' },
		{ label: 'Agent', value: 'Agent' }
	];

	const shopTypes = [
		{ label: "Polli Dokan", value: "polli" },
		{ label: "Business", value: "business" },
	];

	useEffect(() => {
		if(typeof id !== 'undefined') {
			dispatch(getBusinessDetails(id, true));
		}
	}, []);

	useEffect(() => {
		if(businessInput.isEdit) {
			// Dispatch to get default address values
			changeState(businessInput.state);
			changeCity(businessInput.city);
			changeArea(businessInput.area);
			changeBusinessType(businessInput.businessType);
		}
	}, [businessInput.isEdit]);

	return (
		<>
			<div className="container-fluid">
				<div className="card card-custom gutter-b">
					<div className="card-header">
						<div className="card-title">
							<h3 className="card-label">
								{typeof id === 'undefined' ? 'Create' : 'Edit'} Shop
							</h3>
						</div>
					</div>
					<form
						className="form form-label-right"
						onSubmit={handleSubmit(onSubmit)}
						method="post"
						autoComplete="off"
					>
						<div className="card-body">
							<Tabs defaultActiveKey="account" id="uncontrolled-tab-example" className="mb-3">
								<Tab eventKey="account" title="Account Information *">
									<div className="form-group row">
										{/**for business logo & banner */}
										<div className="col-lg-3 border-right p-1">
											<div className="row">
												<div className="col-12">
													<label className="form-label mt-5">Shop Logo</label>
													<div className="text-center mb-2" title="Remove">
														{/* <div className="preview-delete-icon"><i className="fa fa-times text-danger" onClick={() => dispatch(deletePreviewImage('logo'))}></i></div> */}
														{
															businessInput.logoPreviewUrl !== null ?
																<img src={businessInput.logoPreviewUrl} className="img businessPreview img-thumbnail" />
																:
																<img src="/media/default/icons/shop-logo.png" className="img businessPreview img-thumbnail" />
														}
													</div>

													<Form.Control
														type="file"
														placeholder="Choose Logo"
														name="logo"
														onChange={(e) => handleChangeTextInput('logo', e.target.files[0], e)}
														className="fromStyle"
														ref={register({
															required: false,
															maxLength: 100,
														})}
													/>
												</div>
												<div className="col-12">
													<label className="form-label mt-5">Shop Banner</label>
													{
														businessInput.bannerPreviewUrl !== null ?
															<div className="" title="Remove">
																<div className="preview-delete-icon"><i className="fa fa-times text-danger" onClick={() => dispatch(deletePreviewImage('banner'))}></i></div>
																<img src={businessInput.bannerPreviewUrl} className="img bannerPreview img-thumbnail" />
															</div> :
															<img src="/media/default/icons/banner-thumbnail.png" className="img bannerPreview img-thumbnail" />
													}
													<Form.Control
														type="file"
														placeholder="Choose Banner"
														name="banner"
														onChange={(e) => handleChangeTextInput('banner', e.target.files[0], e)}
														className="fromStyle mt-3"
														ref={register({
															required: false,
															maxLength: 100,
														})}
													/>

												</div>
											</div>
										</div>

										{/**for business others information */}
										<div className="col-lg-9">
											<div className="border p-2">
												<h3>Shop Basic Information</h3>
												<div className="form-group row">
													<div className="col-lg-4">
														<label htmlFor="name" className="form-label">Shop name <LabelRequired /></label>
														<Form.Control
															id="name"
															placeholder="Enter Shop Name"
															name="name"
															className="fromStyle"
															value={businessInput.name}
															onChange={(e) => handleChangeTextInput('name', e.target.value)}
															ref={register({
																required: true,
																maxLength: 100,
															})}
														/>

														<div className="inputError margin-minus-8">
															{errors.name &&
																errors.name.type === 'required' &&
																"Shop name Cant be Blank"}
														</div>
													</div>
													<div className="col-lg-4">
														<label htmlFor="business_type" className="form-label mt-2">Shop Type <LabelRequired /></label>
														<RHFInput
															id="business_type"
															as={<Select options={shopTypes} className="form-control" />}
															className="register_rhf_input"
															placeholder="Select Shop Type"
															rules={{ required: true }}
															name="businessType"
															register={register}
															defaultInputValue={businessInput.businessType}
															value={businessInput.businessType}
															onChange={(option) => changeBusinessType(option)}
															setValue={setValue}
														/>
														<div className="inputError margin-minus-8">
															{errors.businessType &&
																errors.businessType.type === 'required' &&
																"Shop Type Can't be Blank"}
														</div>
													</div>
													<div className="col-lg-4">
														<label htmlFor="bin" className="form-label">BIN (Business Identification No.)<LabelRequired /></label>
														<Form.Control
															id="bin"
															placeholder="Enter BIN"
															name="bin"
															className="fromStyle"
															value={businessInput.bin}
															onChange={(e) => handleChangeTextInput('bin', e.target.value)}
															ref={register({
																required: true,
																maxLength: 100,
															})}
														/>
														<div className="inputError margin-minus-8">
															{errors.bin &&
																errors.bin.type === 'required' &&
																"Business Identification No. Cant be Blank"}
														</div>
													</div>
													<div className="col-lg-4">
														<label htmlFor="prefix" className="form-label">Shop Prefix<LabelOptional /></label>
														<Form.Control
															id="prefix"
															placeholder="Enter Shop Prefix; eg: DESHI"
															name="prefix"
															className="fromStyle"
															value={businessInput.prefix}
															onChange={(e) => handleChangeTextInput('prefix', e.target.value)}
															ref={register({
																required: false,
																maxLength: 100,
															})}
														/>
														<TextHelp text={`Shop Prefix. This will be used in your Product SKU. eg: DESHI`} />
													</div>
													<div className="col-lg-4">
														<label htmlFor="slug" className="form-label">Shop URL <LabelOptional /></label>
														<Form.Control
															id="slug"
															placeholder="Enter Store URL"
															name="slug"
															className="fromStyle"
															value={businessInput.slug}
															onChange={(e) => handleChangeTextInput('slug', e.target.value)}
															ref={register({
																required: false,
																maxLength: 100,
																minLength: 3
															})}
														/>
														<TextHelp text={`Store Link: ${process.env.REACT_APP_FRONTEND_WEBSITE}store/[url]`} />
													</div>
													<div className="col-lg-4">
														<label htmlFor="start_date" className="form-label mt-2">Shop Start Date <LabelOptional /></label>
														<DatePicker
															id="start_date"
															name="start_date"
															type="date"
															className="form-control fromStyle formHeight"
															placeholderText="Enter Shop Start Date"
															// value={businessInput.start_date}
															// dateFormat="MM-dd-yyyy"
															selected={(businessInput.start_date !== '' && businessInput.start_date !== null) ? moment(businessInput.start_date).toDate() : null}
															onChange={(date) => handleChangeTextInput("start_date", date)}
															ref={register({
																required: false,
																maxLength: 100,
															})}
														/>
													</div>
													<div className="col-lg-4">
														<label htmlFor={`status`} className="form-label">Status </label>
														<RHFInput
															id={`status`}
															as={<Select options={getSelect2OptionForStatus()} className="form-control" />}
															className="register_rhf_input"
															placeholder="Select Status"
															name={`status`}
															register={register}
															value={getSelectedOption(getSelect2OptionForStatus(), businessInput.status)}
															onChange={(option) => handleChangeTextInput(`status`, option.value)}
															setValue={setValue}
														/>
													</div>
													<div className="col-lg-4">
														<FormControlLabel
															name="enable_auto_publish"
															className="mt-3"
															control={
																<Switch
																	checked={businessInput.enable_auto_publish}
																	onChange={e => handleChangeTextInput('enable_auto_publish', e.target.checked)}
																	// value={businessInput.enable_auto_publish}
																/>
															}
															label="Enable auto Publish Product"
														/>
													</div>
												</div>
											</div>

											<div className="border p-2 mt-2">
												<h3>Shop User Information</h3>
												<div className="form-group row">

													<div className="col-lg-4">
														<label htmlFor="user_name" className="form-label">User Name <LabelRequired /></label>
														<Form.Control
															id="user_name"
															placeholder="Enter User Name"
															name="user_name"
															className="fromStyle"
															value={businessInput.user_name}
															onChange={(e) => handleChangeTextInput('user_name', e.target.value)}
															ref={register({
																required: true,
																maxLength: 100,
															})}
														/>
														<div className="inputError margin-minus-8">
															{errors.user_name &&
																errors.user_name.type === 'required' &&
																"User Name Can't be Blank"}
														</div>
													</div>

													<div className="col-lg-4">
														<label htmlFor="user_phone_no" className="form-label">User Phone No <LabelRequired /></label>
														<Form.Control
															id="user_phone_no"
															placeholder="Enter User Phone No"
															name="user_phone_no"
															className="fromStyle"
															value={businessInput.user_phone_no}
															onChange={(e) => handleChangeTextInput('user_phone_no', e.target.value)}
															ref={register({
																required: true,
																maxLength: 100,
															})}
														/>
														<div className="inputError margin-minus-8">
															{errors.user_phone_no &&
																errors.user_phone_no.type === 'required' &&
																"User Phone No. Can't be Blank"}
														</div>
													</div>

													<div className="col-lg-4">
														<label htmlFor="user_email" className="form-label">User Email Address <LabelRequired /></label>
														<Form.Control
															id="user_email"
															type="email"
															placeholder="Enter Email Address"
															name="user_email"
															className="fromStyle"
															value={businessInput.user_email}
															onChange={(e) => handleChangeTextInput('user_email', e.target.value)}
															ref={register({
																required: true,
																maxLength: 100,
															})}
														/>
														<div className="inputError margin-minus-8">
															{errors.user_email &&
																errors.user_email.type === 'required' &&
																"User Email Address Can't be Blank"}
														</div>
													</div>

													<div className="col-lg-4">
														<label htmlFor="user_password" className="form-label">User Password <LabelRequired /></label>
														<Form.Control
															id="user_password"
															placeholder="Enter Password"
															name="user_password"
															className="fromStyle"
															value={businessInput.user_password}
															onChange={(e) => handleChangeTextInput('user_password', e.target.value)}
															// ref={register({
															// 	required: true,
															// 	maxLength: 100,
															// })}
														/>
														{/* <div className="inputError margin-minus-8">
															{errors.user_password &&
																errors.user_password.type === 'required' &&
																"User Password Can't be Blank"}
														</div> */}
														<button type="button" className="btn btn-sm btn-primary mt-1" onClick={() => {
															const randomPassword = getAutoGeneratedPassword(15);
															handleChangeTextInput('user_password', randomPassword);
															setValue('user_password', randomPassword)
														}}>Generate New</button>
													</div>

													<div className="col-lg-4">
														<FormControlLabel
															name="send_email"
															className="mt-3"
															control={
																<Switch
																	checked={businessInput.send_email}
																	onChange={e => handleChangeTextInput('send_email', e.target.checked)}
																	value={businessInput.send_email}
																/>
															}
															label="Send an Email to User with Login Credential"
														/>
													</div>

													<div className="col-lg-4">
														<FormControlLabel
															name="send_sms"
															className="mt-3"
															control={
																<Switch
																	checked={businessInput.send_sms}
																	onChange={e => handleChangeTextInput('send_sms', e.target.checked)}
																	// value={businessInput.send_sms}
																/>
															}
															label="Send an SMS to User with Login Credential"
														/>
													</div>

												</div>
											</div>

											<div className="border p-2 mt-2">
												<h3>Account Information</h3>
												<div className="form-group row">
													<div className="col-lg-4">
														<label htmlFor="vat_no" className="form-label mt-2">VAT/TAX Number <LabelOptional /></label>
														<FormControl
															id="vat_no"
															name="vat_no"
															className="fromStyle"
															placeholder="VAT/TAX Number"
															value={businessInput.vat_no}
															onChange={(e) => handleChangeTextInput('vat_no', e.target.value)}
														/>
													</div>

													<div className="col-lg-4">
														<label htmlFor="euid_no" className="form-label mt-2">Company ID/EUID Number <LabelOptional /></label>
														<FormControl
															id="euid_no"
															name="euid_no"
															className="fromStyle"
															placeholder="Company ID/EUID Number"
															value={businessInput.euid_no}
															onChange={(e) => handleChangeTextInput('euid_no', e.target.value)}
														/>
													</div>

													<div className="col-lg-4">
														<label htmlFor="bank_name" className="form-label mt-2">TAX Bank Name <LabelOptional /></label>
														<FormControl
															id="bank_name"
															name="bank_name"
															className="fromStyle"
															placeholder="TAX Bank Name"
															value={businessInput.bank_name}
															onChange={(e) => handleChangeTextInput('bank_name', e.target.value)}
														/>
													</div>

													<div className="col-lg-4">
														<label htmlFor="bank_iban" className="form-label mt-2">Bank IBAN <LabelOptional /></label>
														<FormControl
															id="bank_iban"
															name="bank_iban"
															className="fromStyle"
															placeholder="Bank IBAN"
															value={businessInput.bank_iban}
															onChange={(e) => handleChangeTextInput('bank_iban', e.target.value)}
														/>
														<TextHelp text={`IBAN - International Bank Account Number`} />
													</div>

													<div className="col-lg-4">
														<label htmlFor="default_sales_discount" className="form-label mt-2">Default Sales Discount <LabelOptional /></label>
														<InputGroup className="fromStyle">
															<FormControl
																type="number"
																id="default_sales_discount"
																name="default_sales_discount"
																className="fromStyle"
																placeholder="Default Sales Discount - 0%"
																value={businessInput.default_sales_discount}
																onChange={(e) => handleChangeTextInput('default_sales_discount', e.target.value)}
															/>

															<InputGroup.Prepend>
																<InputGroup.Text>%</InputGroup.Text>
															</InputGroup.Prepend>
														</InputGroup>
													</div>
													<div className="col-lg-4">
														<label htmlFor="default_profit_percent" className="form-label mt-2">Default Profit Percent <LabelOptional /></label>
														<InputGroup className="fromStyle">
															<FormControl
																type="number"
																id="default_profit_percent"
																name="default_profit_percent"
																className="fromStyle"
																placeholder="Default Profit Percentange - 10%"
																value={businessInput.default_profit_percent}
																onChange={(e) => handleChangeTextInput('default_profit_percent', e.target.value)}
															/>
															<InputGroup.Prepend>
																<InputGroup.Text>%</InputGroup.Text>
															</InputGroup.Prepend>
														</InputGroup>
													</div>
												</div>
											</div>

										</div>
									</div>
								</Tab>

								<Tab eventKey="address" title="Address Information *">

									<div className="border p-2 mt-2">
										<h3>Address Information</h3>
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="landmark" className="form-label mt-2">Street Address <LabelRequired /></label>
												<FormControl
													id="landmark"
													name="landmark"
													className="fromStyle"
													placeholder="Enter Street 1"
													value={businessInput.landmark}
													onChange={(e) => handleChangeTextInput('landmark', e.target.value)}
													ref={register({
														required: true,
														maxLength: 100,
													})}
												/>
												<div className="inputError margin-minus-8">
													{errors.landmark &&
														errors.landmark.type === 'required' &&
														"Street address Can't be Blank"}
												</div>
											</div>
											<div className="col-lg-4">
												<label htmlFor="state" className="form-label mt-2">Division <LabelRequired /></label>
												<RHFInput
													id="state"
													as={<Select options={divisionList} className="form-control" />}
													className="register_rhf_input"
													placeholder="Select Division"
													rules={{ required: true }}
													name="state"
													register={register}
													value={businessInput.state}
													onChange={(option) => changeState(option)}
													setValue={setValue}
												/>
												<div className="inputError margin-minus-8">
													{errors.state &&
														errors.state.type === 'required' &&
														"Division Can't be Blank"}
												</div>
											</div>
											<div className="col-lg-4">
												<label htmlFor="city" className="form-label mt-2">District <LabelRequired /></label>
												<RHFInput
													id="city"
													as={<Select options={cityList} className="form-control" />}
													className="register_rhf_input"
													placeholder="Select District"
													rules={{ required: true }}
													name="city"
													register={register}
													value={businessInput.city}
													onChange={(option) => changeCity(option)}
													setValue={setValue}
												/>
												<div className="inputError margin-minus-8">
													{errors.city &&
														errors.city.type === 'required' &&
														"District Can't be Blank"}
												</div>
											</div>
											<div className="col-lg-4">
												<label htmlFor="area" className="form-label mt-2">Upazilla <LabelRequired /></label>
												<RHFInput
													id="area"
													as={<Select options={areaList} className="form-control" />}
													className="register_rhf_input"
													placeholder="Select upazilla"
													rules={{ required: true }}
													name="area"
													register={register}
													value={businessInput.area}
													onChange={(option) => changeArea(option)}
													setValue={setValue}
												/>
												<div className="inputError margin-minus-8">
													{errors.area &&
														errors.area.type === 'required' &&
														"Upazilla Can't be Blank"}
												</div>
											</div>
											<div className="col-lg-4">
												<label htmlFor="zip_code" className="form-label">ZIP Code<LabelOptional /></label>
												<Form.Control
													id="zip_code"
													placeholder="Enter ZIP Code"
													name="zip_code"
													className="fromStyle"
													value={businessInput.zip_code}
													onChange={(e) => handleChangeTextInput('zip_code', e.target.value)}
												/>
											</div>
											<div className="col-lg-4">
												<label htmlFor="website" className="form-label">Website<LabelOptional /></label>
												<Form.Control
													id="website"
													placeholder="Enter Website URL"
													name="website"
													className="fromStyle"
													value={businessInput.website}
													onChange={(e) => handleChangeTextInput('website', e.target.value)}
												/>
											</div>
											<div className="col-lg-4">
												<label htmlFor="mobile" className="form-label">Phone No <LabelOptional /></label>
												<Form.Control
													id="mobile"
													placeholder="Enter Business Phone No"
													name="mobile"
													className="fromStyle"
													value={businessInput.mobile}
													onChange={(e) => handleChangeTextInput('mobile', e.target.value)}
												/>
												<TextHelp text="If Empty, it will use User's Phone Number" />
											</div>
											<div className="col-lg-4">
												<label htmlFor="alternate_number" className="form-label">Alternative Phone No <LabelOptional /></label>
												<Form.Control
													id="alternate_number"
													placeholder="Enter Business Alternative Phone No"
													name="alternate_number"
													className="fromStyle"
													value={businessInput.alternate_number}
													onChange={(e) => handleChangeTextInput('alternate_number', e.target.value)}
												/>
											</div>
											<div className="col-lg-4">
												<label htmlFor="email" className="form-label">Business Email <LabelOptional /></label>
												<Form.Control
													id="email"
													placeholder="Enter Business Email"
													name="email"
													className="fromStyle"
													value={businessInput.email}
													onChange={(e) => handleChangeTextInput('email', e.target.value)}
												/>
												<TextHelp text="If Empty, it will use User's Email address" />
											</div>
										</div>
									</div>
								</Tab>

								<Tab eventKey="payment" title="Payment">
									<div className="border p-2 mt-2">
										<h3>Payment Information</h3>
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="payment_active_method" className="form-label mt-2">Active Payment Method <LabelOptional /></label>
												<RHFInput
													id="payment_active_method"
													as={<Select options={paymentMethods} className="form-control" />}
													className="register_rhf_input"
													placeholder="Select Active payment method"
													name="payment_active_method"
													register={register}
													value={getSelectedOption(paymentMethods, businessInput.payment_active_method)}
													onChange={(option) => handleChangeTextInput('payment_active_method', option.value)}
													setValue={setValue}
												/>
											</div>
										</div>
									</div>

									<div className="border p-2 mt-2">
										<h3>Bank Payment Information</h3>
										<div className="form-group row">
											<div className="col-lg-4">
												<label htmlFor="payment_bank_account_name" className="form-label">Bank Account Name <LabelOptional /></label>
												<Form.Control
													id="payment_bank_account_name"
													placeholder="Enter Bank Account Name"
													name="payment_bank_account_name"
													className="fromStyle"
													value={businessInput.payment_bank_account_name}
													onChange={(e) => handleChangeTextInput('payment_bank_account_name', e.target.value)}
												/>
											</div>
											<div className="col-lg-4">
												<label htmlFor="payment_bank_account_no" className="form-label">Bank Account No <LabelOptional /></label>
												<Form.Control
													id="payment_bank_account_no"
													placeholder="Enter Bank Account No"
													name="payment_bank_account_no"
													className="fromStyle"
													value={businessInput.payment_bank_account_no}
													onChange={(e) => handleChangeTextInput('payment_bank_account_no', e.target.value)}
												/>
											</div>
											<div className="col-lg-4">
												<label htmlFor="payment_bank_name" className="form-label">Bank Name <LabelOptional /></label>
												<Form.Control
													id="payment_bank_name"
													placeholder="Enter Bank Name"
													name="payment_bank_name"
													className="fromStyle"
													value={businessInput.payment_bank_name}
													onChange={(e) => handleChangeTextInput('payment_bank_name', e.target.value)}
												/>
											</div>
											<div className="col-lg-4">
												<label htmlFor="payment_bank_address" className="form-label">Bank Address <LabelOptional /></label>
												<Form.Control
													id="payment_bank_address"
													placeholder="Enter Bank Address"
													name="payment_bank_address"
													className="fromStyle"
													value={businessInput.payment_bank_address}
													onChange={(e) => handleChangeTextInput('payment_bank_address', e.target.value)}
												/>
											</div>
											<div className="col-lg-4">
												<label htmlFor="payment_bank_iban" className="form-label">Bank IBAN <LabelOptional /></label>
												<Form.Control
													id="payment_bank_iban"
													placeholder="Enter Bank IBAN"
													name="payment_bank_iban"
													className="fromStyle"
													value={businessInput.payment_bank_iban}
													onChange={(e) => handleChangeTextInput('payment_bank_iban', e.target.value)}
												/>
											</div>
											<div className="col-lg-4">
												<label htmlFor="payment_bank_routing_no" className="form-label">Bank Routing No <LabelOptional /></label>
												<Form.Control
													id="payment_bank_routing_no"
													placeholder="Enter Bank Routing No"
													name="payment_bank_routing_no"
													className="fromStyle"
													value={businessInput.payment_bank_routing_no}
													onChange={(e) => handleChangeTextInput('payment_bank_routing_no', e.target.value)}
												/>
											</div>
											<div className="col-lg-4">
												<label htmlFor="payment_bank_swift" className="form-label">Swift Code <LabelOptional /></label>
												<Form.Control
													id="payment_bank_swift"
													placeholder="Enter Swift Code"
													name="payment_bank_swift"
													className="fromStyle"
													value={businessInput.payment_bank_swift}
													onChange={(e) => handleChangeTextInput('payment_bank_swift', e.target.value)}
												/>
											</div>
										</div>
									</div>

									{
										mobilePaymentMethods.map((paymentMethod, index) => (
											<div className="border p-2 mt-2" key={index}>
												<h3>{paymentMethod.label} Payment Information</h3>
												<div className="row">
													<div className="col-lg-4">
														<label htmlFor={`payment_${paymentMethod.code}_no`} className="form-label">{paymentMethod.label} Mobile No. <LabelOptional /></label>
														<Form.Control
															id={`payment_${paymentMethod.code}_no`}
															placeholder={`Enter ${paymentMethod.label} Mobile No.`}
															name={`payment_${paymentMethod.code}_no`}
															className="fromStyle"
															value={businessInput[`payment_${paymentMethod.code}_no`]}
															onChange={(e) => handleChangeTextInput(`payment_${paymentMethod.code}_no`, e.target.value)}
														/>
													</div>
													<div className="col-lg-4">
														<label htmlFor={`payment_${paymentMethod.code}_no_type`} className="form-label">{paymentMethod.label} No. Type <LabelOptional /></label>
														<RHFInput
															id={`payment_${paymentMethod.code}_no_type`}
															as={<Select options={mobileBankingNoTypes} className="form-control" />}
															className="register_rhf_input"
															placeholder="Select Type"
															name={`payment_${paymentMethod.code}_no_type`}
															register={register}
															value={getSelectedOption(mobileBankingNoTypes, businessInput[`payment_${paymentMethod.code}_no_type`])}
															onChange={(option) => handleChangeTextInput(`payment_${paymentMethod.code}_no_type`, option.value)}
															setValue={setValue}
														/>
													</div>
												</div>
											</div>
										))
									}

								</Tab>
								<Tab eventKey="social" title="Social Links">
									<div className="border p-2 mt-2">
										<h3>Social Links</h3>
										<div className="row">
											{
												socialOptions.map(socialItem => (
													<div className="col-lg-4" key={socialItem}>
														<label htmlFor={`social_${socialItem}`} className="form-label">{socialItem} <LabelOptional /></label>
														<Form.Control
															id={`social_${socialItem}`}
															placeholder="https://example.com"
															name={`social_${socialItem}`}
															className="fromStyle"
															value={businessInput[`social_${socialItem}`]}
															onChange={(e) => handleChangeTextInput(`social_${socialItem}`, e.target.value)}
														/>
													</div>
												))
											}
										</div>
									</div>
								</Tab>
							</Tabs>
						</div>

						<div className="row pb-5 pr-5 mr-5">
							<div className="col-lg-12 text-right">
								<button onClick={() => history.goBack()} type="button" className="btn btn-secondary btn-lg mr-2">
									Back
								</button>
								{isLoading &&
									<button className="btn btn-primary btn-lg" disabled={true} >
										<span>Submitting...</span>
										<span className="ml-3 spinner spinner-white"></span>
									</button>
								}

								{!isLoading &&
									<button type="submit" className="btn btn-primary btn-lg">
										<span>Submit</span>
									</button>
								}
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default BusinessAdd;
