import React from "react";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";
import VoucherEdit from "../../components/Voucher/VoucherEdit";


const VoucherEditContainer = (props) => {
    return (
        <PermissionWiseDisplay permission_name="Voucher.Edit" display={true} >
            <VoucherEdit props={props} />
        </PermissionWiseDisplay>
    );
};

export default VoucherEditContainer;
