import React from "react";
import PermissionWiseDisplay from "../../../app/modules/role/PermissionWiseDisplay";
import UnitsEdit from "../components/UnitsEdit";


const UnitsEditContainer = (props) => {
    return (
        <>
            <PermissionWiseDisplay permission_name="Unit.Edit" display={true}>
                <UnitsEdit props={props} />
            </PermissionWiseDisplay>
        </>
    );
};

export default UnitsEditContainer;
