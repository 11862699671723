import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import SimpleEditor from "../../../master/components/text-editor/SimpleEditor";
import {
  handleChangeStatusInput,
  handleUpdateStatus,
} from "../../_redux/actions/InvoiceAction";
import moment from "moment";

const InvoiceStatusUpdated = () => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();

  const handleChangeTextInput = (name, value) => {
    dispatch(handleChangeStatusInput(name, value));
  };

  const { statusInput, isLoading } = useSelector(
    (state) => state.InvoiceReducer
  );

  const onSubmit = (e) => {
    dispatch(handleUpdateStatus(statusInput));
  };

  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        autoComplete="off"
        encType="multipart/form-data"
      >
        <div className="card card-custom gutter-b mt-3">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Update Invoice Information</h3>
            </div>
          </div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-3">
                <label className="form-label mt-2">
                  Customer Delivery Date
                </label>
                <DatePicker
                  name="customer_delivery_date"
                  type="date"
                  className="form-control fromStyle formHeight"
                  placeholderText="customer delivery date"
                  dateFormat="MM-dd-yyyy"
                  selected={
                    statusInput.customer_delivery_date !== ""
                      ? moment(statusInput.customer_delivery_date).toDate()
                      : null
                  }
                  onChange={(date) =>
                    handleChangeTextInput("customer_delivery_date", date)
                  }
                  ref={register({
                    required: true,
                    maxLength: 100,
                  })}
                />
              </div>

              <div className="col-lg-6">{/*  */}</div>
            </div>

            <div className="form-group row">
              <div className="col-12">
                <label className="form-label">Comment</label>
                <SimpleEditor
                  name="comment"
                  value={statusInput.comment}
                  onValueChange={(e) => handleChangeTextInput("comment", e)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card card-custom gutter-b mt-3">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Update Shipping Information</h3>
            </div>
          </div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-md-3">
                <label className="form-label mt-2">Shipping Provider</label>
                <select
                  name="shipping_provider"
                  id="shipping_provider"
                  className="form-control"
                >
                  <option value="">--Select Provider--</option>
                  <option value="deshi">Deshi Express</option>
                  <option value="shundorban">Shundarban Courier Service</option>
                  <option value="korotua">Korotua Courier Service</option>
                </select>
              </div>
              <div className="col-md-3">
                <label className="form-label mt-2">Tracking Number</label>
                <input
                  type="text"
                  name="shipment_tracking_no"
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <label className="form-label mt-2">Tracking URL (If any)</label>
                <input
                  type="url"
                  name="shipment_tracking_url"
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <label className="form-label mt-2">Shift Date</label>
                <DatePicker
                  name="product_shift_date"
                  type="date"
                  className="form-control fromStyle formHeight"
                  placeholderText="Product shift date"
                  dateFormat="MM-dd-yyyy"
                  selected={
                    statusInput.product_shift_date !== ""
                      ? moment(statusInput.product_shift_date).toDate()
                      : null
                  }
                  onChange={(date) =>
                    handleChangeTextInput("product_shift_date", date)
                  }
                  ref={register({
                    required: true,
                    maxLength: 100,
                  })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-10">
            <button type="submit" className="btn btn-primary btn-lg">
              Update
            </button>

            {/* {isLoading && (
                    <button type="submit" className="btn btn-primary btn-lg" >
                        <span>updating...</span>
                        <span className="ml-3 spinner spinner-white"></span>
                    </button>
                )}

                {!isLoading &&
                    <button type="submit" className="btn btn-primary btn-lg">
                        <span>update</span>
                    </button>
                } */}
          </div>
        </div>
      </form>
    </>
  );
};

export default InvoiceStatusUpdated;
