import React, { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { getRoleListDropdownAction } from "../_redux/actions/RoleAction";
import {
  createNewUser,
  handleChangeUserAction,
} from "../_redux/actions/UserAction";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";

const NewUser = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm();
  const { inputData, isLoading } = useSelector((state) => state.userRole);
  const { roleList } = useSelector((state) => state.roleReducer);

  const onSubmit = () => {
    dispatch(createNewUser(inputData, handleClose));
  };

  const handleChange = (name, value, e = null) => {
    dispatch(handleChangeUserAction(name, value, e));
  };

  useEffect(() => {
    dispatch(getRoleListDropdownAction());
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card card-custom gutter-b p-5">
              <form
                className="form form-label-right voyageEngineerForm"
                method="post"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group row">
                  <div className="col-xl-3 col-lg-3 col-md-6 ">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">
                        First Name
                      </Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="text"
                        name="first_name"
                        placeholder="Enter first name"
                        onChange={(e) =>
                          handleChange("first_name", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 ">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">
                        Sur Name
                      </Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="text"
                        name="surname"
                        placeholder="Enter sur name"
                        onChange={(e) =>
                          handleChange("surname", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 ">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">
                        Last Name
                      </Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="text"
                        name="last_name"
                        placeholder="Enter last name"
                        onChange={(e) =>
                          handleChange("last_name", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">
                        username
                      </Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="text"
                        name="username"
                        placeholder="Enter username"
                        onChange={(e) =>
                          handleChange("username", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">Email</Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="text"
                        name="email"
                        placeholder="Enter email address"
                        onChange={(e) => handleChange("email", e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">
                        Phone No
                      </Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="text"
                        name="phone_no"
                        placeholder="Enter phone no"
                        onChange={(e) =>
                          handleChange("phone_no", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">
                        Password
                      </Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={(e) =>
                          handleChange("password", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="password"
                        name="Cpassword"
                        placeholder="Password"
                        onChange={(e) =>
                          handleChange("Cpassword", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <Form.Label className="formFont pl-1">Role</Form.Label>
                    <RHFInput
                      as={<Select options={roleList} />}
                      rules={{ required: false }}
                      name="role_id"
                      register={register}
                      onChange={(option) => {
                        handleChange("name", option.label);
                        handleChange("role_id", option.value);
                      }}
                      setValue={setValue}
                    />
                  </div>
                </div>
                {isLoading && (
                  <Button
                    className="mr-4 saveButton text-white float-right"
                    disabled={true}
                  >
                    <span>Saving...</span>
                    <span className="ml-3 spinner spinner-white"></span>
                  </Button>
                )}

                {!isLoading && (
                  <Button
                    className="mr-4 saveButton text-white float-right"
                    onClick={() => onSubmit()}
                  >
                    Save
                  </Button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewUser;
