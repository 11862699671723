

/*******************************************************************************************************
        Sub Module: currency
********************************************************************************************************/
export const CURRENCY_INPUT_CHANGE = "CURRENCY_INPUT_CHANGE";
export const STORE_CURRENCIES = "STORE_CURRENCIES";
export const GET_CURRENCIES_LIST = "GET_CURRENCIES_LIST";
export const DELETE_CURRENCY = "DELETE_CURRENCY";
export const GET_CURRENCY_DETAILS = "GET_CURRENCY_DETAILS";
export const UPDATE_CURRENCIES = "UPDATE_CURRENCIES";
export const EMPTY_DISPATCH = "EMPTY_DISPATCH";
