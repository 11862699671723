import * as Types from '../Types/Types';

const initialState = {
    businessList: [],
};

const UnitOptionListReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_UNIT_LIST:
            return {
                ...state,
                UnitOptionList: GetUnitOptionList(action.payload.data)
            };
        default:
            break;
    }
    return newState;
};

/**
 * GetUnitOptionList
 * 
 * @param array data 
 * @return array printable business list
 */
const GetUnitOptionList = (data) => {
    let UnitOptionList = [];
    if (typeof data !== 'undefined' && data.length > 0) {
        data.forEach((item) => {
            let items = {
                value: item.id,
                label: `${item.actual_name}(${item.short_name})`,
            };
            UnitOptionList.push(items);
        });
    }
    return UnitOptionList;
}
export default UnitOptionListReducer;
