import * as Types from "../Types/Types";
import http from "../../../../../services/http";

export const getItemOptionList = () => (dispatch) => {
  let userData = localStorage.getItem("userData") || null;

  if (typeof userData !== "undefined" && userData !== null) {
    userData = JSON.parse(userData);
    let url = `items/all/for-dropdown`;

    if (
      userData.business.is_main_shop === "0" ||
      userData.business.is_main_shop === false
    ) {
      url += `?business_id=${userData.business_id}`;
    }

    http.get(url).then((res) => {
      dispatch({ type: Types.GET_ITEM_OPTION_LIST, payload: res.data });
    });
  }
};
