import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { changeOrderInput } from "../../_redux/actions/OrdersAction";

const DeliveryInformation = () => {
  const dispatch = useDispatch();
  // const [startDate, setStartDate] = useState(new Date());
  const { ordersViewList, orderInput } = useSelector((state) => state.orders);

  const handleOrderInputChange = (name, value) => {
    dispatch(changeOrderInput(name, value));
  };

  return (
    <div className="container-fluid p-5">
      <h3 className="text-muted pt-3 pb-4">Delivery & Shipping Informations</h3>
      {typeof ordersViewList !== "undefined" && ordersViewList !== null && (
        <div>
          <table className="table table-bordered">
            {ordersViewList.shipping_address !== null &&
              ordersViewList.billing_address !== null && (
                <tbody>
                  <tr>
                    <th>
                      Ordered By
                      <br />
                      Billing Information
                    </th>
                    <td>
                      <p>
                        <span className="font-wight-bold">Name: </span>{" "}
                        {ordersViewList.billing_address.name}{" "}
                      </p>
                      <p>
                        <span className="font-wight-bold">Phone No: </span>{" "}
                        {ordersViewList.billing_address.phone_no}{" "}
                      </p>
                      <p>
                        <span className="font-wight-bold">Email: </span>{" "}
                        {ordersViewList.billing_address.email}{" "}
                      </p>
                      <address>
                        {ordersViewList.billing_address.street1 !== null
                          ? ordersViewList.billing_address.street1 + " ,"
                          : ""}
                        {ordersViewList.billing_address.street2 !== null
                          ? ordersViewList.billing_address.street2 + " ,"
                          : ""}
                        {ordersViewList.billing_address.area !== null
                          ? ordersViewList.billing_address.area + " ,"
                          : ""}
                        {ordersViewList.billing_address.city !== null
                          ? ordersViewList.billing_address.city + " ,"
                          : ""}
                        {ordersViewList.billing_address.division !== null
                          ? ordersViewList.billing_address.division
                          : ""}
                      </address>
                    </td>
                  </tr>
                  <tr>
                    <th>Recipient Name</th>
                    <td> {ordersViewList.shipping_address.name} </td>
                  </tr>
                  <tr>
                    <th>Recipient Phone No</th>
                    <td>{ordersViewList.shipping_address.phone_no}</td>
                  </tr>
                  <tr>
                    <th>Customer Notes</th>
                    <td>{ordersViewList.additional_notes}</td>
                  </tr>
                  <tr>
                    <th>Shipping Address</th>
                    <td>
                      {ordersViewList.shipping_address.street1 !== null
                        ? ordersViewList.shipping_address.street1 + " ,"
                        : ""}
                      {ordersViewList.shipping_address.street2 !== null
                        ? ordersViewList.shipping_address.street2 + " ,"
                        : ""}
                      {ordersViewList.shipping_address.area !== null
                        ? ordersViewList.shipping_address.area + " ,"
                        : ""}
                      {ordersViewList.shipping_address.city !== null
                        ? ordersViewList.shipping_address.city + " ,"
                        : ""}
                      {ordersViewList.shipping_address.division !== null
                        ? ordersViewList.shipping_address.division
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <th className="bg-primary">Update Informations</th>
                    <td className="text-primary"></td>
                  </tr>
                  {/* <tr>
                                        <th>Delivery Status & Date</th>
                                        <td>
                                            <p>
                                                <span className="font-weight-bold mr-3">Delivery Date:</span>
                                                <DatePicker selected={startDate} onChange={date => setStartDate(date)} className="form-control" />
                                            </p>
                                        </td>
                                    </tr> */}
                  {/* <tr>
                                        <th>Delivery Man Information</th>
                                        <td>
                                            <div className="form-group row">
                                                <label for="name" className="col-sm-2 col-form-label">Name:</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="name" placeholder="Enter Name"></input>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label for="email" className="col-sm-2 col-form-label">Email:</label>
                                                <div className="col-sm-10">
                                                    <input type="email" className="form-control" id="email" placeholder="Enter Email"></input>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label for="phone" className="col-sm-2 col-form-label">Phone Number:</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="phone" placeholder="Enter Phone Number"></input>
                                                </div>
                                            </div>
                                        </td>
                                    </tr> */}
                  <tr>
                    <th htmlFor="staff_note">Delivery Message</th>
                    <td>
                      <textarea
                        name="staff_note"
                        value={orderInput.staff_note}
                        onChange={(e) =>
                          handleOrderInputChange("staff_note", e.target.value)
                        }
                        className="form-control"
                        name="staff_note"
                        id="staff_note"
                        cols="30"
                        rows="4"
                      >
                        {orderInput.staff_note}
                      </textarea>
                    </td>
                  </tr>
                </tbody>
              )}
          </table>

          <h3 className="text-muted pt-3 pb-4">
            Vendor Wise Delivery Assign{" "}
            <span className="text-danger">Under development</span>
          </h3>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#Invoice No</th>
                <th>Shop Name / Vendor Name</th>
                <th>Total Amount</th>
                <th>Apply Same for Next Order</th>
                <th>Delivery System By</th>
                <th>Shipping Provider</th>
                <th>Tracking No</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>#1229</td>
                <td>Time Pharma / MD. Mahbub</td>
                <td>6000</td>
                <td>
                  <label>
                    {" "}
                    Yes{" "}
                    <input
                      type="radio"
                      name="same_delivery_system"
                      value="1"
                      id=""
                      checked
                    />
                  </label>
                  <br />
                  <label>
                    {" "}
                    No{" "}
                    <input
                      type="radio"
                      name="same_delivery_system"
                      value="0"
                      id=""
                    />
                  </label>
                </td>
                <td>
                  <div>
                    <label>
                      {" "}
                      Own{" "}
                      <input
                        type="radio"
                        name="delivery_system"
                        value="1"
                        id="delivery_system"
                      />
                    </label>
                    <br />
                    <label>
                      {" "}
                      Vendor{" "}
                      <input
                        type="radio"
                        name="delivery_system"
                        value="0"
                        id="delivery_system"
                      />
                    </label>
                  </div>
                </td>
                <td>
                  <select
                    name="shipping_provider"
                    id="shipping_provider"
                    className="form-control"
                  >
                    <option value="">--Select Provider--</option>
                    <option value="deshi">Deshi Express</option>
                    <option value="shundorban">
                      Shundarban Courier Service
                    </option>
                    <option value="korotua">Korotua Courier Service</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    name="shipment_tracking_no"
                    className="form-control"
                  />
                </td>
              </tr>
              <tr>
                <td>#1230</td>
                <td>X Pharma / MD. X</td>
                <td>12000</td>
                <td>
                  <label>
                    {" "}
                    Yes{" "}
                    <input
                      type="radio"
                      name="same_delivery_system"
                      value="1"
                      id=""
                      checked
                    />
                  </label>
                  <br />
                  <label>
                    {" "}
                    No{" "}
                    <input
                      type="radio"
                      name="same_delivery_system"
                      value="0"
                      id=""
                    />
                  </label>
                </td>
                <td>
                  <div>
                    <label>
                      {" "}
                      Own{" "}
                      <input
                        type="radio"
                        name="delivery_system"
                        value="1"
                        id="delivery_system"
                      />
                    </label>
                    <br />
                    <label>
                      {" "}
                      Vendor{" "}
                      <input
                        type="radio"
                        name="delivery_system"
                        value="0"
                        id="delivery_system"
                      />
                    </label>
                  </div>
                </td>
                <td>
                  <select
                    name="shipping_provider"
                    id="shipping_provider"
                    className="form-control"
                  >
                    <option value="">--Select Provider--</option>
                    <option value="deshi">Deshi Express</option>
                    <option value="shundorban">
                      Shundarban Courier Service
                    </option>
                    <option value="korotua">Korotua Courier Service</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    name="shipment_tracking_no"
                    className="form-control"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {/* <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col">
                                        #Invoice No
                                    </div>
                                    <div className="col">
                                        Shop Name / Vendor Name
                                    </div>
                                    <div className="col">

                                    </div>
                                </div>
                            </div>
                        </div> */}
        </div>
      )}
    </div>
  );
};

export default DeliveryInformation;
