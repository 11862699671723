import * as Types from '../../types/Types';

const initialState = {
	unitsList    : [],
	addMessage   : "",
	editMessage  : "",
	deleteMessage: "",
	isLoading    : false,
	addStatus    : false,
	editStatus   : false,
	deleteStatus : false,
};

const UnitsReducer = (state = initialState, action) => {
	const newState = {
		...state
	};

	switch (action.type) {
		case Types.GET_UNITS_LIST:
			return {
				...state,
				unitsList: action.payload.data,
			};

		case Types.ADD_UNITS:
			return {
				...state,
				addMessage: action.payload.message,
				addStatus : action.payload.status,
				isLoading : action.payload.isLoading,
			};

		case Types.UPDATE_UNITS:
			return {
				...state,
				editStatus : action.payload.status,
				editMessage: action.payload.message,
				isLoading  : action.payload.isLoading,
			};

		case Types.DELETE_UNITS:
			return {
				...state,
				deleteStatus : action.payload.status,
				deleteMessage: action.payload.message,
			};

		case Types.EMPTY_UNITS_MESSAGES:
			return {
				...state,
				addMessage   : "",
				editMessage  : "",
				deleteMessage: "",
			};

		default:
			break;
	}
	return newState;
};

export default UnitsReducer;