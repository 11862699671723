import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getItemDetails } from '../_redux/actions/ItemAction';
import { useHistory, useParams } from 'react-router-dom';
import LoadingSpinner from '../../master/spinner/LoadingSpinner';

const ItemDetails = () => {

    const dispatch       = useDispatch();
    const { id }         = useParams();
    const itemDetails    = useSelector((state) => state.item.itemDetails);
    const isDetailLoaded = useSelector((state) => state.item.isDetailLoaded);

    useEffect(() => {
        dispatch(getItemDetails(id))
    }, [])


    return (
        <div className="container-fluid">
            {
                isDetailLoaded && (
                    <div className="card bg-white p-3">

                        {/*Product Basic Information*/}
                        <h4>Basic Information</h4>
                        <div className="card-body bg-light p-2 border mb-3">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Product Name </h6>
                                    <p>{itemDetails.name}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Business </h6>
                                    <p>{itemDetails.business.label}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Category Level-1 </h6>
                                    <p>{itemDetails.category1.label}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Category Level-2 </h6>
                                    <p>{itemDetails.category2.label}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Category Level-3 </h6>
                                    <p>{itemDetails.category3.label}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Brand </h6>
                                    <p>{itemDetails.brand.label}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Unit of Measurement </h6>
                                    <p>{itemDetails.unit.label}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Product SKU </h6>
                                    <p>{itemDetails.sku}</p>
                                </div>
                            </div>
                        </div>

                        {/*Stock & Pricing Information*/}
                        <h4>Stock & Pricing Information</h4>
                        <div className="card-body bg-light p-2 border mb-3">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Tax Type </h6>
                                    <p>{itemDetails.taxType.label}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Tax </h6>
                                    <p>{itemDetails.taxList.label}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Enable Stock </h6>
                                    <p>{itemDetails.enable_stock}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Current Stock</h6>
                                    <p>{itemDetails.current_stock}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Stock Alert Quantity</h6>
                                    <p>{itemDetails.alert_quantity}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Selling Price</h6>
                                    <p>{itemDetails.default_selling_price}</p>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <h6 className="font-weight-bold">Offer Selling Price </h6>
                                    <p>{itemDetails.offer_selling_price}</p>
                                </div>
                            </div>
                        </div>

                        {/*Product Images Gallery*/}
                        <h4>Product Images</h4>
                        <div className="card-body bg-light p-2 border mb-3">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6">
                                    <h6 className="font-weight-bold">Featured Image </h6>
                                    <img style={{ height: "120px" }} src={itemDetails.featured_image_preview} alt="Features Images" className="img img-thumbnail" />
                                </div>
                                {
                                    itemDetails.short_resolation_image_preview && (
                                        <div className="col-lg-3 col-sm-6">
                                            <h6 className="font-weight-bold">Short Resolution Image </h6>
                                            <img style={{ height: "120px" }} src={itemDetails.short_resolation_image_preview} alt="Features Images" className="img img-thumbnail" />
                                        </div>
                                    )
                                }

                                {itemDetails.images && itemDetails.images.length > 0 && (
                                    <div className="col-lg-6">
                                        <h6 className="font-weight-bold">Product Image Gallery</h6>

                                        <table className="table tbl-standard table-bordered tbl-survey">
                                            <thead>
                                                <tr>
                                                    <th>SI</th>
                                                    <th>Size</th>
                                                    <th>Preview</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    itemDetails.images.map((item, index) => (
                                                        <tr key={index + 1}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.image_size}</td>
                                                            <td>
                                                                <img style={{ height: "60px" }} src={item.image_url} alt="" className="img-thumbnail" />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            }

            {
                !isDetailLoaded && (
                    <LoadingSpinner text="Loading product details...." />
                )
            }
        </div>
    );
};

export default ItemDetails;