import React from 'react'
import './PrintInvoice.scss';
import { useSelector } from 'react-redux'
import moment from 'moment';

function PrintInvoice() {
    // const { id } = useParams();
    const { ordersViewList } = useSelector((state) => state.orders);

    if(!ordersViewList) return null

    return (
        <div className="print-invoice my-0 mx-auto" id='print-invoice'>
            <div>
                <div className="print-invoice__top-head">
                    <span>
                        {
                            moment(ordersViewList.transaction_date).format('DD-MM-YYYY')
                        }
                    </span>
                    <span className='d-inline-block pl-3'>Office Copy</span>
                </div>

                <div className="print-invoice__business-invoice-info">
                    <div className='left pr-3'>
                        <div>
                            <img src="/media/logos/logo-light.png" alt="lacefeeta logo" />
                        </div>
                        <span className='d-block'>Sumi Tower 12th Floor, 66/1 Zoo Road</span>
                        <span>Mirpur-2, Dhaka-1216</span>
                    </div>
                    <div className='right pl-3'>
                        <h3>INVOICE</h3>
                        <div>
                            <span className='d-block'>{`#DESHI${ordersViewList.id}`}</span>
                            <span>{`Date: ${moment(ordersViewList.transaction_date).format('YYYY-MM-DD HH:mm:ss')}`}</span>
                        </div>
                        {/* <div>
                            <img src="#" alt="barcode" />
                        </div> */}
                    </div>
                </div>

                <div className='print-invoice__billing-delivery-info'>
                    <div className='left pr-3'>
                        <strong className='d-block mb-2'>Bill To</strong>
                        <span className='d-block'>{ordersViewList.billing_address.name}</span>
                        <span className='d-block'>Mobile no: {ordersViewList.billing_address.phone_no}</span>
                        <div className='mt-3'>
                            <strong className='d-block'>Delivery Address</strong>
                            <span className='d-block'>Mobile no: { ordersViewList.shipping_address && ordersViewList.shipping_address.phone_no }</span>
                            <span>{ ordersViewList.street1 }</span>
                            <span>{ ordersViewList.street2 && ordersViewList.street2 }</span>
                            <span>
                                { 
                                    `${ordersViewList.shipping_address.area}, ${ordersViewList.shipping_address.city}`
                                }
                            </span>
                            <span className="d-block" >{ ordersViewList.shipping_address.division }</span>
                        </div>
                    </div>
                    <div className='right pl-3'>
                        <strong className='d-block mb-2'>Bill From</strong>
                        <div className='mb-2'>
                            <img src="/media/logos/logo-light.png" alt="seller logo" />
                        </div>
                        <div>
                            <span className='d-block'>FatonShop</span>
                            <span className='d-block'>Sumi Tower 12th Floor, 66/1 Zoo Road</span>
                            <span className='d-block'>Mirpur-2, Dhaka-1216</span>
                            <span className='d-block'>+880 9696 848858</span>
                        </div>
                    </div>
                </div>

                <div className='print-invoice__products-info'>
                    <table className='w-100'>
                        <tr>
                            <th>SL</th>
                            <th></th>
                            <th>Items &amp; Description </th>
                            <th>Rate</th>
                            <th>Qty</th>
                            <th>Amount</th>
                        </tr>

                        {
                            ordersViewList.sale_lines.map((item, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                        <div className='invoice-product-img'>
                                            <img src={item.item.featured_url} alt="product img" />
                                        </div>
                                    </td>
                                    <td>
                                        {
                                            item.item.name
                                        }
                                    </td>
                                    <td>
                                        {
                                            `TK. ${item.unit_price_inc_tax}`
                                        }
                                    </td>
                                    <td>
                                        {
                                            item.quantity
                                        }
                                    </td>
                                    <td>
                                        {
                                            `TK. ${item.unit_price_inc_tax * item.quantity}`
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </table>
                </div>

                <div className='print-invoice__total d-flex justify-content-end'>
                    <div>
                        <div>
                            <span>Sub Total</span>
                            <span>
                                {
                                    `TK. ${ordersViewList.total_before_tax}`
                                }
                            </span>
                        </div>
                        <div>
                            <span>Shipping Charge</span>
                            <span>
                                {
                                    `TK. ${ordersViewList.shipping_charges}`
                                }
                            </span>
                        </div>
                        <div>
                            <span>Total</span>
                            <span>
                                {
                                    `TK. ${ordersViewList.final_total}`
                                }
                            </span>
                        </div>
                        <div>
                            <span>Paid</span>
                            <span>
                                {
                                    `TK. ${ordersViewList.paid_total}`
                                }
                            </span>
                        </div>
                        <div>
                            <span>Due</span>
                            <span>
                                {
                                    `TK. ${ordersViewList.due_total}`
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='print-invoice__footer text-center'>
                <span className='d-block'>This is computer generated invoice, no signature required</span>
                <span>
                    {
                        moment().format("YYYY-MM-DD")
                    }
                    {', '}
                    {
                        moment().format("hh:mm:ss a")
                    }
                </span>
            </div>
        </div>
    )
}

export default PrintInvoice




















