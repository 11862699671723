import React from 'react'
import { useHistory } from 'react-router-dom';
import BrandSingleDetails from '../components/BrandSingleDetails';

function BrandViewContainer(props) {
    const history = useHistory();

    return (
        <div className="container">
            <div className="card card-custom gutter-b">
                <div className="card-header">
                    <div className="card-title">
                        <h3 className="card-label">View Category</h3>
                    </div>
                    <div className="card-toolbar">
                        <a
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            <button type="button" className="btn btn-primary">
                                <i className="fa fa-arrow-left"></i> Brands
                            </button>
                        </a>
                    </div>
                </div>
                <BrandSingleDetails id={props.match.params.id} />
            </div>
        </div>
    )
}

export default BrandViewContainer;