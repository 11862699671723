import * as Types from '../types/Types';

const initialState = {
  customerList: [],
  customerPaginatedData: null,
  isLoading: true,
  reviewList: [],
  reviewListPaginated: null,
  updating: false,
};

const CustomerReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.GET_CUSTOMER_LIST:
      return {
        ...state,
        customerList: typeof action.payload.customerList !== 'undefined' ? action.payload.customerList : [],
        customerPaginatedData: action.payload.customerPaginatedData,
        isLoading: action.payload.isLoading
      };

    case Types.GET_REVIEW_LIST:
      return {
        ...state,
        reviewList: action.payload.reviewList,
        reviewListPaginated: action.payload.reviewListPaginated,
        isLoading: action.payload.isLoading,
      };

    case Types.DELETE_CUSTOMER_REVIEW:
      return {
        ...state,
        isLoading: action.payload,
      };

    case Types.UPDATED_REVIEW_STATUS:
      return {
        ...state,
        updating: action.payload.updating,
      };

    default:
      break;
  }
  return newState;
};

export default CustomerReducer;
