/**
 * Get File Size.
 *
 * @param {Number} byte
 * @returns {String}
 */
export const getFileSize = (byte) => {
  const oneMB = 1049000; // in byte
  const oneKB = 1024; // in byte

  if (byte > oneMB) {
    return (byte / oneMB).toFixed(2) + "MB";
  }

  if (byte > oneKB) {
    return (byte / oneKB).toFixed(2) + "KB";
  }

  return byte + " byte";
};

/**
 * Get File Name.
 *
 * @param {String} - fileName with mime type
 * @returns {String} - return filename without mimetype
 */
export const getFileName = (fileName) => {
  const fileNameWithPeriod = fileName.includes(".");

  if (fileNameWithPeriod) {
    const name = fileName.split(".");
    name.pop(); // remove mime type from filename
    return name.join(".");
  } else {
    return fileName;
  }
};

/**
 * Get File Type.
 *
 * @param {string} fileName
 * @return string
 */
export const getFileType = (fileName) => {
  if (fileName) {
    return fileName.split(".").slice(-1)[0]; // get filetype from filename
  } else {
    return "Unknown type";
  }
};
