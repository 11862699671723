import React from 'react';
import CustomerReviewList from '../components/CustomerReviewList';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';

const CustomerReviewContainer = () => {
  return (
    <PermissionWiseDisplay permission_name="customer.review.list" display={true}>
      <CustomerReviewList />
    </PermissionWiseDisplay>
  );
};

export default CustomerReviewContainer;
