import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select";
import SimpleModal from '../../../master/components/Modal/SimpleModal';
import DateRange from '../../../master/dateRange/DateRange';
import { changeFilterOrderList, getFilterOptionDataForOrderList } from '../../_redux/actions/OrdersAction';

const OrderFilter = ({ orderFilter }) => {

    const { register, setValue } = useForm();
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState("");
    const [typeValue, setTypeValue] = useState(null);
    const [dateValue, setDateValue] = useState(null);
    const [customDate, setCustomDate] = useState(null);
    const { filterOptionList } = useSelector((state) => state.orders);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const firstRenderRef = useRef(true);

    useEffect(() => {
        if(firstRenderRef.current) {
            firstRenderRef.current = false
            return
        }

        orderFilter({typeValue: typeValue, searchText: searchText, dateValue: dateValue, customDate: customDate})

        dispatch(getFilterOptionDataForOrderList());
    }, [typeValue, searchText, dateValue, customDate]);

    const typeList = [
        { label: "Sell", value: "sell" },
        { label: "Return", value: "return" },
    ]

    return (
        <>
            <form className="form form-label-right">
                <div className="form-group row mt-2 justify-content-end">
                    <div className="col-lg-3 mt-2">
                        <input
                            type="text"
                            className="form-control"
                            name="searchText"
                            placeholder="Search Items"
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value)
                                dispatch(changeFilterOrderList('search', e.target.value))
                            }}
                        />
                    </div>
                    <div className="col-lg-3 mt-2">
                        <RHFInput
                            as={<Select options={typeList} placeholder="Filter with types" />}
                            rules={{ required: false }}
                            name="filter_id"
                            register={register}
                            setValue={setValue}
                            // isClearable={true}
                            isSearchable={true}
                            onChange={(option) => {
                                setTypeValue(option.value)
                                dispatch(changeFilterOrderList('type', option.value))
                            }}
                        />
                    </div>
                    <div className="col-lg-3 mt-2">
                        <RHFInput
                            as={<Select options={filterOptionList} placeholder="Filter with date" />}
                            rules={{ required: false }}
                            name="type_id"
                            register={register}
                            setValue={setValue}
                            // isClearable={true}
                            isSearchable={true}
                            onChange={(option) => {
                                setDateValue(option.value)
                                setCustomDate(null)
                                dispatch(changeFilterOrderList('date', option.value))
                            }}
                        />
                    </div>
                    <div className="col-lg-3 mt-2">
                        <button type="button" className="btn btn-sm btn-default d-100" onClick={() => handleShow()}>
                            <span className="mr-2">
                                Custom Date
                            </span>
                            <i className="fas fa-calendar-alt date_picker_font"></i>
                        </button>
                    </div>
                </div>
            </form>
            <SimpleModal
                show={show}
                handleClose={handleClose}
                handleShow={handleShow}
                size="lg"
                modalTitle="Custom Order Filter"
            >
                <DateRange handleClose={handleClose} setDate={(val) => {
                    setCustomDate(val)
                    dispatch(changeFilterOrderList('customDate', val))
                }} />
            </SimpleModal>
        </>
    );
}

export default OrderFilter;