/*******************************************************************************************************
        Sub Module: CATEGORIES
********************************************************************************************************/
export const GET_CATEGORIES_LIST = 'GET_CATEGORIES_LIST';
export const GET_CATEGORIES_PARENT_LIST = 'GET_CATEGORIES_PARENT_LIST';
export const GET_CATEGORIES_SORTED_LIST = 'GET_CATEGORIES_SORTED_LIST';
export const ADD_CATEGORIES = 'ADD_CATEGORIES';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const GET_CATEGORY_DETAIL_DATA = 'GET_CATEGORY_DETAIL_DATA';
export const GET_CATEGORY_EDIT_DATA = 'GET_CATEGORY_EDIT_DATA';
export const GET_CATEGORY_EDIT_DETAILS_DATA = 'GET_CATEGORY_EDIT_DETAILS_DATA';

export const GET_CATEGORY_INIT = 'GET_CATEGORY_INIT';
export const GET_CATEGORY_EDIT_INIT = 'GET_CATEGORY_EDIT_INIT';

export const EMPTY_CATEGORY_MESSAGE = 'EMPTY_CATEGORY_MESSAGE';
export const CHANGE_CATEGORY_INPUT = 'CHANGE_CATEGORY_INPUT';
export const CHANGE_EDIT_CATEGORY_INPUT = 'CHANGE_EDIT_CATEGORY_INPUT';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const IS_LOADING = 'IS_LOADING';