import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { getSliderList, handleDeleteSlider } from "../_redux/actions/SliderAction";
import SimpleTooltip from "../../master/components/tooltip/SimpleTooltip";
import LoadingSpinner from "../../master/spinner/LoadingSpinner";
import { InputBase, Paper, IconButton } from "@material-ui/core";
import PaginationLaravel from "../../master/pagination/PaginationLaravel";
import SimpleModal from "../../master/components/Modal/SimpleModal";
import BrandDetails from "./SliderDetails";
import { getImageUrl } from '../../../services/FormatHelper';
import PermissionWiseDisplay from "../../../app/modules/role/PermissionWiseDisplay";

const SliderList = withRouter(({ history }) => {
  const dispatch = useDispatch();
  const {sliderList, sliderPaginationList, isLoading} = useSelector((state) => state.SliderReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(getSliderList(currentPage));
  }, [dispatch, currentPage]);

  const changePage = (data) => {
    setCurrentPage(data.page);
    dispatch(getSliderList(data.page));
  };

  const searchProduct = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText.length === 0) {
      dispatch(getSliderList(currentPage));
    } else {
      dispatch(getSliderList(currentPage, searchText));
    }
  };

  // delete slider
  const confirmDelete = (id) => {
    dispatch(handleDeleteSlider(id));
  }
  const deleteSlider = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No"
        }
      ]
    });
  };
  const [showBrandModal, setShowBrandModal] = useState(false);
  const [BrandID, setBrandID] = useState(null);

  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 class="card-label">Sliders</h3>
            </div>
            <div className="col-xl-4 col-lg-4 col-5 mt-3 float-left">
              <Paper className="searchInput">
                <InputBase
                  className="search-input"
                  placeholder="Search by title "
                  value={searchText}
                  onChange={searchProduct}
                />
                <IconButton aria-label="Search" className="searchPlaceholder purchaseSearch">
                  <i className="flaticon-search "></i>
                </IconButton>
              </Paper>
            </div>
            <div className="col-xl-4 col-lg-4 col-5 mt-3 float-left"></div>
            <div className="card-toolbar">
              <PermissionWiseDisplay permission_name="Slider.Create" display={false}> 
                <a onClick={() => { history.push("/slider/create"); }} >
                  <button type="button" class="btn btn-primary">
                    New Slide
                  </button>
                </a>
              </PermissionWiseDisplay>
            </div>
          </div>
          <div className="card-body">
            {isLoading && <LoadingSpinner text="Loading Slider List..." />}
            <div className="row"></div>
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <td>Sl</td>
                    <td>Title</td>
                    <td>Text</td>
                    <td>Button Text</td>
                    <td>Image</td>
                    <td>Mobile Image</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {sliderList &&
                    sliderList.map((item, index) => (
                      <tr key={index}>
                        <td>{sliderPaginationList.from + index}</td>
                        <td>{item.title}</td>
                        <td>{item.text}</td>
                        <td>{item.button_text}</td>
                        <td>
                          <img
                            src={getImageUrl(item.image, 'slider')}
                            style={{ width: 50 }}
                          />
                        </td>
                        <td>
                          <img
                            src={getImageUrl(item.mobile_image, 'slider')}
                            style={{ width: 50 }}
                          />
                        </td>
                        <td className="d-flex">
                          {/* <SimpleTooltip title="View Slide">
                            <Link
                              className="btn btn-icon btn-light btn-hover-info btn-sm"
                              onClick={() => brandsDataDetalsDisplay(item)}
                            >
                              <i className="fa fa-eye"></i>
                            </Link>
                          </SimpleTooltip> */}
                          &nbsp;&nbsp;
                          <PermissionWiseDisplay permission_name="Slider.Edit" display={false}>
                            <SimpleTooltip title={`Edit Slide ${item.title}`}>
                              <Link
                                to={`/slider/edit/${item.id}`}
                                className="btn btn-icon btn-light btn-hover-info btn-sm"
                              >
                                <i className="fa fa-edit"></i>
                              </Link>
                            </SimpleTooltip>
                          </PermissionWiseDisplay>
                          &nbsp;&nbsp;
                          <PermissionWiseDisplay permission_name="Slider.Delete" display={false}>
                            <SimpleTooltip title={`Delete ${item.title}`}>
                              <a
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                onClick={(id) => deleteSlider(item.id)}
                              >
                                <i className="fa fa-trash text-danger"></i>
                              </a>
                            </SimpleTooltip>
                          </PermissionWiseDisplay>
                        </td>
                      </tr>
                    ))}
                </tbody>

                <tfoot></tfoot>
              </table>
              {!isLoading && sliderList.length === 0 && (
                <div className="alert alert-warning mt-5">
                  Sorry ! Slider List Not Found.
                </div>
              )}
              <PaginationLaravel
                changePage={changePage}
                data={sliderPaginationList}
              />
            </div>
          </div>
        </div>
        <SimpleModal
          size="xl"
          show={showBrandModal}
          handleClose={() => setShowBrandModal(false)}
          handleShow={() => setShowBrandModal(true)}
          id={BrandID}
          modalTitle={"Brand Details"}
        >
          <BrandDetails handleClose={() => setShowBrandModal(false)} id={BrandID} />
        </SimpleModal>
      </div>
    </>
  );
});

export default SliderList;
