import Axios from "axios";
import { showToast } from "../../../../master/utils/ToastHelper";
import * as Types from "../types/Types";

const baseUrl = process.env.REACT_APP_API_URL;

export const AddRolePermissionInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value
  }
  dispatch({ type: Types.CHANGE_ROLE_INPUT, payload: formData })
}

/**
 * Get all roles list.
 *
 * @return void
 */
export const getRoleListByPagination = () => (dispatch) => {
  const responseList = {
    isLoading: true,
    rolesList: [],
    rolesListPaginated: null,
  };
  dispatch({ type: Types.GET_USER_ROLE_LIST_PAGINATED, payload: responseList });
  Axios
    .get(`${baseUrl}roles`)
    .then((res) => {
      const { data, message } = res.data;
      responseList.rolesList = data.data;
      responseList.message = message;
      responseList.rolesListPaginated = data;
      responseList.isLoading = false;
      dispatch({ type: Types.GET_USER_ROLE_LIST_PAGINATED, payload: responseList });
    });
};

/**
 * Get role list for dropdown.
 *
 * @return void
 */
export const getRoleListDropdownAction = () => (dispatch) => {
  Axios
    .get(`${baseUrl}roles`)
    .then((res) => {
      dispatch({ type: Types.GET_USER_ROLE_LIST_DROPDOWN, payload: res.data.data.data });
    });
};

export const getRoleDetailsData = (id) => (dispatch) => {
  const responseList = {
    isLoading: true,
    data: []
  };

  dispatch({ type: Types.GET_ROLE_DETAILS_DATA, payload: responseList });

  Axios
    .get(`${baseUrl}roles/${id}`)
    .then((res) => {
      dispatch({ type: Types.GET_ROLE_DETAILS_DATA, payload: res.data.data });
    });
};

export const emptyRoleStatusMessage = () => (dispatch) => {
  dispatch({ type: Types.EMPTY_ROLE_STATUS, payload: null });
}

export const storeRoleAction = (roleInputData) => (dispatch) => {
  const responseList = {
    isLoading: true,
    status: false,
    message: '',
    data: null
  };
  dispatch({ type: Types.CREATE_ROLE, payload: responseList });

  Axios.post(`${baseUrl}roles/storePermission`, roleInputData)
    .then((res) => {
      const { data, status, message } = res.data;
      responseList.status = status;
      responseList.message = message;
      responseList.isLoading = false;
      responseList.data = data;
      showToast('success', responseList.message);
      dispatch({ type: Types.CREATE_ROLE, payload: responseList });
    }).catch(err => {
      responseList.status = false;
      responseList.isLoading = false;
      responseList.message = 'Somethting went wrong, Please check inputs !';
      showToast('error', responseList.message);
      dispatch({ type: Types.CREATE_ROLE, payload: responseList });
    });
};

export const getRoleList = () => (dispatch) => {
  Axios
    .get(`${baseUrl}roles/permissions`)
    .then((res) => {
      dispatch({ type: Types.GET_USER_ROLE_LIST, payload: res.data.data });
    });
};

export const getPermissionGroups = () => (dispatch) => {
  Axios
    .get(`${baseUrl}roles/permissions`)
    .then((res) => {
      dispatch({ type: Types.GET_USER_PERMISSION_GROUPS, payload: res.data.data });
    });
};

export const roleCheckboxSelect = (checkboxStatus, parentRole, item, indexChild, indexparentRole) => (dispatch) => {
  dispatch({
    type: Types.USER_ROLE_CHECKED, payload: {
      checkboxStatus: checkboxStatus,
      parentRole: parentRole,
      item: item,
      indexChild: indexChild,
      indexparentRole: indexparentRole,
    }
  });

};
export const handleInputData = (name, value) => (dispatch) => {

  let data = {
    name: name,
    value: value,
  }
  dispatch({ type: Types.USER_ROLE_HANDLE_CHANGE, payload: data });

};

export const allCheckboxSelected = (status) => (dispatch) => {
  dispatch({ type: Types.USER_ROLE_ALL_CHECKED, payload: status });
};

export const checkPermissionGroupAction = (index, isGroupChecked) => (dispatch) => {
  dispatch({
    type: Types.USER_ROLE_CHECKED_GROUP, payload: {
      index: index,
      isGroupChecked: isGroupChecked
    }
  });
};
