import React from 'react'
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import CouponEdit from '../components/edit/CouponEdit'

const CouponEditContainer = () => {
    return (
        <PermissionWiseDisplay permission_name="Coupon.Edit" display={true}>
            <div>
                <CouponEdit />
            </div>
        </PermissionWiseDisplay>
    );
}

export default CouponEditContainer;