import * as Types from '../../types/Types';

const initialState = {
	isLoading: false,
	brandsList: [],
	BrandListPaginateData: {
		current_page: 1
	},
	addMessage: '',
	editMessage: '',
	deleteMessage: '',
	addStatus: false,
	editStatus: false,
	deleteStatus: false,
	brandDetails: null,
	formInput: {
		name: '',
		business_id: '',
		business_name: '',
		description: "",
		banner: null,
		image: null,
		created_by: 1,
		bannerPreviewUrl: null,
		logoPreviewUrl: null,
		categories: [],
		status: 1
	},
};

const BrandsReducer = (state = initialState, action) => {
	const newState = { ...state };

	switch (action.type) {
		case Types.GET_BRANDS_LIST:
			return {
				...state,
				brandsList: action.payload.status ? action.payload.brandsList : [],
				BrandListPaginateData: action.payload.status ? action.payload.BrandListPaginateData : {},
				isLoading: action.payload.isLoading
			};

		case Types.CHANGE_BRANDS_INPUT:
			const formInput = { ...state.formInput };
			formInput[action.payload.name] = action.payload.value;
			return {
				...state,
				formInput
			};

		case Types.ADD_BRANDS:
			if (action.payload.status) {
				return {
					...state,
					isLoading: action.payload.isLoading,
					formInput: initialState.formInput,
					addStatus: true,
					addMessage: action.payload.message
				};
			} else {
				return {
					...state,
					isLoading: action.payload.isLoading,
					addStatus: false,
					addMessage: action.payload.message
				};
			}

		case Types.UPDATE_BRANDS:
			if (action.payload.status) {
				return {
					...state,
					isLoading: action.payload.isLoading,
					formInput: initialState.formInput,
					editStatus: true,
					editMessage: action.payload.message
				};
			} else {
				return {
					...state,
					isLoading: action.payload.isLoading,
					editStatus: false,
					editMessage: action.payload.message
				};
			}

		case Types.EMPTY_DISPATCH:
			return {
				...state,
				formInput: initialState.formInput,
				editStatus: false,
				editMessage: '',
				addStatus: false,
				addMessage: ''
			};

		case Types.EMPTY_BRANDS_EDIT_MESSAGE:
			return {
				...state,
				editStatus: "",
				editMessage: "",
			};

		case Types.EMPTY_BRANDS_ADD_MESSAGE:
			return {
				...state,
				addStatus: "",
				addMessage: "",
			};

		case Types.GET_BRANDS_DETAILS:
			return {
				...state,
				formInput: action.payload.formData,
				brandDetails: action.payload.data,
			};

		case Types.DELETE_BRAND_DATA:
			return {
				...state,
				isLoading: action.payload,
			};

		default:
			break;
	}

	return newState;
};
export default BrandsReducer;
