import * as Types from "../../types/Types";
import axios from "axios";
import { showToast } from "../../../master/utils/ToastHelper";

export const GetUnitsList = () => (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};

	axios
		.get(`${process.env.REACT_APP_API_URL}units`, {
			headers: headers,
		})
		.then((res) => {
			let data = res.data;
			dispatch({
				type: Types.GET_UNITS_LIST,
				payload: data
			});
		});
};

export const AddUnits = (stateData) => (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	let responseData = {
		status: false,
		message: "",
		isLoading: true,
	};

	dispatch({
		type: Types.ADD_UNITS,
		payload: responseData
	});

	const { actual_name, short_name } = stateData;

	let postData = {
		actual_name  : actual_name,
		short_name   : short_name,
		allow_decimal: true
	};

	axios
		.post(`${process.env.REACT_APP_API_URL}units`, postData, {
			headers: headers,
		})
		.then((res) => {
			let data               = res.data;
			responseData.message   = data.message;
			responseData.status    = data.status;
			responseData.isLoading = false;
			showToast('success', data.message);

			dispatch({
				type   : Types.ADD_UNITS,
				payload: responseData
			});
		})
		.catch((err) => {
			const { response } = err;
			const { request, ...errorObject } = response;
			showToast('error', errorObject.data.message);

			responseData.isLoading  = false;
			responseData.message = errorObject.data.message;
			dispatch({
				type: Types.ADD_UNITS,
				payload: responseData
			});
		});
};

export const emptyUnitMessages = () => (dispatch) => {
	dispatch({
		type: Types.EMPTY_UNITS_MESSAGES,
		payload: true
	});
};

export const UpdateUnits = (unitData) => (dispatch) => {
	let responseData = {
		status: false,
		message: "",
		isLoading: true,
	};
	dispatch({
		type: Types.UPDATE_UNITS,
		payload: responseData
	});

	const {
		actual_name,
		short_name,
	} = unitData;

	let updateData = {
		actual_name: actual_name,
		short_name: short_name,
		allow_decimal: true
	};

	axios
		.put(`${process.env.REACT_APP_API_URL}units/${unitData.id}`, updateData)
		.then(res => {
			responseData.message = res.data.message;
			responseData.isLoading = false;
			responseData.status = res.data.status;
			showToast('success', res.data.message);

			dispatch({
				type: Types.UPDATE_UNITS,
				payload: responseData
			});
		})
		.catch((err) => {
			const { response } = err;
			const { request, ...errorObject } = response;
			showToast('error', errorObject.data.message);

			responseData.message = errorObject.data.message;
			responseData.isLoading = false;
			dispatch({
				type: Types.UPDATE_UNITS,
				payload: responseData
			});
		});
};

export const DeleteUnits = (intID) => (dispatch) => {
	let responseData = {
		data: {},
		status: false,
		message: "",
	};

	axios.delete(`${process.env.REACT_APP_API_URL}units/${intID}`)
		.then((res) => {
			responseData.status = res.data.status;
			responseData.message = res.data.message;
			showToast('success', res.data.message);

			dispatch({
				type: Types.DELETE_UNITS,
				payload: responseData
			});
			dispatch(GetUnitsList());
		})
		.catch(err => {
			const { response } = err;
			const { request, ...errorObject } = response;
			showToast('error', errorObject.data.message);
		});
};