import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import AttributeFilter from '../components/list/AttributeFilter';
import AttributesList from '../components/list/AttributeList';

const AttributeListContainer = () => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <div className="container-fluid">
        <div className="card card-custom gutter-b">
          <div className="card-header mt-2">
            <h3 className="card-label">Attributes list</h3>
            <PermissionWiseDisplay permission_name="Attribute.View" display={false}>
              <AttributeFilter currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </PermissionWiseDisplay>
            <PermissionWiseDisplay permission_name="Attribute.Create" display={false}>
              <div className="card-toolbar">
                <Link to="/attribute/create">
                  <button type="button" className="btn btn-primary">
                    <i className="fa fa-plus"></i> Add Attribute
                  </button>
                </Link>
              </div>
            </PermissionWiseDisplay>
          </div>
          <AttributesList currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
    </>
  );
};

export default AttributeListContainer;
