import * as Types from "../types/Types";
import axios from "axios";
import { showToast } from "../../../master/utils/ToastHelper";

export const GetCustomerList = (pageNo = 1) => (dispatch) => {
	let response = {
		customerList: [],
		isLoading: true,
	};

	dispatch({ type: Types.GET_CUSTOMER_LIST, payload: response });

	axios
		.get(`${process.env.REACT_APP_API_URL}customer?isPaginated=1&paginateNo=20&page=${pageNo}`)
		.then((res) => {
			const { data } = res.data;
			response.customerList = data.data;
			response.customerPaginatedData = data;
			response.isLoading = false;
			dispatch({ type: Types.GET_CUSTOMER_LIST, payload: response });
		})
		.catch(err => console.log('getCustomerList error => ', err))
};

export const getReviewList = (page = 1) => (dispatch) => {
	const responseList = {
		status: false,
		isLoading: true,
		reviewList: []
	}
	dispatch({ type: Types.GET_REVIEW_LIST, payload: responseList });
	axios.get(`${process.env.REACT_APP_API_URL}item-reviews?isPaginated=1&paginateNo=20&page=${page}`)
		.then((res) => {
			responseList.status = res.data.status;
			responseList.reviewList = res.data.data.data;
			responseList.reviewListPaginated = res.data.data;
			responseList.isLoading = false;
			dispatch({ type: Types.GET_REVIEW_LIST, payload: responseList });
		})
		.catch(err => console.log('getReviewList err => ', err))
}

export const deleteCustomerReview = (reviewID) => async (dispatch) => {
	let responseData = {
		data: {},
		status: false,
		message: "",
	};
	const deleteReview = {
		"id": reviewID
	}
	axios.delete(`${process.env.REACT_APP_API_URL}item-review/delete`, {
		data: deleteReview,
	})
		.then((res) => {
			responseData.status = res.data.status;
			responseData.message = res.data.message;
			showToast('success', responseData.message)
			dispatch({ type: Types.DELETE_CUSTOMER_REVIEW, payload: responseData });
			dispatch(getReviewList())
		});
};

export const updateRevieStatus = (reviewID) => async (dispatch) => {
	let responseData = {
		data: {},
		status: false,
		message: "",
		updating: true,
	};
	dispatch({ type: Types.UPDATED_REVIEW_STATUS, payload: responseData });
	const updatedID = {
		"id": reviewID
	}
	axios.put(`${process.env.REACT_APP_API_URL}item-review/update-status`, updatedID)
		.then((res) => {
			if (res.data.status) {
				responseData.status = res.data.status;
				responseData.message = res.data.message;
				responseData.updating = false;
				showToast('success', responseData.message)
				dispatch({ type: Types.UPDATED_REVIEW_STATUS, payload: responseData });
				dispatch(getReviewList())
			}
		}).catch((error) => {
			const responseLog = error.response;
			responseData.updating = false;
			if (typeof responseLog !== 'undefined') {
				const { request, ...errorObject } = responseLog;
				showToast('error', responseLog.data.message);
				dispatch({ type: Types.UPDATED_REVIEW_STATUS, payload: responseData })
			}
		})
};