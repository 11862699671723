import * as Types from "../Types/Types";
import Axios from "axios";

export const getAttributeList = (id) => async (dispatch) => {
  Axios.get(`${process.env.REACT_APP_API_URL}attributes/category/${id}`)
    .then((res) => {
      let data = res.data;
      dispatch({ type: Types.GET_ATTRIBUTE_OPTION_LIST, payload: data });
    });
};
