import React from 'react';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import ProductOfferList from '../components/list/ProductOfferList';

const ProductOfferListContainer = () => {
  return (
      <PermissionWiseDisplay permission_name="Offer.List" display={true}>
        <ProductOfferList />
      </PermissionWiseDisplay>
  );
};

export default ProductOfferListContainer;
