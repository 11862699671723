import React from "react";
import BrandsEdit from "../components/BrandsEdit";


const BrandsEditContainer = (props) => {
    return (
        <>
            <BrandsEdit props={props} />
        </>
    );
};

export default BrandsEditContainer;
