import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getBrandDetails } from "../_redux/actions/SliderAction";
import moment from "moment"
const BrandDetails = ({ handleClose, id }) => {
    const BrandDetails = useSelector((state) => state.brands.brandDetails);
    const dispatch = useDispatch();
    useEffect(() => {
        // dispatch(getBrandDetails(id))
    }, []);

    return (
        <>
            {
                BrandDetails !== null && (
                    <Row>
                        <Col lg={3} md={4} sm={4} xs={6} className="mb-5">
                            <p>Brand Name</p>
                            <h5>{BrandDetails.name !== null && BrandDetails.name !== "" ? BrandDetails.name : "---"}</h5>
                        </Col>
                        <Col lg={3} md={4} sm={4} xs={6} className="mb-5">
                            <p>Description</p>
                            <h5>{BrandDetails.description !== null && BrandDetails.description !== "" ? BrandDetails.description : "---"}</h5>                </Col>
                        <Col lg={3} md={4} sm={4} xs={6} className="mb-5">
                            <p>Business Name</p>
                            <h5>{BrandDetails.business.name !== null && BrandDetails.business.name !== "" ? BrandDetails.business.name : "---"}</h5>
                        </Col>
                        <Col lg={3} md={4} sm={4} xs={6} className="mb-5">
                            <p>Business ID</p>
                            <h5>{BrandDetails.business.id !== null && BrandDetails.business.id !== "" ? BrandDetails.business.id : "---"}</h5>
                        </Col>

                        <Col lg={3} md={4} sm={4} xs={6} className="mb-5">
                            <p>Brand Product Added Date</p>
                            <h5>{BrandDetails.created_at !== null && BrandDetails.created_at !== "" ? moment(BrandDetails.created_at).format("DD-MM-YYYY") : "---"}</h5>
                        </Col>

                        <Col lg={3} md={4} sm={4} xs={6} className="mb-5">
                            <p>Product Banner</p>
                            <img className="img-fluid img-thumbnail" src={BrandDetails.banner_url !== null && BrandDetails.banner_url !== "" ? BrandDetails.banner_url : ""} alt="brand banner" />
                        </Col>
                        <Col lg={3} md={4} sm={4} xs={6} className="mb-5">
                            <p>Brand Image</p>
                            <img className="img-fluid img-thumbnail" src={BrandDetails.image_url !== null && BrandDetails.image_url !== "" ? BrandDetails.image_url : ""} alt="brand banner" />
                        </Col>
                    </Row>
                )
            }
            <Button variant="secondary" className="float-right" onClick={handleClose}>Close</Button>
        </>
    );
};

export default BrandDetails;
