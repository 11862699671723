import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../master/utils/ToastHelper';
import { postBulkProducts, updateUploadProgress } from '../_redux/actions/ItemAction';
// import { saveAs } from 'file-saver';
import bulkUploadHints from '../files/bulk-upload-hints.png';
import bulkUploadDemo from '../files/bulk-upload-demo.csv';
import { checkFeaturePermission } from '../../../app/modules/Auth/_redux/menu-permission/module-permission';
import { getFileType, getFileName, getFileSize } from '../../../services/FileHelper';

function BulkItemAdd() {
    const [selectedFile, setSelectedFile] = useState(undefined);
    const { uploadProgress } = useSelector((state) => state.item);
    const dispatch = useDispatch();

    const [showProgressBar, setShowProgressBar] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const onChangeHandler = (e) => {
        const selected_file = e.target.files[0];

        if(selected_file) {
            setSelectedFile(selected_file)
        }
        dispatch(updateUploadProgress(0));
        setIsFileUploaded(false);
        setShowProgressBar(false);
    }

    const onFileUpload = (e) => {
        e.preventDefault();

        if(!selectedFile) {
            showToast('error', 'Please select a file before upload');
            return;
        }

        if(selectedFile && getFileType(selectedFile.name) !== 'csv') {
            showToast('error', 'Please choose only CSV file');
            return;
        }

        if(isFileUploaded) {
            showToast('error', 'Already uploaded');
            return;
        };
        setIsFileUploaded(true);
        setShowProgressBar(true);

        dispatch(postBulkProducts(selectedFile));
    };

    useEffect(() => {
        return () => {
            setShowProgressBar(false);
            setIsFileUploaded(false);
        }
    }, [])

    // @todo remove it if no use.
    // const defaultCSVFormatDownloadHandler = () => {
    //     var CSV = ['"product name","business","category level-1","category level-2","category level-3","brand","per unit value","unit of measurement","item type","status","sku","tax type","tax","enable stock: yes","current stock","stock alert quantity","selling price","enable offer: no","featured image","product images","short resolution image","description"'].join('\n');

    //    const blob = new Blob([CSV], {type: "text/csv;charset=utf-8"});
    //    saveAs(blob, "bulk-products-format.csv");
    // }

    return (
        <div className="container">
          <div className="card card-custom gutter-b card-top-border">
            <div className="card-body">
              <div className="py-5">
                <h1 className="py-4">Bulk products upload</h1>
                <ol className="px-7">
                  <li className="mb-2">Download the format file and fill it with proper data.</li>
                  <li className="mb-2">You can download the example file to understand how the data must be filled.</li>
                  <li className="mb-2">Once you have downloaded and filled the format file, upload it in the form below and submit.</li>
                  <li className="mb-2">After uploading foods you need to edit them and set image and variations.</li>
                  <li className="mb-2">You can get restaurant id from their list, please input the right ids.</li>
                  <li className="mb-2">You can upload your product images in product folder from gallery, and copy image's path.</li>
                </ol>

                <div className="my-8">
                  <div className="card mt-5">
                      <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
                            <div>
                                <h3 className="text-uppercase">Upload products</h3>
                            </div>
                            <div>
                                <a href={bulkUploadDemo} target="_blank" className="btn btn-primary text-white">
                                    <i className="fa fa-download"></i> Download Demo CSV File
                                </a>
                            </div>
                      </div>

                      {
                          ! checkFeaturePermission('admin_seller,product.bulk_upload') ?
                            <div className="p-3">
                                You don't have permission to upload bulk products.
                                Only Admin Permitted Users can upload bulk products.
                            </div>
                            :
                            <form
                                method="post"
                                onSubmit={onFileUpload}
                                encType="multipart/form-data"
                            >
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12">
                                            <div className="mb-3" >
                                                Filename:
                                                <strong>{` ${selectedFile ? getFileName(selectedFile.name) : ' Not selected'}`}</strong>
                                            </div>

                                            <div className="mb-3" >
                                                Filetype:
                                                <strong>{` ${selectedFile ? getFileType(selectedFile.name) : ' Not selected'}`}</strong>
                                            </div>

                                            <div className="mb-3" >
                                                File size:
                                                <strong>{` ${selectedFile ? getFileSize(selectedFile.size) : ' Not selected'}`}</strong>
                                            </div>
                                            <div>
                                                <div>
                                                    <input type="file" onChange={onChangeHandler} accept=".csv" />
                                                </div>
                                                <div className="py-3">
                                                    {
                                                        showProgressBar && (
                                                            <div className="progress">
                                                                <div className="progress-bar" role="progressbar" style={{width: `${uploadProgress}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{uploadProgress}%</div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <button type="submit" className="btn btn-primary">
                                                    <i className="fa fa-upload"></i> Upload
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-sm-12">
                                            <div className="mb-3">
                                                <h3>Bulk Upload File Instruction:</h3>
                                                <div>
                                                    <ul>
                                                        <li><span style={{ backgroundColor: '#f9bc03' }}>Yellow Mark</span> Columns are <span className="text-danger">required</span>.</li>
                                                        <li>Other's are optional. See also default column value if needs.</li>
                                                    </ul>
                                                </div>
                                                <img src={bulkUploadHints} className="img img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                      }
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default BulkItemAdd
