import React from 'react';
import { Route } from 'react-router-dom';
import CouponAddContainer from '../views/CouponAddContainer';
import CouponListContainer from '../views/CouponListContainer';
import CouponEditContainer from '../views/OrderEditContainer';

const CouponRoute = [
  {
    path: '/coupon/create',
    name: 'Coupon Create',
    component: CouponAddContainer,
    exact: true,
  },
  {
    path: '/coupon/list',
    name: 'Coupon List',
    component: CouponListContainer,
    exact: true,
  },
  {
    path: '/coupon/edit/:id',
    name: 'Coupon Edit',
    component: CouponEditContainer,
    exact: true,
  }
];

function CouponRoutes() {
  {
    return CouponRoute.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default CouponRoutes;
