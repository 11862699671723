import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import SimpleEditor from '../../master/components/text-editor/SimpleEditor';
import Select from 'react-select';
import { RHFInput } from 'react-hook-form-input';
import TextHelp from '../../master/components/labels/TextHelp';

import { AddBrands, handleChangeBrands, deleteBrandsImage, emptyDispatch } from '../_redux/actions/BrandsAction';
import { GetCategoriesSortedList } from '../../category/_redux/actions/CategoriesAction';
import { getSelect2OptionForStatus, getSelectedOption } from '../../../services/FormatData';

const BrandsAdd = withRouter(({ history }) => {
	const { register, handleSubmit, setValue } = useForm();
	const dispatch = useDispatch();

	const { isLoading, formInput, addStatus, addMessage } = useSelector((state) => state.brands);
	const categoriesSortedList = useSelector((state) => state.categories.categoriesSortedList);

	const handleChangeTextInput = (name, value, e = null) => {
		dispatch(handleChangeBrands(name, value, e));
	};

	useEffect(() => {
		dispatch(GetCategoriesSortedList());
	}, []);

	const onSubmit = async (e) => {
		dispatch(AddBrands(formInput));
	};

	useEffect(() => {
		if (addStatus && addMessage !== null && addMessage.length > 0) {
			dispatch(emptyDispatch());
			history.push('/brands/list');
		}
	}, [addStatus, addMessage]);

	return (
		<>
			<div className="container-fluid">
				<div className="card card-custom gutter-b">
					<div className="card-header">
						<div className="card-title">
							<h3 className="card-label">New Brand</h3>
						</div>
						<div className="card-toolbar">
							<a onClick={() => { history.push("/brands/list") }} >
								<button type="button" className="btn btn-primary">
									<i className="fa fa-arrow-left"></i> Brands
								</button>
							</a>
						</div>
					</div>
					<div className="card-body">
						<form
							className="form form-label-right"
							onSubmit={handleSubmit(onSubmit)}
							method="post"
							autoComplete="off"
							encType="multipart/form-data"
						>
							<div className="form-group row">
								<div className="col-lg-6">
									<div>
										<label className="form-label mt-2">Brand Name</label>
										<Form.Control
											type="text"
											placeholder="Enter Brands name"
											name="name"
											className="fromStyle"
											value={formInput.name}
											onChange={(e) => handleChangeTextInput('name', e.target.value)}
											ref={register({
												required: false,
												maxLength: 100,
											})}
										/>
									</div>
									<div>
										<label className="form-label mt-2">Brand Details</label>
										<SimpleEditor
											name="description"
											value={formInput.description}
											onValueChange={(e) => handleChangeTextInput('description', e)}
										/>
									</div>
									<div>
										<label className="form-label mt-2">Brand Related Categories</label>
										<RHFInput
											as={<Select options={categoriesSortedList} isMulti />}
											rules={{ required: false }}
											name="categories"
											register={register}
											value={formInput.categories}
											setValue={setValue}
											onChange={(option) => handleChangeTextInput("categories", option)}
										/>
										<TextHelp text="Select Multiple categories to use this in product detail page !" />
									</div>
									<div>
										<label htmlFor={`status`} className="form-label">Status </label>
										<RHFInput
											id={`status`}
											as={<Select options={getSelect2OptionForStatus()} className="form-control" />}
											className="register_rhf_input"
											placeholder="Select Status"
											name={`status`}
											register={register}
											value={getSelectedOption(getSelect2OptionForStatus(), formInput.status)}
											onChange={(option) => handleChangeTextInput(`status`, option.value)}
											setValue={setValue}
										/>
									</div>
								</div>

								<div className="col-lg-6">
									<div>
										<label className="form-label mt-2">
											Logo Image
											<span className="text-info text-sm">(Optional)</span>
										</label>
										<Form.Control
											type="file"
											placeholder="Enter Logo Image"
											name="image"
											onChange={(e) => handleChangeTextInput('image', e.target.files[0], e)}
											className="fromStyle"
											ref={register({
												required: false,
												maxLength: 100,
											})}
										/>
										{
											formInput.logoPreviewUrl !== null &&
											<div className="imgPreview" title="Remove">
												<i className="fa fa-times text-danger" onClick={() => dispatch(deleteBrandsImage('image'))}></i>
												<img src={formInput.logoPreviewUrl} className="img img-thumbnail" />
											</div>
										}
									</div>

									<div>
										<label className="form-label mt-2">
											Banner Image
											<span className="text-info text-sm">(Optional)</span>
										</label>
										<Form.Control
											type="file"
											placeholder="Enter Banner Image"
											name="banner"
											className="fromStyle"
											onChange={(e) => handleChangeTextInput('banner', e.target.files[0], e)}
											ref={register({
												required: false,
												maxLength: 100,
											})}
										/>
										{
											formInput.bannerPreviewUrl !== null &&
											<div className="imgPreview" title="Remove">
												<i className="fa fa-times text-danger" onClick={() => dispatch(deleteBrandsImage('banner'))}></i>
												<img src={formInput.bannerPreviewUrl} className="img img-thumbnail" />
											</div>
										}
									</div>

								</div>

							</div>

							<div className="form-group row">
								<div className="col-sm-10">
									<a
										onClick={() => {
											history.push('/brands/list');
										}}
									>
										<button type="button" class="btn btn-secondary btn-lg mr-2">
											Back
										</button>
									</a>
									{isLoading &&
										<button type="submit" class="btn btn-primary btn-lg" disabled={true} >
											<span>Add brand</span>
											<span className="ml-3 spinner spinner-white"></span>
										</button>
									}

									{!isLoading &&
										<button type="submit" class="btn btn-primary btn-lg">
											<span>Add brand</span>
										</button>
									}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
});

export default BrandsAdd;
