import React from 'react';
import { Route } from 'react-router-dom';
import PagesAddContainer from '../views/PagesAddContainer';
import PagesEditContainer from '../views/PagesEditContainer';
import PagesListContainer from '../views/PagesListContainer';

const pageRoutes = [
  {
    path: "/page/list",
    name: "Page List",
    component: PagesListContainer,
    exact: true,
  },
  {
    path: "/page/add",
    name: "Page Add",
    component: PagesAddContainer,
    exact: true,
  },
  {
    path: "/page/edit/:id",
    name: "Page Edit",
    component: PagesEditContainer,
    exact: true,
  },
];

function getPageModuleRoutes() {
  {
    return pageRoutes.map((route, index) => (
      <Route
        key       = {index}
        path      = {route.path}
        component = {route.component}
        exact     = {route.exact}
      />
    ));
  }
}
export default getPageModuleRoutes;
