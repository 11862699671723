import React from 'react';
import { Route } from 'react-router-dom';
import CategoriesListContainer from '../views/categories/CategoriesListContainer';
import CurrenciesListContainer from '../views/currencies/CurrenciesListContainer';
import LocationsListContainer from '../views/locations/LocationsListContainer';
import CategoriesAddContainer from '../views/categories/CategoriesAddContainer';
import TaxAddContainer from '../views/tax/TaxAddContainer';
import TaxListContainer from '../views/tax/TaxListContainer';
import LocationEditContainer from '../views/locations/LocationEditContainer';
import CurrenciesEditContainer from '../views/currencies/CurrenciesEditContainer';
import CurrenciesAddContainer from '../views/currencies/CurrenciesAddContainer';
import LocationAddContainer from '../views/locations/LocationAddContainer';
import TaxEditContainer from '../views/tax/TaxEditContainer';
import GiftCardsListContainer from '../views/giftCards/GiftCardsListContainer';
import GiftCardsAddContainer from '../views/giftCards/GiftCardsAddContainer';
import GiftCardsEditContainer from '../views/giftCards/GiftCardsEditContainer';
import VoucherListContainer from '../views/Voucher/VoucherListContainer';
import VoucherAddContainer from '../views/Voucher/VoucherAddContainer';
import VoucherEditContainer from '../views/Voucher/VoucherEditContainer';
import PollListContainer from '../views/Poll/PollListContainer';
import PollAddContainer from '../views/Poll/PollAddContainer';
import PollEditContainer from '../views/Poll/PollEditContainer';

const supplerRoute = [
  {
    path: "/location/list",
    name: "Locations List",
    component: LocationsListContainer,
    exact: true,
  },
  {
    path: "/location/add",
    name: "Locations add",
    component: LocationAddContainer,
    exact: true,
  },
  {
    path: "/location/edit",
    name: "location Edit",
    component: LocationEditContainer,
    exact: true,
  },
  {
    path: "/currencies/list",
    name: "Currencies List",
    component: CurrenciesListContainer,
    exact: true,
  },
  {
    path: "/currencies/add",
    name: "currencies add",
    component: CurrenciesAddContainer,
    exact: true,
  },
  {
    path: "/currencies/edit/:id",
    name: "currencies Edit",
    component: CurrenciesEditContainer,
    exact: true,
  },
  {
    path: "/giftCards/list",
    name: "Gift Card List",
    component: GiftCardsListContainer,
    exact: true,
  },
  {
    path: "/giftCards/add",
    name: "Gift Card add",
    component: GiftCardsAddContainer,
    exact: true,
  },
  {
    path: "/giftCards/edit/:id",
    name: "Gift Card Edit",
    component: GiftCardsEditContainer,
    exact: true,
  },
  //voucher =================================================================
  {
    path: "/voucher/list",
    name: "Voucher List",
    component: VoucherListContainer,
    exact: true,
  },
  {
    path: "/voucher/add",
    name: "Voucher add",
    component: VoucherAddContainer,
    exact: true,
  },
  {
    path: "/voucher/edit/:id",
    name: "Voucher Edit",
    component: VoucherEditContainer,
    exact: true,
  },
  {
    path: "/tax/list",
    name: "Tax List",
    component: TaxListContainer,
    exact: true,
  },
  {
    path: "/tax/add",
    name: "Tax Add",
    component: TaxAddContainer,
    exact: true,
  },
  {
    path: '/tax/edit',
    name: 'Tax Edit',
    component: TaxEditContainer,
    exact: true,
  },
  {
    path: '/categories/list',
    name: 'Categories List',
    component: CategoriesListContainer,
    exact: true,
  },
  {
    path: "/categories/add",
    name: "Categories add",
    component: CategoriesAddContainer,
    exact: true,
  },

  //====================Poll========================
  {
    path: "/poll/list",
    name: "Poll list",
    component: PollListContainer,
    exact: true,
  },
  {
    path: "/poll/add",
    name: "Poll Create",
    component: PollAddContainer,
    exact: true,
  },
  {
    path: "/poll/edit/:id",
    name: "Poll Create",
    component: PollEditContainer,
    exact: true,
  }
];

function getSupplierRoute() {
  {
    return supplerRoute.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default getSupplierRoute;
