import React from 'react';
import { Route } from 'react-router-dom';
import WebsiteEditContainer from '../views/WebsiteEditContainer';

const websiteRoute = [
  {
    path: "/website/edit",
    name: "Website Edit",
    component: WebsiteEditContainer,
    exact: true,
  },
  
];

function getWebsiteRoute() {
  {
    return websiteRoute
  .map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default getWebsiteRoute;
