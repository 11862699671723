import React from 'react';
import { Route } from 'react-router-dom';
import SliderAddContainer from '../views/SliderAddContainer';
import SliderEditContainer from '../views/SliderEditContainer';
import SliderListContainer from '../views/SliderListContainer';

const sliderRoutes = [
  {
    path: "/slider/list",
    name: "Slider List",
    component: SliderListContainer,
    exact: true,
  },
  {
    path: "/slider/create",
    name: "Create Slide",
    component: SliderAddContainer,
    exact: true,
  },
  {
    path: "/slider/edit/:id",
    name: "Slide Edit",
    component: SliderEditContainer,
    exact: true,
  },
];

function GetSliderRoutes() {
  {
    return sliderRoutes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default GetSliderRoutes;
