import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPageDetails } from "../_redux/actions/PageAction";
import LoadingSpinner from "../../master/spinner/LoadingSpinner";

const PageDetails = ({ handleClose, id }) => {
    const dispatch = useDispatch();
    const { pageDetails, isLoading } = useSelector((state) => state.PageReducer);

    useEffect(() => {
        dispatch(getPageDetails(id))
    }, []);

    return (
        <div className="p-4">
            {isLoading && <LoadingSpinner text="Loading Page Details..." />}
            {
                pageDetails !== null && (
                    <Row>
                        <Col lg={3} md={4} sm={4} xs={6} className="mb-5">
                            <p>Page Title</p>
                            <h5>{pageDetails.title}</h5>
                        </Col>
                        <Col lg={3} md={4} sm={4} xs={6} className="mb-5">
                            <p>Slug</p>
                            <h5>{pageDetails.slug}</h5>
                        </Col>
                        <Col lg={3} md={4} sm={4} xs={6} className="mb-5">
                            <p>Meta Description</p>
                            <h5>{pageDetails.meta_description !== null && pageDetails.meta_description !== "" ? pageDetails.meta_description : "---"}</h5>                </Col>
                        <Col lg={3} md={4} sm={4} xs={6} className="mb-5">
                            <p>Meta Keywords</p>
                            <h5>{pageDetails.meta_keywords !== null && pageDetails.meta_keywords !== "" ? pageDetails.meta_keywords : "---"}</h5>
                        </Col>
                        <Col lg={8} md={8} sm={10} xs={10} className="mb-5">
                            <p>Description</p>
                            {/* dangerously set inner HTML */}
                            <div dangerouslySetInnerHTML={{ __html: pageDetails.description !== null && pageDetails.description !== "" ? pageDetails.description : "---" }} />
                        </Col>
                        <Col lg={3} md={4} sm={4} xs={6} className="mb-5">
                            <p> Image</p>
                            <img className="img-fluid img-thumbnail" src={pageDetails.imagePreviewUrl !== null ? pageDetails.imagePreviewUrl : ""} alt="brand banner" />
                        </Col>
                    </Row>
                )
            }
        </div>
    );
};

export default PageDetails;
