import React from "react";
import LocationEdit from "../../components/locations/LocationEdit";


const LocationEditContainer = (props) => {
    return (
        <>
            <LocationEdit props={props} />
        </>
    );
};

export default LocationEditContainer;
