import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoicesDetials } from '../../_redux/actions/InvoiceAction';
import './InvoiceDetails.css'
import ReactToPrint from 'react-to-print';
import moment from "moment"

const InvoicesDetails = (props) => {
    const { id } = props;
    const dispatch = useDispatch();
    const InvoicesDetails = useSelector((state) => state.InvoiceReducer.InvoicesDetails)
    // const isLoading = useSelector((state) => state.InvoiceReducer.isLoading)
    const componentRef = useRef();

    useEffect(() => {
        dispatch(getInvoicesDetials(id))
    }, [])
    return (
        <>
            {
                InvoicesDetails !== null && (
                    <>
                        <div className="mb-2">

                            <ReactToPrint
                                trigger={() => <Button variant="success" className="float-right btn-print-fixed">Print</Button>}
                                content={() => componentRef.current}
                            />

                        </div>
                        <div ref={componentRef} className="p-3">
                            <div className="invoice-header mt-2">
                                <div className="row">
                                    <div className="col-4">
                                        <img src={InvoicesDetails.business ? InvoicesDetails.business.logo_url : null} style={{ width: 50 }} />
                                        <h1 className="display-4">INVOICE #{InvoicesDetails.transaction_id}</h1>
                                    </div>
                                    <div className="col-4">
                                        {
                                            (InvoicesDetails.business && InvoicesDetails.location) ?
                                                <>
                                                    <p>{InvoicesDetails.business.name}</p>
                                                    <p>{InvoicesDetails.location.email}</p>
                                                    <p>{InvoicesDetails.location.mobile}</p>
                                                    <p>{InvoicesDetails.location.website}</p>
                                                </> : ''
                                        }

                                    </div>
                                    <div className="col-4">
                                        {
                                            (InvoicesDetails.business && InvoicesDetails.location) ?
                                                <>
                                                    <p>{InvoicesDetails.location.landmark}</p>
                                                    <p> {InvoicesDetails.location.state},  {InvoicesDetails.location.country}</p>
                                                    <p>{InvoicesDetails.location.city} - {InvoicesDetails.location.zip_code}</p>
                                                </> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="invoice-body mt-3 p-3">
                                <div className="row">
                                    <div className="col-3">
                                        <h6>Billed To</h6>
                                        <p>{InvoicesDetails.created_by && InvoicesDetails.created_by.first_name !== null && InvoicesDetails.created_by.last_name !== null ? `${InvoicesDetails.created_by.first_name} ${InvoicesDetails.created_by.last_name}` : ''}</p>
                                        <p>{InvoicesDetails.created_by && InvoicesDetails.created_by.phone_no !== null ? InvoicesDetails.created_by.phone_no : 'N / A'}</p>
                                        <p>{InvoicesDetails.created_by && InvoicesDetails.created_by.email !== null ? InvoicesDetails.created_by.email : 'N / A'}</p>
                                    </div>
                                    <div className="col-3">
                                        <h6>Invoice No</h6>
                                        <p># {InvoicesDetails.invoice_no ? InvoicesDetails.invoice_no : 'N / A'}</p>
                                        <h6>Order Date</h6>
                                        <p>{InvoicesDetails.created_at ? moment(InvoicesDetails.created_at).format("DD-MM-YYYY") : 'N / A'}</p>
                                    </div>
                                    <div className="col-6">
                                        <div className="ammount float-right">
                                            <h6>Invoice Summary</h6>
                                            <p>Total &nbsp; &ensp; &ensp; &ensp; &ensp;: {InvoicesDetails.total_amount ? InvoicesDetails.total_amount : '0'}</p>
                                            <p>Discount &ensp; &ensp;: {InvoicesDetails.total_discount ? InvoicesDetails.total_discount : '0'}</p>
                                            <p>Paid &emsp; &emsp; &emsp;: {InvoicesDetails.paid_total ? InvoicesDetails.paid_total : '0'}</p>
                                            <hr />
                                            <p>Grand Total : {InvoicesDetails.grand_total ? InvoicesDetails.grand_total : '0'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="custome-bottom-border">
                            </div>


                            <div className="react-bootstrap-table table-responsive">
                                <table className="table table table-head-custom table-vertical-center">
                                    <thead className="invoice-table-heading">
                                        <tr>
                                            <th>SL</th>
                                            <th>Item Name</th>
                                            <th>Quantity</th>
                                            <th>Amount</th>
                                            <th>Discount</th>
                                            <th>Grand Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            InvoicesDetails.items && InvoicesDetails.items.length > 0 ? (
                                                <>
                                                    {
                                                        InvoicesDetails.items.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.item != null ? item.item.name : 'N/A'}</td>
                                                                <td>{item.qty}</td>
                                                                <td>{item.amount}</td>
                                                                <td>{item.discount_amount}</td>
                                                                <td>{item.grand_total}</td>
                                                            </tr>
                                                        ))
                                                    }

                                                </>
                                            ) : ''
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
};
export default InvoicesDetails;