import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { GetUnitsList, DeleteUnits } from '../_redux/actions/UnitsAction';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';

const UnitsList = withRouter(({ history }) => {
	const dispatch = useDispatch();

	const { unitsList } = useSelector((state) => state.units);

	useEffect(() => {
		dispatch(GetUnitsList());
	}, []);

	const UnitsDelete = (intID) => {
		dispatch(DeleteUnits(intID));
	}

	return (
		<>
			<div className="container">
				<div className="card card-custom gutter-b">
					<div className="card-header">
						<div className="card-title">
							<h3 class="card-label">Units</h3>
						</div>
						<div className="card-toolbar">
							<a
								onClick={() => {
									history.push('/units/add');
								}}
							>
								<button type="button" class="btn btn-primary">
									<i className="fa fa-plus-circle"></i> Create New Unit
								</button>
							</a>
						</div>
					</div>
					<div className="card-body">
						<div className="react-bootstrap-table table-responsive">
							<table className="table table table-head-custom table-vertical-center">
								<thead>
									<tr>
										<td>SL</td>
										<td>Unit name</td>
										<td>Short Name</td>
									</tr>
								</thead>
								<tbody>
									{unitsList &&
										unitsList.map((item, index) => (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>{item.actual_name}</td>
												<td>{item.short_name}</td>

												<td>
													<a
														className="btn btn-icon btn-light btn-hover-info btn-sm"
														onClick={() => {
															history.push('/units/edit', { units: item });
														}}
													>
														<i className="fa fa-edit"></i>
													</a>
													&nbsp;&nbsp;&nbsp;
													<PermissionWiseDisplay permission_name="Unit.Delete" display={false}>
														<a
															className="btn btn-icon btn-light btn-hover-danger btn-sm"
															onClick={() => {
																if (
																	window.confirm(
																		'Are you sure you wish to delete this item?'
																	)
																)
																	UnitsDelete(item.id);
															}}
														>
															<i className="fa fa-trash"></i>
														</a>
													</PermissionWiseDisplay>
												</td>
											</tr>
										))}
									{unitsList == null && (
										<p className="text-danger text-center">No Data Found</p>
									)}
								</tbody>
								<tfoot></tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
});

export default UnitsList;
