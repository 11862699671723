import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const SimpleEditor = (props) => {
    const {value, onValueChange} = props;

    return (
        <CKEditor
            editor={ ClassicEditor }
            data={value}
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                onValueChange(data);
            } }
        />
     );
}

export default SimpleEditor;