import * as Types from '../types/Types';

const initialState = {
    isLoading: false,
    cardDatas: [],
    loadingCardDatas: true
};

const DashboardReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {

        case Types.GET_DASHBOARD_DATA:
            return {
                ...state,
                cardDatas: action.payload,
                loadingCardDatas: false,
            }
        default:
            break;
    }
    return newState;
};

export default DashboardReducer;
