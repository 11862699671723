import axios from "axios";
import * as Type from '../types/Type';

/**
 * Get Payment methods
 * 
 * @return void - Dispatch event
 */
export const getPaymentMethodAction = () => (dispatch) => {
    const URL = `${process.env.REACT_APP_API_URL}payments/methods/get-payment-methods?for_dropdown=true`;

    axios.get(URL)
        .then(res => {
            dispatch({ type: Type.GET_PAYMENT_METHODS, payload: res.data.data });
        });
}