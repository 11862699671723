import React from 'react';
import PermissionWiseDisplay from '../../../../app/modules/role/PermissionWiseDisplay';
import VoucherAdd from '../../components/Voucher/VoucherAdd';

const VoucherAddContainer = () => {
  return (
    <PermissionWiseDisplay permission_name="Voucher.Create" display={true}>
      <VoucherAdd/>
    </PermissionWiseDisplay>
  );
};

export default VoucherAddContainer;
