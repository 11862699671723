import React from 'react';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import SliderList from '../components/SliderList';

const SliderListContainer = () => {
  return (
    <>
      <PermissionWiseDisplay permission_name="Slider.List" display={true} >
          <SliderList />
      </PermissionWiseDisplay>
    </>
  );
};

export default SliderListContainer;
