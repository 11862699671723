import * as Types from '../types/Types';

const initialState = {
  currencyData: {
    country: "",
    countryName: "",
    currency: "",
    code: "",
    symbol: "",
    thousand_separator: "",
    decimal_separator: "",
  },
  currencyList: [],
  currencyPaginatedList: [],
  isLoading: false,
  currencyDetails: null,

};

const CurrenciesReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.CURRENCY_INPUT_CHANGE:
      const currencyData = { ...state.currencyData };
      currencyData[action.payload.name] = action.payload.value;
      return {
        ...state,
        currencyData,
      };
    case Types.STORE_CURRENCIES:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        currencyData: initialState.currencyData

      };

    case Types.GET_CURRENCIES_LIST:
      return {
        ...state,
        currencyList: action.payload.currencyList,
        currencyPaginatedList: action.payload.currencyPaginatedList,
        isLoading: action.payload.isLoading,
      };
    case Types.DELETE_CURRENCY:
      return {
        ...state,
        isLoading: action.payload,
      };

    //get currency deetails 
    case Types.GET_CURRENCY_DETAILS:
      if (action.payload.status) {
        return {
          ...state,
          currencyData: action.payload.currencyDetails,
          currencyDetails: action.payload.currencyDetails,
        };
      } else {
        return {
          ...state,
        };
      }
    case Types.UPDATE_CURRENCIES:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case Types.EMPTY_DISPATCH:
      return {
        ...state,
        currencyData: initialState.currencyData,
      };

    default:
      break;
  }
  return newState;
};

export default CurrenciesReducer;
