import axios from "axios";
import { showToast } from "../../../../modules/master/utils/ToastHelper";
import * as Types from "../_redux/authTypes";
export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export const login = (mail, password) => async (dispatch) => {
  let loginResponse = {
    status: false,
    message: "",
    isLoading: true,
    tokenData: "",
    data: null,
  };
  dispatch({ type: Types.AUTH_LOGIN_CHECK, payload: loginResponse });

  try {
    let postData = {
      email: mail,
      password: password,
      login_source: "seller",
    };
    await axios
      .post(`${process.env.REACT_APP_API_URL}auth/login`, postData, {})
      .then((res) => {
        const { data, message, status } = res.data;
        const { user, access_token, role_permissions, menus } = data;

        localStorage.setItem(
          "role-permissions",
          JSON.stringify(role_permissions)
        );
        localStorage.setItem("menus", JSON.stringify(menus));

        loginResponse.data = user;
        loginResponse.tokenData = access_token;
        loginResponse.message = message;
        loginResponse.status = status;
        showToast("success", message);
      })
      .catch((err) => {
        const { response } = err;
        const { request, ...errorObject } = response;
        loginResponse.message = errorObject.data.message;
        showToast("error", loginResponse.message);
      });
  } catch (error) {
    loginResponse.message = "Something went wrong, Please try again !";
  }

  loginResponse.isLoading = false;
  dispatch({ type: Types.AUTH_LOGIN_CHECK, payload: loginResponse });

  // return axios.post(LOGIN_URL, { email, password });
};

export const authEmptyMessage = () => async (dispatch) => {
  dispatch({ type: Types.EMPTY_AUTH_MESSAGE, payload: true });
};

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function getEmployeData() {
  // Authorization head should be fulfilled in interceptor.
  let userData = localStorage.getItem("userData");
  let employeeData = JSON.parse(userData);
  return employeeData;
}

export function getUserID() {
  let data = getEmployeData();
  if (typeof data !== "undefined" && data != null) return data.id;
}

export function getBusinessData() {
  let data = getEmployeData();

  if (typeof data !== "undefined" && data != null) return data.business;
}

export function getBusinessUrl() {
  const business = getBusinessData();

  if (typeof business !== "undefined" && business != null) {
    return `${process.env.REACT_APP_FRONTEND_WEBSITE}store/${business.slug}`;
  } else {
    return process.env.REACT_APP_FRONTEND_WEBSITE;
  }
}
