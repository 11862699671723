import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Form, Card, Button, Row, Col } from "react-bootstrap";
import "./style.css";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { getCouponList, handleCouponDelete } from "../../_redux/actions/CouponAction";
import { getDiscountTypeList } from "../../../master/OptionData/DiscountType/DiscountTypeAction/DiscountTypeAction";
import { capitalize } from "lodash";
import SimpleTooltip from "../../../master/components/tooltip/SimpleTooltip";
import PaginationLaravel from "../../../master/pagination/PaginationLaravel";
import LoadingSpinner from "../../../master/spinner/LoadingSpinner";
import { confirmAlert } from "react-confirm-alert";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";

const CouponList = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [expireInDays, setExpireInDays] = useState(30);

  const {couponList, isLoading, couponPaginationList} = useSelector((state) => state.CouponReducer);
  const dicountTypeList = useSelector((state) => state.DiscountTypeReducer.dicountTypeList);

  useEffect(() => {
    dispatch(getCouponList(currentPage));
    dispatch(getDiscountTypeList());
  }, [dispatch, currentPage]);

  const changePage = (data) => {
    setCurrentPage(data.page);
    dispatch(getCouponList(data.page));
  };

  const filterByCoupon = (couponType) => {
    dispatch(getCouponList(currentPage, searchText, couponType, null));
  };
  const filterByAmountType = (amountType) => {
    dispatch(getCouponList(currentPage, searchText, null, amountType));
  };

  const searchItems = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText.length === 0) {
      dispatch(getCouponList(currentPage));
    } else {
      dispatch(getCouponList(currentPage, searchText));
    }
  };
  // delete products list 
  const confirmDelete = (id) => {
    dispatch(handleCouponDelete(id));
  }
  const handleDeleteCoupon = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No"
        }
      ]
    });
  };

  const [CertificateDetailShow, setCertificateDetailShow] = useState(false)
  const [CertificateID, setCertificateID] = useState(null)
  const certificateDetails = (certificate) => {
    setCertificateID(certificate.intCertificateDetailsID);
    setCertificateDetailShow(true)
  }
  const amountType = [
    { label: 'Percentage', value: 'percentage' },
    { label: 'Numeric', value: 'numeric' }
  ]
  return (
    <>
      <Card>
        <Card.Body>
          <div className="row">
            <h1 className="tableheading">Coupon List</h1>

            <Form.Group as={Col} controlId="formGridState">
              <input
                type="search"
                className="form-control product-search-input formHeight"
                placeholder="Search by coupon code"
                value={searchText}
                onChange={searchItems}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridState">
              <RHFInput
                as={<Select options={dicountTypeList} />}
                rules={{ required: true }}
                name="coupon_type_id"
                placeholder="Filter by discount type"
                register={register}
                setValue={setValue}
                onChange={(option) => (
                  filterByCoupon(option.value)
                )}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <RHFInput
                as={<Select options={amountType} />}
                rules={{ required: true }}
                placeholder="Filter by amount type"
                name="amount_type_id"
                register={register}
                onChange={(option) => (
                  filterByAmountType(option.value)
                )}
                setValue={setValue}
              />
            </Form.Group>

            <i className="fas fa-filter tableFilter mt-1 mr-2"></i>
            <i className="far fa-filter"></i>
            <Link to="/coupon/create" className="btn btn-primary text-center text-white btn-sm custome-addnew-btn certificate-add-btn" >
              Add New
            </Link>
          </div>
          {isLoading && <LoadingSpinner text="Loading Coupon List....." />}
          {!isLoading && couponList.length > 0 && (
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <th className="td-sl">#</th>
                    <th scope="col">Coupon Code</th>
                    <th scope="col">Coupon Type</th>
                    <th scope="col">Amount Type</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Min Amount</th>
                    <th scope="col">Max Amount</th>
                    <th scope="col">Usage Count</th>
                    <th scope="col">Usage Limit</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Expiry Date</th>
                    <th scope="col">Per-user Limit</th>
                    <th scope="col">Free Shipping</th>
                    <th scope="col">Indivitual Use</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    couponList && couponList.length > 0 && couponList.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <span className="font-weight-bold">{couponPaginationList.from + index}</span>
                        </td>
                        <td className="text-center">
                          <img src={item.image_url} width={100}/>
                          <br/>
                          <span className="font-weight-bold">{item.code !== null && item.code !== '' ? item.code : "N / A"}</span>
                        </td>
                        <td>{item.coupon_type && item.coupon_type.name !== null && item.coupon_type.name !== '' ? item.coupon_type.name : "N / A"}</td>
                        <td>{item.amount_type !== null && item.amount_type !== '' ? capitalize(item.amount_type) : "N / A"}</td>
                        <td>{item.coupon_amount !== null && item.coupon_amount !== '' ? item.coupon_amount : "N / A"}</td>
                        <td>{item.min_amount !== null && item.min_amount !== '' ? item.min_amount : "N / A"}</td>
                        <td>{item.max_amount !== null && item.max_amount !== '' ? item.max_amount : "N / A"}</td>
                        <td>{item.usage_count !== null && item.usage_count !== '' ? item.usage_count : "N / A"}</td>
                        <td>{item.usage_limit !== null && item.usage_limit !== '' ? item.usage_limit : "N / A"}</td>
                        <td>{item.coupon_start_date !== null && item.coupon_start_date !== '' ? item.coupon_start_date : "N / A"}</td>
                        <td>{item.coupon_expiry_date !== null && item.coupon_expiry_date !== '' ? item.coupon_expiry_date : "N / A"}</td>
                        <td>{item.usage_limit_per_user !== null && item.usage_limit_per_user !== '' ? item.usage_limit_per_user : "N / A"}</td>
                        <td>{item.is_free_shiping = "0" ? false : true}</td>
                        <td>{item.is_individual_use = "0" ? false : true}</td>
                        <td className="d-flex">
                          <SimpleTooltip title="View Coupon">
                            <Link className="btn btn-icon btn-light btn-hover-info btn-sm"
                            //  onClick={() => handleProductDetailsModel(item)}
                            >
                              <i className="fa fa-eye"></i>
                            </Link>
                          </SimpleTooltip>
                            &nbsp;&nbsp;
                          <PermissionWiseDisplay permission_name="Coupon.Edit" display={false}>
                            <SimpleTooltip title={`Edit ${item.code}`}>
                              <Link to={`/coupon/edit/${item.id}`} className="btn btn-icon btn-light btn-hover-info btn-sm" >
                                <i className="fa fa-edit"></i>
                              </Link>
                            </SimpleTooltip>
                          </PermissionWiseDisplay>
                            &nbsp;&nbsp;
                            <PermissionWiseDisplay permission_name="Coupon.Delete" display={false} >
                              <SimpleTooltip title={`Delete ${item.code}`}>
                                <a className="btn btn-icon btn-light btn-hover-danger btn-sm" onClick={(id) => handleDeleteCoupon(item.id)} >
                                  <i className="fa fa-trash text-danger"></i>
                                </a>
                              </SimpleTooltip>
                            </PermissionWiseDisplay>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          )}
          {!isLoading && couponList.length === 0 && (
            <div className="alert alert-warning mt-5">
              Sorry ! No Coupon List Found.
            </div>
          )}
          <PaginationLaravel
            changePage={changePage}
            data={couponPaginationList}
          />
        </Card.Body>
      </Card>

      {/* <SimpleModal
        size="xl"
        show={CertificateDetailShow}
        handleClose={() => setCertificateDetailShow(false)}
        handleShow={() => setCertificateDetailShow(true)}
        modalTitle={"Certificate Details"}
      >
        <CertificateDetails handleClose={() => setCertificateDetailShow(false)} CertificateID={CertificateID} />
      </SimpleModal> */}
    </>
  );
};

export default CouponList;
