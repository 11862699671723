import React from 'react';
import { Route } from 'react-router-dom';
import OrderLifeCycle from '../components/OrderLifeCycle/OrderLifeCycle';
import OrderListContainer from '../views/OrderListContainer';
import OrderViewContainer from '../views/OrderViewContainer';
import PrintInvoice from '../views/PrintInvoice/PrintInvoice';

const orderRoutes = [
  {
    path: '/orders',
    name: 'Orders List',
    component: OrderListContainer,
    exact: true,
  },
  {
    path: '/transaction/view/:id',
    name: 'transaction List',
    component: OrderViewContainer,
    exact: true,
  },
  {
    path: '/orders/lifeCycle/:id',
    name: 'Orders Life Cycle',
    component: OrderLifeCycle,
    exact: true,
  },
  {
    path: '/orders/invoice/print/:id',
    name: 'Order Invoice print',
    component: PrintInvoice,
    exact: true,
  },
];

function getOrderModuleRoutes() {
  {
    return orderRoutes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default getOrderModuleRoutes;
