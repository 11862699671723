import React from "react";
import PermissionWiseDisplay from "../../../app/modules/role/PermissionWiseDisplay";
import SlideEdit from "../components/SliderEdit";


const SliderEditContainer = (props) => {
    return (
        <>
            <PermissionWiseDisplay permission_name="Slider.Edit" display={true}>
                <SlideEdit props={props} />
            </PermissionWiseDisplay>
        </>
    );
};

export default SliderEditContainer;
