import * as Types from "../types/Types";
import axios from "axios";
import { showToast } from "../../../master/utils/ToastHelper";
import moment from "moment"
// get invoices list
export const getInvoicesList = (page, searchText = null,) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };
    dispatch({ type: Types.GET_INVOICES_LIST, payload: responseList });
    let InvoicesURL = "";
    InvoicesURL = `${process.env.REACT_APP_API_URL}invoices?isPaginated=1&paginateNo=10`;

    // InvoicesURL = `${process.env.REACT_APP_API_URL}invoices?isPaginated=1&paginateNo=1`;
    if (page !== null || page === "") {
        InvoicesURL += `&page=${page}`;
    }
    if (searchText !== null) {
        InvoicesURL += `&search=${searchText}`;
    } else {
        // url += `&certificate/details?search=${searchText}`
    }
    try {
        await axios.get(InvoicesURL)
            .then((res) => {
                const { data, message, status } = res.data;
                responseList.status = status;
                responseList.InvoicesList = data.data;
                responseList.message = message;
                responseList.InvoicesPaginatedList = data;
                responseList.isLoading = false;
                if (res.data.status) {
                    dispatch({ type: Types.GET_INVOICES_LIST, payload: responseList });
                }
            }).catch((err) => {
            });
    } catch (error) {
        responseList.isLoading = false;
        responseList.message = "Something Went Wrong !";
        showToast('error', responseList.message);
        dispatch({ type: Types.GET_INVOICES_LIST, payload: responseList });
    }
    responseList.isLoading = false;
    dispatch({ type: Types.GET_INVOICES_LIST, payload: responseList });
};

//get invoice details 
export const getInvoicesDetials = (id) => (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };
    dispatch({ type: Types.GET_INVOICES_DETAILS, payload: responseList });
    axios.get(`${process.env.REACT_APP_API_URL}invoices/${id}`)
        .then((res) => {
            if (res.data.status) {
                const { data, message, status } = res.data;
                responseList.status = status;
                responseList.data = data;
                responseList.message = message;
                responseList.isLoading = false;
                dispatch({ type: Types.GET_INVOICES_DETAILS, payload: responseList });
            }
        }).catch((err) => {
            responseList.isLoading = false;
            dispatch({ type: Types.GET_INVOICES_DETAILS, payload: responseList });
        })
}

// get invoices life cycleData 
export const getInvoicesLifeCycleData = (id) => (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };
    dispatch({ type: Types.GET_INVOICE_LIFECYCLE_DATA, payload: responseList });
    axios.get(`${process.env.REACT_APP_API_URL}sales/invoice-lifecycle/${id}`)
        .then((res) => {
            if (res.data.status) {
                const { data, message, status } = res.data;
                responseList.status = status;
                responseList.data = data;
                responseList.message = message;
                responseList.isLoading = false;
                dispatch({ type: Types.GET_INVOICE_LIFECYCLE_DATA, payload: responseList });
            }
        }).catch((err) => {
            responseList.isLoading = false;
            dispatch({ type: Types.GET_INVOICE_LIFECYCLE_DATA, payload: responseList });
        })
}


//handle change invoice status updated input 
export const handleChangeStatusInput = (name, value) => (dispatch) => {
    const statusData = {
        name: name,
        value: value
    }
    dispatch({ type: Types.CHANGE_STATUS_INPUT_DATA, payload: statusData });
}

//submit invoice data 
export const handleUpdateStatus = (statusInput) => (dispatch => {
    
    // let responseData = {
    //     status: false,
    //     message: "",
    //     isLoading: true,
    // };
    // dispatch({ type: Types.UPDATE_INVOICE_STATUS, payload: responseData });
    // const headers = {
    //     "Content-Type": "application/json",
    // };
    // let updatedData = statusInput;
    // updatedData.product_shift_date = moment(updatedData.product_shift_date).format("YYYY-MM-DD");
    // updatedData.customer_delivery_date = moment(updatedData.customer_delivery_date).format("YYYY-MM-DD");

    // axios.post(`${process.env.REACT_APP_API_URL}invoice_status`, updatedData, {
    //     headers: headers,
    // }).then((res) => {
    //     if (res.data.status) {
    //         let data = res.data;
    //         responseData.message = data.message;
    //         responseData.status = data.status;
    //         responseData.isLoading = false;
    //         showToast('success', responseData.message);
    //         dispatch({ type: Types.UPDATE_INVOICE_STATUS, payload: responseData });
    //     }
    // }).catch((err) => {
    //     const { response } = err;
    //     const { request, ...errorObject } = response;
    //     responseData.isLoading = false;
    //     showToast('error', response.data.message);
    //     dispatch({ type: Types.UPDATE_INVOICE_STATUS, payload: responseData });
    // });
})