import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../master/utils/ToastHelper";

export const handleChangeAttributeInput = (name, value) => (dispatch) => {
    const formData = {
        name: name,
        value: value
    }
    dispatch({ type: Types.CHANGE_ATTRIBUTE_INPUT, payload: formData });
}

export const addMultipleAttribute = (attributeData, attributeValues) => (dispatch) => {
    if (attributeData.name === null || attributeData.name === '') {
        showToast('error', "Attribute name can't be blank!")
        return false;
    }
    if (attributeData.category_id === null || attributeData.category_id === '') {
        showToast('error', "Category can't be blank!")
        return false;
    }
    if (attributeValues.value === null || attributeValues.value === '') {
        showToast('error', "Attribute value can't be blank!")
        return false;
    }
    if (attributeValues.code === null || attributeValues.code === '') {
        showToast('error', "Attribute code can't be blank!")
        return false;
    }
    dispatch({ type: Types.ADD_MULTIPLE_ATTRIBUTE, payload: { attributeData, attributeValues } });
}
//delete multiple attribute 
export const DeleteMultipleAttributes = (index, isEdit = false) => (dispatch) => {
    if (!isEdit) {
        dispatch({ type: Types.DELETE_MULTIPLE_ATTRIBUTE, payload: index });
    } else {
        dispatch({ type: Types.DELETE_MULTIPLE_ATTRIBUTE_EDIT, payload: index });
    }
};
//store attribute data 
export const handleSubmitAttribute = (attributeData) => (dispatch) => {
    let responseData = {
        status: false,
        message: "",
        isLoading: true,
    };
    dispatch({ type: Types.SUBMIT_ATTRIBUTE_DATA, payload: responseData });
    const headers = {
        "Content-Type": "application/json",
    };

    Axios.post(`${process.env.REACT_APP_API_URL}attributes`, attributeData, {
        headers: headers,
    })
        .then((res) => {
            if (res.data.status) {
                let data = res.data;
                responseData.message = data.message;
                responseData.status = data.status;
                responseData.isLoading = false;
                showToast('success', responseData.message);
                dispatch({ type: Types.SUBMIT_ATTRIBUTE_DATA, payload: responseData });
            }
        }).catch((err) => {
            const errorMessage = "Sorry, Something went wrong!"
            responseData.isLoading = false;
            showToast('error', errorMessage);
            dispatch({ type: Types.SUBMIT_ATTRIBUTE_DATA, payload: responseData });
        });

}

export const getAttributeList = (page, searchText = null, category = null) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };
    dispatch({ type: Types.GET_ATTRIBUTE_LIST, payload: responseList });
    let attributeURL = "";
    attributeURL = `${process.env.REACT_APP_API_URL}attributes?isPaginated=1&paginateNo=10`;
    if (page !== null || page === "") {
        attributeURL += `&page=${page}`;
    }
    if (searchText !== null) {
        attributeURL += `&search=${searchText}&isActive=1`;
    } else {
        // url += `&certificate/details?search=${searchText}`
    }
    if (category !== null) {
        attributeURL += `&category_id=${category}`;
    }
    try {
        await Axios.get(attributeURL)
            .then((res) => {
                if (res.data.status) {
                    const { data, message, status } = res.data;
                    responseList.status = status;
                    responseList.attributeList = data.data;
                    responseList.message = message;
                    responseList.attributePaginatedList = data;
                    responseList.isLoading = false;
                    dispatch(emptyDispatch());
                    dispatch({ type: Types.GET_ATTRIBUTE_LIST, payload: responseList });
                }

            }).catch((err) => {
                responseList.isLoading = false;
                dispatch({ type: Types.GET_ATTRIBUTE_LIST, payload: responseList });
            });
    } catch (error) {
        responseList.message = "Something Went Wrong !";
        showToast('error', responseList.message);
    }
    responseList.isLoading = false;
    dispatch({ type: Types.GET_ATTRIBUTE_LIST, payload: responseList });
};

//delete products item
export const habdleDeleteAttribute = (id) => (dispatch) => {
    let isLoading = true;
    dispatch({ type: Types.DELETE_ATTRIBUTE, payload: isLoading })
    Axios.delete(`${process.env.REACT_APP_API_URL}attributes/${id}`)
        .then((res) => {
            if (res.data.status) {
                const data = res.data;
                showToast('success', data.message);
                dispatch({ type: Types.DELETE_ATTRIBUTE, payload: false });
                dispatch(getAttributeList());
            }
        }).catch((err) => {
            const errMessage = "Something went wrong, Please try again"
            showToast('error', errMessage);
            dispatch({ type: Types.DELETE_ATTRIBUTE, payload: false });
            dispatch(getAttributeList());
        })
}

//get single attributes data 
export const getAttributeDetails = (id) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };
    dispatch({ type: Types.GET_ATRIBUTE_DETAILS, payload: responseList });
    await Axios.get(`${process.env.REACT_APP_API_URL}attributes/${id}`)
        .then((res) => {
            if (res.data.status) {
                // let data = res.data;
                let { data, message, status } = res.data;
                if (data.category_id !== null) {
                    data.category = {
                        value: data.category.id,
                        label: data.category.name
                    }
                }
                data.deleted_values = [];
                data.values = data.attribute_values;
                responseList.status = status;
                responseList.data = data;
                responseList.message = message;
                responseList.isLoading = false;
                dispatch({ type: Types.GET_ATRIBUTE_DETAILS, payload: responseList });
            }

        }).catch((err) => {
            responseList.isLoading = false;
            dispatch({ type: Types.GET_ATRIBUTE_DETAILS, payload: responseList });
        });

};

export const UpdatedAttribute = (attributeData, id) => (dispatch) => {
    let responseData = {
        status: false,
        message: "",
        isLoading: true,
    };
    dispatch({ type: Types.UPDATE_ATTRIBUTES, payload: responseData });
    const headers = {
        "Content-Type": "application/json",
    };

    Axios.put(`${process.env.REACT_APP_API_URL}attributes/${id}`, attributeData, {
        headers: headers,
    })
        .then((res) => {
            if (res.data.status) {
                let data = res.data;
                responseData.message = data.message;
                responseData.status = data.status;
                responseData.isLoading = false;
                showToast('success', responseData.message);
                dispatch({ type: Types.UPDATE_ATTRIBUTES, payload: responseData });
                dispatch(getAttributeDetails(id))
            }
        }).catch((err) => {
            const errorMessage = "Sorry, Something went wrong!"
            responseData.isLoading = false;
            showToast('error', errorMessage);
            dispatch({ type: Types.UPDATE_ATTRIBUTES, payload: responseData });
        });
}

export const emptyDispatch = () => (dispatch) => {
    dispatch({ type: Types.EMPTY_DISPATCH, payload: true });
}