import React from "react";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";
import NewUser from "../components/NewUser";

export default function NewUserContainer() {
  return (
    <div>
      <PermissionWiseDisplay permission_name="User.Create" display={true}>
        <NewUser />
      </PermissionWiseDisplay>
    </div>
  );
}
