import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  getCategoriesByParent, GetCategoriesList, setCategoryType, setSearchText } from '../../_redux/actions/CategoriesAction';
import Select from 'react-select';
import { RHFInput } from 'react-hook-form-input';
import { useForm } from 'react-hook-form';

const CategoryFilter = () => {
    const { register, setValue } = useForm();
    const dispatch = useDispatch();
    const { searchText, categoryType, categoriesPaginatedList, selectedParentId } = useSelector(state => state.categories);
    const { categoryLvl1, categoryLvl2 } = useSelector(state => state.categories.categoriesByParent);

    const searchItems = (e) => {
        dispatch(setSearchText(e.target.value))
    };

    const dropdownHandler = (option) => {
        if(option) {
            dispatch(setCategoryType(option))
        }
    }

    const parentCtgHandler = (option, categoryLvl) => {
        if(categoryLvl === 2 && !option) {
            dispatch(getCategoriesByParent(selectedParentId, categoryLvl, true));
            return;
        }
        
        if(option && option.label === "All" && categoryLvl === 2) {
            dispatch(getCategoriesByParent(null, categoryLvl, true));
            dispatch(GetCategoriesList(categoriesPaginatedList.current_page, searchText, categoryType.value));
            return;
        }

        if(option) {
            dispatch(getCategoriesByParent(option.value, categoryLvl));
            dispatch(GetCategoriesList(categoriesPaginatedList.current_page, searchText, categoryType.value, option.value));
        }
    }

    useEffect(() => {
        if(!selectedParentId) {
            dispatch(getCategoriesByParent(null, 1));
        } 
    }, [])


    return (
        <>
            <form className="form form-label-right" autoComplete="off">
                <div className="row">
                    <div className="col-3">
                        <input
                            type="text"
                            className="form-control"
                            name="searchText"
                            placeholder="Search Categories..."
                            value={searchText}
                            onChange={searchItems}
                        />
                    </div>
                    <div className="col-3">
                        <RHFInput
                            as={<Select value={categoryType} options={[{label: 'All', value: ''},{label: 'Homepage', value: 'is_visible_homepage=1'}, {label: 'Navbar', value:"is_visible_navbar=1"}]} placeholder="Category Type" />}
                            name="category_type"
                            register={register}
                            setValue={setValue}
                            isClearable
                            onChange={(option) => dropdownHandler(option)}
                        />
                    </div>
                    <div className="col-3">
                        <RHFInput
                            as={<Select value={categoryType} options={[{label: 'All', value: ''}, ...categoryLvl1]} placeholder="Category level 1" />}
                            name="category_level_1"
                            register={register}
                            setValue={setValue}
                            isClearable
                            onChange={(option) => parentCtgHandler(option, 2)}
                        />
                    </div>
                    <div className="col-3">
                        <RHFInput
                            as={<Select value={categoryType} options={categoryLvl2} placeholder="Category level 2" />}
                            name="category_level_2"
                            register={register}
                            setValue={setValue}
                            isClearable
                            onChange={(option) => parentCtgHandler(option, 3)}
                        />
                    </div>
                    <div className="col-4">
                    </div>
                </div>
            </form>
        </>
    );
}

export default CategoryFilter;