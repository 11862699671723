import * as Types from "../../../types/Types";
import axios from "axios";

export const GetCategoriesList = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };

  axios
    .get(`${process.env.REACT_APP_API_URL}categories`, {
      headers: headers,
    })
    .then((res) => {
      let data = res.data;
      dispatch({ type: Types.GET_CATEGORIES_LIST, payload: data });
    });
};

export const AddCategories = (state) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };


  let responseData = {
    status: false,
    message: "",
    isLoading: false
  };

  const { name, short_code } = state
  let postData = {
    name: name,
    business_id: state.businessData.value,
    short_code: short_code,
    parent_id: 1,
    created_by: 1,
  };
  axios
    .post(`${process.env.REACT_APP_API_URL}categories`, postData, {
      headers: headers,
    })
    .then((res) => {
      let data = res.data;
      responseData.message = data.message;
      responseData.status = data.status;
      responseData.isLoading = false;
      dispatch({ type: Types.ADD_CATEGORIES, payload: responseData });
    })
    .catch((err) => {
      responseData.isLoading = false;
      dispatch({ type: Types.ADD_CATEGORIES, payload: responseData });
    });
};
