import React from "react";
import PageEdit from "../components/PageEdit";


const PagesEditContainer = (props) => {
    return (
        <PageEdit props={props} />
    );
};

export default PagesEditContainer;
