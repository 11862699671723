import * as Types from '../types/Types';

const initialState = {
    giftInputData: {
        title: "",
        price_value_for: "",
        change_price_value: "",
        description: "",
        image: "",
        imagePreviewURl: ""
    },
    isLoading: false,
    giftListData: [],
    gitPaginatedData: [],

};

const GiftCartReducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case Types.CHANGE_GIFT_CART_INPUT_DATA:
            const giftInputData = { ...state.giftInputData };
            giftInputData[action.payload.name] = action.payload.value;
            return {
                ...state,
                giftInputData,
            };
        case Types.SUBMIT_GIFT_CARD:
            if (action.payload.status) {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                    giftInputData: initialState.giftInputData,
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            }
        case Types.GET_GIFT_LIST:
            return {
                ...state,
                giftListData: action.payload.giftListData,
                gitPaginatedData: action.payload.gitPaginatedData,
                isLoading: action.payload.isLoading,
            };
        case Types.DELETE_GIFT_DATA:
            return {
                ...state,
                isLoading: action.payload,
            };
        case Types.GET_GIFT_CARD_DETAILS:
            return {
                ...state,
                giftInputData: action.payload.giftCardsDetail,
                // isLoading: action.payload.isLoading,
            };
        //UPDATED GIFT CARDS
        case Types.UPDATED_GIFT_CARDS:
            if (action.payload.status) {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                    // giftInputData: initialState.giftInputData,
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            }
        default:
            break;
    }
    return newState;
};

export default GiftCartReducer;
