import { confirmAlert } from "react-confirm-alert";

/**
 * Confirm delete component
 * 
 * @since 1.0.0
 *  
 * @params int itemID
 * @params function dispatch function
 * 
 * @return Confirm delete component
 */

export const ConfirmModel = (id, confirmAction) => {
    confirmAlert({
        title  : "Confirm To Delete",
        message: "Are you sure to delete..?",
        buttons: [
            {
                label  : "Yes",
                onClick: () => confirmAction(id),
            },
            {
                label  : "No"
            }
        ]
    });
    return ConfirmModel;
};
