import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { RHFInput } from "react-hook-form-input";
import {
  currencyUpdated,
  getCurrencyDetails,
  handleCurrencyInputChange,
  storeCurrency
} from "./_redux/Action/CurrenciesAction";
import { GetCountryListOptionData } from "../../../../modules/master/OptionData/CountryListOptionData/CountryListOptionDataAction/CountryListOptionDataAction";

const CurrenciesEdit = withRouter(({ history }) => {
  const { id } = useParams();
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();

  const countryList = useSelector(
    state => state.CountryListOptionDataReducer.countryList
  );

  const { currencyData, currencyDetails, isLoading } = useSelector(
    state => state.currencies
  );

  const handleChangeTextInput = (name, value) => {
    dispatch(handleCurrencyInputChange(name, value));
  };

  const onSubmit = async e => {
    dispatch(currencyUpdated(currencyData, id));
  };

  useEffect(() => {
    dispatch(GetCountryListOptionData());
    dispatch(getCurrencyDetails(id));
  }, []);

  useEffect(() => {
    if (currencyDetails !== null) {
      setValue("country", currencyDetails.countryData);
    }
  }, [currencyDetails]);

  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 class="card-label">Edit Currency </h3>
            </div>
          </div>
          <div className="card-body">
            <form
              className="form form-label-right"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
              method="post"
            >
              <div className="form-group row">
                <div className="col-lg-4">
                  <label className="form-label mt-2">Country</label>
                  <RHFInput
                    as={<Select options={countryList} />}
                    rules={{ required: false }}
                    name="country"
                    register={register}
                    value={currencyData.countryData}
                    placeholder="Select Country"
                    rules={{ required: true }}
                    onChange={option => (
                      handleChangeTextInput("country", option.value),
                      handleChangeTextInput("countryName", option)
                    )}
                    setValue={setValue}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.country &&
                      errors.country.type === "required" &&
                      "Country can't be blank !"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Currency</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    name="currency"
                    className="fromStyle"
                    value={currencyData.currency}
                    onChange={e =>
                      handleChangeTextInput("currency", e.target.value)
                    }
                    ref={register({
                      required: true,
                      maxLength: 100
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.currency &&
                      errors.currency.type === "required" &&
                      "Currency can't be blank !"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Code</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Code"
                    name="code"
                    className="fromStyle"
                    value={currencyData.code}
                    onChange={e =>
                      handleChangeTextInput("code", e.target.value)
                    }
                    ref={register({
                      required: true,
                      maxLength: 100
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.code &&
                      errors.code.type === "required" &&
                      "Code can't be blank !"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Symbol</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    name="symbol"
                    className="fromStyle"
                    value={currencyData.symbol}
                    onChange={e =>
                      handleChangeTextInput("symbol", e.target.value)
                    }
                    ref={register({
                      required: true,
                      maxLength: 100
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.symbol &&
                      errors.symbol.type === "required" &&
                      "Symbol can't be blank !"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Thousand separator</label>
                  <Form.Control
                    type="text"
                    placeholder="eg: ,"
                    name="thousand_separator"
                    className="fromStyle"
                    value={currencyData.thousand_separator}
                    onChange={e =>
                      handleChangeTextInput(
                        "thousand_separator",
                        e.target.value
                      )
                    }
                    ref={register({
                      required: true,
                      maxLength: 100
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.thousand_separator &&
                      errors.thousand_separator.type === "required" &&
                      "Thousand separator can't be blank !"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Decimal separator</label>
                  <Form.Control
                    type="text"
                    placeholder="eg: ."
                    name="decimal_separator"
                    className="fromStyle"
                    value={currencyData.decimal_separator}
                    onChange={e =>
                      handleChangeTextInput("decimal_separator", e.target.value)
                    }
                    ref={register({
                      required: true,
                      maxLength: 100
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.decimal_separator &&
                      errors.decimal_separator.type === "required" &&
                      "Decimal separator can't be blank !"}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-sm-10">
                  <a
                    onClick={() => {
                      history.push("/currencies/list");
                    }}
                  >
                    <button type="button" class="btn btn-secondary btn-lg mr-2">
                      Back
                    </button>
                  </a>
                  {isLoading && (
                    <button class="btn btn-primary btn-lg" disabled={true}>
                      <span>Saving...</span>
                      <span className="ml-3 spinner spinner-white"></span>
                    </button>
                  )}

                  {!isLoading && (
                    <button type="submit" class="btn btn-primary btn-lg">
                      <span>Save</span>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
});

export default CurrenciesEdit;
