import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Tab, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import BasicInformation from './BasicInformation'
import DeliveryInformation from './DeliveryInformation'
import PaymentInformation from './PaymentInformation'
import { getOrderDetails, updateOrder } from '../../_redux/actions/OrdersAction'
import PermissionWiseDisplay from '../../../../app/modules/role/PermissionWiseDisplay';

const OrderView = (props) => {
    const history = useHistory();
    const [key, setKey] = useState('basic')
    const { handleSubmit } = useForm();
    const { id } = props;
    const dispatch = useDispatch();
    const { ordersViewList, orderInput, isLoading } = useSelector((state) => state.orders);

    useEffect(() => {
        dispatch(getOrderDetails(id))
    }, []);

    const handleSubmitOrder = (e) => {
        if (typeof orderInput !== 'undefined' && orderInput !== null) {
            dispatch(updateOrder(orderInput, ordersViewList.id));
            history.goBack();
        }
    }

    return (

        <form onSubmit={handleSubmit(handleSubmitOrder)} method="POST" autoComplete="off" className="card card-body p-1 pb-10">
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey="basic" title="Basic Information">
                    <BasicInformation id={id} />
                </Tab>
                <Tab eventKey="delivery" title="Delivery and Shiping Information">
                    <DeliveryInformation />
                </Tab>
                <Tab eventKey="payment" title="Payment Information">
                    <PaymentInformation />
                </Tab>
            </Tabs>

            <div className="ml-10">
                {
                    typeof orderInput !== 'undefined' && orderInput !== null &&
                    <PermissionWiseDisplay permission_name={'order.edit'} display={false}>
                        {
                            !isLoading ?
                                <button type="submit" className="btn btn-primary btn-lg" >
                                    <span>Update</span>
                                </button> :
                                <button type="button" disabled className="btn btn-primary btn-lg" >
                                    <span>Updating</span>
                                    <Spinner animation="border" />
                                </button>
                        }
                    </PermissionWiseDisplay>
                }
            </div>
        </form>
    );
}
export default OrderView;