import * as Types from "../types/Types";

const initialState = {
    isLoading: false,
    lifeCycleLoading: true,
    ordersList: [],
    ordersPaginatedData: {
        current_page: 1
    },
    ordersViewList: null,
    OrderLifeCycleDetails: null,
    filterOptionList: [],
    orderTypes: [],
    orderInput: null,
    isUpdated: false,
    updateMessage: '',
    isDeleted: false,
    deleteMessage: '',
    filter: {
        searchText: '',
        type: null,
        date: null,
        customDate: null,
        status: 'pending',
        page: 1
    }
};
const OrdersReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_ORDER_TYPES:
            return {
                ...state,
                orderTypes: action.payload,
            };

        case Types.GET_ORDERS_LIST:
            return {
                ...state,
                ordersList: action.payload.data,
                ordersPaginatedData: action.payload,
                isLoading: false
            };

        case Types.GET_ORDERS_VIEW_LIST:
            return {
                ...state,
                ordersViewList: action.payload,
                orderInput: action.payload,
                isLoading: false
            };

        case Types.IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };

        case Types.GET_ORDER_LIFECYCLE_DETAILS:
            return {
                ...state,
                OrderLifeCycleDetails: action.payload.data,
                lifeCycleLoading: action.payload.isLoading,
            }

        case Types.GET_ORDER_FILTER_OPTION_DATA:
            return {
                ...state,
                filterOptionList: action.payload,
            };

        case Types.CHANGE_ORDER_LIST_FILTER:
            let filter = { ...state.filter };
            filter[action.payload.name] = action.payload.value;

            return {
                ...state,
                filter,
            };

        case Types.UPDATE_ORDER:
            return {
                ...state,
                isLoading: action.payload.loading,
                isUpdated: action.payload.status,
                updateMessage: action.payload.message
            };

        case Types.DELETE_ORDER:
            return {
                ...state,
                isLoading: action.payload.loading,
                isDeleted: action.payload.status,
                deleteMessage: action.payload.message
            };

        case Types.CHANGE_ORDER_INPUT:
            let orderInput = { ...state.orderInput };
            orderInput[action.payload.name] = action.payload.value;

            return {
                ...state,
                orderInput,
            };

        case Types.CLEAR_ORDER_DATA:
            return {
                ...state,
                ordersViewList: null,
                orderInput: null
            };

        default:
            break;
    }

    return newState;
};


export default OrdersReducer;