import React from 'react';
import LocationsList from '../../components/locations/LocationsList';

const LocationsListContainer = () => {
  return (
    <>
      <LocationsList />
    </>
  );
};

export default LocationsListContainer;
