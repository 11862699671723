import * as Types from "../Types/Types";
import Axios from "axios";

export const getCategoriListLevel1 = (categoryID2) => async (dispatch) => {
    Axios.get(`${process.env.REACT_APP_API_URL}categories/sub-categories/null`)
      .then((res) => {
        let data = res.data;
        dispatch({ type: Types.GET_PARENT_CATEGORY_OPTIONS, payload: data });
      });
  };

export const getCategoriListLevel2 = (categoryID2) => async (dispatch) => {
    Axios.get(`${process.env.REACT_APP_API_URL}categories/sub-categories/${categoryID2}`)
      .then((res) => {
        let data = res.data;
        dispatch({ type: Types.GET_SUB_CATEGORY_OPTION_LIST2, payload: data });
      });
  };
export const getCategoriListLevel3 = (categoryID3) => async (dispatch) => {
    Axios.get(`${process.env.REACT_APP_API_URL}categories/sub-categories/${categoryID3}`)
      .then((res) => {
        let data = res.data;
        dispatch({ type: Types.GET_SUB_CATEGORY_OPTION_LIST3, payload: data });
      });
  };