import React from 'react';

const LabelOptional = () => {
    const styleOptional = {
        color: 'red'
    }

    return (
        <span className={`optional text-primary`}>(optional)</span>
    );
}

export default LabelOptional;