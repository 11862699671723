/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { GetMenuListsByPermission } from "../../../../../app/modules/Auth/_redux/menu-permission/authMenuPermissionAction";
import { getBusinessUrl } from "../../../../../app/modules/Auth/_redux/authCrud";

export function AsideMenuList({ layoutProps }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  useEffect(() => {
    dispatch(GetMenuListsByPermission());
  }, [dispatch]);

  /**
   * Handle Parent Menu Click.
   *
   * Do extra stuff like, if the menu is `visit-store-menu`, then just redirect to store page.
   *
   * @param {object} menu object
   */
  const handleMenuClick = (menu) => {
    if (menu.slug === "visit-store-menu") {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = getBusinessUrl();
      a.click();
      // window.location.href = getBusinessUrl();
    }
  };

  const { menuList } = useSelector((state) => state.menu);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Modules */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text mt-2">Modules</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {menuList &&
          menuList.map((menu, index) => (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                menu.moduleRouteUrl
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
              key={index}
            >
              <NavLink
                className="menu-link menu-toggle"
                to={menu.moduleRouteUrl}
                onClick={() => handleMenuClick(menu)}
              >
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl(menu.moduleImageIcon)} />
                </span>
                <span className="menu-text">{menu.moduleName}</span>
                <i className="menu-arrow" />
              </NavLink>

              {/* Sub Menus of Module */}
              {typeof menu.subModules !== "undefined" &&
                menu.subModules.map((subMenu, subIndex) => (
                  <div className="menu-submenu " key={subIndex}>
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className="menu-item  menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text">{menu.menuName}</span>
                        </span>
                      </li>
                      <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                          subMenu.subModuleRouteUrl
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to={
                            subMenu.features.length === 0
                              ? subMenu.subModuleRouteUrl
                              : ""
                          }
                          onClick={() =>
                            history.push(
                              subMenu.features.length === 0
                                ? subMenu.subModuleRouteUrl
                                : ""
                            )
                          }
                        >
                          <i className={subMenu.subModuleIcon}>
                            <span />
                          </i>
                          <span className="menu-text">
                            {subMenu.subModuleName}
                          </span>
                          <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu ">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                            {subMenu.features.length > 0 &&
                              subMenu.features.map(
                                (featureMenu, featureIndex) => (
                                  <li
                                    className={`menu-item  ${getMenuItemActive(
                                      featureMenu.featureRouteUrl
                                    )}`}
                                    aria-haspopup="true"
                                    key={featureIndex}
                                  >
                                    <NavLink
                                      className="menu-link"
                                      to={featureMenu.featureRouteUrl}
                                    >
                                      <i className={featureMenu.featureIcon}>
                                        <span />
                                      </i>
                                      <span className="menu-text">
                                        {featureMenu.featureName}
                                      </span>
                                    </NavLink>
                                  </li>
                                )
                              )}
                          </ul>
                        </div>
                      </li>
                      {/*end::2 Level*/}
                    </ul>
                  </div>
                ))}
            </li>
          ))}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
