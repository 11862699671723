/*******************************************************************************************************
        Sub Module: Pages
********************************************************************************************************/
export const CHANGE_PAGE_INPUT = 'CHANGE_PAGE_INPUT';
export const ADD_PAGE = 'ADD_PAGE';
export const GET_PAGE_LIST = 'GET_PAGE_LIST';
export const GET_PAGE_DETAILS = 'GET_PAGE_DETAILS';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const DELETE_PAGE_DATA = 'DELETE_PAGE_DATA';
export const EMPTY_PAGE_INPUT = 'EMPTY_PAGE_INPUT';

