import React from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import WebsiteInfo from './sub-components/WebsiteInfo';

const WebsiteEdit = withRouter(() => {

  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="">
            <div className="card-title">
              <Tabs defaultActiveKey="websiteInfo" id="uncontrolled-tab-example">
                <Tab eventKey="websiteInfo" title="Website Information">
                  <WebsiteInfo />
                </Tab>
                {/* <Tab eventKey="Payment" title="Payment Information">
                  <p className="p-5">Payment Information Coming Soon....</p>
                </Tab> */}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default WebsiteEdit;
