import * as Types from '../Types/Types';

const initialState = {
    itemOptionList: [],
};

const ItemOptionListReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_ITEM_OPTION_LIST:
            return {
                ...state,
                itemOptionList: getItemOptionListData(action.payload.data)
            };
        default:
            break;
    }
    return newState;
};

/**
 * getItemOptionListData
 * 
 * @param array data 
 * @return array printable business list
 */
const getItemOptionListData = (data) => {
    let itemOptionList = [];
    if (typeof data !== 'undefined' && data.length > 0) {
        data.forEach((item) => {
            let items = {
                value: item.id,
                label: item.name,
                price: item.price
            };
            itemOptionList.push(items);
        });
    }
    return itemOptionList;
}
export default ItemOptionListReducer;
