/*******************************************************************************************************
        Sub Module: Coupon
********************************************************************************************************/
export const CHANGE_COUPON_INPUT = 'CHANGE_COUPON_INPUT';
export const ADD_COUPON = 'ADD_COUPON';
export const GET_COUPON_LIST = 'GET_COUPON_LIST';
export const GET_COUPON_DETAILS = 'GET_COUPON_DETAILS';
export const DELETE_COUPON = 'DELETE_COUPON';
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const EMPTY_DISPATCH = 'EMPTY_DISPATCH';
