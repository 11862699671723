import Axios from "axios";
import { showToast } from "../../../../master/utils/ToastHelper";
import * as Types from "../types/Types";

export const handleChangeUserAction = (name, value, e
) => (dispatch) => {

  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_USER_ROLE_INPUT_DATA, payload: data });
};

/**
 * Get User List.
 *
 * @param {string} searchKeyword
 * @param {number} page
 *
 * @return void
 */
export const getUserList = (searchValue = "", page) => (dispatch) => {
  let response = {
    userList: [],
    userPaginationList: [],
    status: false,
    message: "",
    isLoading: true,
    errors: [],
  };
  dispatch({ type: Types.GET_USER_LIST, payload: response });
  let url = `${process.env.REACT_APP_API_URL}users?search=${searchValue}&isPaginated=1&paginateNo=10`;
  if (page !== null || page === "") {
    url += `&page=${page}`;
  }

  try {
    Axios.get(url)
      .then((res) => {
        const { data, message, status } = res.data;
        response.status = status;
        response.userList = data.data;
        response.message = message;
        response.userPaginationList = data;
        response.isLoading = false;
        dispatch({ type: Types.GET_USER_LIST, payload: response });

      }).catch((err) => {
      });
  } catch (error) {
    response.message = "Something Went Wrong !";
    showToast('error', response.message);
    response.isLoading = false;
    dispatch({ type: Types.GET_USER_LIST, payload: response });
  }
}

/**
 * Create New user.
 *
 * @param {object} inputData
 * @param {mixed} handleClose
 * @return void
 */
export const createNewUser = (inputData, handleClose) => (dispatch) => {
  if (inputData.first_name.length === 0) {
    showToast('error', "First name can't blank!");
    return false;
  }
  if (inputData.last_name.length === 0) {
    showToast('error', "Last name can't blank!");
    return false;
  }
  if (inputData.email.length === 0) {
    showToast('error', "Email can't blank!");
    return false;
  }
  if (inputData.phone_no.length === 0) {
    showToast('error', "Phone number can't blank!");
    return false;
  }
  if (inputData.password.length === 0) {
    showToast('error', "Password can't blank!");
    return false;
  }
  if (inputData.role_id === '' || inputData.role_id === null) {
    showToast('error', "Role can't blank!");
    return false;
  }
  const responseList = {
    isLoading: true,
    rolesList: [],
    rolesListPaginated: null,
    inputData
  };

  dispatch({ type: Types.CREATE_USER, payload: responseList });

  Axios.post(`${process.env.REACT_APP_API_URL}users`, inputData)
    .then((res) => {
      if (res.data.status) {
        const { data, message } = res.data;
        responseList.rolesList = data.data;
        responseList.message = message;
        responseList.rolesListPaginated = data;
        responseList.isLoading = false;
        showToast('success', responseList.message)
        dispatch({ type: Types.CREATE_USER, payload: responseList });
        handleClose()
        dispatch(getUserList())
      }
    }).catch((err) => {
      responseList.isLoading = false;
      responseList.message = "Something went wrong!"
      showToast('error', responseList.message)
      dispatch({ type: Types.CREATE_USER, payload: responseList });
    })
};

/**
 * Update User.
 *
 * @param {object} inputData
 * @param {object} handleClose
 * @param {int} id
 *
 * @return void
 */
export const updatedUser = (inputData, handleClose, id) => (dispatch) => {
  if (inputData.first_name && inputData.first_name.length === 0) {
    showToast('error', "First name can't blank!");
    return;
  }

  if (inputData.last_name && inputData.last_name.length === 0) {
    showToast('error', "Last name can't blank!");
    return;
  }

  if (inputData.email && inputData.email.length === 0) {
    showToast('error', "Email can't blank!");
    return;
  }

  if (inputData.phone_no && inputData.phone_no.length === 0) {
    showToast('error', "Phone number can't blank!");
    return;
  }

  if (inputData.role_id === '' || inputData.role_id === null ) {
    showToast('error', "Role can't blank!");
    return;
  }

  const responseList = {
    isLoading: true,
    rolesList: [],
    rolesListPaginated: null,
  };

  dispatch({ type: Types.UPDATE_USER, payload: responseList });

  Axios.put(`${process.env.REACT_APP_API_URL}users/${id}`, inputData)
    .then((res) => {
      if (res.data.status) {
        const { data, message } = res.data;
        responseList.rolesList = data.data;
        responseList.message = message;
        responseList.rolesListPaginated = data;
        responseList.isLoading = false;
        showToast('success', responseList.message)
        dispatch({ type: Types.UPDATE_USER, payload: responseList });
        handleClose()
        dispatch(getUserList())
      }
    }).catch((err) => {
      responseList.isLoading = false;
      responseList.message = "Something went wrong!"
      showToast('error', responseList.message)
      dispatch({ type: Types.UPDATE_USER, payload: responseList });
    })
};

/**
 * Get User details.
 *
 * @param {int} id
 * @return void
 */
export const getUserDetails = (id) => (dispatch) => {
  let response = {
    status: false,
    message: "",
    isLoading: true,
    errors: [],
    userDetails: null,
  };
 dispatch({ type: Types.GET_USER_DETAILS, payload: response });

  Axios.get(`${process.env.REACT_APP_API_URL}users/${id}`)
    .then((res) => {
      if (res.data.status) {
        if (res.data.data.role_id !== null && res.data.data.role_name) {
          res.data.data.role = {
            label: res.data.data.role_name,
            value: res.data.data.role_id
          }
        }
        res.data.data.password = '';
        response.status = res.data.status;
        response.isLoading = false;
        response.userDetails = res.data.data;
        dispatch({ type: Types.GET_USER_DETAILS, payload: response });
      }
    }).catch((err) => {
      response.isLoading = false;
      dispatch({ type: Types.GET_USER_DETAILS, payload: response });
    })
}

/**
 * Delete a User.
 *
 * @param {int} id
 * @return void
 */
export const deleteUser = (id) => (dispatch) => {
  let response = {
    isLoading: true,
  };
//  dispatch({ type: Types.GET_USER_DETAILS, payload: response });

  Axios.delete(`${process.env.REACT_APP_API_URL}users/${id}`)
    .then((res) => {
      if (res.data.status) {
        response.isLoading = false;
        showToast('success', res.data.message)
        dispatch(getUserList());
      }
    }).catch((err) => {
      response.isLoading = false;
      showToast('error', 'User can not be deleted!');
    })
}