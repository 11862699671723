import * as Types from './Types';

const initialState = {
    isLoading: false,
    bestSoldProductsData: [],
    bestSoldProductsLabels: []
};

const BestSoldReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {

        case Types.GET_BEST_SOLD_PRODUCT_DATA:
            return {
                ...state,
                bestSoldProductsData: action.payload.data,
                bestSoldProductsLabels: action.payload.labels,
                isLoading: action.payload.isLoading
            }
        default:
            break;
    }
    return newState;
};

export default BestSoldReducer;
