import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LoadingSpinner from '../../../master/components/loading/LoadingSpinner';
import { deleteCategoryImage, GetCategoryDetailById } from '../../_redux/actions/CategoriesAction';
import axios from 'axios';

const CategoriesView = (props) => {
    const history = useHistory();
    const { id } = props;
    const dispatch = useDispatch();
    const [productFound, setProductFound] = useState(null);

    const isLoading = useSelector((state) => state.categories.isLoading);
    const detailData = useSelector((state) => state.categories.detailData);

    useEffect(() => {
        dispatch(GetCategoryDetailById(id));
        axios.get(`${process.env.REACT_APP_API_URL}get-items?category=${id}`)
            .then(res => {
                const foundProd = res.data.data.total;
                setProductFound(foundProd);
            })
            .catch(err => {
                setProductFound(0)
            })
    }, []);

    return (
        <div className="card-body">
            {
                !isLoading && detailData !== null &&
                <div>
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Category Name : {detailData.name && detailData.name}
                            </label>
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Vendor : {detailData.businessData && detailData.businessData.label && detailData.businessData.label }
                            </label>
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Category Code : {detailData.short_code && detailData.short_code}
                            </label>
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Parent Category Name : {detailData && detailData.parentCategory && detailData.parentCategory.label && detailData.parentCategory.label}
                            </label>
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Parent Category Id : {detailData && detailData.parent_id && detailData.parent_id}
                            </label>
                        </div>


                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Priority {detailData.priority && detailData.priority}
                            </label>
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Visible Home Page :  {detailData.is_visible_homepage ? 'Yes' : 'No'}
                            </label>
                            <label className="form-label mt-2 d-block">
                                Product found :  {productFound ? productFound : 0}
                            </label>
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Short Description
                            </label>
                            <br />
                            <div>
                                {detailData.short_description && detailData.short_description}
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <label className="form-label mt-2">
                                Description
                            </label>
                            <br />
                            {
                                detailData.description && detailData.description
                            }
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Logo Image
                            </label>
                            <br />
                            {
                                detailData.logoPreviewUrl !== null &&
                                <img src={detailData.logoPreviewUrl} className="img img-thumbnail" />
                            }
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Banner Image
                            </label>
                            <br />
                            {
                                detailData.bannerPreviewUrl !== null &&
                                <img src={detailData.bannerPreviewUrl} className="img img-thumbnail" />
                            }
                        </div>

                    </div>

                    <div className="form-group row">
                        <div className="col-sm-10">
                            <a
                                onClick={() => {
                                    history.push('/categories');
                                }}
                            >
                                <button type="button" className="btn btn-secondary btn-lg mr-2">
                                    <i className="fa fa-chevron-left"></i> Back
                                </button>
                            </a>
                            <Link to={`/categories/edit/${id}`}>
                                <button type="button" className="btn btn-primary btn-lg mr-2">
                                    <i className="fa fa-edit"></i> Edit
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

            }
            {
                isLoading &&
                <LoadingSpinner text="Loading Category Data" />
            }

        </div>
    );
};

export default CategoriesView;
