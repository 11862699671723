import React from 'react'
import OrderView from '../components/list/OrderView';

const OrderViewContainer = (props) => {
    return (
        <div>
            <OrderView id={props.match.params.id} />
        </div>
    );
}

export default OrderViewContainer;