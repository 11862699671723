import * as Types from '../Types/Types';

const initialState = {
    AttributeValuesList: [],
};

const AttributeValuesReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_ATTRIBUTE_VALUES:
            return {
                ...state,
                AttributeValuesList: getAttributesValues(action.payload.data)
            };
        default:
            break;
    }
    return newState;
};

/**
 * getTaxTypeData
 * 
 * @param array data 
 * @return array printable business list
 */
const getAttributesValues = (data) => {
    let AttributeValueList = [];
    if (typeof data !== 'undefined' && data.length > 0) {
        data.forEach((item) => {
            let items = {
                value: item.id,
                label: item.value,
            };
            AttributeValueList.push(items);
        });
    }
    return AttributeValueList;
}
export default AttributeValuesReducer;
