import React from 'react';
import AttributeCreate from '../components/create/AttributeCreate';

const AttributeAddContainer = () => {
  return (
    <>
      <AttributeCreate />
    </>
  );
};

export default AttributeAddContainer;
