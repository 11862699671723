import * as Types from "../../types/Types";

const initialState = {
    isLoading: false,
    searchText: "",
    categoryType: {label: "", value: ""},
    categoriesList: [],
    categoriesByParent: {
        categoryLvl1: [],
        categoryLvl2: []
    },
    selectedParentId: null,
    categoriesSortedList: [],
    categoriesPaginatedList: {
        current_page: 1
    },
    printableCategoryList: [],
    addStatus: false,
    editStatus: false,
    deleteStatus: false,
    addMessage: "",
    editMessage: "",
    deleteMessage: "",
    formInput: {
        name: '',
        businessData: null,
        parentCategory: null,
        description: '',
        short_description: '',
        short_code: '',
        is_visible_homepage: false,
        priority: 1,
        is_visible_navbar: false,
        navbar_priority: 1,
        banner: null,
        image: null,
        bannerPreviewUrl: null,
        logoPreviewUrl: null
    },
    editFormInputData: null,
    isEditing: false,
    isAdding: false,
    isDeleting: false,
    detailData: null
};
const CategoriesReducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case Types.GET_CATEGORY_EDIT_DATA:
            
            return {
                ...state,
                selectedParentId: action.payload.parentId,
                categoriesByParent: {
                    ...state.categoriesByParent,
                    categoryLvl1: action.payload.categoryLevel === 1 ? action.payload.data : state.categoriesByParent.categoryLvl1,
                    categoryLvl2: action.payload.categoryLevel === 2 ? action.payload.data : state.categoriesByParent.categoryLvl2,
                }
            }
            
        case Types.GET_CATEGORIES_LIST:
            if (action.payload.status) {
                return {
                    ...state,
                    categoriesList: action.payload.categoriesList,
                    categoriesPaginatedList: {
                        ...state.categoriesPaginatedList,
                        ...action.payload.categoriesPaginatedList
                    },
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: true,
                };
            }

        case Types.GET_CATEGORIES_SORTED_LIST:
            return {
                ...state,
                categoriesSortedList: action.payload,
            };
        case Types.GET_CATEGORY_DETAIL_DATA:
            return {
                ...state,
                isLoading: false,
                detailData: action.payload
            };
        case Types.GET_CATEGORY_EDIT_DETAILS_DATA:
            return {
                ...state,
                isLoading: false,
                editFormInputData: action.payload
            };
        case Types.ADD_CATEGORIES:
            return {
                ...state,
                addMessage: action.payload.message,
                addStatus: action.payload.status,
                isLoading: action.payload.isLoading,
                isAdding: action.payload.isAdding,
            };
        case Types.CHANGE_CATEGORY_INPUT:
            let formInput = { ...state.formInput };
            formInput[action.payload.name] = action.payload.value;
            return {
                ...state,
                formInput
            };
        case Types.CHANGE_EDIT_CATEGORY_INPUT:
            let editFormInputData = { ...state.editFormInputData };
            editFormInputData[action.payload.name] = action.payload.value;
            return {
                ...state,
                editFormInputData
            };
        case Types.EMPTY_CATEGORY_MESSAGE:
            return {
                ...state,
                addMessage: null,
                editMessage: null,
                deleteMessage: null,
            };
        case Types.UPDATE_CATEGORIES:
            return {
                ...state,
                categoriesList: action.payload.data.length > 0 ? action.payload.data : state.categoriesList,
                editStatus: action.payload.status,
                editMessage: action.payload.message,
                isEditing: action.payload.isEditing,
            };

        case Types.IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case Types.DELETE_CATEGORY:
            const ctgClone = [...state.categoriesList];
            const updatedCtg = ctgClone.filter(ctg => +ctg.id !== +action.payload);
            return {
                ...state,
                categoriesList: updatedCtg
            };
        case 'UPDATE_SEARCH_TEXT':
            return {
                ...state,
                searchText: action.payload
            };
        case 'UPDATE_CATEGORY_TEXT':
            return {
                ...state,
                categoriesPaginatedList: {
                    current_page: 1
                },
                categoryType: {
                    ...state.categoryType,
                    label: action.payload.label,
                    value: action.payload.value
                }
            };

        case Types.GET_CATEGORY_INIT:
            return {
                ...state,
                isLoading: action.payload,
                editFormInputData: null,
            }

        case Types.GET_CATEGORY_EDIT_INIT:
            return {
                ...state,
                isLoading: action.payload,
                editFormInputData: null,
            }

        default:
            return state;
    }
};

/**
 * getPrintableCategoryList
 * 
 * @param array data 
 * @return array printable category list
 */
// const getPrintableCategoryList = (data) => {
//     let printableCategoryList = [];
//     if (typeof data !== 'undefined' && data.length > 0) {
//         data.forEach((item) => {
//             let items = {
//                 value: item.id,
//                 label: item.name,
//             };
//             printableCategoryList.push(items);
//         });
//     }
//     return printableCategoryList;
// }
export default CategoriesReducer;