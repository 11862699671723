import React from "react";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";
import RolePermissionCreate from "../components/RolePermissionCreate";

export default function RolePermissionCreateContainer() {
  return (
    <div>
      <PermissionWiseDisplay permission_name="Role.Create" display={true}>
        <RolePermissionCreate />
      </PermissionWiseDisplay>
    </div>
  );
}
