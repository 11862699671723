import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { ChangeVoucherInputData, deletePreviewImage, getVoucherDeails, updatedVoucher } from './_redux/VoucherAction/VoucherAction';
import SimpleEditor from '../../../../modules/master/components/text-editor/SimpleEditor';

const VoucherEdit = withRouter(({ history, props }) => {
  const { id } = useParams();
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();
  const voucherInputData = useSelector((state) => state.VoucherReducer.voucherInputData)
  const isLoading = useSelector((state) => state.VoucherReducer.isLoading)

  const handleChangeTextInput = (name, value, e = null) => {
    dispatch(ChangeVoucherInputData(name, value, e))
  }

  useEffect(() => {
    dispatch(getVoucherDeails(id))
  }, [])
  const onSubmit = async (e) => {
    dispatch(updatedVoucher(voucherInputData, id));
  };
  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 class="card-label">Add Gift Card</h3>
            </div>
          </div>
          <div className="card-body">
            <form
              className="form form-label-right"
              onSubmit={handleSubmit(onSubmit)}
              method="post"
            >
              <div className="form-group row">
                <div className="col-lg-4">
                  <label className="form-label mt-2">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Title "
                    name="title"
                    className="fromStyle"
                    value={voucherInputData.title ? voucherInputData.title : ''}
                    onChange={(e) => handleChangeTextInput('title', e.target.value)}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.title &&
                      errors.title.type === 'required' &&
                      'Title Can not be blank'}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Price value</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="price_value_for"
                    className="fromStyle"
                    value={voucherInputData.price_value_for ? voucherInputData.price_value_for : ''}
                    onChange={(e) => handleChangeTextInput('price_value_for', e.target.value)}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.price_value_for &&
                      errors.price_value_for.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2">Change Value </label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="change_price_value"
                    className="fromStyle"
                    value={voucherInputData.change_price_value ? voucherInputData.change_price_value : ''}
                    onChange={(e) => handleChangeTextInput('change_price_value', e.target.value)}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.change_price_value &&
                      errors.change_price_value.type === 'required' &&
                      "Tax label 1 Can't be blank"}
                  </div>
                </div>
                <div className="col-lg-8">
                  <label className="form-label">Description</label>
                  <SimpleEditor
                    name="description"
                    value={voucherInputData.description ? voucherInputData.description : ''}
                    onValueChange={(e) => handleChangeTextInput('description', e)}
                  />
                </div>
                <div className="col-lg-4">
                  <label className="form-label">Image</label>
                  <Form.Control
                    type="file"
                    placeholder="Enter Image"
                    name="image"
                    onChange={(e) => handleChangeTextInput('image', e.target.files[0], e)}
                    className="fromStyle"
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                  {
                    voucherInputData.imagePreviewURl !== null && voucherInputData.imagePreviewURl !== '' &&
                    <div className="imgPreview" title="Remove">
                      <div className="preview-delete-icon"><i className="fa fa-times text-danger" onClick={() => dispatch(deletePreviewImage('image'))}></i></div>
                      <img src={voucherInputData.imagePreviewURl ? voucherInputData.imagePreviewURl : voucherInputData.image_url} className="img img-thumbnail" />
                    </div>
                  }
                </div>

              </div>

              <div className="form-group row">
                <div className="col-sm-10">
                  <a
                    onClick={() => {
                      history.push('/voucher/list');
                    }}
                  >
                    <button type="button" class="btn btn-secondary btn-lg mr-2">
                      Back
                    </button>
                  </a>
                  {isLoading && (
                    <button
                      type="submit"
                      class="btn btn-primary btn-lg"
                      disabled={true}
                    >
                      <span>Submitting...</span>
                      <span className="ml-3 spinner spinner-white"></span>
                    </button>
                  )}

                  {!isLoading && (
                    <button type="submit" class="btn btn-primary btn-lg">
                      <span>Submit</span>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
});

export default VoucherEdit;