/*******************************************************************************************************
        Sub Module: BUSINESS
********************************************************************************************************/
export const BUSINESS_INPUT_CHANGE = 'BUSINESS_INPUT_CHANGE';
export const GET_BUSINESS_LIST = 'GET_BUSINESS_LIST';
export const ADD_BUSINESS = 'ADD_BUSINESS';
export const UPDATE_BUSINESS = 'UPDATE_BUSINESS';
export const DELETE_BUSINESS = 'DELETE_BUSINESS';
export const GET_BUSINESS_DETAILS = 'GET_BUSINESS_DETAILS';
export const EMPTY_BUSINESS_EDIT_MESSAGE = 'EMPTY_BUSINESS_EDIT_MESSAGE';
export const EMPTY_DISPATCH = 'EMPTY_DISPATCH';
