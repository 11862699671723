import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { deleteBusiness, GetBusinessListData } from '../_redux/actions/BusinessAction';
import { confirmAlert } from "react-confirm-alert";
import PaginationLaravel from '../../master/pagination/PaginationLaravel';
import LoadingSpinner from '../../master/spinner/LoadingSpinner';
import SimpleTooltip from '../../master/components/tooltip/SimpleTooltip';
import moment from 'moment';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import { copyToClipboard } from '../../../services/KeyboardHelper';
import { showToast } from '../../master/utils/ToastHelper';

const BusinessList = () => {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const [searchText, setSearchText] = useState("");
	const businessList = useSelector((state) => state.business.businessList);
	const businessPaginatedList = useSelector((state) => state.business.businessPaginatedList);
	const isLoading = useSelector((state) => state.business.isLoading);

	useEffect(() => {
		dispatch(GetBusinessListData(currentPage, searchText));
	}, [currentPage, searchText]);

	const changePage = (data) => {
		setCurrentPage(data.page);
		dispatch(GetBusinessListData(data.page));
	};
	const confirmDelete = (id) => {
		dispatch(deleteBusiness(id));
	}
	const handleDeleteBusiness = (id) => {
		confirmAlert({
			title: "Confirm To Delete",
			message: `Are you sure to delete..?`,
			buttons: [
				{
					label: "Yes",
					onClick: () => confirmDelete(id),
				},
				{
					label: "No"
				}
			]
		});
	};

	const businessSearchHandler = e => {
		setSearchText(e.target.value)
	}

	return (
		<>
			<div className="container-fluid">
				<div className="card card-custom gutter-b">
					<div className="card-header">
						<div className="card-title">
							<h3 className="card-label">Shops</h3>
							<div className="ml-5">
								<input
									type="text"
									className="form-control"
									name="searchText"
									placeholder="Search Business..."
									value={searchText}
									onChange={businessSearchHandler}
								/>
							</div>
						</div>
						<div className="card-toolbar">
							<Link to="/business/add">
								<button type="button" className="btn btn-primary">
									Create New Shop <i className="fa fa-plus-circle ml-2"></i>
								</button>
							</Link>
						</div>
					</div>
					<div className="card-body">
						{isLoading && <LoadingSpinner text="Loading Shops..." />}
						<div className="react-bootstrap-table table-responsive">
							<table className="table table table-head-custom table-vertical-center">
								<thead>
									<tr>
										<td>SL</td>
										<td>Shop Name</td>
										<td>Shop Code & Prefix</td>
										<td>Shop Type</td>
										<td>Logo</td>
										<td>Banner</td>
										<td>Registered at</td>
										<td>Commission</td>
										<td>Owner</td>
										<td>Auto Publish</td>
										<td>Status</td>
										<td>Action</td>
									</tr>
								</thead>
								<tbody>
									{businessList &&
										businessList.map((item, index) => (
											<tr key={index}>
												<td>{businessPaginatedList.from + index}</td>
												<td>
													{item.name}
													<br />
													<a href={`${process.env.REACT_APP_FRONTEND_WEBSITE}store/${item.slug}`} target="_blank" className="text-danger">
														<i className="fa fa-external-link-alt text-danger"></i>
														{' '} Visit Store
													</a>
												</td>
												<td className="pointer" onClick={() => {
													copyToClipboard(item.slug);
													showToast('success', 'Code Copied.')
												}}>
													Code: {item.slug}
													<br />
													Prefix: <b>{item.prefix}</b>
												</td>
												<td>
													{
														(item.business_type !== null && item.business_type !== "") && <span className="badge badge-info">{item.business_type.toUpperCase()}</span>
													}
													{
														(item.business_type === null || item.business_type === "") && <span className="badge badge-danger">Not Set</span>
													}
												</td>
												<td>
													{item.logo !== null ? <img style={{ width: "80px" }} src={`${process.env.REACT_APP_API_PUBLIC_URL}images/vendors/${item.logo}`} alt="" /> : "N/A"}
												</td>
												<td>
													{item.banner !== null ? <img style={{ width: "80px" }} src={`${process.env.REACT_APP_API_PUBLIC_URL}images/vendors/${item.banner}`} alt="" /> : "N/A"}
												</td>
												<td>
													{(item.start_date !== null && item.start_date !== '0000-00-00') ? moment(item.start_date).format("MMM Do YYYY") : ""}
												</td>
												<td>{item.default_profit_percent}%</td>
												<td>
													<b>{item.owner_name}</b> <br />
													{
														(item.owner_no !== null && item.owner_no !== '') &&
														<a href={`tel:${item.owner_no}`} target="_blank" className="text-danger">
															<i className="fa fa-phone"></i> {item.owner_no}
														</a>
													}
												</td>
												<td>
													{item.enable_auto_publish ? <span className="badge badge-success">Yes</span> : <span className="badge badge-danger">No</span>}
												</td>
												<td>
													{item.status ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Inactive</span>}
												</td>
												<td className="d-flex">
													{/*
														<SimpleTooltip title="View Attribute">
															<Link to={``} className="btn btn-icon btn-light btn-hover-info btn-sm" >
															<i className="fa fa-eye"></i>
															</Link>
														</SimpleTooltip> 
													*/}
													&nbsp;&nbsp;
													<SimpleTooltip title={`Edit ${item.name}`}>
														<Link to={`/business/edit/${item.id}`} className="btn btn-icon btn-light btn-hover-info btn-sm" >
															<i className="fa fa-edit"></i>
														</Link>
													</SimpleTooltip>
													&nbsp;&nbsp;
													<PermissionWiseDisplay permission_name="Shop.Delete" display={false}>
														<SimpleTooltip title={`Delete ${item.name}`}>
															<a
																className="btn btn-icon btn-light btn-hover-danger btn-sm"
																onClick={(id) => handleDeleteBusiness(item.id)}
															>
																<i className="fa fa-trash text-danger"></i>
															</a>
														</SimpleTooltip>
													</PermissionWiseDisplay>
												</td>
											</tr>
										))}

								</tbody>
								<tfoot></tfoot>
							</table>
							{!isLoading && businessList && businessList.length === 0 && (
								<div className="alert alert-warning mt-5">
									Sorry ! Shop List Not Found.
								</div>
							)}
							<PaginationLaravel
								changePage={changePage}
								data={businessPaginatedList}
							/>
						</div>

					</div>
				</div>
			</div>
		</>
	);
};

export default BusinessList;
