import * as Types from '../types/Type';

const initialState = {
    paymentMethods      : [],
    mobilePaymentMethods: [],
};

const PaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: action.payload,
                mobilePaymentMethods: getMobilePaymentMethods(action.payload)
            };

        default:
            return state
    }

};

const getMobilePaymentMethods = (methods) => {
    const mobileMethods = ['bkash', 'nogod', 'rocket'];

    const mobilePaymentMethods = methods.filter(method => {
        return mobileMethods.includes(method.code)
    });
    return mobilePaymentMethods;
}
export default PaymentReducer;
