import React, { useEffect } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { storePage, handleChangePageInput, deletePageImage, emptyPageInputAction } from '../_redux/actions/PageAction';
import ErrorMessage from '../../master/ErrorMessage/ErrorMessage';
import SimpleEditor from '../../master/components/text-editor/SimpleEditor';

const PageAdd = withRouter(() => {
  const { register, handleSubmit, errors } = useForm();
  const dispatch                           = useDispatch();
  const history                            = useHistory();
  const { formInput, isSubmitting }        = useSelector((state) => state.PageReducer);

  useEffect(() => {
    // Empty the initial form input
    dispatch(emptyPageInputAction());
    handleChangeTextInput('description', 'Please enter page details...', null)
  }, []);

  console.log(formInput);

  const handleChangeTextInput = (name, value, e = null) => {
    dispatch(handleChangePageInput(name, value, e));
  };

  const onSubmit = async (e) => {
    history.goBack();
    dispatch(storePage(formInput));
  };

  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">New Page</h3>
            </div>
            <div className="card-toolbar">
              <Link to="/page/list">
                <button type="button" className="btn btn-primary">
                  <i className="fa fa-arrow-left"></i> Pages
                </button>
              </Link>
            </div>
          </div>
          <div className="card-body">
          <form
                className="form form-label-right"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                autoComplete="off"
                encType="multipart/form-data"
              >
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label className="form-label mt-2">Page Title</label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Page Title"
                      name="title"
                      className="fromStyle"
                      value={formInput.title}
                      onChange={(e) => handleChangeTextInput('title', e.target.value)}
                      ref={register({
                        required: true,
                        maxLength: 100,
                      })}
                    />
                    {errors.title && <ErrorMessage text="Page title can't be blank!" />}

                  </div>

                  <div className="col-lg-6">
                    <label className="form-label mt-2">Slug</label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Slug"
                      name="slug"
                      className="fromStyle"
                      value={formInput.slug}
                      onChange={(e) => handleChangeTextInput('slug', e.target.value)}
                      ref={register({
                        required: true,
                        maxLength: 100,
                      })}
                    />
                    {errors.slug && <ErrorMessage text="Slug can't be blank!" />}
                  </div>

                  <div className="col-lg-12">
                    <label className="form-label">Description</label>
                    <SimpleEditor
                      type="text"
                      name="description"
                      rules={{ required: true }}
                      value={formInput.description}
                      onValueChange={(e) => handleChangeTextInput('description', e, null)}
                    />
                    {errors.description && <ErrorMessage text="Description can't be blank!" />}
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label mt-2">Meta Description</label>
                    <Form.Control
                      as="textarea" 
                      rows={3}
                      type="text"
                      placeholder="Enter Meta Description"
                      name="meta_description"
                      className="custom_form_height"
                      value={formInput.meta_description}
                      onChange={(e) => handleChangeTextInput('meta_description', e.target.value)}
                      ref={register({
                        required: false,
                        maxLength: 100,
                      })}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label mt-2">Meta Keywords</label>
                    <Form.Control
                      as="textarea" 
                      rows={3}
                      type="text"
                      placeholder="Enter Meta Keywords"
                      name="meta_keywords"
                      className="custom_form_height"
                      value={formInput.meta_keywords}
                      onChange={(e) => handleChangeTextInput('meta_keywords', e.target.value)}
                      ref={register({
                        required: false,
                        maxLength: 100,
                      })}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label mt-2">
                      Image
                    </label>
                    <Form.Control
                      type="file"
                      placeholder="Enter Image"
                      name="image"
                      onChange={(e) => handleChangeTextInput('image', e.target.files[0], e)}
                      className="fromStyle"
                      ref={register({
                        required: false,
                        maxLength: 100,
                      })}
                    />
                    {
                      formInput.imagePreviewUrl !== null &&
                      <div className="imgPreview" title="Remove">
                        <i className="fa fa-times text-danger" onClick={() => dispatch(deletePageImage('image'))}></i>
                        <img alt="" src={formInput.imagePreviewUrl} className="img img-thumbnail" />
                      </div>
                    }
                  </div>

                </div>

                <div className="form-group row">
                  <div className="col-sm-10">

                    <Link to="/page/list">
                      <button type="button" className="btn btn-secondary btn-lg mr-3"> Back </button>
                    </Link>
                    {isSubmitting &&
                      <button type="submit" className="btn btn-primary btn-lg" disabled={true} >
                        <span>Submitting...</span>
                        <span className="ml-3 spinner spinner-white p-1"></span>
                      </button>
                    }

                    {!isSubmitting &&
                      <button type="submit" className="btn btn-primary btn-lg">
                        <span>Submit</span>
                      </button>
                    }
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
    </>
  );
});

export default PageAdd;
