import React, { useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { getInvoicesLifeCycleData } from '../../_redux/actions/InvoiceAction';
import StoreIcon from '@material-ui/icons/Store';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import FeedbackIcon from '@material-ui/icons/Feedback';
import moment from "moment";
import './Invoice.css'
import LoadingSpinner from '../../../master/spinner/LoadingSpinner';

const InvoiceLifeCycle = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const InvoiceLifeCycleDetails = useSelector((state) => state.InvoiceReducer.InvoiceLifeCycleDetails)
    const isLoading = useSelector((state) => state.InvoiceReducer.isLoading)
    useEffect(() => {
        dispatch(getInvoicesLifeCycleData(id))
    }, [])
    return (
        <Container>
            {
                isLoading && <LoadingSpinner text="Loading Invoice Life Cycle History" />
            }

            {
                !isLoading && InvoiceLifeCycleDetails !== null && InvoiceLifeCycleDetails !== "" && (
                    <>
                        <h4 className="vertical-title-order">#{InvoiceLifeCycleDetails.transaction_id}</h4>
                        <VerticalTimeline VerticalTimeline >
                            <VerticalTimelineElement
                                className="topTimeLines"
                                iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                                icon={InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.invoice_id !== null ? (
                                    <>
                                        <div className="customeInvoice d-flex justify-content-center align-item-center">
                                            <i className="fa fa-shopping-cart text-white"></i>
                                        </div>
                                    </>
                                ) : "N/A"}
                            />
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                date={InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.order_create_date !== null && InvoiceLifeCycleDetails.order_create_date !== "" ? moment(InvoiceLifeCycleDetails.order_create_date).format("MMMM - YYYY") : "vendor do not confirmed yet!"}
                                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                iconStyle={InvoiceLifeCycleDetails.order_create_date === null || InvoiceLifeCycleDetails.order_create_date === "" ? { background: '#ccc', color: '#fff' } : { background: 'rgb(33, 150, 243)', color: '#fff' }}
                                icon={<ShoppingCartIcon />} >
                                <h3 className="vertical-timeline-element-title">Order Created</h3>
                                <p> {InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.order_create_date !== null && InvoiceLifeCycleDetails.order_create_date !== "" ? `Order created date - ${moment(InvoiceLifeCycleDetails.order_create_date).format("DD-MM-YYYY")}` : "order do not create yet!"} </p>
                            </VerticalTimelineElement>


                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                date={InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.confirmed_by_vendor_date !== null && InvoiceLifeCycleDetails.confirmed_by_vendor_date !== "" ? moment(InvoiceLifeCycleDetails.confirmed_by_vendor_date).format("MMMM - YYYY") : "vendor do not confirmed yet!"}
                                // iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                iconStyle={InvoiceLifeCycleDetails.confirmed_by_vendor_date === null || InvoiceLifeCycleDetails.confirmed_by_vendor_date === "" ? { background: '#ccc', color: '#fff' } : { background: 'rgb(33, 150, 243)', color: '#fff' }}
                                icon={<ReceiptIcon />}>
                                <h3 className="vertical-timeline-element-title">Confirmed By Vendor</h3>
                                <p> {InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.confirmed_by_vendor_date !== null && InvoiceLifeCycleDetails.confirmed_by_vendor_date !== "" ? `Confirmed Date : ${moment(InvoiceLifeCycleDetails.confirmed_by_vendor_date).format("DD-MM-YYYY")}` : "vendor do not confirmed yet!"} </p>
                            </VerticalTimelineElement>


                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                date={InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.shipped_by_vendor_date !== null && InvoiceLifeCycleDetails.shipped_by_vendor_date !== "" ? moment(InvoiceLifeCycleDetails.shipped_by_vendor_date).format("MMMM - YYYY") : "N / A"}
                                // iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                iconStyle={InvoiceLifeCycleDetails.shipped_by_vendor_date === null || InvoiceLifeCycleDetails.shipped_by_vendor_date === "" ? { background: '#ccc', color: '#fff' } : { background: 'rgb(33, 150, 243)', color: '#fff' }}
                                icon={<LocalShippingIcon />} >
                                <h3 className="vertical-timeline-element-title">Shipped By Vendor</h3>
                                <p> {InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.shipped_by_vendor_date !== null && InvoiceLifeCycleDetails.shipped_by_vendor_date !== "" ? moment(InvoiceLifeCycleDetails.shipped_by_vendor_date).format("DD-MM-YYYY") : "N / A"} </p>
                            </VerticalTimelineElement>


                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                date={InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.delivery_by_vendor_date !== null && InvoiceLifeCycleDetails.delivery_by_vendor_date !== "" ? moment(InvoiceLifeCycleDetails.delivery_by_vendor_date).format("MMMM - YYYY") : "N / A"}
                                // iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                iconStyle={InvoiceLifeCycleDetails.delivery_by_vendor_date === null || InvoiceLifeCycleDetails.delivery_by_vendor_date === "" ? { background: '#ccc', color: '#fff' } : { background: 'rgb(33, 150, 243)', color: '#fff' }}
                                icon={<StoreIcon />}  >
                                <h3 className="vertical-timeline-element-title">Delivery By Vendor</h3>
                                <p> {InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.delivery_by_vendor_date !== null && InvoiceLifeCycleDetails.delivery_by_vendor_date !== "" ? `Delivery Date : ${moment(InvoiceLifeCycleDetails.delivery_by_vendor_date).format("DD-MM-YYYY")}` : "N / A"} </p>
                            </VerticalTimelineElement>


                            <VerticalTimelineElement
                                className="vertical-timeline-element--education"
                                date={InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.received_by_customer_date !== null && InvoiceLifeCycleDetails.received_by_customer_date !== "" ? moment(InvoiceLifeCycleDetails.received_by_customer_date).format("MMMM - YYYY") : "N / A"}
                                // iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                                iconStyle={InvoiceLifeCycleDetails.received_by_customer_date === null || InvoiceLifeCycleDetails.received_by_customer_date === "" ? { background: '#ccc', color: '#fff' } : { background: 'rgb(33, 150, 243)', color: '#fff' }}
                                icon={<SportsKabaddiIcon />} >
                                <h3 className="vertical-timeline-element-title">Received By Customer</h3>
                                <p> {InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.received_by_customer_date !== null && InvoiceLifeCycleDetails.received_by_customer_date !== "" ? `Customer Received Date : ${moment(InvoiceLifeCycleDetails.received_by_customer_date).format("DD-MM-YYYY")}` : "N / A"} </p>
                            </VerticalTimelineElement>


                            <VerticalTimelineElement
                                className="vertical-timeline-element--education"
                                date={InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.feedback_by_customer_date !== null && InvoiceLifeCycleDetails.feedback_by_customer_date !== "" ? moment(InvoiceLifeCycleDetails.feedback_by_customer_date).format("MMMM - YYYY") : "N / A"}
                                // iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                                iconStyle={InvoiceLifeCycleDetails.feedback_by_customer_date === null || InvoiceLifeCycleDetails.feedback_by_customer_date === "" ? { background: '#ccc', color: '#fff' } : { background: 'rgb(33, 150, 243)', color: '#fff' }}
                                icon={<FeedbackIcon />}>
                                <h3 className="vertical-timeline-element-title">Feedback From Customer</h3>
                                <p> {InvoiceLifeCycleDetails && InvoiceLifeCycleDetails.feedback_by_customer_date !== null && InvoiceLifeCycleDetails.feedback_by_customer_date !== "" ? `Date : ${moment(InvoiceLifeCycleDetails.feedback_by_customer_date).format("DD-MM-YYYY")}` : "N / A"} </p>
                            </VerticalTimelineElement>


                            <VerticalTimelineElement
                                iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                                icon={<CheckCircleOutlineIcon />}
                            />
                        </VerticalTimeline>
                    </>
                )
            }
        </Container >
    );
};

export default InvoiceLifeCycle;