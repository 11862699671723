/*******************************************************************************************************
        Sub Module: Attributes
********************************************************************************************************/
export const CHANGE_ATTRIBUTE_INPUT = "CHANGE_ATTRIBUTE_INPUT";
export const CHANGE_MULTIPLE_ATTRIBUTE_INPUT = "CHANGE_MULTIPLE_ATTRIBUTE_INPUT";
export const EMPTY_DISPATCH = "EMPTY_DISPATCH";
export const ADD_MULTIPLE_ATTRIBUTE = "ADD_MULTIPLE_ATTRIBUTE";
export const DELETE_MULTIPLE_ATTRIBUTE = "DELETE_MULTIPLE_ATTRIBUTE";
export const DELETE_MULTIPLE_ATTRIBUTE_EDIT = "DELETE_MULTIPLE_ATTRIBUTE_EDIT";
export const SUBMIT_ATTRIBUTE_DATA = "SUBMIT_ATTRIBUTE_DATA";
export const GET_ATTRIBUTE_LIST = "GET_ATTRIBUTE_LIST";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const GET_ATRIBUTE_DETAILS = "GET_ATRIBUTE_DETAILS";
export const UPDATE_ATTRIBUTES = "UPDATE_ATTRIBUTES";