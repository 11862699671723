import React from 'react';
import TaxAdd from '../../components/tax/TaxAdd';



const TaxAddContainer = () => {
  return (
    <>
      <TaxAdd />
    </>
  );
};

export default TaxAddContainer;
