import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Select from "react-select";
import { RHFInput } from 'react-hook-form-input';
import { useForm } from 'react-hook-form';
import { formatCurrency } from '../../../../services/currency';
import { getSelectedOption } from '../../../../services/FormatData';
import { getOrderTypes, changeOrderInput, clearOrderData } from '../../_redux/actions/OrdersAction';
import { checkFeaturePermission } from '../../../../app/modules/Auth/_redux/menu-permission/module-permission';
import OrderStatus from '../OrderStatus';

const BasicInformation = ({id}) => {
    const dispatch = useDispatch();
    const { ordersViewList, orderTypes, orderInput } = useSelector((state) => state.orders);
    const [shippingCharge, setShippingCharge] = useState(null);
    const { register, setValue } = useForm();

    useEffect(() => { 
        dispatch(getOrderTypes(true));
        setShippingCharge(null);
        return () => {
            dispatch(clearOrderData())
        }
    }, []);

    const handleOrderInputChange = (name, value) => {
        dispatch(changeOrderInput(name, value));
    }

    const getItemSubTotal = () => {
        let total = 0;
        if (ordersViewList.sale_lines) {
            ordersViewList.sale_lines.forEach(item => {
                const singleSubtotal = parseFloat(parseFloat(item.quantity) * parseFloat(item.unit_price));
                total += singleSubtotal;
            });
        }

        return total;
    }

    return (
        <div className="container-fluid pb-5 pt-5">
            {
                typeof ordersViewList !== 'undefined' && ordersViewList !== null &&
                <>
                    <div className="row">
                        <div className="col-md-4">
                            <ul className="list-group">
                                <li className="list-group-item"><h4>Order Invoice No</h4></li>
                                <li className="list-group-item">#{ordersViewList.id}</li>
                                <li className="list-group-item"><h4>Final Total</h4></li>
                                <li className="list-group-item">{formatCurrency(ordersViewList.final_total)}</li>
                                <li className="list-group-item"><h4>Due Total</h4></li>
                                <li className="list-group-item">{formatCurrency(ordersViewList.due_total)}</li>
                                <li className="list-group-item"><h4>Paid Total</h4></li>
                                <li className="list-group-item">{formatCurrency(ordersViewList.paid_total)}</li>
                                <li className="list-group-item"><h4>Discount</h4></li>
                                <li className="list-group-item">{formatCurrency(ordersViewList.discount_amount === null ? 0 : ordersViewList.discount_amount)}</li>
                                <li className="list-group-item"><h4>Shipping Charge</h4></li>
                                <li className="list-group-item">
                                    {/* {formatCurrency(ordersViewList.shipping_charges === null ? 0 : ordersViewList.shipping_charges)} */}
                                    <input
                                        disabled={checkFeaturePermission("order.edit") ? false : true}
                                        type="text"
                                        className="form-control"
                                        name="searchText"
                                        placeholder="Search Categories..."
                                        value={shippingCharge}
                                        defaultValue={shippingCharge ? null : !ordersViewList.shipping_charges ? 0 : ordersViewList.shipping_charges}
                                        onChange={e => {setShippingCharge(e.target.value); handleOrderInputChange('shipping_charges', e.target.value)}}
                                    />
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <ul className="list-group">
                                <li className="list-group-item"><h4>Order Status Informations</h4></li>
                                <li className="list-group-item">Order Type: <span className="badge badge-primary">{ordersViewList.type}</span></li>
                                <li className="list-group-item"><h4>Current Status</h4></li>
                                <li className="list-group-item">
                                    <OrderStatus status={ordersViewList.status} />
                                </li>

                                <li className="list-group-item"><h4>Payment Status</h4></li>
                                <li className="list-group-item">
                                    <OrderStatus status={ordersViewList.payment_status} />
                                </li>

                                <li className="list-group-item"><h4>Delivery Status</h4></li>
                                <li className="list-group-item">
                                    <OrderStatus status={ordersViewList.delivery_status} />
                                </li>

                                <li className="list-group-item"><h4>Change Order Status</h4></li>
                                <li className="list-group-item">
                                    {
                                        (checkFeaturePermission('order.edit') || checkFeaturePermission('order.status_update')) ?
                                            <RHFInput
                                                as={<Select options={orderTypes} placeholder="Select order status" />}
                                                rules={{ required: false }}
                                                name="status"
                                                register={register}
                                                setValue={setValue}
                                                isSearchable={true}
                                                value={getSelectedOption(orderTypes, orderInput.status)}
                                                onChange={(option) => {
                                                    if (option !== null) {
                                                        handleOrderInputChange('status', option.value)
                                                    }
                                                }}
                                            />
                                            :
                                            <span className={`text-capitalize badge badge-${ordersViewList.status === 'pending' ? 'danger' : 'info'}`}>{ordersViewList.status}</span>
                                    }
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <div className='mb-3'>
                                {/* <Link to={`/orders/invoice/print/${id}`} className="brand-logo btn btn-light mr-2">
                                    View Invoice
                                </Link> */}
                                <a className='btn btn-light' target="_blank" href={`${process.env.REACT_APP_API_URL}invoice/${id}`}>
                                    Download Invoice
                                </a>
                            </div>
                            <ul className="list-group mb-3">
                                <li className="list-group-item"><h4>Order Notes</h4></li>
                                {
                                    ordersViewList.notes !== null &&
                                    ordersViewList.notes.map(note => (
                                        <li className="list-group-item" key={note.id}>
                                            {note.note} <br />
                                            <span className="badge badge-secondary">
                                                at {moment(note.created_at).format('DD-MM-YYYY hh:mm A')}  
                                            </span>
                                        </li>
                                    ))
                                }
                            </ul>
                            <ul className="list-group">
                                <li className="list-group-item"><h4>Delivery Info</h4></li>
                                {
                                    ordersViewList.shipping_address !== null && (
                                        <li className="list-group-item">
                                            <h5>Shipping Address</h5>
                                            <div>
                                                <address>
                                                    {ordersViewList.shipping_address.street1 !== null
                                                    ? ordersViewList.shipping_address.street1 + " ,"
                                                    : ""}
                                                    {ordersViewList.shipping_address.street2 !== null
                                                    ? ordersViewList.shipping_address.street2 + " ,"
                                                    : ""}
                                                    {ordersViewList.shipping_address.area !== null
                                                    ? ordersViewList.shipping_address.area + " ,"
                                                    : ""}
                                                    {ordersViewList.shipping_address.city !== null
                                                    ? ordersViewList.shipping_address.city + " ,"
                                                    : ""}
                                                    {ordersViewList.shipping_address.division !== null
                                                    ? ordersViewList.shipping_address.division
                                                    : ""}
                                                </address>
                                            </div>

                                            <div>
                                                <span>Name: </span>
                                                {
                                                    ordersViewList.shipping_address.name
                                                }
                                            </div>
                                            <div>
                                                <span>Phone: </span>
                                                {
                                                    ordersViewList.shipping_address.phone_no
                                                }
                                            </div>
                                            <div>
                                                <span>Email: </span>
                                                {
                                                    ordersViewList.shipping_address.email ? ordersViewList.shipping_address.email : 'Not given'
                                                }
                                            </div>
                                            
                                        </li>
                                    )
                                }
                                {
                                    ordersViewList.shipping_address !== null && (
                                        <li className="list-group-item pb-2">
                                            <h5>Billing Address</h5>
                                            <div className='mb-2'>
                                                <address>
                                                    {ordersViewList.billing_address.street1 !== null
                                                    ? ordersViewList.billing_address.street1 + " ,"
                                                    : ""}
                                                    {ordersViewList.billing_address.street2 !== null
                                                    ? ordersViewList.billing_address.street2 + " ,"
                                                    : ""}
                                                    {ordersViewList.billing_address.area !== null
                                                    ? ordersViewList.billing_address.area + " ,"
                                                    : ""}
                                                    {ordersViewList.billing_address.city !== null
                                                    ? ordersViewList.billing_address.city + " ,"
                                                    : ""}
                                                    {ordersViewList.billing_address.division !== null
                                                    ? ordersViewList.billing_address.division
                                                    : ""}
                                                </address>
                                            </div>

                                            <div>
                                                <span>Name: </span>
                                                {
                                                    ordersViewList.billing_address.name
                                                }
                                            </div>
                                            <div>
                                                <span>Phone: </span>
                                                {
                                                    ordersViewList.billing_address.phone_no
                                                }
                                            </div>
                                            <div>
                                                <span>Email: </span>
                                                {
                                                    ordersViewList.billing_address.email ? ordersViewList.billing_address.email : 'Not given'
                                                }
                                            </div>
                                            
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <h3 className="text-muted mt-5">Product Details</h3>
                    <table className="table table-bordered pb-5">
                        <thead>
                            <tr>
                                <th>SI</th>
                                <th>Sku</th>
                                <th>Product</th>
                                <th>Image</th>
                                <th>Unit Price X Quantity</th>
                                <th>Total Discount</th>
                                {/* <th>Tax</th> */}
                                <th>Sub Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(ordersViewList.sale_lines) && ordersViewList.sale_lines.map((item, index) => {
                                return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.item && item.item.sku_manual}</td>
                                    <td>{(typeof item.item !== 'undefined' && item.item !== null) ? item.item.name : 'N/A'}</td>
                                    <td>{
                                        (typeof item.item !== 'undefined' && item.item !== null) &&
                                        <img src={item.item.featured_url} width={50} alt="" />
                                    }
                                    </td>
                                    <td>{formatCurrency(parseFloat(item.unit_price))} X {formatCurrency(item.quantity, ',', '')}</td>
                                    <td>{formatCurrency(parseFloat(item.quantity) * parseFloat(item.discount_amount))}</td>
                                    {/* <td>{formatCurrency(item.item_tax)}</td> */}
                                    <td>{formatCurrency((parseFloat(item.quantity) * parseFloat(item.unit_price)))}</td>
                                </tr>
                            )})}
                        </tbody>
                        <tfoot className="order-footer-details">
                            <tr className="border-top">
                                <td colSpan={6} className="text-bold">Item Sub Total</td>
                                <td>{formatCurrency(getItemSubTotal())}</td>
                            </tr>
                            <tr className="border-top">
                                <td colSpan={6} className="text-bold">Shipping Cost (+)</td>
                                <td>{formatCurrency(ordersViewList.shipping_charges)}</td>
                            </tr>
                            {/* <tr className="border-top">
                                <td colSpan={6} className="text-bold">Order Discount (-)</td>
                                <td>{formatCurrency(ordersViewList.discount_amount)}</td>
                            </tr> */}
                            <tr className="border-top">
                                <td colSpan={6} className="text-bold">Grand Total</td>
                                <td>{formatCurrency(ordersViewList.final_total)}</td>
                            </tr>
                            <tr className="border-top">
                                <td colSpan={6} className="text-bold">10% of Grand Total</td>
                                <td>{floorNum((ordersViewList.final_total * 10) / 100)}</td>
                            </tr>
                        </tfoot>
                    </table>
                </>
            }
            {
                typeof ordersViewList === 'undefined' || ordersViewList === null &&
                <h4>Loading Order Informations...</h4>
            }
        </div>
    );
}

const floorNum = (val) => Math.floor(val)

export default BasicInformation;