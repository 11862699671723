import * as Types from "../../types/Types";
import axios from "axios";
import { showToast } from "../../../master/utils/ToastHelper";
import { getAuthId } from "../../../master/utils/AuthHelper";
import { generateFormDataFromObject } from "../../../master/utils/FileHelper";

const baseUrl = process.env.REACT_APP_API_URL;

export const getCategoriesByParent = (parentId, categoryLevel, reset = false) => dispatch => {
    const categoriesURL = `${baseUrl}categories?isPaginated=0&parent_id=${parentId}`;

    if(reset && categoryLevel === 2){
        dispatch({type: Types.GET_CATEGORY_EDIT_DATA, payload: {data: [], categoryLevel: categoryLevel, parentId: parentId}});
        return;
    }

    axios.get(categoriesURL)
    .then(res => {
        const { data } = res.data;
        const processedData = data.map(category => ({label: category.name, value: category.id}));

        dispatch({type: Types.GET_CATEGORY_EDIT_DATA, payload: {data: processedData, categoryLevel: categoryLevel, parentId: parentId}})
    })
    .catch(err => {
        // console.log('err => ', err)
    })
}

export const GetCategoriesList = (page, searchText = null, categoryType = "", parentId = "") => async (dispatch) => {

    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };

    dispatch({ type: Types.GET_CATEGORIES_LIST, payload: responseList });
    let categoriesURL = `${baseUrl}categories?isPaginated=1&isActive=1&paginateNo=10`;

    if(parentId) {
        categoriesURL += `&parent_id=${parentId}`;
    }

    if (page) {
        categoriesURL += `&page=${page}`;
    }

    if(categoryType) {
        categoriesURL += `&${categoryType}`
    }

    if (searchText) {
        categoriesURL += `&search=${searchText}`;
    }

    await axios.get(categoriesURL)
        .then((res) => {
            const { data, message, status } = res.data;
            responseList.status = status;
            responseList.categoriesList = data.data;
            responseList.message = message;
            responseList.categoriesPaginatedList = data;
            responseList.isLoading = false;
            dispatch({ type: Types.GET_CATEGORIES_LIST, payload: responseList });
        }).catch((err) => {
            responseList.message = "Something Went Wrong !";
            showToast('error', responseList.message);
        });
};

export const setSearchText = (searchText) => {
    return {
        type: 'UPDATE_SEARCH_TEXT',
        payload: searchText
    }
}

export const setCategoryType = (categoryType) => {
    return {
        type: 'UPDATE_CATEGORY_TEXT',
        payload: categoryType
    }
}

export const GetCategoriesSortedList = () => (dispatch) => {
    axios.get(`${baseUrl}frontend-categories?type=all_sorted`)
        .then((res) => {
            let categories = [];
            res.data.data.forEach(categoryLabel1 => {
                const categoryLabel1Data = {
                    label: categoryLabel1.name+' ('+categoryLabel1.childs.length+')',
                    value: categoryLabel1.id,
                }
                categories.push(categoryLabel1Data);

                // Go to it's child and push it to categories list
                categoryLabel1.childs.forEach(categoryLabel2 => {
                    const categoryLabel2Data = {
                        label:  '—— ' + categoryLabel2.name +' ('+categoryLabel2.childs.length+')',
                        value: categoryLabel2.id,
                    }
                    categories.push(categoryLabel2Data);

                    categoryLabel2.childs.forEach(categoryLabel3 => {
                        const categoryLabel3Data = {
                            label: '———— ' +  categoryLabel3.name,
                            value: categoryLabel3.id,
                        }
                        categories.push(categoryLabel3Data);
                    });
                });
            });
            dispatch({ type: Types.GET_CATEGORIES_SORTED_LIST, payload: categories });
        });
};

/**
 * Get Category Detail By Id
 * 
 * @param {integer} id Category ID
 * @param {boolean} isEdit Is Edit Mode or Detail Mode
 */
export const GetCategoryDetailById = (id, isEdit = false) => (dispatch) => {
    const type = !isEdit ? Types.GET_CATEGORY_DETAIL_DATA : Types.GET_CATEGORY_EDIT_DETAILS_DATA;
    dispatch({ type: Types.GET_CATEGORY_INIT, payload: true });
    dispatch({ type: Types.GET_CATEGORY_EDIT_INIT, payload: true });

    axios
        .get(`${process.env.REACT_APP_API_URL}categories/${id}`)
        .then((res) => {
            const data = res.data.data;
            if (data !== null) {
                data.businessData = data.business !== null ? {
                    label: data.business.name,
                    value: data.business.id,
                } : null;
                data.parentCategory = data.parent_category !== null ? {
                    label: data.parent_category.name,
                    value: data.parent_category.id,
                } : null;
                data.bannerPreviewUrl = data.banner_url;
                data.logoPreviewUrl = data.image_url;
                data.banner = null;
                data.image = null;
                if (data.is_visible_homepage === "1" || data.is_visible_homepage === 1 ) {
                    data.is_visible_homepage = true;
                } else {
                    data.is_visible_homepage = false;
                }

                if (data.is_visible_navbar === "1" || data.is_visible_navbar === 1 ) {
                    data.is_visible_navbar = true;
                } else {
                    data.is_visible_navbar = false;
                }
            }
            dispatch({ type: Types.GET_CATEGORY_INIT, payload: false });
            dispatch({ type: Types.GET_CATEGORY_EDIT_INIT, payload: false });
            dispatch({ type: type, payload: data });
        });
};

/**
 * Handle Category Input change for add/edit
 */
export const handleChangeCategory = (name, value, e = null, isEdit = false) => (dispatch) => {
    let data = {
        name: name,
        value: value,
    }

    const type = !isEdit ? Types.CHANGE_CATEGORY_INPUT : Types.CHANGE_EDIT_CATEGORY_INPUT;
    dispatch({ type: type, payload: data });

    if (name === 'banner' || name === 'image') {
        let reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            if (name === "banner") {
                data.name = 'bannerPreviewUrl';  
            } else {
                data.name = 'logoPreviewUrl';
            }
            data.value = reader.result;
            dispatch({ type: type, payload: data });
        }
        reader.readAsDataURL(file)
    }
};

export const deleteCategoryImage = (name, isEdit = false) => (dispatch) => {
    let data = {
        name: name,
        value: null,
    }
    const type = !isEdit ? Types.CHANGE_CATEGORY_INPUT : Types.CHANGE_EDIT_CATEGORY_INPUT;
    dispatch({ type: type, payload: data });

    if (name === 'banner') {
        data.name = 'bannerPreviewUrl';
    } else {
        data.name = 'logoPreviewUrl';
    }
    dispatch({ type: type, payload: data });
};

export const emptyCategoryMessage = () => (dispatch) => {
    dispatch({ type: Types.EMPTY_CATEGORY_MESSAGE, payload: null });
};

/**
 * Create New Category
 * 
 * @param object category input data
 */
export const storeCategory = (state) => async (dispatch) => {
    const headers = {
        "Content-Type": "application/json",
    };

    let responseData = {
        status: false,
        message: "",
        isLoading: false,
        isAdding: true,
    };
    dispatch({ type: Types.ADD_CATEGORIES, payload: responseData });
    const userId = await getAuthId();
    const { name, short_code, short_description, description, priority, is_visible_homepage, is_visible_navbar, navbar_priority, businessData, parentCategory, banner, image } = state
    let postData = {
        name,
        business_id: businessData !== null ? businessData.value : null,
        short_code,
        short_description,
        description,
        parent_id: parentCategory !== null ? parentCategory.value : null,
        created_by: userId,
        banner,
        is_visible_homepage: is_visible_homepage ? 1 : 0,
        is_visible_navbar: is_visible_navbar ? 1 : 0,
        navbar_priority: parseInt(navbar_priority),
        priority: parseInt(priority),
        image
    };
    const formData = generateFormDataFromObject(postData);
    axios
        .post(`${process.env.REACT_APP_API_URL}categories`, formData, {
            headers: headers,
        })
        .then((res) => {
            const data = res.data;
            responseData.message = data.message;
            responseData.status = data.status;
            responseData.isLoading = false;
            responseData.isAdding = false;
            if (!data.status) {
                showToast('error', data.message);
            } else {
                showToast('success', data.message);
            }
            dispatch({ type: Types.ADD_CATEGORIES, payload: responseData });
        })
        .catch((err) => {
            responseData.isLoading = false;
            responseData.isAdding = false;
            showToast('error', err);
            dispatch({ type: Types.ADD_CATEGORIES, payload: responseData });
        });
};

/**
 * Update Category by given data
 * 
 * @param object category input data
 */
export const updateCategory = (state, id, categories = null) => async (dispatch) => {
    const headers = {
        "Content-Type": "application/json",
    };

    let responseData = {
        data: [],
        status: false,
        message: "",
        isEditing: true,
    };
    dispatch({ type: Types.UPDATE_CATEGORIES, payload: responseData });

    const userId = await getAuthId();
    const { name, short_code, short_description, description, priority, is_visible_homepage, is_visible_navbar, navbar_priority, businessData, parentCategory, banner, image } = state

    let postData = {
        name,
        business_id: businessData !== null ? businessData.value : null,
        short_code,
        short_description,
        description,
        parent_id: parentCategory !== null ? parentCategory.value : null,
        created_by: userId,
        banner: banner === {} ? null : banner,
        is_visible_homepage: is_visible_homepage ? 1 : 0,
        is_visible_navbar: is_visible_navbar ? 1 : 0,
        navbar_priority,
        priority,
        image: image === {} ? null : image
    };
    const formData = generateFormDataFromObject(postData);
    axios
        .post(`${process.env.REACT_APP_API_URL}categories/${id}?_method=PUT`, formData, {
            headers: headers,
        })
        .then((res) => {
            const data = res.data;
            responseData.message = data.message;
            responseData.status = data.status;
            responseData.isEditing = false;
            
            if (!data.status) {
                showToast('error', data.message);
            } else {
                showToast('success', data.message);
            }


            if(categories) {
                const categoryWithoutOldProd = categories.filter(category => {
                    return category.id !== res.data.data.id
                })
    
                const updatedCategory = categoryWithoutOldProd.concat(res.data.data);
                responseData.data = updatedCategory;
            }

            dispatch({ type: Types.UPDATE_CATEGORIES, payload: responseData });
        })
        .catch((err) => {
            showToast('error', err);
            responseData.isEditing = false;
            dispatch({ type: Types.UPDATE_CATEGORIES, payload: responseData });
        });
};

/**
 * 
 * @param {int} id //category ID
 * @returns categoryList
 */
export const deleteCategory = (id) => (dispatch) => {
    dispatch({ type: Types.DELETE_CATEGORY, payload: id })
    axios.delete(`${process.env.REACT_APP_API_URL}categories/${id}`)
        .then((res) => {
            if (res.data.status) {
                const data = res.data;
                showToast('success', data.message);
            }
        })
}