import moment from "moment";
/**
 * Format Date.
 *
 * @param {string} date
 * @param {string} format
 *
 * @return {string} Formatted Date
 */
export const formatDate = (date, format = "Do MMMM YYYY") => {
  if (date !== null && date !== "") {
    return moment(date).format(format);
  }

  return "";
};

/**
 * Get Human Friendly Date Difference.
 *
 * @param {string} date
 *
 * @return {string} Formatted Date
 */
export const getHumanDifTime = (date) => {
  if (date === null && date === "") {
    return '---';
  }

  return moment(date).fromNow();
};

/**
 * Get Current Date.
 *
 * @param {string} format
 *
 * @return {string} Current date.
 */
export const getCurrentDate = (format = "YYYY-MM-DD") => {
  return moment().format(format);
};

/**
 * Get Current Date Time.
 *
 * @param {string} format
 *
 * @return {string} Current date time.
 */
export const getCurrentDateTime = (format = "YYYY-MM-DD h:mm:ss a") => {
  return moment().format(format);
};

/**
 * Get To Date Time From Date.
 *
 * @param {string} date
 *
 * @return {string} To Date Time From Date.
 */
export const getToDate = (date) => {
  return moment(date).toDate()
};
