import React, { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  handleChangeUserAction,
  updatedUser,
  getUserDetails,
} from "../_redux/actions/UserAction";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import LoadingSpinner from "../../../master/spinner/LoadingSpinner";
import { getRoleListDropdownAction } from "../_redux/actions/RoleAction";
import { getSelectedOption } from "../../../../services/FormatData";

const UserEdit = ({ handleClose, id }) => {
  const { register, handleSubmit, setValue } = useForm();
  const {
    userDetails,
    detailsLoading,
    updatedLoading,
    inputData,
  } = useSelector((state) => state.userRole);
  const { roleList } = useSelector((state) => state.roleReducer);
  const userInput = inputData;
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(updatedUser(userInput, handleClose, id));
  };

  const handleChange = (name, value, e = null) => {
    dispatch(handleChangeUserAction(name, value, e));
  };

  useEffect(() => {
    dispatch(getRoleListDropdownAction());
    dispatch(getUserDetails(id));
  }, []);

  // User statuses
  const statuses = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card card-custom gutter-b p-5">
              {userDetails === null && detailsLoading && (
                <LoadingSpinner text="Loading User Details.." />
              )}

              {userDetails !== null && userInput !== null && (
                <form
                  className="form form-label-right voyageEngineerForm"
                  method="post"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group row">
                    <div className="col-xl-3 col-lg-3 col-md-6 ">
                      <Form.Group>
                        <Form.Label className="formFont pl-1">
                          First Name
                        </Form.Label>
                        <Form.Control
                          className="formHeight"
                          type="text"
                          name="first_name"
                          placeholder="Enter first name"
                          value={userInput.first_name}
                          onChange={(e) =>
                            handleChange("first_name", e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 ">
                      <Form.Group>
                        <Form.Label className="formFont pl-1">
                          Surname
                        </Form.Label>
                        <Form.Control
                          className="formHeight"
                          type="text"
                          name="surname"
                          placeholder="Enter surname"
                          value={userInput.surname}
                          onChange={(e) =>
                            handleChange("surname", e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 ">
                      <Form.Group>
                        <Form.Label className="formFont pl-1">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          className="formHeight"
                          type="text"
                          name="last_name"
                          value={userInput.last_name}
                          placeholder="Enter last name"
                          onChange={(e) =>
                            handleChange("last_name", e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6">
                      <Form.Group>
                        <Form.Label className="formFont pl-1">
                          username
                        </Form.Label>
                        <Form.Control
                          className="formHeight"
                          type="text"
                          name="username"
                          value={userInput.username}
                          placeholder="Enter username"
                          onChange={(e) =>
                            handleChange("username", e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6">
                      <Form.Group>
                        <Form.Label className="formFont pl-1">Email</Form.Label>
                        <Form.Control
                          className="formHeight"
                          type="text"
                          name="email"
                          value={userInput.email}
                          placeholder="Enter email address"
                          onChange={(e) =>
                            handleChange("email", e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6">
                      <Form.Group>
                        <Form.Label className="formFont pl-1">
                          Phone No
                        </Form.Label>
                        <Form.Control
                          className="formHeight"
                          type="text"
                          name="phone_no"
                          placeholder="Enter phone no"
                          value={userInput.phone_no}
                          onChange={(e) =>
                            handleChange("phone_no", e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6">
                      <Form.Label className="formFont pl-1">Role</Form.Label>
                      <RHFInput
                        as={<Select options={roleList} />}
                        rules={{ required: false }}
                        name="role_id"
                        register={register}
                        value={userInput.role}
                        defaultValue={userInput.role}
                        onChange={(option) => {
                          handleChange("name", option.label);
                          handleChange("role_id", option.value);
                          handleChange("role", option);
                        }}
                        // value={CourseName.label}
                        setValue={setValue}
                      />
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6">
                      <Form.Label className="formFont pl-1">Status</Form.Label>
                      <RHFInput
                        as={<Select options={statuses} />}
                        rules={{ required: false }}
                        name="status"
                        register={register}
                        value={getSelectedOption(statuses, userInput.status)}
                        onChange={(option) => handleChange("status", option.value)}
                        setValue={setValue}
                      />
                    </div>
                    <br />
                  </div>

                  <div className="card card-body mb-2">
                    <h2>Change Password (optional)</h2>
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-6">
                        <Form.Group>
                          <Form.Label className="formFont pl-1">
                            Password
                          </Form.Label>
                          <Form.Control
                            className="formHeight"
                            type="password"
                            name="password"
                            placeholder="Enter Password"
                            onChange={(e) =>
                              handleChange("password", e.target.value)
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6">
                        <Form.Group>
                          <Form.Label className="formFont pl-1">
                            Confirm Password
                          </Form.Label>
                          <Form.Control
                            className="formHeight"
                            type="password"
                            name="Cpassword"
                            placeholder="Confirm Password"
                            onChange={(e) =>
                              handleChange("Cpassword", e.target.value)
                            }
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  {updatedLoading && (
                    <Button
                      className="mr-4 saveButton text-white float-right"
                      disabled={true}
                    >
                      <span>Updating...</span>
                      <span className="ml-3 spinner spinner-white"></span>
                    </Button>
                  )}

                  {!updatedLoading && (
                    <Button
                      className="mr-4 saveButton text-white float-right"
                      variant=""
                      onClick={() => onSubmit()}
                    >
                      Update
                    </Button>
                  )}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEdit;
