import * as Types from '../../types/Types';

const initialState = {
  taxList: [],
  addMessage: '',
  addStatus: false,
  editMessage: '',
  editStatus: false,
  deleteStatus: false,
  deleteMessage: '',
};

const TaxReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.GET_TAX_LIST:
      return {
        ...state,
        taxList: action.payload.data,
      };
      case Types.ADD_TAX:
      return {
        ...state,
        addMessage:action.payload.message,
        addStatus:action.payload.status,
        isLoading:action.payload.isLoading,
      };
      case Types.UPDATE_TAX:
          return {
            ...state,
            editStatus: action.payload.status,
            editMessage: action.payload.message,
            isLoading: action.payload.isLoading,
          };
        case Types.DELETE_TAX:
          let DeleteCustomerList = state.taxList.filter(function (el) {
            return el.intID !== action.payload.intID;
          });  
        return {
          ...state,
          taxList: DeleteCustomerList,
          deleteStatus: action.payload.status,
          deleteMessage: action.payload.message,
        };
        case Types.EMPTY_TAX_EDIT_MESSAGE:
          return {
            ...state,
            editStatus: "",
            editMessage: "",
          };
    default:
      break;
  }
  return newState;
};
export default TaxReducer;
