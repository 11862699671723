import React from 'react';
import { Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { changeItemInput, deleteMultipleAttributesValues } from '../_redux/actions/ItemAction';

const MultipleAttributeTable = () => {
    const dispatch = useDispatch();
    const itemInput = useSelector((state) => state.item.itemInput);

    const deleteMultipleAttributeValue = (index, attribute=null) => {
        if(attribute !== null){
            const deleted_attributes = [...itemInput.deleted_attributes, attribute];
            dispatch(changeItemInput("deleted_attributes", deleted_attributes));
        }
        dispatch(deleteMultipleAttributesValues(index));
    };
    
    return (
        <>
            {
                itemInput.attributes.length > 0 && (
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>SI</th>
                                        <th>Attribute</th>
                                        <th>Attribute Values</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        typeof itemInput.attributes !== 'undefined' &&
                                        itemInput.attributes.length > 0 &&
                                        itemInput.attributes.map((item, index) =>  (
                                            <>
                                                {
                                                    typeof item.id !== 'undefined' &&
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{`${item.name}`}</td>
                                                        <td>
                                                            {
                                                                item.values.attribute_values_data.length > 0 && item.values.attribute_values_data.map((val) => (
                                                                    <span key={val}>
                                                                        <Badge className="badge badge-success text-light ml-2">{val.value}</Badge>
                                                                    </span>
                                                                ))
                                                            }
                                                        </td>
                                                        <td style={{ width: 70, textAlign: "center" }}>
                                                            <i className="fa fa-trash text-danger pointer" onClick={() => deleteMultipleAttributeValue(index, item)}> </i>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    typeof item.id === 'undefined' &&
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{`${item.attribute_name}`}</td>
                                                        <td>
                                                            {
                                                                item.attribute_values_print.length > 0 && item.attribute_values_print.map((values) => (
                                                                    <span key={values}>
                                                                        <Badge className="badge badge-success text-light ml-2">{values}</Badge>
                                                                    </span>
                                                                ))

                                                            }
                                                        </td>
                                                        <td style={{ width: 70, textAlign: "center" }}>
                                                            <i className="fa fa-trash text-danger pointer" onClick={() => deleteMultipleAttributeValue(index)}> </i>
                                                        </td>
                                                    </tr>
                                                }
                                            </>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default MultipleAttributeTable;