import { getAutoGeneratedPassword } from '../../../../services/FormatHelper';
import * as Types from '../../types/Types';

const initialState = {
  businessList: [],
  businessPaginatedList: [],
  isLoading: false,
  businessInput: {
    isEdit                   : false,
    /** Basic Information Part */
    business_type            : "business",
    status                   : 1,
    name                     : "",
    bin                      : "",
    slug                     : "",
    prefix                   : "",
    start_date               : "",
    tax_label_1              : "TAX",
    tax_label_2              : "TAX",
    tax_number_1             : "",
    tax_number_2             : "",
    autopublish_product      : false,
    owner_id                 : null,
    time_zone                : "Asia/Dhaka",
    fy_start_month           : 1,
    accounting_method        : "FIFO",
    sell_price_tax           : "0",
    currency_id              : 2,
    logo                     : "",
    sku_prefix               : "",
    enable_tooltip           : 0,
    banner                   : "",
    logoPreviewUrl           : null,
    bannerPreviewUrl         : null,

    /** User Part */
    user_name                : '',
    user_email               : '',
    user_password            : '',
    user_phone_no            : '',
    send_email               : false,
    send_sms                 : false,

    /** Account Part */
    vat_no                   : '',
    euid_no                  : '',
    bank_name                : '',
    bank_iban                : '',
    default_sales_discount   : "0",
    default_profit_percent   : "10",

    /** Address Part */
    state                    : '',
    state_id                 : '',
    city                     : '',
    city_id                  : '',
    area                     : '',
    area_id                  : '',
    landmark                 : '',
    zip_code                 : '',
    alternate_number         : '',
    website                  : '',
    email                    : '',

    /** Payment Part */
    payment_active_method    : '',

    // Bank Payment
    payment_bank_account_name: '',
    payment_bank_account_no  : '',
    payment_bank_name        : '',
    payment_bank_address     : '',
    payment_bank_routing_no  : '',
    payment_bank_iban        : '',
    payment_bank_swift       : '',

    payment_bkash_no         : '',
    payment_bkash_no_type    : 'Personal',

    payment_rocket_no        : '',
    payment_rocket_no_type   : 'Personal',

    payment_nogod_no         : '',
    payment_nogod_no_type    : 'Personal',

    payment_paypal_email     : '',
  },
  processing: false,
  socialOptions: ['Facebook', 'Flicker', 'Twitter', 'Youtube', 'Linkedin', 'Pinterest', 'Instagram'],
  businessDetails: null,
};

const BusinessReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.BUSINESS_INPUT_CHANGE:
      const businessInput = { ...state.businessInput };
      businessInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        businessInput,
      };

    case Types.ADD_BUSINESS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case Types.GET_BUSINESS_LIST:
      return {
        ...state,
        businessList: action.payload.businessList,
        businessPaginatedList: action.payload.businessPaginatedList,
        isLoading: action.payload.isLoading,
      };

    case Types.DELETE_BUSINESS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case Types.GET_BUSINESS_DETAILS:
      return {
        ...state,
        businessDetails: action.payload.businessDetails,
        businessInput: action.payload.businessDetails,
        isLoading: action.payload.isLoading,
      };

    case Types.UPDATE_BUSINESS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case Types.EMPTY_BUSINESS_EDIT_MESSAGE:
      return {
        ...state,
        editStatus: "",
        editMessage: "",
      };

    case Types.EMPTY_DISPATCH:
      return {
        ...state,
        businessInput: initialState.businessInput,
      };

    default:
      break;
  }
  return newState;
};

export default BusinessReducer;
