import * as Types from "../../types/Types";
import axios from "axios";
import { showToast } from "../../../master/utils/ToastHelper";
import { generateFormDataFromObject } from "../../../master/utils/FileHelper";
import moment from "moment";

/**
 * Handle Business Input data and pass it to redux store
 *
 * @param {string} name busines name
 * @param {string} value input value
 * @param {object} e
 *
 * @return
 */
export const handleBusinessInputChange = (name, value, e = null) => (dispatch) => {
	let data = {
		name: name,
		value: value,
		e
	}
	dispatch({ type: Types.BUSINESS_INPUT_CHANGE, payload: data });

	if (name === 'logo' || name === 'banner') {
		let reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			if (name === "logo") {
				data.name = 'logoPreviewUrl';
			} else {
				data.name = 'bannerPreviewUrl';
			}
			data.value = reader.result;
			dispatch({ type: Types.BUSINESS_INPUT_CHANGE, payload: data });
		}
		reader.readAsDataURL(file)
	}
};

/**
 * Delete business preview image
 *
 * @param {string} name Image file name
 *
 * @return
 */
export const deletePreviewImage = (name) => (dispatch) => {

	let data = {
		name: name,
		value: null,
	}
	dispatch({ type: Types.BUSINESS_INPUT_CHANGE, payload: data });

	if (name === 'logo') {
		data.name = 'logoPreviewUrl';
	} else {
		data.name = 'bannerPreviewUrl';
	}
	dispatch({ type: Types.BUSINESS_INPUT_CHANGE, payload: data });
};

/**
 * Create a Business with the input data
 *
 * @param {object} businessInput
 *
 * @return void Dispatch
 */
export const AddBusiness = (businessInput) => (dispatch) => {
	let responseData = {
		status: false,
		message: "",
		isLoading: true,
	};
	dispatch({ type: Types.ADD_BUSINESS, payload: responseData });

	const headers = {
		"Content-Type": "application/json",
	};

	let businessInputData = businessInput;

	if (typeof businessInputData.start_date ==='undefined' || businessInputData.start_date === null || businessInputData.start_date === '') {
		businessInputData.start_date = moment().format("YYYY-MM-DD");
	} else {
		businessInputData.start_date = moment(businessInputData.start_date).format("YYYY-MM-DD");
	}

	// Validate Inputs if necessary

	// Process inputs for backend processing
	businessInputData.state = (typeof businessInputData.state !== 'undefined' && businessInputData.state != '' && businessInputData.state !== null) ? businessInputData.state.label : '';
	businessInputData.city = (typeof businessInputData.city !== 'undefined' && businessInputData.city !== '' && businessInputData.city !== null) ? businessInputData.city.label : '';
	businessInputData.area = (typeof businessInputData.area !== 'undefined' && businessInputData.area !== '' && businessInputData.area !== null) ? businessInputData.area.label : '';

	const businessData = generateFormDataFromObject(businessInputData);

	axios.post(`${process.env.REACT_APP_API_URL}business`, businessData, {
		headers: headers,
	})
		.then((res) => {
			if (res.data.status) {
				let data = res.data;
				responseData.message = data.message;
				responseData.status = data.status;
				responseData.isLoading = false;
				showToast('success', responseData.message);
				dispatch({ type: Types.ADD_BUSINESS, payload: responseData });
			}
		}).catch((err) => {
			const errorMessage = "Sorry, Something went wrong ! Please fill all data and try again !"
			responseData.isLoading = false;
			showToast('error', errorMessage);
			dispatch({ type: Types.ADD_BUSINESS, payload: responseData });
		});
};

/**
 * Get Business List Data
 *
 * @param {int} page Page No to fix pagination issue
 * @param {string} searchText
 *
 * @return
 */
export const GetBusinessListData = (page, searchText = null) => async (dispatch) => {
	let responseList = {
		isLoading: true,
		data: {},
		status: false,
	};
	dispatch({ type: Types.GET_BUSINESS_LIST, payload: responseList });
	let businessListURL = `${process.env.REACT_APP_API_URL}business?isPaginated=1&status=-1`;

	if (page !== null || page === "") {
		businessListURL += `&page=${page}`;
	}
	if (searchText !== null) {
		businessListURL += `&search=${searchText}&isActive=1`;
	}

	try {
		await axios.get(businessListURL)
			.then((res) => {
				if (res.data.status) {
					const { data, message, status } = res.data;
					responseList.status = status;
					responseList.businessList = data.data;
					responseList.message = message;
					responseList.businessPaginatedList = data;
					responseList.isLoading = false;
					dispatch(emptyDispatch())
					dispatch({ type: Types.GET_BUSINESS_LIST, payload: responseList });
				}

			}).catch((err) => {
				responseList.isLoading = false;
				dispatch({ type: Types.GET_BUSINESS_LIST, payload: responseList });
			});
	} catch (error) {
		responseList.message = "Something Went Wrong !";
		showToast('error', responseList.message);
	}
	responseList.isLoading = false;
	dispatch({ type: Types.GET_BUSINESS_LIST, payload: responseList });
};


/**
 * Delete a business by ID
 *
 * @param {int} id
 * @return
 */
export const deleteBusiness = (id) => (dispatch) => {
	let isLoading = true;
	dispatch({ type: Types.DELETE_BUSINESS, payload: isLoading })
	axios.delete(`${process.env.REACT_APP_API_URL}business/${id}`)
		.then((res) => {
			if (res.data.status) {
				const data = res.data;
				showToast('success', data.message);
				dispatch({ type: Types.DELETE_BUSINESS, payload: false });
				dispatch(GetBusinessListData())
			}
		}).catch(err => {
			const errorMessage = err?.response?.data?.message;
			showToast('error', errorMessage);
			dispatch({ type: Types.DELETE_BUSINESS, payload: false });
		});
}


/**
 * Get Business Details Information
 *
 * @param {int} id
 * @param {boolean} isEdit
 *
 * @return
 */
export const getBusinessDetails = (id, isEdit = false) => async (dispatch) => {
	let response = {
		status: false,
		message: "",
		isLoading: true,
		businessDetails: {}
	};
	dispatch({ type: Types.GET_BUSINESS_DETAILS, payload: response });

	await axios.get(`${process.env.REACT_APP_API_URL}business/${id}?withUser=1`)
		.then((res) => {
			if (res.data.status) {
				const { data, message, status } = res.data;
				if (data.accounting_method !== null && data.accounting_method !== "") {
					data.accountingMethod = {
						label: data.accounting_method.toUpperCase(),
						value: data.accounting_method
					}
				} else {
					data.accountingMethod = ''
				}

				data.bin = (data.bin === '' || data.bin === null) ? data.slug : data.bin;

				if (data.business_type !== null && data.business_type !== "") {
					const shopName = data.business_type === 'polli' ? 'Polli Dokan' : data.business_type.toUpperCase();

					data.businessType = {
						label: shopName,
						value: data.business_type
					}
				} else {
					data.businessType = {
						label: 'Business',
						value: 'business'
					}
				}

				if (data.currency_id !== null && data.currency_id !== "" && data.currency_name !== null && data.currency_name !== "") {
					data.currency = {
						label: data.currency_name,
						value: data.currency_id,
					}
				} else {
					data.currency = ''
				}

				if (data.state_id !== null && data.state_id !== "" && data.state !== null && data.state !== "") {
					data.state = {
						label: data.state,
						value: data.state_id,
					}
				} else {
					data.state = ''
				}

				if (data.city_id !== null && data.city_id !== "" && data.city !== null && data.city !== "") {
					data.city = {
						label: data.city,
						value: data.city_id,
					}
				} else {
					data.city = ''
				}

				if (data.area_id !== null && data.area_id !== "" && data.area !== null && data.area !== "") {
					data.area = {
						label: data.area,
						value: data.area_id,
					}
				} else {
					data.area = ''
				}

				if (data.logo_url !== null && data.logo_url !== "") {
					data.logoPreviewUrl = data.logo_url;
					data.logo           = null;
				}

				if (data.banner_url !== null && data.banner_url !== "") {
					data.bannerPreviewUrl = data.banner_url;
					data.banner           = null;
				}

				if (data.start_date === null || data.start_date === "" || data.start_date === '0000-00-00') {
					data.start_date = data.created_at; //moment(data.created_at).format('MM-dd-yyyy');
				}

				response.status          = status;
				response.businessDetails = data;
				response.message         = message;
				response.isLoading       = false;

				response.businessDetails.isEdit = isEdit;
				dispatch({ type: Types.GET_BUSINESS_DETAILS, payload: response });
			}
		}).catch((err) => {
			response.isLoading = false;
			dispatch({ type: Types.GET_BUSINESS_DETAILS, payload: response });
		});
}

/**
 * Update Business
 *
 * @param {object} businessInput Business Form Input Data
 * @param {int} id
 *
 * @return void Dispatch Business Updated Events
 */
export const updatedBusiness = (businessInput, id) => async (dispatch) => {
	let responseData = {
		status: false,
		message: "",
		isLoading: true,
	};
	dispatch({ type: Types.UPDATE_BUSINESS, payload: responseData });
	const headers = {
		"Content-Type": "application/json",
	};

	let businessInputData = businessInput;
	businessInputData.start_date = moment(businessInputData.start_date).format("YYYY-MM-DD");

	const businessData = generateFormDataFromObject(businessInputData)
	axios.post(`${process.env.REACT_APP_API_URL}business/${id}?_method=PUT`, businessData, {
		headers: headers,
	})
		.then((res) => {
			if (res.data.status) {
				let data = res.data;
				responseData.message = data.message;
				responseData.status = data.status;
				responseData.isLoading = false;
				showToast('success', responseData.message);
				dispatch({ type: Types.UPDATE_BUSINESS, payload: responseData });
			}
		}).catch((err) => {
			const errorMessage = "Sorry, Something went wrong!"
			responseData.isLoading = false;
			showToast('error', errorMessage);
			dispatch({ type: Types.UPDATE_BUSINESS, payload: responseData });
		});
};

/**
 * Empty Dispatch redux store for business
 *
 * @return
 */
export const emptyDispatch = () => (dispatch) => {
	dispatch({ type: Types.EMPTY_DISPATCH, payload: true });
}