import React from 'react';
import { Route } from 'react-router-dom';
import AttributeAddContainer from '../views/CategoryAddContainer';
import AttributeEditContainer from '../views/CategoryEditContainer';
import AttributeListContainer from '../views/CategoryListContainer';

const attributes = [
  {
    path: '/attribute/list',
    name: 'Attribute List',
    component: AttributeListContainer,
    exact: true,
  },
  {
    path: '/attribute/create',
    name: 'Attribute Create',
    component: AttributeAddContainer,
    exact: true,
  },
  {
    path: '/attribute/edit/:id',
    name: 'Attribute Create',
    component: AttributeEditContainer,
    exact: true,
  },
];

function AttributeRoute() {
  {
    return attributes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default AttributeRoute;
