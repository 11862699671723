import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { ChangePollInputData, getItemsOptionList, updatedPolls, getPollsDetails } from './_redux/PollAction/PollAction';
import SimpleEditor from '../../../../modules/master/components/text-editor/SimpleEditor';
import { RHFInput } from 'react-hook-form-input';
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import LabelRequired from '../../../../modules/master/components/labels/LabelRequired';

const PollEdit = withRouter(({ history, props }) => {
  const {id} = useParams()
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();
  const PollInputData = useSelector((state) => state.PollReducer.PollInputData)
  const isSubmitting = useSelector((state) => state.PollReducer.isSubmitting)
  const itemOptionList = useSelector((state) => state.PollReducer.itemOptionList)
  
  const handleChangeTextInput = (name, value, index) => {
    dispatch(ChangePollInputData(name, value, index))
  }
  const pollType = [
    { label: 'Radio', value: 'radio' },
    { label: 'Checkbox', value: 'checkbox' }
  ]
  useEffect(() => {
    dispatch(getItemsOptionList())
    dispatch(getPollsDetails(id))
  },[])
  const onSubmit = async (e) => {
    dispatch(updatedPolls(PollInputData, id));
  };
  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 class="card-label">Edit Poll</h3>
            </div>
          </div>
          <div className="card-body">
            <form
              className="form form-label-right"
              onSubmit={handleSubmit(onSubmit)}
              method="post"
              autoComplete="off"
              encType="multipart/form-data">

              <div className="form-group row">
                <div className="col-lg-4">
                  <label className="form-label mt-2">Title <LabelRequired /></label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Title "
                    name="title"
                    className="fromStyle"
                    value={PollInputData.title}
                    onChange={(e) => handleChangeTextInput('title', e.target.value, null)}
                    ref={register({
                      required: true,
                      maxLength: 100,
                    })}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.title &&
                      errors.title.type === 'required' &&
                      "Please enter a poll title !"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="form-label mt-2"> Type <LabelRequired /></label>
                  <RHFInput
                    as={<Select options={pollType} />}
                    rules={{ required: false }}
                    name="type"
                    register={register}
                    value={PollInputData.PollTypes}
                    defaultValue={PollInputData.PollTypes}
                    setValue={setValue}
                    onChange={(option) => handleChangeTextInput("type", option.value, null)}
                  />
                  <div className="inputError margin-minus-8">
                    {errors.type &&
                      errors.type.type === 'required' &&
                      "Please select a poll type !"}
                  </div>
                </div>
                <div className="col-lg-8">
                  <label className="form-label">Description</label>
                  <SimpleEditor
                    type="text"
                    name="description"
                    rules={{ required: true }}
                    value={PollInputData.description}
                    onValueChange={(e) => handleChangeTextInput('description', e, null)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <Form.Check
                    type="checkbox"
                    label="Enable Item Comparison"
                    name="enable_item_comparison"
                    id="enable_item_comparison"
                    checked={PollInputData.enable_item_comparison}
                    value={PollInputData.enable_item_comparison}
                    onChange={(e) => {
                      handleChangeTextInput("enable_item_comparison", PollInputData.enable_item_comparison === false ? true : false, null)
                    }}
                  />
                </div>
                <div className="col-lg-8">
                  {
                    PollInputData.options && PollInputData.options.length > 0 && (
                      <div className="card card-body">
                        <div className="row">
                          <div className="col-4">

                          </div>
                          <div className="col-8">
                            <div className="row">
                              <div className="col-6">
                                Option - 1
                              </div>
                              <div className="col-6">
                                Option - 2
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-2">
                            <div className="mb-5">
                              Value
                            </div>
                            <div>
                              {
                                PollInputData.enable_item_comparison &&
                                "Select Item (If Any)"
                              }
                            </div>
                          </div>
                          <div className="col-10">
                            <div className="row">
                              {
                                PollInputData.options.map((item, index) => (
                                  <div className="col-6" key={index}>
                                    <div className="mb-2">
                                      <Form.Control
                                        type="text"
                                        placeholder="options value"
                                        name="value"
                                        className="fromStyle"
                                        value={item.value}
                                        onChange={(e) => handleChangeTextInput('value', e.target.value, index)}
                                        ref={register({
                                          required: true,
                                          maxLength: 100,
                                        })}
                                      />
                                      <div className="inputError margin-minus-8">
                                        {errors.value &&
                                          errors.value.type === 'required' &&
                                          "Please enter a options value !"}
                                      </div>
                                    </div>
                                    {
                                      PollInputData.enable_item_comparison &&
                                      <div>
                                        <RHFInput
                                          as={<Select options={itemOptionList} />}
                                          rules={{ required: true }}
                                          name="item_id"
                                          register={register}
                                          value={item.item_id}
                                          defaultValue={item.item_id}
                                          setValue={setValue}
                                          onChange={(option) => (
                                            handleChangeTextInput("item_id", option, index)
                                          )}
                                        />
                                        <div className="inputError margin-minus-8">
                                          {PollInputData.enable_item_comparison && errors.item_id &&
                                            errors.item_id.type === 'required' &&
                                            "Please select option item !"}
                                        </div>
                                      </div>
                                    }

                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>

                      </div>
                    )
                  }
                </div>
              </div>

              <div className="form-group row mt-3">
                <div className="col-sm-10">
                  <a
                    onClick={() => {
                      history.push('/poll/list');
                    }}
                  >
                    <button type="button" class="btn btn-secondary btn-lg mr-2">
                      Back
                    </button>
                  </a>
                  {isSubmitting && (
                    <button
                      type="submit"
                      class="btn btn-primary btn-lg"
                      disabled={true}
                    >
                      <span>Submitting...</span>
                      <span className="ml-3 spinner spinner-white"></span>
                    </button>
                  )}

                  {!isSubmitting && (
                    <button type="submit" class="btn btn-primary btn-lg">
                      <span>Submit</span>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
});

export default PollEdit;
