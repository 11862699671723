import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  GetCurrenciesList,
  deleteCurrency
} from "./_redux/Action/CurrenciesAction";
import LoadingSpinner from "../../../../modules/master/spinner/LoadingSpinner";
import SimpleTooltip from "../../../../modules/master/components/tooltip/SimpleTooltip";
import PaginationLaravel from "../../../../modules/master/pagination/PaginationLaravel";
import { confirmAlert } from "react-confirm-alert";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";

const CurrenciesList = withRouter(({ history, props }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { currencyList, isLoading, currencyPaginatedList } = useSelector(
    state => state.currencies
  );

  useEffect(() => {
    dispatch(GetCurrenciesList(currentPage, null));
  }, [dispatch, currentPage]);

  const changePage = data => {
    setCurrentPage(data.page);
    dispatch(GetCurrenciesList(data.page));
  };

  // delete currency
  const confirmDelete = id => {
    dispatch(deleteCurrency(id));
  };
  const handleDeleteCurrency = id => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id)
        },
        {
          label: "No"
        }
      ]
    });
  };

  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Currencies</h3>
            </div>
            <div className="card-toolbar">
              <PermissionWiseDisplay
                permission_name="Currency.Create"
                display={false}
              >
                <a
                  onClick={() => {
                    history.push("/currencies/add");
                  }}
                >
                  <button type="button" className="btn btn-primary">
                    New Currency
                  </button>
                </a>
              </PermissionWiseDisplay>
            </div>
          </div>
          <div className="card-body">
            {isLoading && <LoadingSpinner text="Loading Currency List..." />}

            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <td>SL</td>
                    <td>Country</td>
                    <td>Currency</td>
                    <td>Code</td>
                    <td>symbol</td>
                    <td>Thousand Separator</td>
                    <td>Decimal Separator</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {currencyList &&
                    currencyList.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.country_name}</td>
                        <td>{item.currency}</td>
                        <td>{item.code}</td>
                        <td>{item.symbol}</td>
                        <td>{item.thousand_separator}</td>
                        <td>{item.decimal_separator}</td>
                        <td className="d-flex">
                          {/* <SimpleTooltip title="View Attribute">
                           <Link to={``} className="btn btn-icon btn-light btn-hover-info btn-sm" >
                             <i className="fa fa-eye"></i>
                           </Link>
                         </SimpleTooltip> */}
                          &nbsp;&nbsp;
                          <SimpleTooltip title={`Edit ${item.currency}`}>
                            <Link
                              to={`/currencies/edit/${item.id}`}
                              className="btn btn-icon btn-light btn-hover-info btn-sm"
                            >
                              <i className="fa fa-edit"></i>
                            </Link>
                          </SimpleTooltip>
                          &nbsp;&nbsp;
                          <PermissionWiseDisplay
                            permission_name="Currency.Delete"
                            display={false}
                          >
                            <SimpleTooltip title={`Delete ${item.currency}`}>
                              <a
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                onClick={id => handleDeleteCurrency(item.id)}
                              >
                                <i className="fa fa-trash text-danger"></i>
                              </a>
                            </SimpleTooltip>
                          </PermissionWiseDisplay>
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot></tfoot>
              </table>
              {!isLoading && currencyList && currencyList.length === 0 && (
                <div className="alert alert-warning mt-5">
                  Sorry ! No currency found.
                </div>
              )}
              <PaginationLaravel
                changePage={changePage}
                data={currencyPaginatedList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default CurrenciesList;
