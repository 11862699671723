import React from 'react';
import { Route } from 'react-router-dom';
import CategoryAddContainer from '../views/CategoryAddContainer';
import CategoryEditContainer from '../views/CategoryEditContainer';
import CategoryListContainer from '../views/CategoryListContainer';
import CategoryViewContainer from '../views/CategoryViewContainer';

const categoryRoutes = [
  {
    path: '/categories',
    name: 'Categories List',
    component: CategoryListContainer,
    exact: true,
  },
  {
    path: "/categories/create",
    name: "Create Category",
    component: CategoryAddContainer,
    exact: true,
  },
  {
    path: "/categories/edit/:id",
    name: "Edit Category",
    component: CategoryEditContainer,
    exact: true,
  },
  {
    path: "/categories/view/:id",
    name: "View Category",
    component: CategoryViewContainer,
    exact: true,
  }
];

function getCategoryModuleRoutes() {
  {
    return categoryRoutes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default getCategoryModuleRoutes;
