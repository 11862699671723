import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SimpleTooltip from '../../../modules/master/components/tooltip/SimpleTooltip';
import { deleteCustomerReview, getReviewList, updateRevieStatus } from '../_redux/actions/CustomerAction';
import LoadingSpinner from '../../../modules/master/spinner/LoadingSpinner';
import { confirmAlert } from "react-confirm-alert";
import ReactStars from "react-rating-stars-component";
import { Badge } from 'react-bootstrap';
import PaginationLaravel from '../../master/pagination/PaginationLaravel';

const CustomerReviewList = () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const { reviewList, isLoading, updating, reviewListPaginated } = useSelector((state) => state.customer);

    useEffect(() => {
        dispatch(getReviewList(1))
    }, [])

    const confirmDelete = (reviewID) => {
        dispatch(deleteCustomerReview(reviewID));
    }

    const handleDeleteReview = (reviewID) => {
        confirmAlert({
            title: "Confirm To Delete",
            message: `Are you sure to delete..?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => confirmDelete(reviewID),
                },
                {
                    label: "No"
                }
            ]
        });
    };

    const [editingID, setEditingID] = useState(null);

    const handleUpdatedReviwStatus = (reviewID) => {
        dispatch(updateRevieStatus(reviewID))
        setEditingID(reviewID)
    }

    const changePage = (data) => {
        setCurrentPage(data.page);
        dispatch(getReviewList(data.page));
    };

    return (
        <>
            <div className="container">
                <div className="card card-custom gutter-b">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 class="card-label">Customer Reviews</h3>
                        </div>
                    </div>
                    <div className="card-body">
                        {isLoading && <LoadingSpinner text="Loading Customer Reviews..." />}
                        <div className="react-bootstrap-table table-responsive">
                            <table className="table table table-head-custom table-vertical-center">
                                <thead>
                                    <tr>
                                        <td>SL</td>
                                        <td>Customer</td>
                                        <td>Item</td>
                                        <td>Seller</td>
                                        <td>Comments</td>
                                        <td>Rating Value</td>
                                        <td>Status</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reviewList &&
                                        reviewList.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.rating_by ? item.rating_by : "N/A"}</td>
                                                <td>{item.item_name ? item.item_name : "N/A"}</td>
                                                <td>{item.seller_name ? item.seller_name : "N/A"}</td>
                                                <td>{item.comment ? item.comment : "N/A"}</td>
                                                <td>
                                                    <ReactStars
                                                        count={5}
                                                        size={24}
                                                        value={item.rating_value}
                                                        edit={false}
                                                        activeColor="#ffd700"
                                                        color="#c7c7c7"
                                                    />,
                                                </td>
                                                <td>
                                                    <Badge variant={item.status === 0 ? "warning" : "success"}>{item.status === 0 ? "Pending" : "Approved"}</Badge>
                                                </td>
                                                <td className="d-flex">
                                                    &nbsp;&nbsp;
                                                    {
                                                        item.status === 0 ? <>
                                                            {updating && editingID === item.id ? (
                                                                <Link className="btn btn-icon btn-light btn-hover-success btn-sm">
                                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                </Link>
                                                            ) : (
                                                                <SimpleTooltip title={`Approve`}>
                                                                    <Link onClick={() => handleUpdatedReviwStatus(item.id)} className="btn btn-icon btn-light btn-hover-success btn-sm">
                                                                        <i class="far fa-check-circle"></i>
                                                                    </Link>
                                                                </SimpleTooltip>
                                                            )}
                                                        </> :
                                                            ''
                                                    }

                                                    &nbsp;&nbsp;
                                                    <SimpleTooltip title={`Reject`}>
                                                        <Link onClick={() => handleDeleteReview(item.id)} className="btn btn-icon btn-light btn-hover-danger btn-sm" >
                                                            <i class="far fa-times-circle"></i>
                                                        </Link>
                                                    </SimpleTooltip>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                                <tfoot></tfoot>
                            </table>
                            {!isLoading && reviewList && reviewList.length === 0 && (
                                <div className="alert alert-warning mt-5 m-3">
                                    Sorry ! No reviews found.
                                </div>
                            )}

                            <PaginationLaravel
                                changePage={changePage}
                                data={reviewListPaginated}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomerReviewList;
