import React from 'react';
import { Route } from 'react-router-dom';

import BusinessAddContainer from '../views/BusinessAddContainer';
import BusinessEditContainer from '../views/BusinessEditContainer';
import BusinessListContainer from '../views/BusinessListContainer';

const businessRoutes = [
  {
    path: "/business/add",
    name: "Business add",
    component: BusinessAddContainer,
    exact: true,
  },
  {
    path: "/business/list",
    name: "Business List",
    component: BusinessListContainer,
    exact: true,
  },

  {
    path: "/business/edit/:id",
    name: "Business Edit",
    component: BusinessEditContainer,
    exact: true,
  },
];

function getBusinessRoutes() {
  {
    return businessRoutes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default getBusinessRoutes;
