import React from 'react';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import CustomerList from '../components/CustomerList';

const CustomerListContainer = () => {
  return (
    <PermissionWiseDisplay permission_name="customer.view" display={true}>
      <CustomerList />
    </PermissionWiseDisplay>
  );
};

export default CustomerListContainer;
