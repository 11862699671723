import * as Types from '../Types/Types';

const initialState = {
    SubCategoryOptionList: [],
    parentCategories: [],
    SubCategoryOptionList2: [],
    SubCategoryOptionList3: []
};

const SubCategoryReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_SUB_CATEGORY_OPTION_LIST2:
            return {
                ...state,
                SubCategoryOptionList2: getCategoriesPrintable(action.payload.data)
            };
        case Types.GET_PARENT_CATEGORY_OPTIONS:
            return {
                ...state,
                parentCategories: getCategoriesPrintable(action.payload.data)
            };
        case Types.GET_SUB_CATEGORY_OPTION_LIST3:
            return {
                ...state,
                SubCategoryOptionList3: getCategoriesPrintable(action.payload.data)
            };
        default:
            break;
    }
    return newState;
};

const getCategoriesPrintable = (data) => {
    let categories = [];
    if (typeof data !== 'undefined' && data !== null && data.length > 0) {
        data.forEach((item) => {
            let items = {
                value: item.id,
                label: item.name,
            };
            categories.push(items);
        });
    }
    return categories;
}
export default SubCategoryReducer;
