/*******************************************************************************************************
        Sub Module: ORDERS
********************************************************************************************************/
export const GET_ORDERS_LIST = 'GET_ORDERS_LIST';
export const CLEAR_ORDER_DATA = 'CLEAR_ORDER_DATA';
export const GET_ORDER_TYPES = 'GET_ORDER_TYPES';
export const CHANGE_ORDER_LIST_FILTER = 'CHANGE_ORDER_LIST_FILTER';
export const CHANGE_ORDER_INPUT = 'CHANGE_ORDER_INPUT';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const IS_LOADING = 'IS_LOADING';
export const GET_ORDERS_VIEW_LIST = 'GET_ORDERS_VIEW_LIST';
export const GET_ORDER_LIFECYCLE_DETAILS = 'GET_ORDER_LIFECYCLE_DETAILS';
export const GET_ORDER_FILTER_OPTION_DATA = 'GET_ORDER_FILTER_OPTION_DATA';