import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router";
import { useLocation, useHistory } from "react-router-dom";
import { Dropdown, Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  changeFilterOrderList,
  deleteOrder,
  GetOrdersList,
  getOrderTypes,
} from "../../_redux/actions/OrdersAction";
import { formatCurrency } from "../../../../services/currency";
import PaginationLaravel from "../../../master/pagination/PaginationLaravel";
import LoadingSpinner from "../../../master/spinner/LoadingSpinner";
import OrderFilter from "./OrderFilter";
import { confirmAlert } from "react-confirm-alert";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";
import { checkFeaturePermission } from "../../../../app/modules/Auth/_redux/menu-permission/module-permission";
import OrderStatus from "../OrderStatus";
import { formatDate, getHumanDifTime } from "../../../../services/DateHelper";

const OrderList = () => {
  const location = useLocation();

  const status = parseQuery(location.search);

  const history = useHistory();

  const dispatch = useDispatch();
  const [activeStatus, setActiveStatus] = useState(status);

  const {
    ordersPaginatedData,
    ordersList,
    isLoading,
    orderTypes,
    filter
  } = useSelector((state) => state.orders);

  useEffect(() => {
    setActiveStatus(status);
    dispatch(changeFilterOrderList("status", status));

    if(orderTypes.length === 0) {
      dispatch(getOrderTypes(true));
    }
  }, [status]);

  useEffect(() => {
    if(status) {
      dispatch(
        GetOrdersList(
          ordersPaginatedData.current_page,
          filter.type,
          filter.search,
          filter.date,
          filter.customDate,
          status
        )
      );
    }
  }, [ordersPaginatedData.current_page, status]);

  const changePage = (data) => {
    dispatch(changeFilterOrderList("page", data.page));

    dispatch(
      GetOrdersList(
        data.page,
        filter.type,
        filter.search,
        filter.date,
        filter.customDate,
        status
      )
    );
  };

  const getFormattedStatus = (status) => {
    let formattedStatus = status.label;

    if (status.value === activeStatus) {
      if (typeof ordersPaginatedData !== "undefined") {
        formattedStatus += `(${ordersPaginatedData.total})`;
      }
    }
    return formattedStatus;
  };

  const handleOrderDelete = (id) => {
    confirmAlert({
      title: "Confirm To Delete Order",
      message: `All data of order will be deleted permanently. Are you sure to delete?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(deleteOrder(id, filter)),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const orderFilterHandler = ({ typeValue, searchText, dateValue, customDate}) => {
      dispatch(GetOrdersList(ordersPaginatedData.current_page, typeValue, searchText, dateValue, customDate));
  }

  return (
    <>
      <div className="container-fluid">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div
              className="row justify-content-between"
              style={{ width: "100%" }}
            >
              <div className="col-lg-3 mt-2">
                <div className="card-title">
                  <h3 className="card-label">All Orders</h3>
                </div>
              </div>
              <div className="col-lg-9">
                <OrderFilter orderFilter={orderFilterHandler} />
              </div>
            </div>
          </div>

          <Tabs
            id="order-type-controlled-tab"
            activeKey={activeStatus}
            onSelect={(status) => {
              history.replace({
                search: `status=${status}`
              })
            }}
            className="mb-3"
          >
            {orderTypes.map((orderType) => (
              <Tab
                key={orderType.value}
                eventKey={orderType.value}
                title={getFormattedStatus(orderType)}
              ></Tab>
            ))}
          </Tabs>

          <div className="card-body">
            {isLoading && <LoadingSpinner text="Loading Order Data List..." />}
            <div className="react-bootstrap-table table-responsive vh-100">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <th>Sl</th>
                    <th>Date</th>
                    <th>Order No</th>
                    <th>Status</th>
                    <th>Product Amount</th>
                    <th>Discount</th>
                    <th>Shipping</th>
                    <th>Total Paid</th>
                    <th>Payment Due</th>
                    <th>Grand Total</th>
                    <th className="f-width">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersList &&
                    ordersList.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {index + 1}
                        </td>
                        <td>
                          {formatDate(item.created_at, 'DD-MM-YY')}
                          <br />
                          <span className="text-primary">
                          {getHumanDifTime(item.created_at)}
                          </span>
                        </td>
                        <td>#{item.id}</td>
                        <td>
                          <b>Payment: </b> <OrderStatus status={item.payment_status} />
                          <br />
                          <b>Order: </b> <OrderStatus status={item.status} />
                          <br />
                          <b>Delivery: </b> <OrderStatus status={item.delivery_status} />
                        </td>
                        <td>{formatCurrency(parseFloat(item.final_total))}</td>
                        <td>
                          {formatCurrency(
                            item.discount_amount === null
                              ? 0
                              : item.discount_amount
                          )}
                        </td>
                        <td>
                          {formatCurrency(parseFloat(item.shipping_charges))}
                        </td>
                        <td>{formatCurrency(parseFloat(item.paid_total))}</td>
                        <td>{formatCurrency(parseFloat(item.due_total))}</td>
                        <td>{formatCurrency(parseFloat(item.final_total))}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle variant="primary">
                              Action
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <PermissionWiseDisplay permission_name="order.view">
                                <Dropdown.Item
                                  onClick={() =>
                                    history.push(`/transaction/view/${item.id}`)
                                  }
                                >
                                  View{" "}
                                  {checkFeaturePermission("order.edit")
                                    ? "& Edit"
                                    : ""}
                                </Dropdown.Item>
                              </PermissionWiseDisplay>
                              <Dropdown.Item
                                onClick={() =>
                                  history.push(`/orders/lifeCycle/${item.id}`)
                                }
                              >
                                Life Cycle
                              </Dropdown.Item>
                              <PermissionWiseDisplay permission_name="order.delete">
                                <Dropdown.Item
                                  onClick={() => handleOrderDelete(item.id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </PermissionWiseDisplay>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {!isLoading && ordersList && ordersList.length === 0 && (
                <div className="alert alert-warning mt-5">
                  Sorry ! No Order Found by this Query...
                </div>
              )}

              <PaginationLaravel
                changePage={changePage}
                data={ordersPaginatedData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * 
 * @param {String} -> search ex: "?status=pending". return 'pending' 
 * @returns {String}
 */

const parseQuery = (search) => {
  const searchQuery = search || '';
  const searchQueryArr = searchQuery.split('=') || [];

  return searchQueryArr[1] || ""
}

export default OrderList;
