import React from 'react';
import PollList from '../../components/Poll/PollList';

const PollListContainer = () => {
  return (
    <>
      <PollList />
    </>
  );
};

export default PollListContainer;
