import React from "react";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";
import GiftCardsEdit from "../../components/giftCards/GiftCardsEdit";


const GiftCardsEditContainer = (props) => {
    return (
        <>
            <PermissionWiseDisplay permission_name="Giftcard.Edit" display={true}>
                <GiftCardsEdit props={props} />
            </PermissionWiseDisplay>
        </>
    );
};

export default GiftCardsEditContainer;
