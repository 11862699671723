import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../../services/currency';
import OrderStatus from '../OrderStatus';

const PaymentInformation = () => {

    const { ordersViewList }      = useSelector((state) => state.orders);
    const [ payment, setPayment ] = useState({});

    useEffect(() => {
        if (ordersViewList !== null && ordersViewList.payments !== null && ordersViewList.payments.length > 0) {
            setPayment(ordersViewList.payments[0])
        }
    }, [ordersViewList]);

    return (
        <div className="tab-pane fade active show p-5" id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab">
            <h3 className="pb-5 pt-5 text-muted">Payment Information</h3>
            {
                typeof ordersViewList !== 'undefined' && ordersViewList !== null &&
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">Payment Status</label>
                            <div>
                                <OrderStatus status={ordersViewList.payment_status} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">Paid Total</label>
                            <div>
                                {formatCurrency(ordersViewList.paid_total)}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">Due Total</label>
                            <div>
                                {formatCurrency(ordersViewList.due_total)}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">Shipping Charge</label>
                            <div>
                                {formatCurrency(ordersViewList.shipping_charges)}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">Final Total</label>
                            <div>
                                {formatCurrency(ordersViewList.final_total)}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">Payment Method</label>
                            <div>
                                <span className="badge badge-success">
                                    {payment.method === 'cash' ? 'Cash on Delivery' : payment.method }
                                </span>
                                {
                                    payment.method !== 'cash' &&
                                    <div className="bg-secondary p-2 mt-2">
                                        <p>Transaction Number: <b>{payment.transaction_number}</b></p>
                                        <p>Card Holder: <b>{payment.card_holder_name}</b></p>
                                        <p>Card Number: <b>{payment.card_number}</b></p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default PaymentInformation;