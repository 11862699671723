import React, { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { RHFInput } from 'react-hook-form-input';

import { deleteBrandsImage, getSliderDetails, handleChangeSlideInput, updatedSlider } from '../_redux/actions/SliderAction';
import { GetBusinessList } from '../../master/OptionData/BusinessList/BusinessListAction/BusinessOptionListAction';
import SimpleEditor from '../../master/components/text-editor/SimpleEditor';

const SlideEdit = withRouter(({ history, props }) => {
  const { id } = useParams()
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();
  const { businessList } = useSelector((state) => state.BusinessOptionList);
  const { isLoading, sliderInput } = useSelector((state) => state.SliderReducer);

  const handleChangeTextInput = (name, value, e = null) => {
    dispatch(handleChangeSlideInput(name, value, e));
  };

  useEffect(() => {
    dispatch(GetBusinessList());
    dispatch(getSliderDetails(id))
  }, []);

  const onSubmit = (e) => {
    dispatch(updatedSlider(sliderInput, id));
  };

  return (
    <>
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 class="card-label">Edit Slider </h3>
            </div>
          </div>
          <div className="card-body">
            <form
              className="form form-label-right"
              onSubmit={handleSubmit(onSubmit)}
              method="post"
              autoComplete="off"
              encType="multipart/form-data"
            >
              <div className="form-group row">
                <div className="col-lg-3">
                  <label className="form-label mt-2">Title <span className="text-danger">*</span></label>
                  <Form.Control
                    type="text"
                    placeholder="Slide title"
                    name="title"
                    className="fromStyle"
                    value={sliderInput.title}
                    onChange={(e) => handleChangeTextInput('title', e.target.value)}
                    ref={register({
                      required: false,
                      maxLength: 100,
                    })}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="form-label mt-2">Business <span className="text-danger">*</span></label>
                  <RHFInput
                    as={<Select options={businessList} />}
                    rules={{ required: false }}
                    name="business_id"
                    register={register}
                    value={sliderInput.businessType}
                    onChange={(e) => (
                      handleChangeTextInput('business_id', e.value),
                      handleChangeTextInput('business_name', e.label)
                    )}
                    setValue={setValue}
                    ref={register({
                      required: true,
                      maxLength: 100,
                    })}
                  />
                </div>
                <div className="col-lg-3 pt-5">
                  <Form.Check
                    type="checkbox"
                    label="Is text enable?"
                    name="is_text_enable"
                    id="is_text_enable"
                    value={sliderInput.is_text_enable}
                    checked={sliderInput.is_text_enable}
                    onChange={(e) => handleChangeTextInput('is_text_enable', sliderInput.is_text_enable === 0 ? 1 : 0)}
                  />
                </div>
                {
                  sliderInput.is_text_enable === 1 && (
                    <>
                      <div className="col-lg-3">
                        <label className="form-label mt-2">Text</label>
                        <Form.Control
                          type="text"
                          placeholder="Text"
                          name="text"
                          className="fromStyle"
                          value={sliderInput.text}
                          onChange={(e) => handleChangeTextInput('text', e.target.value)}
                          ref={register({
                            required: false,
                            maxLength: 100,
                          })}
                        />
                      </div>
                      <div className="col-lg-2">
                        <label className="form-label mt-2">Text Color</label> <br />
                        <input
                          type="color"
                          name="text_color"
                          style={{ minHeight: "30px", width: "80px" }}
                          value={sliderInput.text_color}
                          onChange={(e) => handleChangeTextInput('text_color', e.target.value)}
                          ref={register({
                            required: false,
                            maxLength: 100,
                          })}
                        />
                      </div>
                    </>
                  )
                }
                <div className="col-lg-3 pt-5">
                  <Form.Check
                    type="checkbox"
                    label="Is button enable?"
                    name="is_button_enable"
                    id="is_button_enable"
                    value={sliderInput.is_button_enable}
                    checked={sliderInput.is_button_enable}
                    onChange={(e) => handleChangeTextInput('is_button_enable', sliderInput.is_button_enable === 0 ? 1 : 0)}
                  />
                </div>
                {
                  sliderInput.is_button_enable === 1 && (
                    <>
                      <div className="col-lg-3">
                        <label className="form-label mt-2">Button Text</label>
                        <Form.Control
                          type="text"
                          placeholder="Button Text"
                          name="button_text"
                          className="fromStyle"
                          value={sliderInput.button_text}
                          onChange={(e) => handleChangeTextInput('button_text', e.target.value)}
                          ref={register({
                            required: false,
                            maxLength: 100,
                          })}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label className="form-label mt-2">Button Link</label>
                        <Form.Control
                          type="text"
                          placeholder="Button Link"
                          name="button_link"
                          className="fromStyle"
                          value={sliderInput.button_link}
                          onChange={(e) => handleChangeTextInput('button_link', e.target.value)}
                          ref={register({
                            required: false,
                            maxLength: 100,
                          })}
                        />
                      </div>
                      <div className="col-lg-2">
                        <label className="form-label mt-2">Button Color</label> <br />
                        <input
                          type="color"
                          name="button_color"
                          style={{ minHeight: "30px", width: "80px" }}
                          value={sliderInput.button_color}
                          onChange={(e) => handleChangeTextInput('button_color', e.target.value)}
                          ref={register({
                            required: false,
                            maxLength: 100,
                          })}
                        />
                      </div>
                    </>
                  )
                }
                <div className="col-lg-3">
                  <div>
                    <label className="form-label mt-2">
                      Image <span className="text-danger">* 1440 X 450</span>
                    </label>
                    <Form.Control
                      type="file"
                      placeholder="Enter Slider Image"
                      name="image"
                      onChange={(e) => handleChangeTextInput('image', e.target.files[0], e)}
                      className="fromStyle"
                      ref={register({
                        required: false,
                        maxLength: 100,
                      })}
                    />
                    {
                      sliderInput.imagePreviewURl !== "" &&
                      <div className="imgPreview" title="Remove">
                        <i className="fa fa-times text-danger" onClick={() => dispatch(deleteBrandsImage('image'))}></i>
                        <img src={sliderInput.imagePreviewURl} className="img img-thumbnail" />
                      </div>
                    }
                  </div>
                  <div>
                    <label className="form-label mt-2">
                      Mobile Device Image <span className="text-danger">* 480 X 330</span>
                    </label>
                    <Form.Control
                      type="file"
                      placeholder="Mobile Slider Image"
                      name="mobile_image"
                      onChange={(e) => handleChangeTextInput('mobile_image', e.target.files[0], e)}
                      className="fromStyle"
                      ref={register({
                        required: false,
                        maxLength: 100,
                      })}
                    />
                    {
                      sliderInput.mobileImagePreviewURl !== "" &&
                      <div className="imgPreview" title="Remove">
                        <i className="fa fa-times text-danger" onClick={() => dispatch(deleteBrandsImage('mobile_image'))}></i>
                        <img src={sliderInput.mobileImagePreviewURl} className="img img-thumbnail" />
                      </div>
                    }
                  </div>
                </div>
                <div className="col-lg-5">
                  <label className="form-label mt-2">Description</label>
                  <SimpleEditor
                    name="description"
                    value={sliderInput.description}
                    onValueChange={(e) => handleChangeTextInput('description', e)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-sm-10">

                  <button type="button" class="btn btn-secondary btn-lg mr-2" onClick={() => { history.push('/slider/list') }}>
                    Back
                  </button>

                  {isLoading &&
                    <button type="submit" class="btn btn-primary btn-lg" disabled={true} >
                      <span>Submit</span>
                      <span className="ml-3 spinner spinner-white"></span>
                    </button>
                  }

                  {!isLoading &&
                    <button type="submit" class="btn btn-primary btn-lg">
                      <span>Submit</span>
                    </button>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
});

export default SlideEdit;
