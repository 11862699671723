import * as Types from '../../types/Types';

const initialState = {
    itemInput: {
        business_id: '',
        unit_id: '',
        per_unit_value: '1',
        category_id: '',
        sub_category_id: '',
        sub_category_id2: '',
        brand_id: '',
        barcode_type: '',
        tax_type: '',
        type: '',
        name: '',
        tax: '',
        enable_quantity: '',
        enable_stock: true,
        alert_quantity: 5,
        sku: '',
        status: 'pending',
        barcode_type: '',
        sku_manual: '',
        created_by: 1,
        featured_image: null,
        featured_image_preview: null,
        short_resolation_image: null,
        short_resolation_image_preview: null,
        current_stock: 0,
        vendor_price: 0,
        default_selling_price: 0,
        is_offer_enable: false,
        offer_selling_price: 0,
        description: '',
        product_image: null,
        product_image_preview: null,
        attribute_id: '',
        values: [],
        attributes: [],
        deleted_attributes: [],
        deleted_images: [],
        images: [],
        isMoreOpen: false,
        isProductAttributeOpen: false,
        approx_delivery_time: 0,
        shipping_charge: 0
    },
    itemDetails: null,
    isItemAdded: false,
    itemAddMessage: '',
    isDetailLoaded: false,
    isItemEdited: false,
    itemEditMessage: '',
    isLoading: false,
    ProductList: [],
    ProductPaginatedList: {
        current_page: 1
    },
    BarcodeType: [],
    itemTypes: [],
    itemStatuses: [],
    filter: {
        page    : 1,
        brand   : null,
        category: null,
        search  : '',
        sku     : '',
        status  : '',
        shop    : null,
        type    : null,
        unit    : null,
    },
    uploadProgress: 0
};

const ItemReducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case Types.CHANGE_ITEM_INPUT:
            const itemInput = { ...state.itemInput };
            itemInput[action.payload.name] = action.payload.value;
            return {
                ...state,
                itemInput
            }

        case Types.GET_ITEM_TYPE_LIST:
            return {
                ...state,
                itemTypes: action.payload
            }

        case Types.GET_ITEM_STATUS_LIST:
            return {
                ...state,
                itemStatuses: action.payload
            }

        case Types.DELETE_SURVEY_MULTIPLE_ATTACHMENT:
            const multiDataSetAttachment = { ...state.itemInput };
            multiDataSetAttachment.images.splice(action.payload, 1);
            return {
                ...state,
                itemInput: multiDataSetAttachment,
            };

        case Types.DELETE_SURVEY_MULTIPLE_ATTACHMENT_EDIT:
            const multiDataSetAttachmentEdit = { ...state.itemInput };
            multiDataSetAttachmentEdit.images.splice(action.payload, 1);
            return {
                ...state,
                itemInput: multiDataSetAttachmentEdit,
            };

        case Types.ADD_ITEM:
            if (action.payload.status) {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                    itemInput: initialState.itemInput,
                    isItemAdded: true,
                    itemAddMessage: action.payload.message
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            }

        case Types.GET_BARCODE_TYPE:
            return {
                ...state,
                BarcodeType: action.payload
            };

        case Types.EMPTY_ITEM_INPUT:
            return {
                ...state,
                isLoading: false,
                isItemAdded: false,
                itemInput: initialState.itemInput,
                itemAddMessage: '',
                isItemEdited: false,
                itemEditMessage: '',
            };

        case Types.GET_ITEM_LIST:
            if (action.payload.status) {
                return {
                    ...state,
                    ProductList: action.payload.ProductList,
                    ProductPaginatedList: action.payload.ProductPaginatedList,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    isLoading: action.payload.isLoading
                };
            }

        case Types.GET_ITEM_DETAILS:
            if (action.payload.status) {
                return {
                    ...state,
                    itemInput: action.payload.data,
                    itemDetails: action.payload.data,
                    isLoading: action.payload.isLoading,
                    isDetailLoaded: action.payload.isDetailLoaded,
                }
            } else {
                return {
                    ...state,
                    isLoading: false
                }
            }

        case Types.EDIT_ITEM:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                isItemEdited: action.payload.isItemEdited,
                itemEditMessage: action.payload.itemEditMessage,
            };

        case Types.DELETE_PRODUCT_ITEMS:
            return {
                ...state,
                isLoading: action.payload,
            };

        case Types.EMPTY_DISPATCH:
            return {
                ...state,
                isLoading: false,
                itemInput: initialState.itemInput,
                isDetailLoaded: false
            };

        case Types.ADD_MULTIPLE_ATTRIBUTE_VALUE:
            const newItemInput = { ...state.itemInput };
            newItemInput.attributes.push(action.payload);
            return {
                ...state,
                itemInput: newItemInput
            }

        case Types.DELETE_MULTIPLE_ATTRIBUTES_VALUES:
            const MultipleAttributesValues = { ...state.itemInput };
            MultipleAttributesValues.attributes.splice(action.payload, 1);
            return {
                ...state,
                itemInput: MultipleAttributesValues,
            };

        case Types.DELETE_MULTIPLE_ATTRIBUTES_VALUES_EDIT:
            const MultipleAttributesValuesEdit = { ...state.itemInput };
            MultipleAttributesValuesEdit.attributes.splice(action.payload, 1);
            return {
                ...state,
                itemInput: MultipleAttributesValuesEdit,
            };

        case Types.CHANGE_ITEM_FILTER:
            let filter = {...state.filter};
            filter[action.payload.name] = action.payload.value;

            return {
                ...state,
                filter
            };

        case Types.BULK_UPLOAD_PROGRESS:
            return {
                ...state,
                uploadProgress: action.payload
            };

        default:
            break;
    }

    return newState;
};

export default ItemReducer;
