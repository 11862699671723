import React from "react";
import PermissionWiseDisplay from "../../../../app/modules/role/PermissionWiseDisplay";
import RolePermissionEdit from "../components/RolePermissionEdit";

export default function RolePermissionEditContainer() {
  return (
    <div>
      <PermissionWiseDisplay permission_name="Role.Edit" display={true} >
        <RolePermissionEdit />
      </PermissionWiseDisplay>
    </div>
  );
}
