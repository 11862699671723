import React, { useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ErrorMessage from "../../../../modules/master/ErrorMessage/ErrorMessage";
import { resetPassword } from "../_redux/actions/forget-password-action";

export default function ResetPassword() {
    const { register, errors, handleSubmit, watch } = useForm({});
    const dispatch = useDispatch();
    const history = useHistory();
    const { otp, isLoading, passwordUpdated, email } = useSelector(state => state.ForgetPasswordReducer)
    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = async data => {
        dispatch(resetPassword(otp, email, data.password));
    };

    useEffect(() => {
        if (passwordUpdated) {
            history.push('/auth/login')
        }
    }, [passwordUpdated, history])

    return (
        <>
            <div className="forget-password my-5 row justify-content-center">
                <div className='col-md-6 container card card-body p-4'>
                    <div className="">
                        <h4 className="pb-3">Reset password</h4>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="bg-white p-4">
                                    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                                        <div>
                                            <div className="mb-3">
                                                <label className="d-block" htmlFor="password">Password</label>
                                                <input
                                                    className="form-control"
                                                    name="password"
                                                    type="password"
                                                    placeholder="Please enter your password"
                                                    ref={register({
                                                        required: "You must specify a password",
                                                        minLength: {
                                                            value: 6,
                                                            message: "Password must have at least 6 characters"
                                                        }
                                                    })}
                                                />

                                                {errors.password && (
                                                    <ErrorMessage errorText={errors.password.message} />
                                                )}

                                            </div>
                                        </div>

                                        <div>
                                            <div className="mb-3">
                                                <label className="d-block" htmlFor="password_repeat">Retype password</label>
                                                <input
                                                    className="form-control"
                                                    name="password_repeat"
                                                    type="password"
                                                    placeholder="Please retype your password"
                                                    ref={register({
                                                        validate: value =>
                                                            value === password.current || "passwords do not match"
                                                    })}
                                                />

                                                {errors.password_repeat && (
                                                    <ErrorMessage errorText={errors.password_repeat.message} />
                                                )}

                                            </div>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-success btn-sign-up">
                                                Reset password
                                                &#8203;
                                                {
                                                    isLoading && (
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    )
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white p-4">
                                    <div className="alert bg-light">
                                        <span className="text-danger">Standard Password Hint: </span>
                                        <ul>
                                            <li>Minimum 6 characters</li>
                                            <li>Minimum 1 Uppercase & 1 Lowercase Letter</li>
                                            <li>Minimum 1 Digit</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}