import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../../../modules/master/utils/ToastHelper";
import { generateFormDataFromObject } from "../../../../../../modules/master/utils/FileHelper";

export const ChangeVoucherInputData = (name, value, e = null) => (dispatch) => {
    const formData = {
        name: name,
        value: value,
        e,
    }
    dispatch({ type: Types.CHANGE_VOUCER_INPUT_DATA, payload: formData });
    if (name === 'image') {
        let reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            if (name === "image") {
                formData.name = 'imagePreviewURl';
            }
            formData.value = reader.result;
            dispatch({ type: Types.CHANGE_VOUCER_INPUT_DATA, payload: formData });
        }
        reader.readAsDataURL(file)
    }
}
//delete preview image url
export const deletePreviewImage = (name) => (dispatch) => {
    let formData = {
        name: name,
        value: null,
    }
    dispatch({ type: Types.CHANGE_VOUCER_INPUT_DATA, payload: formData });
    if (name === 'image') {
        formData.name = 'imagePreviewURl';
    }
    dispatch({ type: Types.CHANGE_VOUCER_INPUT_DATA, payload: formData });
};

// submit gift card 
export const submitVoucher = (giftInputData) => (dispatch => {
    if (giftInputData.title === "" || giftInputData.title === null) {
        showToast('error', "Title Can't be blank!");
        return false;
    }
    if (giftInputData.price_value_for === "" || giftInputData.price_value_for === null) {
        showToast('error', "Price value Can't be blank!");
        return false;
    }
    if (giftInputData.change_price_value === "" || giftInputData.change_price_value === null) {
        showToast('error', "Change value Can't be blank!");
        return false;
    }
    if (giftInputData.description === "" || giftInputData.description === null) {
        showToast('error', "Description Can't be blank!");
        return false;
    }

    let responseData = {
        status: false,
        message: "",
        isLoading: true,
    };
    dispatch({ type: Types.SUBMIT_VOUCHER, payload: responseData });
    const headers = {
        "Content-Type": "application/json",
    };

    const giftData = generateFormDataFromObject(giftInputData)
    Axios.post(`${process.env.REACT_APP_API_URL}vouchers`, giftData, {
        headers: headers,
    }).then((res) => {
        if (res.data.status) {
            let data = res.data;
            responseData.message = data.message;
            responseData.status = data.status;
            responseData.isLoading = false;
            showToast('success', responseData.message);
            dispatch({ type: Types.SUBMIT_VOUCHER, payload: responseData });
        }
    }).catch((err) => {
        const { response } = err;
        const { request, ...errorObject } = response;
        responseData.isLoading = false;
        showToast('error', response.data.message);
        dispatch({ type: Types.SUBMIT_VOUCHER, payload: responseData });
    });
})

//get voucher list data
export const getVoucherList = (page, searchText = null) => async (dispatch) => {
    let response = {
        couponList: [],
        status: false,
        message: "",
        isLoading: true,
        errors: [],
    };
    dispatch({ type: Types.GET_VOUCHER_LIST, payload: response });
    let url = "";
    url = `${process.env.REACT_APP_API_URL}vouchers?isPaginated=1&paginateNo=10`;

    if (page !== null || page === "") {
        url += `&page=${page}`;
    }


    if (searchText !== null) {
        url += `&search=${searchText}`;
    } else {
        // url += `&certificate/details?search=${searchText}`
    }
    // if (couponType !== null) {
    //     url += `&coupon_type_id=${couponType}`;
    // }
    // if (amountType !== null) {
    //     url += `&amount_type=${amountType}`;
    // }


    try {
        await Axios.get(url)
            .then((res) => {
                const { data, message, status } = res.data;
                response.status = status;
                response.voucherListData = data.data;
                response.message = message;
                response.voucherPaginateList = data;
                response.isLoading = false;
                dispatch({ type: Types.GET_VOUCHER_LIST, payload: response });
            })
            .catch((err) => {
                // toast.error(err);
            });
    } catch (error) {
        response.isLoading = false;
        dispatch({ type: Types.GET_VOUCHER_LIST, payload: response });        // toast.error(error);
    }
    // response.isLoading = false;
    // dispatch({ type: Types.GET_VOUCHER_LIST, payload: response });
};

//delete gift data list 
export const handleVoucherDelete = (id) => (dispatch) => {
    let isLoading = true;
    dispatch({ type: Types.DELETE_VOUCHER_DATA, payload: isLoading })
    Axios.delete(`${process.env.REACT_APP_API_URL}vouchers/${id}`)
        .then((res) => {
            if (res.data.status) {
                const data = res.data;
                showToast('success', data.message);
                dispatch({ type: Types.DELETE_VOUCHER_DATA, payload: false });
                dispatch(getVoucherList())
            }
        })
}

export const getVoucherDeails = (id) => async (dispatch) => {
    let response = {
        status: false,
        message: "",
        isLoading: true,
        errors: [],
        voucherDetails: {},
    };
    dispatch({ type: Types.GET_VOUCHER_DETAILS, payload: response });

    await Axios.get(`${process.env.REACT_APP_API_URL}vouchers/${id}`)
        .then((res) => {
            const { data, message, status } = res.data;
            response.status = status;
            response.message = message;
            response.voucherDetails = data;
            response.isLoading = false;
            dispatch({ type: Types.GET_VOUCHER_DETAILS, payload: response });
        })
        .catch((err) => {
            response.isLoading = false;
            dispatch({ type: Types.GET_VOUCHER_DETAILS, payload: response });
        });
}

//updated gift Cards 
export const updatedVoucher = (giftInputData, id) => (dispatch => {
    if (giftInputData.title === "" || giftInputData.title === null) {
        showToast('error', "Title Can't be blank!");
        return false;
    }
    if (giftInputData.price_value_for === "" || giftInputData.price_value_for === null) {
        showToast('error', "Price value Can't be blank!");
        return false;
    }
    if (giftInputData.change_price_value === "" || giftInputData.change_price_value === null) {
        showToast('error', "Change value Can't be blank!");
        return false;
    }
    if (giftInputData.description === "" || giftInputData.description === null) {
        showToast('error', "Description Can't be blank!");
        return false;
    }

    let responseData = {
        status: false,
        message: "",
        isLoading: true,
    };
    dispatch({ type: Types.UPDATED_VOUCHER_DATA, payload: responseData });
    const headers = {
        "Content-Type": "application/json",
    };

    const giftData = generateFormDataFromObject(giftInputData)
    Axios.post(`${process.env.REACT_APP_API_URL}vouchers/${id}?_method=PUT`, giftData, {
        headers: headers,
    }).then((res) => {
        if (res.data.status) {
            let data = res.data;
            responseData.message = data.message;
            responseData.status = data.status;
            responseData.isLoading = false;
            showToast('success', responseData.message);
            dispatch({ type: Types.UPDATED_VOUCHER_DATA, payload: responseData });
        }
    }).catch((err) => {
        const { response } = err;
        const { request, ...errorObject } = response;
        responseData.isLoading = false;
        showToast('error', response.data.message);
        dispatch({ type: Types.UPDATED_VOUCHER_DATA, payload: responseData });
    });
})
