import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from 'react-redux';
import { getBestSoldProductsAction } from "./redux/BestSoldAction";

const BestSoldProductChart = () => {
  const dispatch = useDispatch();
  const labels = useSelector(state => state.bestSoldReducer.bestSoldProductsLabels);
  const data = useSelector(state => state.bestSoldReducer.bestSoldProductsData);
  // const isLoading = useSelector(state => state.bestSoldReducer.isLoading);

  const barProperties = {
    labels: labels,

    datasets: [
      {
        label: "Sales Amount",
        backgroundColor: [
          "rgba(54, 153, 255, 1)",
        ],
        borderColor: [],

        borderWidth: 1,
        data: data,
      },
    ],
  };

  useEffect(() => {
    dispatch(getBestSoldProductsAction());
  }, []);

  return (
    <>
      <div className="chart__box chart__box__custom bg-white">
        <div className="chart__head">
          <h4 className="text-bold">Best Sold Products 
            <span className="ml-2 badge badge-info">Top 10</span>
          </h4>
        </div>
        <div className="test">
          <Bar
            data={barProperties}
            width="100%"
            height="80%"
            options={
              {
                // title: {
                //   display: true,
                //   text: "User Count",
                //   fontSize: 12,
                // },
                // legend: {
                //   display: true,
                //   position: "left",
                // },
              }
            }
          />
        </div>
      </div>
    </>
  );
};

export default BestSoldProductChart;
