import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { copyToClipboard } from '../../../services/KeyboardHelper';
import { showToast } from '../../master/utils/ToastHelper';

const FileManagerSingle = ({ document }) => {
    const { directory } = useParams();

    /**
     * Get directory Link to go to next directory.
     *
     * @return {string}
     */
    const getDirectoryLink = () => {
        let link = `/file-manager/`;

        if (typeof directory !== 'undefined' && directory !== '' && directory !== null) {
            link += `${directory}/`;
        }

        return `${link}${document.id}`
    }

    /**
     * Copy to Clipboard. Added aditional toaster.
     *
     * @param {string} url
     */
    const copyToClipboardUrl = (url) => {
        copyToClipboard(url);
        showToast('success', 'Url Copied to Clipboard.');
    }

    return (
        <div className="col-md-2">
            {
                document.type === 'directory' &&
                <Link to={getDirectoryLink()}>
                    <i className="fa fa-folder text-warning" style={{ fontSize: 80 }}></i>
                    <br />
                    {document.name}
                </Link>
            }

            {
                document.type === 'file' &&
                <div>
			        <p className="text-center" style={{ fontSize: 10 }}>
                        <img src={document.link} width={80} />
                        <br />
                        <span className="text-info pointer" onClick={() => copyToClipboardUrl(document.link)} title="Click to Copy Link">
                            Copy
                        </span>
                    </p>
                </div>
            }
        </div>
    );
}

export default FileManagerSingle;