import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { RHFInput } from 'react-hook-form-input';
import { useHistory, useParams } from 'react-router-dom';
import Select from "react-select";
import { useForm } from "react-hook-form";
import { changeItemInput, deleteProductImagePreview, emptyDispatch, getBarcodeType, ProductMultipleAttachmentDelete, handleAddMultiAttributeValue, emptyItemInputs, getItemDetails, updatedProductItem, getItemTypes, getItemStatuses } from '../_redux/actions/ItemAction';
import { getBrandListOptonData } from '../../master/OptionData/BrandOptionList/BrandOptionListAction/BrandOptionListAction';
import { GetBusinessList } from '../../master/OptionData/BusinessList/BusinessListAction/BusinessOptionListAction';
import { GetParentCategoriesList } from '../../master/OptionData/ParentCategory/ParentCategoryAction/ParentCategoryOptionListAction';
import { getUnitOptionList } from '../../master/OptionData/Unit/UnitOptionListAction/UnitOptionListAction';
import { getTaxTypeOptionList } from '../../master/OptionData/TaxType/TaxTypeOptionListAction/TaxTypeOptionListAction';
import { getCategoriListLevel2, getCategoriListLevel3 } from '../../master/OptionData/SubCategory/SubCategoryAction/SubCategoryListAction';
import SimpleEditor from '../../master/components/text-editor/SimpleEditor';
import FileBase64 from "react-file-base64";
import MultipplePreviewAttachment from '../../master/components/previews/MultiplePreviewAttachment';
import { getAttributeList } from '../../master/OptionData/AttributeOptionList/AttributeOptionListAction/AttributeOptionListAction';
import makeAnimated from 'react-select/animated';
import { getAtributesValuesList } from '../../master/OptionData/AttributeValues/AttributeValuesListAction/AttributeValuesListAction';
import MultipleAttributeTable from './MultipleAttributeTable';
import LabelOptional from '../../master/components/labels/LabelOptional';
import LabelRequired from '../../master/components/labels/LabelRequired';
// import Scrollspy from 'react-scrollspy';
import TextHelp from '../../master/components/labels/TextHelp';
import LoadingSpinner from '../../master/spinner/LoadingSpinner';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import { getSelectedOption } from '../../../services/FormatData';

const animatedComponents = makeAnimated();

const ItemEdit = () => {
    const { id } = useParams();
    const { register, handleSubmit, errors, setValue } = useForm();
    const dispatch = useDispatch();
    const history = useHistory()
    const BrandsOptionList = useSelector((state) => state.BrandOptionListReducer.BrandsOptionList);
    const businessList = useSelector((state) => state.BusinessOptionList.businessList);
    const parentCategoryList = useSelector((state) => state.parentCategoryList.parentCategoryList);
    const UnitOptionList = useSelector((state) => state.UnitOptionListReducer.UnitOptionList);
    const taxOptionList = useSelector((state) => state.TaxTypeOptionListReducer.taxTypeOptionList);
    const SubCategoryOptionList2 = useSelector((state) => state.SubCategoryReducer.SubCategoryOptionList2);
    const SubCategoryOptionList3 = useSelector((state) => state.SubCategoryReducer.SubCategoryOptionList3);
    const attributeList = useSelector((state) => state.AttributeListReducer.attributeList);
    const { BarcodeType, itemInput, isLoading, itemTypes, itemStatuses } = useSelector((state) => state.item);
    const AttributeValuesList = useSelector((state) => state.AttributeValuesReducer.AttributeValuesList);

    useEffect(() => {
        dispatch(emptyDispatch())
        dispatch(getBrandListOptonData())
        dispatch(GetBusinessList())
        dispatch(GetParentCategoriesList())
        dispatch(getUnitOptionList())
        dispatch(getTaxTypeOptionList())
        dispatch(getBarcodeType())
        dispatch(getItemDetails(id))
        dispatch(getItemTypes())
        dispatch(getItemStatuses(true, id))
    }, [])

    const handleChangeTextInput = (name, value, e = null) => {
        dispatch(changeItemInput(name, value, e, itemInput));
    }

    const taxType = [
        { label: 'Inclusive', value: 'inclusive' },
        { label: 'Exclusive', value: 'exclusive' }
    ]

    const getFiles = (files) => {
        if (files.length > 0) {
            files.forEach((file) => {
                const filesUpdated = [
                    file,
                    ...itemInput.images,
                ];
                dispatch(
                    changeItemInput("images", filesUpdated)
                );
            });
        }
    };

    const [attachmentPreviewModel, setAttachmentPreviewModel] = useState(false);
    const [previewAttachment, setPreviewAttachment] = useState(false);
    const PreviewAttachment = (attachment) => {
        setAttachmentPreviewModel(true);
        setPreviewAttachment(attachment)
    }
    const deleteMultipleAttachmentData = (index, attachment_image) => {
        if (attachment_image.id > 0) {
            const deleted_images = [...itemInput.deleted_images, attachment_image];
            dispatch(changeItemInput("deleted_images", deleted_images));
        }
        dispatch(ProductMultipleAttachmentDelete(index));
    };

    const onSubmit = async (e) => {
        dispatch(updatedProductItem(itemInput, id))
    };
    const addMultipleAttributeValues = () => {
        dispatch(handleAddMultiAttributeValue(itemInput))
    }

    const isItemEdited = useSelector((state) => state.item.isItemEdited);
    const itemEditMessage = useSelector((state) => state.item.itemEditMessage);

    useEffect(() => {
        if (isItemEdited && itemEditMessage.length > 0) {
            dispatch(emptyItemInputs());
            history.goBack();
        }
    }, [isItemEdited, itemEditMessage]);

    const isDetailLoaded = useSelector((state) => state.item.isDetailLoaded);
    useEffect(() => {
        if (isDetailLoaded) {
            dispatch(getCategoriListLevel2(itemInput.category_id));
            dispatch(getCategoriListLevel3(itemInput.sub_category_id));
            dispatch(getAttributeList(itemInput.sub_category_id2))
        }
    }, [isDetailLoaded]);

    return (
        <>
            <div className="container-fluid">
                <div className="card card-custom gutter-b card-top-border">
                    {/* <div>
                        <ul className="product-tab">
                            <Scrollspy items={['section-product-basic-information', 'section-product-images', 'section-product-attributes', 'section-product-more-informations']} currentClassName="is-current">
                                <li><a href="#section-product-basic-information">Products Basic Information</a></li>
                                <li><a href="#section-product-images">Product Images</a></li>
                                <li><a href="#section-product-attributes">Product Attributes</a></li>
                                <li><a href="#section-product-more-informations">Product More Informations</a></li>
                            </Scrollspy>
                        </ul>
                    </div> */}
                    {
                        isDetailLoaded &&
                        <div className="card-body">
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                method="post"
                                autoComplete="off"
                                encType="multipart/form-data">

                                {/**===================== Product Basic Information ==================== */}
                                <section id="section-product-basic-information">
                                    <div className="card card-body p-2 mb-3 mt-3">
                                        <h4>Basic Informations - <mark>{itemInput.sku_manual}</mark></h4>
                                        <div className="form-group row">

                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Product Name <LabelRequired /></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Product name"
                                                    name="name"
                                                    className="fromStyle"
                                                    value={itemInput.name}
                                                    onChange={(e) => handleChangeTextInput('name', e.target.value)}
                                                    ref={register({
                                                        required: true,
                                                        maxLength: 145,
                                                    })}
                                                />
                                                <div className="inputError margin-minus-8">
                                                    {errors.name &&
                                                        errors.name.type === 'required' &&
                                                        "Please give product name !"}
                                                </div>
                                            </div>

                                            <PermissionWiseDisplay permission_name="admin_seller" display={false}>
                                                <div className="col-lg-3">
                                                    <label className="form-label mt-2">Business <LabelRequired /></label>
                                                    <RHFInput
                                                        as={<Select options={businessList} />}
                                                        rules={{ required: true }}
                                                        name="business_id"
                                                        register={register}
                                                        value={itemInput.business}
                                                        defaultValue={itemInput.business}
                                                        setValue={setValue}
                                                        onChange={(option) => (
                                                            handleChangeTextInput("business_id", option.value),
                                                            handleChangeTextInput("business_name", option.label),
                                                            handleChangeTextInput("business", option)
                                                        )}
                                                    />
                                                    <div className="inputError margin-minus-8">
                                                        {errors.business_id &&
                                                            errors.business_id.type === 'required' &&
                                                            "Please select your business !"}
                                                    </div>
                                                </div>
                                            </PermissionWiseDisplay>

                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Category Level-1 <LabelRequired /></label>
                                                <RHFInput
                                                    as={<Select options={parentCategoryList} />}
                                                    rules={{ required: true }}
                                                    name="category_id"
                                                    register={register}
                                                    value={itemInput.category1}
                                                    defaultValue={itemInput.category1}
                                                    setValue={setValue}
                                                    onChange={(option) => (
                                                        handleChangeTextInput("category_id", option.value),
                                                        handleChangeTextInput("category_name", option.label),
                                                        handleChangeTextInput("category1", option),
                                                        setValue('sub_category_id', ""),
                                                        dispatch(getCategoriListLevel2(option.value))
                                                    )}
                                                />
                                                <div className="inputError margin-minus-8">
                                                    {errors.category_id &&
                                                        errors.category_id.type === 'required' &&
                                                        "Please select category label 1 !"}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Category Level-2 <LabelRequired /></label>
                                                <RHFInput
                                                    as={<Select options={SubCategoryOptionList2} />}
                                                    rules={{ required: true }}
                                                    name="sub_category_id"
                                                    register={register}
                                                    value={itemInput.category2}
                                                    defaultValue={itemInput.category2}
                                                    setValue={setValue}
                                                    onChange={(option) => (
                                                        handleChangeTextInput("sub_category_id", option.value),
                                                        handleChangeTextInput("sub_category_name", option.label),
                                                        handleChangeTextInput("category2", option),
                                                        setValue('sub_category_id2', ""),
                                                        dispatch(getCategoriListLevel3(option.value)),
                                                        dispatch(getAttributeList(option.value))
                                                    )}
                                                />
                                                <div className="inputError margin-minus-8">
                                                    {errors.sub_category_id &&
                                                        errors.sub_category_id.type === 'required' &&
                                                        "Please select category label 2 !"}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Category Level-3 <LabelOptional /></label>
                                                <RHFInput
                                                    as={<Select options={SubCategoryOptionList3} />}
                                                    rules={{ required: false }}
                                                    name="sub_category_id2"
                                                    register={register}
                                                    value={itemInput.category3}
                                                    defaultValue={itemInput.category3}
                                                    setValue={setValue}
                                                    onChange={(option) => (
                                                        handleChangeTextInput("sub_category_id2", option.value),
                                                        handleChangeTextInput("sub_category_name2", option.label),
                                                        handleChangeTextInput("category2", option),
                                                        dispatch(getAttributeList(option.value))
                                                    )}
                                                />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Brand <LabelRequired /></label>
                                                <RHFInput
                                                    as={<Select options={BrandsOptionList} />}
                                                    rules={{ required: true }}
                                                    name="brand_id"
                                                    register={register}
                                                    value={itemInput.brand}
                                                    defaultValue={itemInput.brand}
                                                    setValue={setValue}
                                                    onChange={(option) => (
                                                        handleChangeTextInput("brand_id", option.value),
                                                        handleChangeTextInput("brand_name", option.label),
                                                        handleChangeTextInput("brand", option)
                                                    )}
                                                />
                                                <div className="inputError margin-minus-8">
                                                    {errors.brand_id &&
                                                        errors.brand_id.type === 'required' &&
                                                        "Please select brand name !"}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Per Unit Value <LabelOptional /></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="1"
                                                    name="per_unit_value"
                                                    className="fromStyle"
                                                    min="0"
                                                    value={itemInput.per_unit_value}
                                                    onChange={(e) => handleChangeTextInput('per_unit_value', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Unit of Measurement<LabelRequired /></label>
                                                <RHFInput
                                                    as={<Select options={UnitOptionList} />}
                                                    rules={{ required: true }}
                                                    name="unit_id"
                                                    register={register}
                                                    value={itemInput.unit}
                                                    defaultValue={itemInput.unit}
                                                    setValue={setValue}
                                                    onChange={(option) => (
                                                        handleChangeTextInput("unit_id", option.value),
                                                        handleChangeTextInput("unit_name", option.label),
                                                        handleChangeTextInput("unit", option)
                                                    )}
                                                />
                                                <div className="inputError margin-minus-8">
                                                    {errors.unit_id &&
                                                        errors.unit_id.type === 'required' &&
                                                        "Please select Unit of Measurement !"}
                                                </div>
                                            </div>

                                            <PermissionWiseDisplay permission_name="admin_seller" display={false}>
                                                <div className="col-lg-3">
                                                    <label className="form-label mt-2">Item Type <LabelOptional /></label>
                                                    <RHFInput
                                                        as={<Select options={itemTypes} />}
                                                        rules={{ required: false }}
                                                        name="type"
                                                        register={register}
                                                        value={getSelectedOption(itemTypes, itemInput.type)}
                                                        setValue={setValue}
                                                        isClearable
                                                        onChange={(option) => {
                                                            if (option !== null && typeof option.value !== 'undefined') {
                                                                handleChangeTextInput("type", option.value)
                                                            } else {
                                                                handleChangeTextInput("type", "")
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </PermissionWiseDisplay>


                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Status <LabelOptional /></label>
                                                <RHFInput
                                                    as={<Select options={itemStatuses} />}
                                                    rules={{ required: false }}
                                                    name="status"
                                                    register={register}
                                                    value={getSelectedOption(itemStatuses, itemInput.status)}
                                                    setValue={setValue}
                                                    onChange={(option) => handleChangeTextInput("status", option.value)}
                                                />
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Product Short Name <LabelOptional /></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Short Name. eg; samsung-gallaxy-j10"
                                                    name="sku"
                                                    register={register}
                                                    className="fromStyle"
                                                    value={itemInput.sku}
                                                    onChange={(e) => handleChangeTextInput("sku", e.target.value)}
                                                />
                                                <TextHelp text="Keep Empty to auto generate" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Delivery time<LabelOptional /></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="ex: 1080"
                                                    name="approx_delivery_time"
                                                    register={register}
                                                    className="fromStyle"
                                                    value={itemInput.approx_delivery_time}
                                                    onChange={(e) => handleChangeTextInput("approx_delivery_time", e.target.value)}
                                                />
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Shipping charge<LabelOptional /></label>
                                                <Form.Control
                                                    type="text"
                                                    name="shipping_charge"
                                                    register={register}
                                                    className="fromStyle"
                                                    value={itemInput.shipping_charge}
                                                    onChange={(e) => handleChangeTextInput("shipping_charge", e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card card-body p-2 mb-3">
                                        <h4>Stock & Pricing Informations</h4>
                                        <div className="form-group row">
                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Tax Type <LabelRequired /></label>
                                                <RHFInput
                                                    as={<Select options={taxType} defaultValue={itemInput.taxType} />}
                                                    rules={{ required: true }}
                                                    name="tax_type"
                                                    register={register}
                                                    value={itemInput.taxType}
                                                    defaultValue={itemInput.taxType}
                                                    setValue={setValue}
                                                    onChange={(option) => (
                                                        handleChangeTextInput("tax_type", option.value),
                                                        handleChangeTextInput("taxType", option)
                                                    )
                                                    }
                                                />
                                                <div className="inputError margin-minus-8">
                                                    {errors.tax_type &&
                                                        errors.tax_type.type === 'required' &&
                                                        "Please select a Tax type !"}
                                                </div>
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">
                                                    Tax <LabelRequired />
                                                </label>
                                                <RHFInput
                                                    as={<Select options={taxOptionList} />}
                                                    rules={{ required: true }}
                                                    name="tax"
                                                    register={register}
                                                    value={itemInput.taxList}
                                                    defaultValue={itemInput.taxList}
                                                    setValue={setValue}
                                                    onChange={(option) => (
                                                        handleChangeTextInput("tax", option.value),
                                                        handleChangeTextInput("taxList", option)
                                                    )}
                                                />
                                                <div className="inputError margin-minus-8">
                                                    {errors.tax &&
                                                        errors.tax.type === 'required' &&
                                                        "Please select a Tax !"}
                                                </div>
                                            </div>

                                            <div className="col-lg-3 mt-10">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Enable Stock"
                                                    name="enable_stock"
                                                    register={register}
                                                    value={itemInput.enable_stock}
                                                    checked={itemInput.enable_stock}
                                                    onChange={(e) => handleChangeTextInput('enable_stock', itemInput.enable_stock === false ? true : false)}
                                                />
                                            </div>
                                            {
                                                itemInput.enable_stock &&
                                                <>
                                                    <div className="col-lg-3">
                                                        <label className="form-label mt-2">Current Stock</label>
                                                        <Form.Control
                                                            type="number"
                                                            name="current_stock"
                                                            className="fromStyle form-control"
                                                            defaultValue={0}
                                                            min={0}
                                                            value={itemInput.current_stock}
                                                            onChange={(e) => handleChangeTextInput("current_stock", e.target.value)}
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <label className="form-label mt-2">Stock Alert Quantity</label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Enter Alert Quantity"
                                                            name="alert_quantity"
                                                            ref={register({
                                                                required: false,
                                                            })}
                                                            min={0}
                                                            defaultValue={5}
                                                            className="fromStyle"
                                                            onChange={(e) => handleChangeTextInput("alert_quantity", e.target.value)}
                                                        />
                                                        <TextHelp text="You'll get notification when stock goes down this quanty" />
                                                    </div>
                                                </>
                                            }

                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Vendor Price <LabelOptional /></label>
                                                <InputGroup>
                                                    <Form.Control
                                                        type="number"
                                                        min={0}
                                                        max={itemInput.default_selling_price != 0 ? itemInput.default_selling_price : 1000000000}
                                                        name="vendor_price"
                                                        className="fromStyle form-control"
                                                        value={itemInput.vendor_price}
                                                        defaultValue={itemInput.vendor_price}
                                                        onChange={(e) => handleChangeTextInput("vendor_price", e.target.value)}
                                                        ref={register({
                                                            required: true,
                                                        })}
                                                    />
                                                    <InputGroup.Text>৳</InputGroup.Text>
                                                </InputGroup>
                                                <TextHelp text="Vendor Price Should be less than mrp" />
                                            </div>

                                            <div className="col-lg-3">
                                                <label className="form-label mt-2">Mrp <LabelRequired /></label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        type="number"
                                                        min={0}
                                                        name="default_selling_price"
                                                        className="fromStyle form-control"
                                                        value={itemInput.default_selling_price}
                                                        defaultValue={itemInput.default_selling_price}
                                                        onChange={(e) => handleChangeTextInput("default_selling_price", e.target.value)}
                                                        ref={register({
                                                            required: true,
                                                        })}
                                                    />
                                                    <InputGroup.Text>৳</InputGroup.Text>
                                                </InputGroup>
                                                <div className="inputError margin-minus-8">
                                                    {errors.default_selling_price &&
                                                        errors.default_selling_price.type === 'required' &&
                                                        "Please give product Mrp!"}
                                                </div>
                                            </div>

                                            <div className="col-lg-3 mt-10">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Enable Offer"
                                                    name="is_offer_enable"
                                                    id="is_offer_enable"
                                                    value={itemInput.is_offer_enable}
                                                    checked={itemInput.is_offer_enable}
                                                    onChange={(e) => {
                                                        handleChangeTextInput("is_offer_enable", itemInput.is_offer_enable === false ? true : false)
                                                    }}
                                                />
                                            </div>
                                            {
                                                itemInput.is_offer_enable ?
                                                    <div className="col-lg-3">
                                                        <label className="form-label mt-2">Offer mrp </label>
                                                        <div className="row">
                                                            <div className="col-5 col-md-5">
                                                                <InputGroup className="mb-3">
                                                                    <Form.Control
                                                                        type="text"
                                                                        min={0}
                                                                        max={100}
                                                                        disabled={itemInput.default_selling_price == "" || itemInput.default_selling_price == 0 ? true : false}
                                                                        name="offer_selling_price_percent"
                                                                        className="fromStyle form-control pl-1 pr-1"
                                                                        value={itemInput.offer_selling_price_percent}
                                                                        onChange={(e) => handleChangeTextInput("offer_selling_price_percent", e.target.value)}
                                                                    />
                                                                    <InputGroup.Text>%</InputGroup.Text>
                                                                </InputGroup>

                                                            </div>
                                                            <div className="col-7 col-md-7">
                                                                <InputGroup>
                                                                    <Form.Control
                                                                        type="text"
                                                                        min={0}
                                                                        disabled={itemInput.default_selling_price == "" || itemInput.default_selling_price == 0 ? true : false}
                                                                        name="offer_selling_price"
                                                                        className="fromStyle form-control pl-1 pr-1"
                                                                        value={itemInput.offer_selling_price}
                                                                        onChange={(e) => handleChangeTextInput("offer_selling_price", e.target.value)}
                                                                    />
                                                                    <InputGroup.Text>৳</InputGroup.Text>
                                                                </InputGroup>
                                                            </div>
                                                        </div>
                                                    </div> : ''
                                            }

                                        </div>
                                    </div>

                                </section>

                                {/**===================== Product Images Information ==================== */}
                                <section id="section-product-images">
                                    <div className="card card-body p-2 mb-3">
                                        <h4>Product Images</h4>
                                        <div className="row p-3">
                                            <div className="col-md-5">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <label className="form-label">Featured Image <LabelOptional /></label>
                                                        <Form.Control
                                                            type="file"
                                                            placeholder="Enter Featured Image"
                                                            name="featured_image"
                                                            onChange={(e) => handleChangeTextInput('featured_image', e.target.files[0], e)}
                                                            className="fromStyle"
                                                            ref={register({
                                                                required: false,
                                                                maxLength: 190,
                                                            })}
                                                        />

                                                        {
                                                            itemInput.featured_image_preview !== null &&
                                                            <div className="imgPreview" title="Remove">
                                                                <div className="preview-delete-icon"><i className="fa fa-times text-danger" onClick={() => dispatch(deleteProductImagePreview('featured_image'))}></i></div>
                                                                <img src={itemInput.featured_image_preview} className="img img-thumbnail" />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-lg-12 mt-3 mb-5">
                                                        <label className="form-label">Short Resolution Image <LabelOptional /></label>
                                                        <Form.Control
                                                            type="file"
                                                            placeholder="Enter Featured Image"
                                                            name="short_resolation_image"
                                                            onChange={(e) => handleChangeTextInput('short_resolation_image', e.target.files[0], e)}
                                                            className="fromStyle"
                                                            ref={register({
                                                                required: false,
                                                                maxLength: 190,
                                                            })}
                                                        />
                                                        <TextHelp text="Give a short resolution image if you have" />
                                                        {
                                                            itemInput.short_resolation_image_preview !== null &&
                                                            <div className="imgPreview" title="Remove">
                                                                <div className="preview-delete-icon"><i className="fa fa-times text-danger" onClick={() => dispatch(deleteProductImagePreview('short_resolation_image'))}></i></div>
                                                                <img src={itemInput.short_resolation_image_preview} className="img img-thumbnail" />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <label className="form-label"> Product Images <LabelOptional /></label>
                                                {
                                                    itemInput.images.length === 6 ? "" :
                                                        <div className="attachment-file">
                                                            <FileBase64
                                                                name="multipleAttachments"
                                                                multiple={true}
                                                                onDone={getFiles.bind(this)}
                                                            />
                                                            <TextHelp text="Add maximum 6 product images" />
                                                        </div>
                                                }
                                                <div className="col-md-12 mt-3">
                                                    {itemInput.images.length > 0 && (
                                                        <table className="table tbl-standard table-bordered tbl-survey">
                                                            <thead>
                                                                <tr>
                                                                    <th>SI</th>
                                                                    <th>Name</th>
                                                                    <th>Size</th>
                                                                    <th>Preview</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {itemInput.images !== null &&
                                                                    itemInput.images.map(
                                                                        (attachment, index) => (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{attachment.name ? attachment.name : attachment.image_title}</td>
                                                                                <td>{attachment.size ? attachment.size : attachment.image_size}</td>
                                                                                <td>
                                                                                    {" "}
                                                                                    <span className="btn border-none" onClick={() => PreviewAttachment(attachment)}>
                                                                                        <MultipplePreviewAttachment
                                                                                            url={`${attachment.image_url ? attachment.image_url : attachment.base64}`}
                                                                                            base64={attachment.base64}
                                                                                            title="Preview"
                                                                                            height={50}
                                                                                            width={50}
                                                                                        />
                                                                                    </span>
                                                                                </td>
                                                                                <td style={{ width: 70, textAlign: "center" }}>
                                                                                    <i
                                                                                        className="fa fa-trash text-danger pointer"
                                                                                        onClick={() =>
                                                                                            deleteMultipleAttachmentData(index, attachment)
                                                                                        }
                                                                                    ></i>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>


                                {/**===================== Product Attributes ==================== */}
                                <section id="section-product-attributes">
                                    <div className="card card-body p-2 mb-3">
                                        <h4 className="pointer" onClick={() => handleChangeTextInput("isProductAttributeOpen", !itemInput.isProductAttributeOpen)}>
                                            <div className="float-left">
                                                Product Attributes
                                            </div>
                                            <div className="float-right">
                                                <i className={!itemInput.isProductAttributeOpen ? 'fa fa-plus text-primary' : 'fa fa-minus text-primary'}></i>
                                            </div>
                                        </h4>
                                        {
                                            itemInput.isProductAttributeOpen &&
                                            <>
                                                <div className="form-group row p-3">
                                                    <div className="col-lg-4">
                                                        <label className="form-label mt-2">Product Attributes</label>
                                                        <RHFInput
                                                            as={<Select options={attributeList} />}
                                                            rules={{ required: false }}
                                                            name="attribute_id"
                                                            register={register}
                                                            // value={itemInput.barcode_type}
                                                            setValue={setValue}
                                                            onChange={(option) => (
                                                                handleChangeTextInput("attribute_id", option.value),
                                                                handleChangeTextInput("attribute_name", option.label),
                                                                setValue('values', ""),
                                                                dispatch(getAtributesValuesList(option.value))

                                                            )}
                                                        />
                                                        <TextHelp text="Select Category Label 2/3 to get suggestion" />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label className="form-label mt-2"> Attribute Value</label>
                                                        <RHFInput
                                                            as={
                                                                <Select
                                                                    className="custome-select"
                                                                    options={AttributeValuesList}
                                                                    components={animatedComponents}
                                                                    closeMenuOnSelect={false}
                                                                    isMulti
                                                                />
                                                            }
                                                            rules={{ required: false }}
                                                            name="values"
                                                            register={register}
                                                            // value={itemInput.attributeValues}
                                                            setValue={setValue}
                                                            onChange={(option) => (
                                                                handleChangeTextInput("values", option)
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="mt-5">
                                                        <Button variant="primary" onClick={() => addMultipleAttributeValues()}><i className="fas fa-plus-circle"></i>Add</Button>
                                                    </div>
                                                </div>
                                                <MultipleAttributeTable />
                                            </>
                                        }

                                    </div>
                                </section>

                                {/**===================== Product More Informations ==================== */}
                                <section id="section-product-more-informations">
                                    <div className="card card-body p-2 mb-3 pointer">
                                        <h4 onClick={() => handleChangeTextInput("isMoreOpen", !itemInput.isMoreOpen)}>
                                            <div className="float-left">
                                                More Informations
                                            </div>
                                            <div className="float-right">
                                                <i className={!itemInput.isMoreOpen ? 'fa fa-plus text-primary' : 'fa fa-minus text-primary'}></i>
                                            </div>
                                        </h4>
                                        {
                                            itemInput.isMoreOpen &&
                                            <div className="form-group row">
                                                <div className="col-lg-3">
                                                    <label className="form-label mt-2">Barcode Type <LabelOptional /></label>
                                                    <RHFInput
                                                        as={<Select options={BarcodeType} />}
                                                        rules={{ required: false }}
                                                        name="barcode_type"
                                                        register={register}
                                                        value={itemInput.barcode_type}
                                                        defaultValue={itemInput.barcode}
                                                        setValue={setValue}
                                                        onChange={(option) => (
                                                            handleChangeTextInput("barcode_type", option.value),
                                                            handleChangeTextInput("barcode_typeName", option.label),
                                                            handleChangeTextInput("barcode", option)
                                                        )}
                                                    />
                                                </div>

                                                {/* <div className="col-lg-3">
                                                <label className="form-label mt-2">SKU Manual</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Sku Manually"
                                                    name="sku_manual"
                                                    className="fromStyle"
                                                    register={register}
                                                    value={itemInput.sku_manual}
                                                    onChange={(e) => handleChangeTextInput("sku_manual", e.target.value)}
                                                />
                                                </div> */}
                                                <div className="col-lg-9">
                                                    <label className="form-label mt-2">Description <LabelOptional /></label>
                                                    <SimpleEditor
                                                        name="description"
                                                        value={itemInput.description}
                                                        onValueChange={(e) => handleChangeTextInput('description', e)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </section>

                                <div className="form-group row">
                                    <div className="col-sm-10">
                                        <button onClick={() => history.goBack()} type="button" className="btn btn-secondary btn-lg mr-2">
                                            Cancel
                                        </button>
                                        {
                                            !isLoading ?
                                                <button type="submit" className="btn btn-primary btn-lg" >
                                                    <span>Update Product</span>
                                                </button> :
                                                <button type="button" disabled className="btn btn-primary btn-lg" >
                                                    <span>update Product...</span>
                                                    <Spinner animation="border" />
                                                </button>
                                        }

                                    </div>
                                </div>
                            </form>
                        </div>
                    }

                    {
                        !isDetailLoaded &&
                        <div className="mt-5">
                            <LoadingSpinner text="Loading Product Detail Data" />
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default ItemEdit;
