import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../master/utils/ToastHelper";
import { generateFormDataFromObject } from "../../../master/utils/FileHelper";
import moment from "moment";

export const handleCouponInputChange = (name, value, e = null) => (dispatch) => {
    const formData = {
        name: name,
        value: value,
        e,
    }
    dispatch({ type: Types.CHANGE_COUPON_INPUT, payload: formData });
    if (name === 'image') {
        let reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            if (name === "image") {
                formData.name = 'imagePreviewURl';
            }
            formData.value = reader.result;
            dispatch({ type: Types.CHANGE_COUPON_INPUT, payload: formData });
        }
        reader.readAsDataURL(file)
    }
}

export const deletePreviewImage = (name) => (dispatch) => {
    let formData = {
        name: name,
        value: null,
    }
    dispatch({ type: Types.CHANGE_COUPON_INPUT, payload: formData });
    if (name === 'image') {
        formData.name = 'imagePreviewURl';
    }
    dispatch({ type: Types.CHANGE_COUPON_INPUT, payload: formData });
};

export const handleCouponSubmit = (couponInputData) => (dispatch => {
    let responseData = {
        status: false,
        message: "",
        isLoading: true,
    };
    dispatch({ type: Types.ADD_COUPON, payload: responseData });
    const headers = {
        "Content-Type": "application/json",
    };
    let updatedData = couponInputData;
    updatedData.coupon_start_date = moment(updatedData.coupon_start_date).format("YYYY-MM-DD");
    updatedData.coupon_expiry_date = moment(updatedData.coupon_expiry_date).format("YYYY-MM-DD");
    const couponData = generateFormDataFromObject(updatedData)
    Axios.post(`${process.env.REACT_APP_API_URL}coupons`, couponData, {
        headers: headers,
    }).then((res) => {
        if (res.data.status) {
            let data = res.data;
            responseData.message = data.message;
            responseData.status = data.status;
            responseData.isLoading = false;
            showToast('success', responseData.message);
            dispatch({ type: Types.ADD_COUPON, payload: responseData });
        }
    }).catch((err) => {
        const { response } = err;
        const { request, ...errorObject } = response;
        responseData.isLoading = false;
        showToast('error', response.data.message);
        dispatch({ type: Types.ADD_COUPON, payload: responseData });
    });
})

// get coupon list
export const getCouponList = (page, searchText = null, couponType = null, amountType = null) => async (dispatch) => {
    let response = {
        couponList: [],
        status: false,
        message: "",
        isLoading: true,
        errors: [],
    };
    dispatch({ type: Types.GET_COUPON_LIST, payload: response });
    let url = "";
    url = `${process.env.REACT_APP_API_URL}coupons?isPaginated=1&paginateNo=20`;

    if (page !== null || page === "") {
        url += `&page=${page}`;
    }

    if (searchText !== null) {
        url += `&search=${searchText}`;
    } else {
        // url += `&certificate/details?search=${searchText}`
    }
    if (couponType !== null) {
        url += `&coupon_type_id=${couponType}`;
    }
    if (amountType !== null) {
        url += `&amount_type=${amountType}`;
    }


    try {
        await Axios.get(url)
            .then((res) => {
                const { data, message, status } = res.data;
                response.status = status;
                response.couponList = data.data;
                response.message = message;
                response.couponPaginationList = data;
                response.isLoading = false;
                dispatch(emptyDispatch())
            })
            .catch((err) => {
                // toast.error(err);
            });
    } catch (error) {
        response.message = "Something Went Wrong !";
        // toast.error(error);
    }
    response.isLoading = false;
    dispatch({ type: Types.GET_COUPON_LIST, payload: response });
};

/**
 * Get Coupon Details By ID
 *
 * @param {int} id Coupon ID
 *
 * @return void
 */
export const getCouponDetails = (id) => (dispatch) => {
    let response = {
        status: false,
        message: "",
        isLoading: true,
        couponDetails: {}
    };
    dispatch({ type: Types.GET_COUPON_DETAILS, payload: response });

    Axios.get(`${process.env.REACT_APP_API_URL}coupons/${id}`)
        .then((res) => {
            if (res.data.status) {
                const { data, message, status } = res.data;

                if (data.coupon_type_id !== null && data.coupon_type_id !== "") {
                    data.coupon_type = {
                        label: data.coupon_type.name,
                        value: data.coupon_type.id,
                    }
                } else {
                    data.coupon_type = ''
                }

                if (data.amount_type !== null && data.amount_type !== "") {
                    data.amountType = {
                        label: data.amount_type.toUpperCase(),
                        value: data.amount_type,
                    }
                } else {
                    data.amountType = ''
                }

                if (data.image_url !== null && data.image_url !== "") {
                    data.imagePreviewURl = data.image_url;
                    data.image = null;
                }

                if (data.description === null) {
                    data.description = '';
                }

                response.status = status;
                response.couponDetails = data;
                response.message = message;
                response.isLoading = false;

                dispatch({ type: Types.GET_COUPON_DETAILS, payload: response });
            }
        }).catch((err) => {
            response.isLoading = false;
            dispatch({ type: Types.GET_COUPON_DETAILS, payload: response });
        });
}

//delete coupon
export const handleCouponDelete = (id) => (dispatch) => {
    let isLoading = true;
    dispatch({ type: Types.DELETE_COUPON, payload: isLoading })
    Axios.delete(`${process.env.REACT_APP_API_URL}coupons/${id}`)
        .then((res) => {
            if (res.data.status) {
                const data = res.data;
                showToast('success', data.message);
                dispatch({ type: Types.DELETE_COUPON, payload: false });
                dispatch(getCouponList())
            }
        })
}

//COUPON UPDATED 
export const updatedCoupon = (couponInputData, id) => (dispatch => {
    let responseData = {
        status: false,
        message: "",
        isLoading: true,
    };
    dispatch({ type: Types.UPDATE_COUPON, payload: responseData });
    const headers = {
        "Content-Type": "application/json",
    };
    let updatedData = couponInputData;
    updatedData.coupon_start_date = moment(updatedData.coupon_start_date).format("YYYY-MM-DD");
    updatedData.coupon_expiry_date = moment(updatedData.coupon_expiry_date).format("YYYY-MM-DD");

    const couponData = generateFormDataFromObject(updatedData)
    Axios.post(`${process.env.REACT_APP_API_URL}coupons/${id}?_method=PUT`, couponData, {
        headers: headers,
    }).then((res) => {
        if (res.data.status) {
            let data = res.data;
            responseData.message = data.message;
            responseData.status = data.status;
            responseData.isLoading = false;
            showToast('success', responseData.message);
            dispatch({ type: Types.UPDATE_COUPON, payload: responseData });
        }
    }).catch((err) => {
        const { response } = err;
        const { request, ...errorObject } = response;
        responseData.isLoading = false;
        showToast('error', response.data.message);
        dispatch({ type: Types.UPDATE_COUPON, payload: responseData });
    });
})


export const emptyDispatch = () => (dispatch) => {
    dispatch({ type: Types.EMPTY_DISPATCH, payload: true });
}