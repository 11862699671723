import React from 'react';
import { Link } from 'react-router-dom';
import OrderList from '../components/list/InvoicesList';

const InvoicesListContainer = () => {

  return (
    <>
      <div className="container-fluid">
        <OrderList />
      </div>
    </>
  );
};

export default InvoicesListContainer;
