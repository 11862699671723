import * as Types from "../../types/Types";
import axios from "axios";
import { showToast } from "../../../master/utils/ToastHelper";
import { capitalize, upperCase } from "lodash";
import { getSellerID, getUserID } from "../../../../services/AuthService";
import { generateFormDataFromObject } from "../../../master/utils/FileHelper";

//handle change product input
export const changeItemInput = (name, value, e = null, itemInput = null) => (dispatch) => {
    let data = {
        name: name,
        value: value,
    }
    dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: data });

    if (name === "image") {
        let reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            // data.name = "imagePreviewUrl";
            data.value = reader.result;
            dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: data });
        };
        reader.readAsDataURL(file);
    }

    if (name === 'featured_image' || name === 'product_image' || name === 'short_resolation_image') {
        let reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            if (name === "featured_image") {
                data.name = 'featured_image_preview';
            } else if (name === 'product_image') {
                data.name = 'product_image_preview';
            } else {
                data.name = 'short_resolation_image_preview';
            }
            data.value = reader.result;
            dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: data });

            if (name === "featured_image") {
                data.name = 'featured_image';
            } else if (name === "short_resolation_image") {
                data.name = 'short_resolation_image';
            }
            data.value = reader.result;
            dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: data });
        }
        reader.readAsDataURL(file)
    }

    if (name === 'default_selling_price' || name === 'offer_selling_price' || name === 'offer_selling_price_percent') {
        priceCalculation(name, value, itemInput, dispatch);
    }
}

export const changeFilterItemList = (name, value) => (dispatch) => {
    const filter = {
        name,
        value
    }
    dispatch({ type: Types.CHANGE_ITEM_FILTER, payload: filter });
}

/**
 * Calculate Price and set data
 *
 * If Item input values are 'default_selling_price', 'offer_selling_price', 'offer_selling_price_percent'
 * Process them to auto fill vise versa data
 *
 * @param {string} name
 * @param {float} value
 * @param {object} itemInput
 * @param {object} dispatch
 */
const priceCalculation = (name, value, itemInput, dispatch) => {
    let defaultSellingPrice = parseFloat(itemInput.default_selling_price),
        offerSellingPrice = parseFloat(itemInput.offer_selling_price),
        offerSellingPricePercent = parseFloat(itemInput.offer_selling_price_percent);

    if (name === 'default_selling_price') {
        defaultSellingPrice = value;
        if (offerSellingPricePercent > 0 && offerSellingPricePercent <= 100) {
            offerSellingPrice = parseFloat(defaultSellingPrice - ((defaultSellingPrice * offerSellingPricePercent) / 100)).toFixed(2);

            dispatch({
                type: Types.CHANGE_ITEM_INPUT, payload: {
                    name: 'offer_selling_price',
                    value: offerSellingPrice
                }
            });
        } else if (offerSellingPricePercent > 100) {
            showToast('error', 'You can not make offer greater than 100%');
            dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: { name, value: 0 } });
            dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: { name: 'offer_selling_price', value: 0 } });
        } else {
            dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: { name: 'offer_selling_price_percent', value: '' } });
            dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: { name: 'offer_selling_price', value: '' } });
        }
    }

    if (defaultSellingPrice > 0) {
        if (name === 'offer_selling_price') {
            offerSellingPrice = parseFloat(value);
            if (offerSellingPrice > 0 && offerSellingPrice < defaultSellingPrice) {
                offerSellingPricePercent = parseFloat((100 * (defaultSellingPrice - offerSellingPrice)) / defaultSellingPrice).toFixed(2);
                dispatch({
                    type: Types.CHANGE_ITEM_INPUT, payload: {
                        name: 'offer_selling_price_percent',
                        value: offerSellingPricePercent
                    }
                });
            } else if (offerSellingPrice >= defaultSellingPrice) {
                showToast('error', 'You can not make offer of same/big price than selling price');
                dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: { name, value: 0 } });
                dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: { name: 'offer_selling_price_percent', value: 0 } });
            } else {
                dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: { name: 'offer_selling_price_percent', value: '' } });
            }
        }

        if (name === 'offer_selling_price_percent') {
            offerSellingPricePercent = parseFloat(value);
            if (offerSellingPricePercent > 0 && offerSellingPricePercent <= 100) {
                offerSellingPrice = parseFloat(defaultSellingPrice - ((defaultSellingPrice * offerSellingPricePercent) / 100)).toFixed(2);

                dispatch({
                    type: Types.CHANGE_ITEM_INPUT, payload: {
                        name: 'offer_selling_price',
                        value: offerSellingPrice
                    }
                });
            } else if (offerSellingPricePercent > 100) {
                showToast('error', 'You can not make offer greater than 100%');
                dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: { name, value: 0 } });
                dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: { name: 'offer_selling_price', value: 0 } });
            } else {
                dispatch({ type: Types.CHANGE_ITEM_INPUT, payload: { name: 'offer_selling_price', value: '' } });
            }
        }
    }
}

//delete multiple preview image
export const ProductMultipleAttachmentDelete = (index, isEdit = false) => (dispatch) => {
    if (!isEdit) {
        dispatch({ type: Types.DELETE_SURVEY_MULTIPLE_ATTACHMENT, payload: index });
    } else {
        dispatch({ type: Types.DELETE_SURVEY_MULTIPLE_ATTACHMENT_EDIT, payload: index });
    }
};

//handle product image preview delete 
export const deleteProductImagePreview = (name, isEdit = false) => (dispatch) => {
    let data = {
        name: name,
        value: null,
    }
    const type = !isEdit ? Types.CHANGE_ITEM_INPUT : Types.CHANGE_ITEM_INPUT_UPDATE;
    dispatch({ type: type, payload: data });

    if (name === 'featured_image') {
        data.name = 'featured_image_preview';
    } else {
        data.name = 'short_resolation_image_preview';
    }
    dispatch({ type: type, payload: data });
};

//store product items
export const AddItem = (itemInput) => async (dispatch) => {

    if (itemInput.name === null || itemInput.name === "") {
        showToast('error', "Product name can't be blank.")
        return false;
    }
    if (itemInput.business_id === null || itemInput.business_id === "") {
        itemInput.business_id = getSellerID();
    }
    if (itemInput.category_id === null || itemInput.category_id === "") {
        showToast('error', "Category can't be blank.")
        return false;
    }
    if (itemInput.sub_category_id1 === null || itemInput.sub_category_id1 === "") {
        showToast('error', "Please select a sub category-1.")
        return false;
    }
    // if (itemInput.sub_category_id2 === null || itemInput.sub_category_id2 === "") {
    //     showToast('error', "Please select a sub category-2!")
    //     return false;
    // }
    if (itemInput.brand_id === null || itemInput.brand_id === "") {
        showToast('error', "Please select a brand name.")
        return false;
    }
    if (itemInput.unit_id === null || itemInput.unit_id === "") {
        showToast('error', "Please select a unit.")
        return false;
    }
    if (itemInput.tax_type === null || itemInput.tax_type === "") {
        showToast('error', "Please select a tax type.")
        return false;
    }
    if (itemInput.tax === null || itemInput.tax === "") {
        showToast('error', "Tax can't be blank.")
        return false;
    }
    // if (itemInput.enable_stock === null || itemInput.enable_stock === "") {
    //     showToast('error', "Please confirm this product is enable or not in stock!")
    //     return false;
    // }
    if (itemInput.alert_quantity === null || itemInput.alert_quantity === "") {
        showToast('error', "Alert quantity can't be blank.")
        return false;
    }
    // if (itemInput.sku === null || itemInput.sku === "") {
    //     showToast('error', "SKU can't be blank!")
    //     return false;
    // }
    // if (itemInput.barcode_type === null || itemInput.barcode_type === "") {
    //     showToast('error', "Barcode type can't be blank!")
    //     return false;
    // }
    // if (itemInput.sku_manual === null || itemInput.sku_manual === "") {
    //     showToast('error', "SKU Manual type can't be blank!")
    //     return false;
    // }
    if (itemInput.current_stock === null || itemInput.current_stock === "") {
        showToast('error', "Current stock can't be blank.")
    }
    if (itemInput.default_selling_price === null || itemInput.default_selling_price === "") {
        showToast('error', "Default selling price can't be blank.")
        return false;
    }
    if (itemInput.is_offer_enable) {
        if (itemInput.offer_selling_price === null || itemInput.offer_selling_price === "" || itemInput.offer_selling_price == "0") {
            itemInput.is_offer_enable = false;
            itemInput.offer_selling_price = 0;
        }

        if (itemInput.offer_selling_price === itemInput.default_selling_price || (itemInput.offer_selling_price === "" || itemInput.offer_selling_price == 0)) {
            itemInput.is_offer_enable = false;
            itemInput.offer_selling_price = 0;
        }
    }
    itemInput.created_by = getUserID();

    let responseData = {
        status: false,
        message: "",
        isLoading: true,
    };
    dispatch({ type: Types.ADD_ITEM, payload: responseData });
    const headers = {
        "Content-Type": "application/json",
    };
    let newProductData = itemInput;
    newProductData.featured_image = newProductData.featured_image_preview;
    newProductData.short_resolation_image = newProductData.short_resolation_image_preview;
    axios.post(`${process.env.REACT_APP_API_URL}items`, newProductData, {
        headers: headers,
    })
        .then((res) => {
            if (res.data.status) {
                let data = res.data;
                responseData.message = data.message;
                responseData.status = data.status;
                responseData.isLoading = false;
                showToast('success', responseData.message);
                dispatch({ type: Types.ADD_ITEM, payload: responseData });
            }
        }).catch((err) => {
            const errorMessage = "Sorry, Something went wrong!"
            responseData.isLoading = false;
            showToast('error', errorMessage);
            dispatch({ type: Types.ADD_ITEM, payload: responseData });
        });

}

export const emptyItemInputs = () => dispatch => {
    dispatch({ type: Types.EMPTY_ITEM_INPUT, payload: null });
}

/**
 * Get Seller Product List
 *
 * @since 1.0.0
 *
 * @param {int} page pageNo
 * @param {string} searchText Search product title
 * @param {int} itemCategory category id
 * @param {int} brand brand id
 *
 * @return void
 */
export const getProductItemsList = (page, searchText = null, itemCategory = null, brand = null, status='active', sku = null) => (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
        message: ''
    };
    dispatch({ type: Types.GET_ITEM_LIST, payload: responseList });
    let productUrl = "";
    // productUrl = `${process.env.REACT_APP_API_URL}items?isPaginated=1&paginateNo=10&category_id=1&brand_id=1&page=1`;

    productUrl = `${process.env.REACT_APP_API_URL}items?isPaginated=1&paginateNo=20`;
    if (page !== null || page === "") {
        if (searchText !== null && searchText !== '') {
            productUrl += `&page=1`;
        } else {
            productUrl += `&page=${page}`;
        }
    }
    if (searchText !== null) {
        productUrl += `&search=${searchText}`;
    } else {
        // url += `&certificate/details?search=${searchText}`
    }
    //item filter by category
    if (itemCategory !== null) {
        productUrl += `&category_id=${itemCategory}`;
    }
    if (brand !== null) {
        productUrl += `&brand_id=${brand}`;
    }
    if (status !== null) {
        productUrl += `&status=${status}`;
    }
    if (sku !== null) {
        productUrl += `&sku=${sku}`;
    }
    try {
        axios.get(productUrl)
            .then((res) => {
                const { data, message, status } = res.data;
                responseList.status = status;
                responseList.ProductList = data.data;
                responseList.message = message;
                responseList.ProductPaginatedList = data;
                responseList.isLoading = false;
                if (res.data.status) {
                    dispatch({ type: Types.GET_ITEM_LIST, payload: responseList });
                }
            }).catch((err) => {
            });
    } catch (error) {
        responseList.isLoading = false;
        responseList.message = "Something Went Wrong !";
        showToast('error', responseList.message);
        dispatch({ type: Types.GET_ITEM_LIST, payload: responseList });
    }
    // responseList.isLoading = false;
    // dispatch({ type: Types.GET_ITEM_LIST, payload: responseList });
};

//get Barcode type
export const getBarcodeType = () => (dispatch) => {
    const barcode = [
        { label: 'C39', value: 'c39' },
        { label: 'C128', value: 'c128' },
        { label: 'EAN-13', value: 'ean-13' },
        { label: 'EAN-8', value: 'ean-8' },
        { label: 'UPC-A', value: 'upc-a' },
        { label: 'UPC-E', value: 'upc-e' },
        { label: 'ITF-14', value: 'itf-14' },
    ];
    dispatch({ type: Types.GET_BARCODE_TYPE, payload: barcode });
}
/**
 * Get Item Types
 *
 * @returns void
 */
export const getItemTypes = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}item-types`)
        .then((res) => {
            dispatch({ type: Types.GET_ITEM_TYPE_LIST, payload: res.data.data });
        });
}

/**
 * Get Product Details By ID
 *
 * @param {int} id
 *
 * @returns void
 */
export const getItemDetails = (id) => (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
        isDetailLoaded: false
    };
    dispatch({ type: Types.GET_ITEM_DETAILS, payload: responseList });
    axios.get(`${process.env.REACT_APP_API_URL}items/${id}`)
        .then((res) => {
            if (res.data.status) {
                let data = res.data;
                if (data.data.business) {
                    data.data.business = {
                        label: data.data.business.name,
                        value: data.data.business.id
                    }
                } else {
                    data.data.business = ""
                }
                if (data.category_id !== null && data.category_id !== "" && data.data.category !== null) {
                    data.data.category1 = {
                        label: data.data.category.name,
                        value: data.data.category.id
                    }
                } else {
                    data.data.category1 = ""
                }
                if (data.sub_category_id !== null && data.sub_category_id !== "" && data.data.sub_category !== null) {
                    data.data.category2 = {
                        label: data.data.sub_category.name,
                        value: data.data.sub_category.id
                    }
                } else {
                    data.data.category2 = ""
                }

                if (data.sub_category_id2 !== null && data.sub_category_id2 !== "" && data.data.sub_category2 !== null) {
                    data.data.category3 = {
                        label: data.data.sub_category2.name,
                        value: data.data.sub_category2.id
                    }
                } else {
                    data.data.category3 = ""
                }

                if (data.brand_id !== null && data.brand_id !== "" && data.data.brand !== null) {
                    data.data.brand = {
                        label: data.data.brand.name,
                        value: data.data.brand.id
                    }
                } else {
                    data.data.brand = ""
                }
                if (data.unit_id !== null && data.unit_id !== "" && data.data.unit !== null) {
                    data.data.unit = {
                        label: `${data.data.unit.actual_name}`,
                        value: data.data.unit.id
                    }
                } else {
                    data.data.unit = ""
                }
                if (data.tax_type !== null && data.tax_type !== "" && data.data.tax_type !== null) {
                    data.data.taxType = {
                        label: capitalize(data.data.tax_type),
                        value: data.data.tax_type
                    }
                } else {
                    data.data.taxType = ""
                }

                if (data.tax !== null && data.tax !== "" && data.data.tax !== null) {
                    data.data.taxList = {
                        label: capitalize(data.data.tax.name),
                        value: data.data.tax.id
                    }
                    data.data.tax = data.data.tax.id;
                } else {
                    data.data.taxList = ""
                }

                if (data.barcode_type !== null && data.barcode_type !== "" && data.data.barcode_type !== null) {
                    data.data.barcode = {
                        label: upperCase(data.data.barcode_type),
                        value: data.data.barcode_type
                    }
                } else {
                    data.data.barcode = ""
                }
                // if (data.tax !== null && data.tax !== "") {
                //     data.data.taxList = {
                //         label: upperCase(data.data.tax),
                //         value: data.data.tax
                //     }
                // } else {
                //     data.data.taxList = ""
                // }
                if (data.short_resolation_image !== null) {
                    data.data.short_resolation_image_preview = data.data.short_resolation_url;
                }
                if (data.featured_url !== null) {
                    data.data.featured_image_preview = data.data.featured_url;
                }

                if (data.data.is_offer_enable === "0" || data.data.is_offer_enable === 0 || data.data.is_offer_enable === "false") {
                    data.data.is_offer_enable = false;
                } else {
                    data.data.is_offer_enable = true;
                    data.data.offer_selling_price_percent = parseFloat((100 * (data.data.default_selling_price - data.data.offer_selling_price)) / data.data.default_selling_price).toFixed(2);
                }

                if(data.data.enable_stock == 0 || !data.data.enable_stock) {
                    data.data.enable_stock = false;
                }

                data.data.deleted_attributes = [];
                data.data.deleted_images = [];
                data.isDetailLoaded = true;
                dispatch({ type: Types.GET_ITEM_DETAILS, payload: data })
            }
        })
}

/**
 * Update Product Details
 *
 * @param object Product Item
 * @param int    id
 */
export const updatedProductItem = (productItem, id) => (dispatch) => {
    let responseList = {
        isLoading: true,
        status: false,
        isItemEdited: false,
        itemEditMessage: '',
        data: {}
    }
    dispatch({ type: Types.EDIT_ITEM, payload: responseList });

    if (productItem.is_offer_enable) {
        if (productItem.offer_selling_price === null || productItem.offer_selling_price === "" || productItem.offer_selling_price == "0") {
            productItem.is_offer_enable = false;
            productItem.offer_selling_price = 0;
        }

        if (productItem.offer_selling_price === productItem.default_selling_price || (productItem.offer_selling_price === "" || productItem.offer_selling_price == 0)) {
            productItem.is_offer_enable = false;
            productItem.offer_selling_price = 0;
        }
    }

    const url = `${process.env.REACT_APP_API_URL}items/${id}?_method=PUT`;
    axios.post(url, productItem)
        .then((response) => {
            responseList.isLoading = false;
            responseList.isItemEdited = true;
            responseList.data = response.data.data;
            responseList.status = response.data.status;
            responseList.itemEditMessage = response.data.message;
            if (responseList.status) {
                showToast("success", response.data.message);
                dispatch({ type: Types.EDIT_ITEM, payload: responseList })
            } else {
                showToast("error", response.data.message)
            }
        })
        .catch(function (error) {
            responseList.isLoading = false;
            const { response } = error;
            showToast("error", response.data.message);
            dispatch({ type: Types.EDIT_ITEM, payload: responseList })
        });
}

//delete products item
export const handleDeleteProductItems = (id, current_page = 1) => (dispatch) => {
    let isLoading = true;
    dispatch({ type: Types.DELETE_PRODUCT_ITEMS, payload: isLoading })
    axios.delete(`${process.env.REACT_APP_API_URL}items/${id}`)
        .then((res) => {
            if (res.data.status) {
                const data = res.data;
                showToast('success', data.message);
                dispatch({ type: Types.DELETE_PRODUCT_ITEMS, payload: false });
                dispatch(getProductItemsList(current_page))
            }
        })
}

export const emptyDispatch = () => (dispatch) => {
    dispatch({ type: Types.EMPTY_DISPATCH, payload: null })
}

export const handleAddMultiAttributeValue = (itemInput) => (dispatch) => {
    let value = [];
    let valuePrintable = [];
    for (let i = 0; i < itemInput.values.length; i++) {
        const element = itemInput.values[i].value;
        value.push(element)
        valuePrintable.push(itemInput.values[i].label);

    }
    if (itemInput.values.length > 0) {
        const attributeValue = {
            attribute_id: itemInput.attribute_id,
            value: value,
            attribute_name: itemInput.attribute_name,
            attribute_values: value,
            attribute_values_print: valuePrintable,
            // attribute_values: "["+value.toString()+"]"
        }
        dispatch({ type: Types.ADD_MULTIPLE_ATTRIBUTE_VALUE, payload: attributeValue });
    }

    if (itemInput.values.length === 0)
        showToast('error', 'Please select attribute and give proper value !');
}

//delete multiple attributes values
export const deleteMultipleAttributesValues = (index, isEdit = false) => (dispatch) => {
    if (!isEdit) {
        dispatch({ type: Types.DELETE_MULTIPLE_ATTRIBUTES_VALUES, payload: index });
    } else {
        dispatch({ type: Types.DELETE_MULTIPLE_ATTRIBUTES_VALUES_EDIT, payload: index });
    }
};

/**
 * toggle product is featured status
 *
 * @since 1.0.0
 *
 * @param {number} id - product id
 *
 * @returns {void}
 */
export const toggleFeaturedProduct = (id) => (dispatch) => {
    axios.post(`${process.env.REACT_APP_API_URL}items/featured/toggle`, {
        item_id: id
    }).then(res => {
        showToast('success', res.data.message);
        dispatch(getProductItemsList());
    }).catch(err => {
        showToast('error', 'Featured product not added !');
    });
};

/**
 * Get Item Default Statuses
 *
 * @returns void
 */
 export const getItemStatuses = (formatted = true, productId = null) => (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}item-statuses?formatted=${formatted}&product_id=${productId}`)
        .then((res) => {
            dispatch({ type: Types.GET_ITEM_STATUS_LIST, payload: res.data.data });
        });
}

/**
 * Process Bulk Product Upload.
 *
 * @param {object} formData - form data
 *
 * @returns {void}
 */
export const postBulkProducts = (file) => dispatch => {
    const data = {
        file: file
    };

    const postData = generateFormDataFromObject(data);

    const config = {
        onUploadProgress: data => dispatch(updateUploadProgress(data))
    }

    try {
        axios.post(`${process.env.REACT_APP_API_URL}items/bulk-upload`, postData, config)
        .then(res => {
            if (res.data.status) {
                showToast('success', res.data.message);
            }
        });
    } catch (error) {
        showToast('error', 'Something went wrong in the upload.');
    }
}

export const updateUploadProgress = (data) => ({type: Types.BULK_UPLOAD_PROGRESS, payload: typeof data === 'object' ? Math.round((100 * data.loaded) / data.total) : 0})