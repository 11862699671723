import * as Types from "../authTypes";
import axios from "axios";
import { showToast } from "../../../../../modules/master/utils/ToastHelper";
import { printErrorMessages } from "../../../../../services/ServerErrorMessages";

//handle change register input field
export const ChangeRegisterInputField = (name, value) => (dispatch) => {
	const registerData = {
		name: name,
		value: value,
	};
	dispatch({ type: Types.CHANGE_REGISTER_INPUT_FIELD, payload: registerData });
};

// handle register first step
export const RegisterFirstStep = (registerInput) => (dispatch) => {
	let response = {
		message: null,
		status: false,
		isLoading: true,
	};
	dispatch({ type: Types.REGISTER_FIRST_STEP, payload: response });
	const URL = `${process.env.REACT_APP_API_URL}auth/register`;
	try {
		axios
			.post(URL, registerInput)
			.then((res) => {
				if (res.data.status) {
					response.message = res.data.message;
					response.isLoading = false;
					showToast("success", response.message);
					dispatch({ type: Types.REGISTER_FIRST_STEP, payload: response });
				}
			})
			.catch((error) => {
				const responseLog = error.response;
				response.isLoading = false;
				if (typeof responseLog !== "undefined") {
					const { request, ...errorObject } = responseLog;
					dispatch({ type: Types.REGISTER_FIRST_STEP, payload: responseLog });

					if (responseLog.data.errors) {
						const errorMessage = responseLog.data.errors.phone_no[0];

						showToast("error", errorMessage);
					} else {
						showToast("error", responseLog.data.message);
						return;
					}
				} else {
					response.isLoading = false;
					showToast("error", "Something went wrong !");
				}
			});
	} catch (error) {
		response.isLoading = false;
		showToast("error", "Network Error, Please Fix this !");
	}
	dispatch({ type: Types.REGISTER_FIRST_STEP, payload: response });
};

/**
 * vendorRegisterFirstStep
 * 
 * This will send an OTP to the vendor mobile Phone
 * 
 * @since 1.0.0
 * 
 * @param {object} registerInput 
 * 
 * @returns 
 */
export const vendorRegisterFirstStep = (registerInput) => (dispatch) => {

	if (registerInput.business_type.length === 0) {
		showToast('error', "Please choose your shop type !")
		return false;
	}

	if (registerInput.first_name.length === 0) {
		showToast('error', "Please give your first name !")
		return false;
	}

	if (registerInput.business_name.length === 0) {
		showToast('error', "Please give your business name !")
		return false;
	}

	if (registerInput.phone_no === null) {
		showToast('error', "Please give your Mobile number !")
		return false;
	}

	if (registerInput.state_id.length === 0) {
		showToast('error', "Please select your division !")
		return false;
	}

	if (registerInput.city_id.length === 0) {
		showToast('error', "Please select your zilla !")
		return false;
	}

	if (registerInput.area_id.length === 0) {
		showToast('error', "Please select your upzilla !")
		return false;
	}

	if (registerInput.landmark.length === 0) {
		showToast('error', "Please give your area name !")
		return false;
	}

	let response = {
		message: null,
		status: false,
		isLoading: true,
		isSendOTP: false,
	}

	dispatch({ type: Types.VENDOR_REGISTER_GET_OTP, payload: response });

	const URL = `${process.env.REACT_APP_API_URL}auth/vendor-register`;
	try {
		axios.post(URL, registerInput)
			.then((res) => {
				if (res.data.status) {
					response.message = res.data.message;
					response.isLoading = false;
					response.isSendOTP = true;
					showToast('success', response.message);
					dispatch({ type: Types.VENDOR_REGISTER_GET_OTP, payload: response });
				}
			}).catch((error) => {
				const responseLog = error.response;
				response.isLoading = false;
				response.isSendOTP = false;
				if (typeof responseLog !== 'undefined') {
					const { request, ...errorObject } = responseLog;
					dispatch({ type: Types.VENDOR_REGISTER_GET_OTP, payload: responseLog })

					if (responseLog.data.errors) {
						const errorMessage = printErrorMessages(responseLog.data.errors);
						showToast('error', errorMessage);
					} else {
						showToast('error', responseLog.data.message);
						return;
					}
				} else {
					response.isLoading = false;
					showToast('error', "Something went wrong !");
				}
			})
	} catch (error) {
		response.isLoading = false;
		showToast('error', 'Network Error, Please Fix this !');
	}
	dispatch({ type: Types.VENDOR_REGISTER_GET_OTP, payload: response })
}

/**
 * Vendor Register Final Submission
 *
 * @since 1.0.0
 *
 * @param {object} registerInput
 *
 * @retur void
 */
export const vendorRegister = (registerInput) => async (dispatch) => {
	if (registerInput.business_type.length === 0) {
		showToast('error', "Please choose your shop type !")
		return false;
	}

	if (registerInput.first_name.length === 0) {
		showToast('error', "Please give your first name !")
		return false;
	}

	if (registerInput.business_name.length === 0) {
		showToast('error', "Please give your business name !")
		return false;
	}

	if (registerInput.phone_no === null) {
		showToast('error', "Please give your Mobile number !")
		return false;
	}

	if (registerInput.otp.length === 0) {
		showToast('error', "OTP can't be blank!")
		return false;
	}

	if (registerInput.password.length === 0) {
		showToast('error', "Password can't be blank !")
		return false;
	}

	if (registerInput.password_confirmation.length === 0) {
		showToast('error', "Confirmation password can't be blank !")
		return false;
	}

	if (registerInput.password !== registerInput.password_confirmation) {
		showToast('error', "Password doesn't match !")
		return false;
	}

	let response = {
		message: null,
		status: false,
		data: null,
		isLoading: true,
	}
	dispatch({ type: Types.VENDOR_REGISTER, payload: response });

	axios.post(`${process.env.REACT_APP_API_URL}auth/vendor-register-next`, registerInput)
		.then((res) => {
			if (res.data.status) {
				response.message = res.data.message;
				response.data = null;
				response.status = res.data.status;
				response.isLoading = false;
				showToast('success', response.message);
				dispatch({ type: Types.VENDOR_REGISTER, payload: response })
			}
		})
		.catch((err) => {
			const { response } = err;
			const { request, ...errorObject } = response;
			response.isLoading = false;
			dispatch({ type: Types.VENDOR_REGISTER, payload: response })
			showToast('error', response.data.message);
		})
	dispatch({ type: Types.VENDOR_REGISTER, payload: response })
}

// customer register step two / final
export const customerRegister = (registerInput) => async (dispatch) => {
	if (registerInput.otp.length === 0) {
		showToast("error", "OTP can't be blank!");
		return false;
	}
	if (registerInput.password.length === 0) {
		showToast("error", "Password can't be blank!");
		return false;
	}
	if (registerInput.password_confirmation.length === 0) {
		showToast("error", "Confirm password can't be blank!");
		return false;
	}
	let response = {
		message: null,
		status: false,
		data: null,
		isLoading: true,
	};
	dispatch({ type: Types.AUTH_REGISTER, payload: response });
	axios
		.post(`${process.env.REACT_APP_API_URL}auth/register-next`, registerInput)
		.then((res) => {
			if (res.data.status) {
				response.message = res.data.message;
				response.data = null;
				response.isLoading = false;
				showToast("success", response.message);
				dispatch({ type: Types.AUTH_REGISTER, payload: response });
			}
		})
		.catch((err) => {
			const { response } = err;
			const { request, ...errorObject } = response;
			response.isLoading = false;
			dispatch({ type: Types.AUTH_REGISTER, payload: response });
			showToast("error", response.data.message);
		});
	dispatch({ type: Types.AUTH_REGISTER, payload: response });
};

// get countries list 
export const getCountry = () => (dispatch) => {
	axios.get(`${process.env.REACT_APP_API_URL}countries`)
		.then((res) => {
			dispatch({ type: Types.GET_COUNTRIES_LIST, payload: res.data.data });
		})
}

// get division list 
export const getDivision = (countryID) => (dispatch) => {
	axios.get(`${process.env.REACT_APP_API_URL}divisions?country=${countryID}`)
		.then((res) => {
			dispatch({ type: Types.GET_DIVISION_LIST, payload: res.data.data });
		})
}

// get city list 
export const getCity = (cityID) => (dispatch) => {
	axios.get(`${process.env.REACT_APP_API_URL}cities?division=${cityID}`)
		.then((res) => {
			dispatch({ type: Types.GET_CITIES_LIST, payload: res.data.data });
		})
}
// get upzilla list 
export const getArea = (cityID) => (dispatch) => {
	axios.get(`${process.env.REACT_APP_API_URL}areas?city=${cityID}`)
		.then((res) => {
			dispatch({ type: Types.GET_AREA_LIST, payload: res.data.data });
		})
}