import * as Types from "../../../types/Types";
import axios from "axios";

export const GetTaxList = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };

  axios
    .get(`${process.env.REACT_APP_API_URL}tax`, {
      headers: headers,
    })
    .then((res) => {
      let data = res.data;
      dispatch({ type: Types.GET_TAX_LIST, payload: data });
    });
};

export const AddTax = (state) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };

  let responseData = {
    status: false,
    message: "",
    isLoading: true,
  };

  dispatch({ type: Types.ADD_TAX, payload: responseData });

  const { name, businessData, startDate, amount } = state;
  let postData = {
    name: name,
    business_id: businessData.value,
    start_date: startDate,
    calculation_type: "Fixed",
    amount: amount,
    is_tax_group: true,
    rounding_type: "up",
    created_by: 1,
  };

  axios
    .post(`${process.env.REACT_APP_API_URL}tax`, postData, {
      headers: headers,
    })
    .then((res) => {
      let data = res.data;
      responseData.message = data.message;
      responseData.status = data.status;
      responseData.isLoading = false;
      dispatch({ type: Types.ADD_TAX, payload: responseData });
    })
    .catch((err) => {
      responseData.isLoading = false;
      dispatch({ type: Types.ADD_TAX, payload: responseData });
    });
};

export const UpdateTax = (tax) => async (dispatch) => {
  let responseData = {
    status: false,
    message: "",
    isLoading: true,
  };
  dispatch({ type: Types.UPDATE_TAX, payload: responseData });
  const { name, businessData, startDate, amount } = tax;
  let updateData = {
    name: name,
    business_id: businessData.value,
    start_date: startDate,
    calculation_type: "Fixed",
    amount: amount,
    is_tax_group: true,
    rounding_type: "up",
    created_by: 1,
  };

  axios
    .put(`${process.env.REACT_APP_API_URL}tax/${tax.id}`, updateData)
    .then(async (res) => {
      responseData.message = res.data.message;
      responseData.isLoading = false;
      responseData.status = res.data.status;
      dispatch({ type: Types.UPDATE_TAX, payload: responseData });
    })
    .catch((err) => {
      // responseData.message = res.data.message;
      responseData.isLoading = false;
      dispatch({ type: Types.UPDATE_TAX, payload: responseData });
    });
};
export const TaxEmptyAddMessage = () => async (dispatch) => {
  dispatch({ type: Types.EMPTY_TAX_ADD_MESSAGE, payload: true });
};
export const TaxEmptyEditMessage = () => async (dispatch) => {
  dispatch({ type: Types.EMPTY_TAX_EDIT_MESSAGE, payload: true });
};

export const DeleteTax = (intID) => async (dispatch) => {
  let responseData = {
    data: {},
    status: false,
    message: "",
  };
  axios.delete(`${process.env.REACT_APP_API_URL}tax/${intID}`).then((res) => {
    responseData.status = res.data.status;
    responseData.message = res.data.message;
    dispatch({ type: Types.DELETE_TAX, payload: responseData });
  });
};
