import * as Types from "../authTypes";

export const GetMenuListsByPermission = () => (dispatch) => {

	let updatedMenuList = [];
	const menus 		= localStorage.getItem('menus') || [];

	if (typeof menus !== 'undefined' && menus !== null) {
		updatedMenuList = JSON.parse(menus);
	}

	dispatch({ type: Types.GET_MENU_LIST, payload: updatedMenuList });
};
