import React from 'react';
import PollAdd from '../../components/Poll/PollAdd';

const PollAddContainer = () => {
  return (
    <>
      <PollAdd />
    </>
  );
};

export default PollAddContainer;
