import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { RHFInput } from 'react-hook-form-input';
import SimpleEditor from '../../../master/components/text-editor/SimpleEditor';
import LoadingSpinner from '../../../master/components/loading/LoadingSpinner';
import { updateCategory, handleChangeCategory, deleteCategoryImage, emptyCategoryMessage, GetCategoryDetailById, GetCategoriesSortedList } from '../../_redux/actions/CategoriesAction';
import { GetBusinessList } from '../../../master/OptionData/BusinessList/BusinessListAction/BusinessOptionListAction';
import TextHelp from '../../../master/components/labels/TextHelp';

const CategoryEdit = (props) => {
    const history = useHistory();
    const { id } = props;
    const { register, handleSubmit, errors, setValue } = useForm();
    const dispatch = useDispatch();

    const handleChangeTextInput = (name, value, e = null) => {
        dispatch(handleChangeCategory(name, value, e, true));
    };

    const businessList = useSelector((state) => state.BusinessOptionList.businessList);
    const categoriesSortedList = useSelector((state) => state.categories.categoriesSortedList);
    const editStatus = useSelector((state) => state.categories.editStatus);
    const editMessage = useSelector((state) => state.categories.editMessage);
    const isLoading = useSelector((state) => state.categories.isLoading);
    const isEditing = useSelector((state) => state.categories.isEditing);
    const editFormInputData = useSelector((state) => state.categories.editFormInputData);

    useEffect(() => {
        dispatch(GetBusinessList());
        dispatch(GetCategoriesSortedList());
        dispatch(GetCategoryDetailById(id, true));
    }, []);

    useEffect(() => {
        if (editStatus && editMessage !== null && editMessage.length > 0) {
            dispatch(emptyCategoryMessage());
            history.goBack();
        }
    }, [editStatus, editMessage]);

    const onSubmit = () => {
        dispatch(updateCategory(editFormInputData, id));
    };

    return (
        <div className="card-body">
            {
                !isLoading && editFormInputData !== null &&
                <form
                    className="form form-label-right"
                    onSubmit={handleSubmit(onSubmit)}
                    method="post"
                    encType="multipart/form-data"
                >
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Category Name
                                <span className="text-danger text-sm">*</span>
                            </label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Category Name"
                                name="name"
                                className="fromStyle"
                                defaultValue={editFormInputData.name}
                                onChange={(e) => handleChangeTextInput('name', e.target.value)}
                                ref={register({
                                    required: true,
                                    maxLength: 100,
                                })}
                            />
                            <div className="inputError margin-minus-8">
                                {errors.name &&
                                    errors.name.type === 'required' &&
                                    "Category name can't be blank !"}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Vendor
                                <span className="text-danger text-sm">*</span>
                            </label>
                            <RHFInput
                                as={<Select options={businessList}
                                    defaultValue={editFormInputData.businessData}
                                />}
                                rules={{ required: true }}
                                name="businessData"
                                register={register}
                                defaultValue={editFormInputData.businessData}
                                onChange={(e) => handleChangeTextInput('businessData', e)}
                                setValue={setValue}
                            />
                            <div className="inputError margin-minus-8">
                                {errors.businessData &&
                                    errors.businessData.type === 'required' &&
                                    "Business name can't be blank !"}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Category Code
                                <span className="text-danger text-sm">*</span>
                            </label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Category Code"
                                name="short_code"
                                className="fromStyle"
                                defaultValue={editFormInputData.short_code}
                                onChange={(e) => handleChangeTextInput('short_code', e.target.value)}
                                ref={register({
                                    required: true,
                                    maxLength: 100,
                                })}
                            />
                            <div className="inputError margin-minus-8">
                                {errors.short_code &&
                                    errors.short_code.type === 'required' &&
                                    "Category Code Can't be blank in edit mode"}
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Parent Category
                                <span className="text-danger text-sm">*</span>
                            </label>
                            <RHFInput
                                as={<Select options={categoriesSortedList}
                                    defaultValue={editFormInputData.parentCategory}
                                    isClearable={true}
                                />}
                                name="parentCategory"
                                register={register}
                                defaultValue={editFormInputData.parentCategory}
                                onChange={(e) => handleChangeTextInput('parentCategory', e)}
                                setValue={setValue}
                            />
                            <TextHelp text="Please select if it has a parent category, otherwise leave as empty !" />
                            <div className="inputError margin-minus-8">
                                {errors.parentCategory &&
                                    errors.parentCategory.type === 'required' &&
                                    "Parent Category can't be blank !"}
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Visible Navbar
                                <span className="text-info text-sm">(Optional)</span>
                            </label>
                            <Form.Group controlId="formBasicCheckboxNavbar" >
                                <Form.Check
                                    type="checkbox"
                                    label={editFormInputData.is_visible_navbar ? 'Yes' : 'No'}
                                    name="is_visible_navbar"
                                    checked={editFormInputData.is_visible_navbar}
                                    defaultValue={editFormInputData.is_visible_navbar}
                                    onChange={(e) => handleChangeTextInput('is_visible_navbar', e.target.checked)}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Navbar Sl. No.
                                <span className="text-info text-sm">(Optional)</span>
                            </label>
                            <Form.Control
                                type="number"
                                placeholder="Set Priority, eg: 1, 2, 100"
                                name="navbar_priority"
                                defaultValue={editFormInputData.navbar_priority}
                                className="fromStyle"
                                onChange={(e) => handleChangeTextInput('navbar_priority', e.target.value)}
                                ref={register({
                                    required: false,
                                    maxLength: 100,
                                })}
                            />
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Visible Home Page
                                <span className="text-info text-sm">(Optional)</span>
                            </label>
                            <Form.Group controlId="formBasicCheckbox" >
                                <Form.Check
                                    type="checkbox"
                                    label={editFormInputData.is_visible_homepage ? 'Yes' : 'No'}
                                    name="is_visible_homepage"
                                    checked={editFormInputData.is_visible_homepage}
                                    defaultValue={editFormInputData.is_visible_homepage}
                                    onChange={(e) => handleChangeTextInput('is_visible_homepage', e.target.checked)}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Homepage Priority
                                <span className="text-info text-sm">(Optional)</span>
                            </label>
                            <Form.Control
                                type="number"
                                placeholder="Set Priority, eg: 1, 2, 100"
                                name="priority"
                                defaultValue={editFormInputData.priority}
                                className="fromStyle"
                                onChange={(e) => handleChangeTextInput('priority', e.target.value)}
                                ref={register({
                                    required: false,
                                    maxLength: 100,
                                })}
                            />
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Short Description
                                <span className="text-info text-sm">(Optional)</span>
                            </label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter Category Short Description"
                                name="short_description"
                                className="fromStyle"
                                defaultValue={editFormInputData.short_description}
                                onChange={(e) => handleChangeTextInput('short_description', e.target.value)}
                                ref={register({
                                    required: false,
                                    maxLength: 100,
                                })}
                            />
                        </div>

                        <div className="col-lg-8">
                            <label className="form-label mt-2">
                                Description
                                <span className="text-info text-sm">(Optional)</span>
                            </label>
                            <SimpleEditor
                                value={editFormInputData.description}
                                onValueChange={(e) => handleChangeTextInput('description', e)}
                            />
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Logo Image
                                <span className="text-info text-sm">(Optional)</span>
                            </label>
                            <Form.Control
                                type="file"
                                placeholder="Enter Logo Image"
                                name="image"
                                onChange={(e) => handleChangeTextInput('image', e.target.files[0], e)}
                                className="fromStyle"
                                ref={register({
                                    required: false,
                                    maxLength: 100,
                                })}
                            />
                            {
                                editFormInputData.logoPreviewUrl !== null &&
                                <div className="imgPreview" title="Remove">
                                    <div className="preview-delete-icon"><i className="fa fa-times text-danger" onClick={() => dispatch(deleteCategoryImage('image', true))}></i></div>
                                    <img src={editFormInputData.logoPreviewUrl} className="img img-thumbnail" />
                                </div>
                            }
                        </div>

                        <div className="col-lg-4">
                            <label className="form-label mt-2">
                                Banner Image
                                <span className="text-info text-sm">(Optional)</span>
                            </label>
                            <Form.Control
                                type="file"
                                placeholder="Enter Banner Image"
                                name="banner"
                                className="fromStyle"
                                onChange={(e) => handleChangeTextInput('banner', e.target.files[0], e)}
                                ref={register({
                                    required: false,
                                    maxLength: 100,
                                })}
                            />
                            {
                                editFormInputData.bannerPreviewUrl !== null &&
                                <div className="imgPreview" title="Remove">
                                    <i className="fa fa-times text-danger" onClick={() => dispatch(deleteCategoryImage('banner', true))}></i>
                                    <img src={editFormInputData.bannerPreviewUrl} className="img img-thumbnail" />
                                </div>
                            }
                        </div>

                    </div>

                    <div className="form-group row">
                        <div className="col-sm-10">
                            <a
                                onClick={() => {
                                    history.push('/categories');
                                }}
                            >
                                <button type="button" className="btn btn-secondary btn-lg mr-2">
                                    <i className="fa fa-chevron-left"></i> Back
                                </button>
                            </a>
                            {isEditing &&
                                <button type="submit" className="btn btn-primary btn-lg" disabled={true} >
                                    <span>Updating...</span>
                                    <span className="ml-3 spinner spinner-white"></span>
                                </button>
                            }

                            {!isEditing &&
                                <button type="submit" className="btn btn-primary btn-lg">
                                    <i className="fa fa-check"></i> <span>Update</span>
                                </button>
                            }
                        </div>
                    </div>
                </form>

            }
            {
                isLoading &&
                <LoadingSpinner text="Loading Category Data" />
            }

        </div>
    );
};

export default CategoryEdit;
