import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faPen } from '@fortawesome/free-solid-svg-icons';
// import { SiFacebook } from 'react-icons/si'
// import { AiFillGoogleCircle } from 'react-icons/ai'
import './Login.scss';
import * as site from '../../../../site.json';

const initialValues = {
	email: "",
	password: "",
};

const Login = withRouter(({ history, props }) => {
	let dispatch = useDispatch();
	const [isLogged, setIsLogged] = useState(false);
	const status = useSelector((state) => state.auth.status);
	const message = useSelector((state) => state.auth.message);
	const isLoading = useSelector((state) => state.auth.isLoading);

	useEffect(() => {
		if (status && message.length > 0) {
			history.push("/dashboard");
		}
	}, [status, message, dispatch, history]);

	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.max(50, "Maximum 50 symbols")
			.required("Please Give Username or Email Address"),
		password: Yup.string()
			.min(3, "Minimum 3 symbols")
			.max(50, "Maximum 50 symbols")
			.required("Please Give Password"),
	});

	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return "is-invalid";
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return "is-valid";
		}
		return "";
	};

	const formik = useFormik({
		initialValues,
		validationSchema: LoginSchema,
		onSubmit: async (values) => {
			dispatch(login(values.email, values.password));
		},
	});

	return (
		<div>
			<div className="auth-page__header">
				<h1 className="auth-page__heading"> Welcome To <span>Seller Center</span> </h1>
			</div>

			<div className="login-page row">
				<div className="login-page__body-left col-12 col-sm-7">
					<div>
						<div className="login-page__body-left_head">
							<h2>3 simple step to become <br /> successful seller on {site.websiteName}</h2>
						</div>
						<div className="login-page__body-left_steps">
							<div className="login-page__body-left_step">
								<div className="login-page__body-left_step-icon">
									<div>
										<FontAwesomeIcon icon={faPen} />
									</div>
								</div>
								<p>Sign Up & Create Own Store</p>
							</div>
							<div className="login-page__body-left_step">
								<div className="login-page__body-left_step-icon">
									<div>
										<FontAwesomeIcon icon={faBoxOpen} />
									</div>
								</div>
								<p>Upload Products To Start Selling</p>
							</div>
							<div className="login-page__body-left_step">
								<div className="login-page__body-left_step-icon">
									<div>
										<img src="/media/icon/dashboard.png" alt="" />
									</div>
								</div>
								<p>Adopt Tools To Maximize Sell</p>
							</div>
						</div>
					</div>
				</div>
				<div className="login-page__body-right col-12 col-sm-5">
					<div>
						<div className="login-page__box">
							<div className="login">
								<div className="login__title">Sign in</div>
								<div className="login__form">
									<form
										onSubmit={formik.handleSubmit}
										className="form fv-plugins-bootstrap fv-plugins-framework"
									>
										{formik.status && !isLogged ? (
											<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
												<div className="alert-text font-weight-bold">{formik.status}</div>
											</div>
										) : (
											""
										)}

										{formik.status && isLogged ? (
											<div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
												<div className="alert-text font-weight-bold">{formik.status}</div>
											</div>
										) : (
											""
										)}

										<div className="form-group fv-plugins-icon-container">
											<input
												placeholder="Email Or Username or Phone No"
												type="text"
												className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
													"email"
												)}`}
												name="email"
												{...formik.getFieldProps("email")}
											/>
											{formik.touched.email && formik.errors.email ? (
												<div className="fv-plugins-message-container">
													<div className="fv-help-block">{formik.errors.email}</div>
												</div>
											) : null}
										</div>
										<div className="form-group fv-plugins-icon-container">
											<input
												placeholder="Password"
												type="password"
												className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
													"password"
												)}`}
												name="password"
												{...formik.getFieldProps("password")}
											/>
											{formik.touched.password && formik.errors.password ? (
												<div className="fv-plugins-message-container">
													<div className="fv-help-block">{formik.errors.password}</div>
												</div>
											) : null}
										</div>
										<div className="login__action">
											<Link to="/auth/forget-password" >
												Forgot User Id / Password
											</Link>
											<button
												type="submit"
												disabled={isLoading}
												className="login__btn"
											>
												{isLoading ? <i className="fas fa-spinner fa-spin"></i> : ''}
												<span>{isLoading ? ' Signing in...' : 'Sign in'}</span>
											</button>
										</div>
									</form>
								</div>
								{/* <div className="login__option">
									<p>Sign in with</p>
									<div className="login__option-icons">
										<div className="login__option-icon">
											<SiFacebook />
										</div>
										<div className="login__option-icon">
											<AiFillGoogleCircle />
										</div>
									</div>
								</div> */}
								<div className="login__footer-polygon mt-2"></div>
								<div className="login__footer">
									<div>
										<div className="login__footer-content">
											<p>Don't have an account yet? <span>Become A Seller</span> </p>
											<button onClick={() => history.push('/auth/registration')} className="login__btn-create">
												CREATE AN ACCOUNT
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export default injectIntl(connect(null, auth.actions)(Login));