import React from 'react';
import TaxList from '../../components/tax/TaxList';


const TaxListContainer = () => {
  return (
    <>
      <TaxList />
    </>
  );
};

export default TaxListContainer;
