import React from 'react';
import { Link } from 'react-router-dom';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import OrderList from '../components/list/OrderList';

const OrderListContainer = () => {

  return (
    <>
      {/* <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">All Orders</h3>
            </div>
          </div>
          <OrderList />
        </div>
      </div> */}
      <PermissionWiseDisplay permission_name="Order.View" display={true} >
        <OrderList />
      </PermissionWiseDisplay>
    </>
  );
};

export default OrderListContainer;
