import * as Types from '../Types/Types';

const initialState = {
    businessList: [],
};

const BusinessListOptionReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_BUSINESS_LIST:
            return {
                ...state,
                businessList: getBusinessList(action.payload.data)
            };
        default:
            break;
    }
    return newState;
};

/**
 * getBusinessList
 *
 * @param array data
 * @return array printable business list
 */
const getBusinessList = (data) => {
    let businessList = [];
    if (typeof data !== 'undefined' && data.length > 0) {
        data.forEach((item) => {
            let items = {
                value: item.id,
                label: item.name,
            };
            businessList.push(items);
        });
    }
    return businessList;
}
export default BusinessListOptionReducer;
