import React from 'react';
import LocationAdd from '../../components/locations/LocationAdd';



const LocationAddContainer = () => {
  return (
    <>
      <LocationAdd />
    </>
  );
};

export default LocationAddContainer;
