import React from "react";
import { Route } from "react-router-dom";
import ItemListContainer from "../views/ItemListContainer";
import ItemAddContainer from "../views/ItemAddContainer";
import ItemEditContainer from "../views/ItemEditContainer";
import ItemDetailsContainer from "../views/ItemDetailsContainer";
import BulkItemAdd from "../components/BulkItemAdd";

const routesItems = [
  {
    path: "/item/list",
    name: "Basic Item Information Component",
    component: ItemListContainer,
    exact: true,
  },

  {
    path: "/item/add",
    name: "Basic Item Information Component",
    component: ItemAddContainer,
    exact: true,
  },
  {
    path: "/item/bulk-upload",
    name: "Basic Item Information Component",
    component: BulkItemAdd,
    exact: true,
  },
  {
    path: "/items/edit/:id",
    name: "Product Item Edit",
    component: ItemEditContainer,
    exact: true,
  },
  {
    path: "/items/details/:id",
    name: "Product Item Edit",
    component: ItemDetailsContainer,
    exact: true,
  },
];

function getItemRoutes() {
  return routesItems.map((route, index) => (
    <Route
      key={index}
      path={route.path}
      component={route.component}
      exact={route.exact}
    />
  ));
}
export default (getItemRoutes);
