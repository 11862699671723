import * as Types from '../types/Types';

const initialState = {
  isLoading: false,
  isSubmitting: false,
  pageList: [],
  pagePaginationList: [],
  formInput: {
    title: "",
    slug: "",
    description: "",
    meta_description: "",
    meta_keywords: "",
    image: null,
    imagePreviewUrl: null,
  },
  pageDetails: null,
};

const PageReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.GET_PAGE_LIST:
      if (action.payload.status) {
        return {
          ...state,
          pageList: action.payload.pageList,
          pagePaginationList: action.payload.pagePaginationList,
          formInput: initialState.formInput,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: true,
        };
      }
    case Types.CHANGE_PAGE_INPUT:
      const formInput = { ...state.formInput };
      formInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        formInput
      };
    case Types.ADD_PAGE:
      if (action.payload.status) {
        return {
          ...state,
          isSubmitting: action.payload.isLoading,
          formInput: initialState.formInput,
        };
      } else {
        return {
          ...state,
          isSubmitting: action.payload.isLoading,
        };
      }

    case Types.UPDATE_PAGE:
      if (action.payload.status) {
        return {
          ...state,
          isSubmitting: action.payload.isLoading,
          // formInput: initialState.formInput,
        };
      } else {
        return {
          ...state,
          isSubmitting: action.payload.isLoading,
        };
      }
    case Types.GET_PAGE_DETAILS:
      return {
        ...state,
        formInput: action.payload.pageDetails,
        pageDetails: action.payload.pageDetails,
        isLoading: action.payload.isLoading,
      };
    case Types.DELETE_PAGE_DATA:
      return {
        ...state,
        isLoading: action.payload,
      };
    case Types.EMPTY_PAGE_INPUT:
      return {
        ...state,
        formInput: {
          title: "",
          slug: "",
          description: "",
          meta_description: "",
          meta_keywords: "",
          image: null,
          imagePreviewUrl: null,
        },
      };
    default:
      break;
  }
  return newState;
};
export default PageReducer;
