import React from 'react';
import CategoriesAdd from '../../components/categories/CategoriesAdd';


const CategoriesAddContainer = () => {
  return (
    <>
      <CategoriesAdd />
    </>
  );
};

export default CategoriesAddContainer;
