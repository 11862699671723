import React from 'react';
import PermissionWiseDisplay from '../../../app/modules/role/PermissionWiseDisplay';
import ProductOfferCreate from '../components/create/ProductOfferCreate';

const ProductOfferEditContainer = () => {
    return (
        <>
            <PermissionWiseDisplay permission_name="Offer.Edit" display={true}>
                <ProductOfferCreate />
            </PermissionWiseDisplay>
        </>
    );
};

export default ProductOfferEditContainer;