export function getFeaturesPermissionData() {
    let rolePermissionData = localStorage.getItem('role-permissions') || {};
    return JSON.parse(rolePermissionData);
}

/**
 * 
 * @param {String} ex: permissionName="shop.edit" || permissionName="shop.edit,shop.own.edit" 
 * @returns {Boolean}
 */
  
export function checkFeaturePermission(permissionName) {
    let permissions = getFeaturesPermissionData() || '';

    const permissionNames = permissionName.toLowerCase().split(',') || [];

    const permissionNameIndex =  permissionNames.findIndex((permission) => permission === permissions[permission]);

    if(permissionNameIndex !== -1) {
        return true;
    } else {
        return false
    }
}