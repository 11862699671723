import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";

import getSupplierRoute from "../domains/suppliers/routes";

import getCategoryModuleRoutes from "../modules/category/routes";
import getItemRoutes from "../modules/item/routes";
import getBrandModuleRoutes from "../modules/brand/routes";
import getUnitRoutes from "../modules/units/routes";
import getOrderModuleRoutes from "../modules/orders/routes";
import getRolePermissionManagementRoutes from "../modules/role-permission-management/routes";
import InvoiceRoutes from "../modules/Invoices/routes";
import AttributeRoute from "../modules/attribute/routes";
import CouponRoutes from "../modules/coupon/routes";
import CustomerModuleRoutes from "../modules/customer/routes";
import GetSliderRoutes from "../modules/Slider/routes";
import GetProductOfferRoute from "../modules/ProductOffer/routes";
import getBusinessRoutes from "../modules/business/routes";
import getWebsiteRoute from "../modules/website-edit/routes";
import getFileManagerRoutes from "../modules/file-manager/routes";
import getPageModuleRoutes from "../modules/pages/routes";

const BasePage = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/auth/login" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        {/* <ContentRoute path="/my-page" component={MyPage} /> */}
        {/* <Route path="/procurement" component={procurementPages} /> */}

        {getSupplierRoute()}


        {/* Module Wise Routes */}
        {getItemRoutes()}
        {getCategoryModuleRoutes()}
        {getOrderModuleRoutes()}
        {getBrandModuleRoutes()}
        {getUnitRoutes()}
        {getRolePermissionManagementRoutes()}
        {InvoiceRoutes()}
        {AttributeRoute()}
        {CouponRoutes()}
        {CustomerModuleRoutes()}
        {GetSliderRoutes()}
        {GetProductOfferRoute()}
        {getBusinessRoutes()}
        {getWebsiteRoute()}
        {getPageModuleRoutes()}
        {getFileManagerRoutes()}

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
};

export default BasePage;
