import * as Types from '../Types/Types';

const initialState = {
    parentCategoryList: [],
};

const CategoryListData = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_CATEGORIES_LIST:
            return {
                ...state,
                CategoryListData: getCategory(action.payload.data)
            };
        default:
            break;
    }
    return newState;
};

/**
 * getParentCategory
 * 
 * @param array data 
 * @return array printable business list
 */
const getCategory = (data) => {
    let categoryList = [];
    if (typeof data !== 'undefined' && data.length > 0) {
        data.forEach((item) => {
            let items = {
                value: item.id,
                label: item.name,
            };
            categoryList.push(items);
        });
    }
    return categoryList;
}
export default CategoryListData;
