/**
 * Format Data For Select2 options
 *
 * @since 1.0.0
 *
 * @param array data arrays of the list to be processed
 * @param array pairs label-value pairs for the selected columns; eg: { 'value' : 'id', 'label' : 'name' }
 *
 * @return array formatted select2 Options
 */
export function formatSelect2Options(data, pairs) {

    const select2Options = [];

    if (typeof data !== 'undefined' && data.length > 0) {
        data.forEach(option => {
            select2Options.push({
                value: option[pairs['value']],
                label: option[pairs['label']],
            });
        });
    }

    return select2Options;
}

export const getSelectedOption = (options, value) => {
    if (typeof options !== 'undefined' && Array.isArray(options) && typeof value !== 'undefined' && value !== null && value !== '') {
        const optionValues = options.filter(option => option.value == value);

        if (optionValues.length > 0) {
            return optionValues[0];
        }

        return null;
    }
}

export const getSelect2OptionForStatus = () => {
    return [
      {
        label: 'Active',
        value: "1"
      },
      {
        label: 'Inactive',
        value: "0"
      }
    ];
}