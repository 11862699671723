import React from "react";
import PermissionWiseDisplay from "../../../app/modules/role/PermissionWiseDisplay";
import ItemList from "../components/ItemList";

const ItemListContainer = () => {
    return (
        <PermissionWiseDisplay permission_name={'product.view'} display={true}>
            <ItemList />
        </PermissionWiseDisplay>
    );
};

export default ItemListContainer;
