import React from 'react';
import PermissionWiseDisplay from '../../../../app/modules/role/PermissionWiseDisplay';
import CurrenciesAdd from '../../components/currencies/CurrenciesAdd';


const CurrenciesAddContainer = () => {
  return (
    <>
      <PermissionWiseDisplay permission_name="Currency.Create" display={true}>
        <CurrenciesAdd />
      </PermissionWiseDisplay>
    </>
  );
};

export default CurrenciesAddContainer;
