/*******************************************************************************************************
        Module: CCO
********************************************************************************************************/

/*******************************************************************************************************
        Sub Module: BUSINESS
********************************************************************************************************/
export const GET_BUSINESS_LIST = 'GET_BUSINESS_LIST';
export const ADD_BUSINESS = 'ADD_BUSINESS';
export const UPDATE_BUSINESS = 'UPDATE_BUSINESS';
export const EMPTY_BUSINESS_EDIT_MESSAGE = 'EMPTY_BUSINESS_EDIT_MESSAGE';

export const POST_VESSEL = 'POST_VESSEL';
export const EDIT_VESSEL = 'EDIT_VESSEL';
export const DELETE_VESSEL = 'DELETE_VESSEL';

export const EMPTY_VESSEL_ADD_MESSAGE = 'EMPTY_VESSEL_ADD_MESSAGE';
export const EMPTY_VESSEL_EDIT_MESSAGE = 'EMPTY_VESSEL_EDIT_MESSAGE';
export const EMPTY_VESSEL_DELETE_MESSAGE = 'EMPTY_VESSEL_DELETE_MESSAGE';

/*******************************************************************************************************
        Sub Module:Locations
********************************************************************************************************/
export const GET_LOCATIONS_LIST = 'GET_LOCATIONS_LIST';
export const ADD_LOCATIONS = 'ADD_LOCATIONS';
export const UPDATE_LOCATIONS = 'UPDATE_LOCATIONS';
export const EMPTY_LOCATIONS_EDIT_MESSAGE = 'EMPTY_LOCATIONS_EDIT_MESSAGE';
export const EMPTY_LOCATION_ADD_MESSAGE = 'EMPTY_LOCATION_ADD_MESSAGE';

/*******************************************************************************************************
        Sub Module:Tax
********************************************************************************************************/
export const GET_TAX_LIST = 'GET_TAX_LIST';
export const ADD_TAX = 'ADD_TAX';
export const UPDATE_TAX = 'UPDATE_TAX';
export const DELETE_TAX = 'DELETE_TAX';
export const EMPTY_TAX_ADD_MESSAGE = 'EMPTY_TAX_ADD_MESSAGE';
export const EMPTY_TAX_EDIT_MESSAGE = 'EMPTY_TAX_EDIT_MESSAGE';


/*******************************************************************************************************
        Sub Module: Country
********************************************************************************************************/
export const GET_CURRENCIES_LIST = 'GET_CURRENCIES_LIST';
export const ADD_CURRENCIES = 'ADD_CURRENCIES';
export const UPDATE_CURRENCIES = 'UPDATE_CURRENCIES';
export const EMPTY_CURRENCIES_EDIT_MESSAGE = 'EMPTY_CURRENCIES_EDIT_MESSAGE';

/*******************************************************************************************************
        Sub Module: GIFT CARD
********************************************************************************************************/
export const GET_GIFT_CARD_LIST = 'GET_GIFT_CARD_LIST';
export const ADD_GIFT_CARD = 'ADD_GIFT_CARD';
export const UPDATE_GIFT_CARD = 'UPDATE_GIFT_CARD';
export const DELETE_GIFT_CARD = 'DELETE_GIFT_CARD';
export const EMPTY_GIFT_CARD_ADD_MESSAGE = 'EMPTY_GIFT_CARD_ADD_MESSAGE';
export const EMPTY_GIFT_CARD_EDIT_MESSAGE = 'EMPTY_GIFT_CARD_EDIT_MESSAGE';


/*******************************************************************************************************
        Sub Module: Brands
********************************************************************************************************/


/*******************************************************************************************************
        Sub Module: CATEGORIES
********************************************************************************************************/
export const GET_CATEGORIES_LIST = 'GET_CATEGORIES_LIST';
export const ADD_CATEGORIES = 'ADD_CATEGORIES';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const EMPTY_CATEGORIES_EDIT_MESSAGE = 'EMPTY_CATEGORIES_EDIT_MESSAGE';


/*******************************************************************************************************
        Sub Module: CATEGORIES
********************************************************************************************************/
export const GET_UNITS_LIST = 'GET_UNITS_LIST';
export const ADD_UNITS = 'ADD_UNITS';
export const UPDATE_UNITS = 'UPDATE_UNITS';
export const DELETE_UNITS = 'DELETE_UNITS';
export const EMPTY_UNITS_ADD_MESSAGE = 'EMPTY_UNITS_ADD_MESSAGE';
export const EMPTY_UNITS_EDIT_MESSAGE = 'EMPTY_UNITS_EDIT_MESSAGE';
