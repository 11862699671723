import * as Types from "../types/Types";
import axios from "axios";
import { showToast } from "../../../../../../modules/master/utils/ToastHelper";

export const handleCurrencyInputChange = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  }
  dispatch({ type: Types.CURRENCY_INPUT_CHANGE, payload: formData });
}

// store currencies 
export const storeCurrency = (currencyData) => (dispatch => {
  let responseData = {
    status: false,
    message: "",
    isLoading: true,
  };
  dispatch({ type: Types.STORE_CURRENCIES, payload: responseData });
  const headers = {
    "Content-Type": "application/json",
  };

  axios.post(`${process.env.REACT_APP_API_URL}currencies`, currencyData, {
    headers: headers,
  }).then((res) => {
    if (res.data.status) {
      let data = res.data;
      responseData.message = data.message;
      responseData.status = data.status;
      responseData.isLoading = false;
      showToast('success', responseData.message);
      dispatch({ type: Types.STORE_CURRENCIES, payload: responseData });
    }
  }).catch((err) => {
    const { response } = err;
    const { request, ...errorObject } = response;
    responseData.isLoading = false;
    showToast('error', response.data.message);
    dispatch({ type: Types.STORE_CURRENCIES, payload: responseData });
  });
})

//get currencies list 
export const GetCurrenciesList = (page, searchText = null) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  dispatch({ type: Types.GET_CURRENCIES_LIST, payload: responseList });
  let currencyListURL = "";
  currencyListURL = `${process.env.REACT_APP_API_URL}currencies?isPaginated=1&paginateNo=10`;
  if (page !== null || page === "") {
    currencyListURL += `&page=${page}`;
  }
  if (searchText !== null) {
    currencyListURL += `&search=${searchText}&isActive=1`;
  }

  try {
    await axios.get(currencyListURL)
      .then((res) => {
        if (res.data.status) {
          const { data, message, status } = res.data;
          responseList.status = status;
          responseList.currencyList = data.data;
          responseList.message = message;
          responseList.currencyPaginatedList = data;
          responseList.isLoading = false;
          dispatch(emptyDispatch())
          dispatch({ type: Types.GET_CURRENCIES_LIST, payload: responseList });
        }

      }).catch((err) => {
        responseList.isLoading = false;
        dispatch({ type: Types.GET_CURRENCIES_LIST, payload: responseList });
      });
  } catch (error) {
    responseList.message = "Something Went Wrong !";
    showToast('error', responseList.message);
  }
  responseList.isLoading = false;
  dispatch({ type: Types.GET_CURRENCIES_LIST, payload: responseList });
};

//delete currency 

export const deleteCurrency = (id) => (dispatch) => {
  let isLoading = true;
  dispatch({ type: Types.DELETE_CURRENCY, payload: isLoading })
  axios.delete(`${process.env.REACT_APP_API_URL}currencies/${id}`)
    .then((res) => {
      if (res.data.status) {
        const data = res.data;
        showToast('success', data.message);
        dispatch({ type: Types.DELETE_CURRENCY, payload: false });
        dispatch(GetCurrenciesList())
      }
    })
}

//get currency details 
export const getCurrencyDetails = (id) => async (dispatch) => {
  let response = {
    status: false,
    message: "",
    isLoading: true,
    currencyDetails: {}
  };
  dispatch({ type: Types.GET_CURRENCY_DETAILS, payload: response });

  await axios.get(`${process.env.REACT_APP_API_URL}currencies/${id}`)
    .then((res) => {
      if (res.data.status) {
        const { data, message, status } = res.data;
        if (data.country !== null && data.country !== "") {
          data.countryData = {
            label: data.country_name,
            value: data.country,
          }
        } else {
          data.countryData = ''
        }

        response.status = status;
        response.currencyDetails = data;
        response.message = message;
        response.isLoading = false;
        dispatch({ type: Types.GET_CURRENCY_DETAILS, payload: response });
      }
    }).catch((err) => {
      response.isLoading = false;
      dispatch({ type: Types.GET_CURRENCY_DETAILS, payload: response });
    });
}

//currency updated
export const currencyUpdated = (currencyData, id) => (dispatch => {
  let responseData = {
    status: false,
    message: "",
    isLoading: true,
  };
  dispatch({ type: Types.UPDATE_CURRENCIES, payload: responseData });
  const headers = {
    "Content-Type": "application/json",
  };

  axios.post(`${process.env.REACT_APP_API_URL}currencies/${id}?_method=PUT`, currencyData, {
    headers: headers,
  }).then((res) => {
    if (res.data.status) {
      let data = res.data;
      responseData.message = data.message;
      responseData.status = data.status;
      responseData.isLoading = false;
      showToast('success', responseData.message);
      dispatch({ type: Types.UPDATE_CURRENCIES, payload: responseData });
    }
  }).catch((err) => {
    const { response } = err;
    const { request, ...errorObject } = response;
    responseData.isLoading = false;
    showToast('error', response.data.message);
    dispatch({ type: Types.UPDATE_CURRENCIES, payload: responseData });
  });
})

export const emptyDispatch = () => (dispatch) => {
  dispatch({ type: Types.EMPTY_DISPATCH, payload: true });
}