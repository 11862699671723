import React from 'react';
import { Route } from 'react-router-dom';
import ProductOfferCreateContainer from '../views/ProductOfferCreateContainer';
import ProductOfferEditContainer from '../views/ProductOfferEditContainer';
import ProductOfferListContainer from '../views/ProductOfferListContainer';

const OfferProductRoute = [
  {
    path: '/offer-product/create',
    name: 'Offer Product Create',
    component: ProductOfferCreateContainer,
    exact: true,
  },
  {
    path: '/offer-product/list',
    name: 'Offer Product List',
    component: ProductOfferListContainer,
    exact: true,
  },
  {
    path: '/offer-product/edit/:id',
    name: 'Offer Product Edit',
    component: ProductOfferEditContainer,
    exact: true,
  }
];

function GetProductOfferRoute() {
  {
    return OfferProductRoute.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default GetProductOfferRoute;
