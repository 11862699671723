const AuthService = (function() {
  var _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _getBusinessId() {
    return localStorageUserData("business_id");
  }

  function _getUserId() {
    return localStorageUserData("id");
  }

  return {
    getService: _getService,
    getBusinessId: _getBusinessId,
    getUserId: _getUserId,
  };
})();

export const localStorageUserData = (prop = null) => {
  let data = localStorage.getItem("userData");
  if (typeof data !== "undefined" && data !== null) {
    data = JSON.parse(data);
  }

  if (prop !== null) {
    if (typeof data[prop] !== "undefined") {
      return data[prop];
    }
  }

  return data;
};

/**
 * Get User ID
 *
 * @return int Get User ID
 */
export const getUserID = () => {
  return localStorageUserData("id");
};

/**
 * Get Seller ID
 *
 * @return int Get Seller ID
 */
export const getSellerID = () => {
  return localStorageUserData("business_id");
};

export default AuthService;
