import * as Types from '../../types/Types';

const initialState = {
  LocationsList: [],

  isLoading: false,
  addMessage: "",
  editMessage: "",
  deleteMessage: "",

  addStatus: false,
  editStatus: false,
  deleteStatus: false,
};

const LocationsReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.GET_LOCATIONS_LIST:
      return {
        ...state,
        LocationsList: action.payload.data,
      };
    case Types.ADD_LOCATIONS:
      return {
        ...state,
        addMessage: action.payload.message,
        addStatus: action.payload.status,
        isLoading: action.payload.isLoading
      };
    case Types.EMPTY_LOCATIONS_EDIT_MESSAGE:
      return {
        ...state,
        addStatus: "",
        addMessage: "",
      };
    case Types.EMPTY_LOCATIONS_EDIT_MESSAGE:
      return {
        ...state,
        editStatus: "",
        editMessage: "",
      };
    case Types.UPDATE_LOCATIONS:
      return {
        ...state,
        editStatus: action.payload.status,
        editMessage: action.payload.message,
        isLoading: action.payload.isLoading
      };

    default:
      break;
  }
  return newState;
};
export default LocationsReducer;
