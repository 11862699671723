import * as Types from "../../types/Types";
import axios from "axios";

export const GetBusinessList = () => (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  axios
    .get(`${process.env.REACT_APP_API_URL}business`, {
      headers: headers,
    })
    .then((res) => {
      let data = res.data;
      dispatch({ type: Types.GET_BUSINESS_LIST, payload: data });
    });
};

export const AddBusiness = (state) => (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };

  let responseData = {
    status: false,
    message: "",
    isLoading: true,
  };
  dispatch({ type: Types.ADD_BUSINESS, payload: responseData });

  const {
    bin,
    businessname,
    tax_label_1,
    tax_label_2,
    tax_number_1,
    tax_number_2,
  } = state;

  let postData = {
    name: businessname,
    bin: bin,
    start_date: "2020-01-01",
    tax_label_1: tax_label_1,
    tax_label_2: tax_label_2,
    tax_number_1: tax_number_1,
    tax_number_2: tax_number_2,
    default_profit_percent: 0,
    owner_id: 1,
    time_zone: "string",
    fy_start_month: 1,
    default_sales_discount: 10,
    accounting_method: "FIFO",
    sell_price_tax: "includes",
    currency_id: state.currencyData.value,
    logo: "string",
    sku_prefix: "string",
    enable_tooltip: true,
  };

  axios
    .post(`${process.env.REACT_APP_API_URL}business`, postData, {
      headers: headers,
    })
    .then((res) => {
      let data = res.data;
      responseData.message = data.message;
      responseData.status = data.status;
      responseData.isLoading = false;
      dispatch({ type: Types.ADD_BUSINESS, payload: responseData });
    })
    .catch((err) => {
      responseData.isLoading = false;
      dispatch({ type: Types.ADD_BUSINESS, payload: responseData });
    });
};

export const BusinessEmptyEditMessage = () => (dispatch) => {
  dispatch({ type: Types.EMPTY_BUSINESS_EDIT_MESSAGE, payload: true });
};

export const UpdateBusiness = (BusinessData) => (dispatch) => {
  let responseData = {
    status: false,
    message: "",
    isLoading: true,
  };
  dispatch({ type: Types.UPDATE_BUSINESS, payload: responseData });
  const {
    bin,
    businessname,
    tax_label_1,
    tax_label_2,
    tax_number_1,
    tax_number_2,
  } = BusinessData;
  let updateData = {
    name: businessname,
    bin: bin,
    start_date: " 2020-01-01",
    tax_label_1: tax_label_1,
    tax_label_2: tax_label_2,
    tax_number_1: tax_number_1,
    tax_number_2: tax_number_2,
    default_profit_percent: 0,
    owner_id: 1,
    time_zone: "string",
    fy_start_month: 1,
    default_sales_discount: 10,
    accounting_method: "FIFO",
    sell_price_tax: "includes",
    currency_id: BusinessData.currencyDataSet.value,
    logo: "string",
    sku_prefix: "string",
    enable_tooltip: true,
  };

  axios
    .put(
      `${process.env.REACT_APP_API_URL}business/${BusinessData.businessId}`,
      updateData
    )
    .then(res => {
      responseData.message = res.data.message;
      responseData.isLoading = false;
      responseData.status = res.data.status;
      dispatch({ type: Types.UPDATE_BUSINESS, payload: responseData });
    })
    .catch(err => {
      // responseData.message = res.data.message;
      responseData.isLoading = false;
      dispatch({ type: Types.UPDATE_BUSINESS, payload: responseData });
    });
};
