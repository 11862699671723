import * as Types from "../types/Types";
import http from "../../../../services/http";
import { showToast } from "../../../master/utils/ToastHelper";

import { getItemOptionList } from "../../../master/OptionData/ItemOptionList/ItemOptionListAction/ItemOptionListAction";
import { getBrandListOptonData } from "../../../master/OptionData/BrandOptionList/BrandOptionListAction/BrandOptionListAction";
import { GetCategoriesSortedList } from "../../../category/_redux/actions/CategoriesAction";
import { GetBusinessList } from "../../../master/OptionData/BusinessList/BusinessListAction/BusinessOptionListAction";
import { generateFormDataFromObject } from "../../../master/utils/FileHelper";
/**
 * Handle Input changes of offer items.
 *
 * @param string name
 * @param string value
 * @param object event
 * @return void
 */
export const handleOfferInputChange = (name, value, e = null) => (dispatch) => {
  const formData = { name: name, value: value, e };

  // Modify if name is `item`
  if (name === "item") {
    formData.name = "current_price";
    formData.value = value.price;
  }

  dispatch({ type: Types.CHANGE_OFFER_INPUT, payload: formData });

  // Do other dispaches based on selection type
  if (name === "selection_type") {
    switch (value) {
      case "item":
        showToast('info', 'Loading items..');
        dispatch(getItemOptionList());
        break;
      case "brand":
        showToast('info', 'Loading brands..');
        dispatch(getBrandListOptonData());
        break;
      case "category":
        showToast('info', 'Loading categories..');
        dispatch(GetCategoriesSortedList());
        break;
      case "shop":
        showToast('info', 'Loading shops..');
        dispatch(GetBusinessList());
        break;
      default:
        break;
    }
  }
};

/**
 * Handle Store Functionality.
 *
 * @since 1.0.0
 *
 * @param object productOfferData
 * @param int id
 *
 * @return void Dispatch an action
 */
export const handleStoreOffer = (productOfferData, id = null) => (dispatch) => {
  let responseData = {
    status: false,
    message: "",
    isLoading: true,
    isOfferUpdated: false,
  };

  dispatch({ type: Types.STORE_PRODUCT_OFFER, payload: responseData });

  if (typeof id !== 'undefined' && id !== null){
    productOfferData.id = id;
  }

  http.post(`offers`, generateFormDataFromObject(productOfferData))
    .then((res) => {
      if (res.data.status) {
        let data = res.data;
        responseData.message = data.message;
        responseData.status = data.status;
        responseData.isLoading = false;
        responseData.isOfferUpdated = true;
        showToast("success", responseData.message);
        dispatch({ type: Types.STORE_PRODUCT_OFFER, payload: responseData });
        dispatch(emptyDispatch())
      }
    })
    .catch((err) => {
      const { response } = err;
      const { request, ...errorObject } = response;
      responseData.isLoading = false;
      showToast("error", response.data.message);
      dispatch({ type: Types.STORE_PRODUCT_OFFER, payload: responseData });
    });
};

/**
 * Handle Offer Status change.
 *
 * @param int id
 *
 * @return void Dispatch an action
 */
export const toggleOfferStatus = (id, activate = true) => (dispatch) => {
  let responseData = {
    status: false,
    message: "",
    isStatusChanging: true,
  };

  dispatch({ type: Types.CHANGE_OFFER_STATUS, payload: responseData });

  http.post(`offers/toggle-status/${id}`, {
    activate
  })
    .then((res) => {
      if (res.data.status) {
        let data = res.data;
        responseData.message = data.message;
        responseData.status = data.status;
        responseData.isStatusChanging = false;
        showToast("success", responseData.message);
        dispatch({ type: Types.CHANGE_OFFER_STATUS, payload: responseData });
        dispatch(getOfferList(1))
        dispatch(emptyDispatch())
      }
    })
    .catch((err) => {
      const { response } = err;
      const { request, ...errorObject } = response;
      responseData.isStatusChanging = false;
      showToast("error", response.data.message);
      dispatch({ type: Types.CHANGE_OFFER_STATUS, payload: responseData });
    });
};

/**
 * Get Offers Data.
 *
 * @param {int} page
 * @param {string} searchText
 * @param {string} couponType
 * @param {string} amountType
 *
 * @returns void
 */
export const getOfferList = (
  page,
  searchText = null,
  couponType = null,
  amountType = null
) => async (dispatch) => {
  let response = {
    offerList: [],
    status: false,
    message: "",
    isLoading: true,
    errors: [],
  };
  dispatch({ type: Types.GET_OFFER_LIST, payload: response });
  let url = `offers?isPaginated=1&paginateNo=10`;

  if (page !== null || page === "") {
    url += `&page=${page}`;
  }

  if (searchText !== null) {
    url += `&search=${searchText}`;
  } else {
    // url += `&certificate/details?search=${searchText}`
  }
  if (couponType !== null) {
    url += `&coupon_type_id=${couponType}`;
  }
  if (amountType !== null) {
    url += `&amount_type=${amountType}`;
  }

  try {
    await http.get(url)
      .then((res) => {
        const { data, message, status } = res.data;
        response.status = status;
        response.offerList = data.data;
        response.message = message;
        response.offerPaginationList = data;
        response.isLoading = false;
        dispatch(emptyDispatch())
      })
      .catch((err) => {
        showToast("error", err);
      });
  } catch (error) {
    response.message = "Something Went Wrong !";
  }

  response.isLoading = false;
  dispatch({ type: Types.GET_OFFER_LIST, payload: response });
};

/**
 * Get Offer Details.
 *
 * @param {int} id
 * @returns void
 */
export const getOfferDetails = (id) => (dispatch) => {
  let response = {
    status: false,
    message: "",
    isLoading: true,
    itemDetails: {},
  };

  dispatch({ type: Types.GET_OFFER_DETAILS, payload: response });

  http.get(`offers/${id}`)
    .then((res) => {
      if (res.data.status) {
        const { data, message, status } = res.data;
        dispatch(handleOfferInputChange("selection_type", data.selection_type));

        response.status = status;
        data.apply_only_no_offer = "0";
        response.itemDetails = data;
        response.message = message;
        response.isLoading = false;
        dispatch({ type: Types.GET_OFFER_DETAILS, payload: response });
      }
    })
    .catch((err) => {
      response.isLoading = false;
      dispatch({ type: Types.GET_OFFER_DETAILS, payload: response });
    });
};

/**
 * Delete Offer.
 *
 * @param {int} id
 * @returns void
 */
export const deleteOffer = (id) => (dispatch) => {
  dispatch({ type: Types.DELETE_OFFER, payload: true });
  http.delete(`offers/${id}`).then((res) => {
    if (res.data.status) {
      const data = res.data;
      showToast("success", data.message);
      dispatch({ type: Types.DELETE_OFFER, payload: false });
      dispatch(getOfferList(1));
    }
  });
};

/**
 * Get Offer Type List
 *
 * @since 1.0.0
 *
 * @returns void Dispatch offer types event
 */
export const getOfferTypes = () => (dispatch) => {
  http.get('offer-types').then(res => {
    dispatch({ type: Types.GET_OFFER_TYPE_LIST, payload: res.data.data });
  });
};


export const emptyDispatch = () => (dispatch) => {
  dispatch({ type: Types.EMPTY_DISPATCH, payload: true });
}